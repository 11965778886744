import axios from "axios";
import { AgGridReact } from "ag-grid-react";
import { Row, Spinner } from "react-bootstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { AC_LIST_HOTEL } from "../../actions/hotelAction";
import {
  AC_LIST_TODO_LIST_ITEM,
  AC_VIEW_TODO_LIST_ITEM,
  AC_EMPTY_LIST_TODO_ITEM,
} from "../../actions/todolistAction";
import jsPDF from "jspdf";
import Swal from "sweetalert2";
import Select from "react-select";
import ImportedURL from "../../common/api";
import { Success, Error } from "../../common/swal";
import {
  Textareavalidation,
  TimeFormat,
  imageCellRendererList,
  onErrorImage,
} from "../../common/validate";
import showSwalDialog from "../../utilities/Modal/swal";
import ErrorResponse from "../../utilities/Error/errorstatus";
import { AC_ADD_TODO_APIS } from "../../actions/todolistAction";

const initialState = {
  redirect: false,
  columnDefs: [
    {
      headerName: "Logo",
      field: "logo",
      hide: true,
      sortable: false,
      filter: false,
      resizable: false,
      width: 100,
      cellStyle: { "text-align": "center" },
      cellRenderer: imageCellRendererList,
    },
    {
      headerName: "Hotel",
      field: "hotelName",
      hide: true,
      width: 200,
      cellStyle: { "text-align": "left" },
    },
    {
      headerName: "User Category",
      field: "categoryName",
      width: 200,
      cellStyle: { "text-align": "left" },
    },
    { headerName: "Title", field: "title", width: 200 },
    { headerName: "Todo", field: "todo", width: 200 },
    //  { headerName: 'Status', field: 'activeStatus', width: 200 },

    {
      headerName: "Status",
      field: "status",
      width: 170,
      cellStyle: { "text-align": "center" },
      headerClass: "ag-center-header",
      suppressMenu: true,
      cellRenderer: function (params) {
        if (params.data && params.data.status == true) {
          return `<span type="button" class="tag tag-green" data-action-type="Status">Active</span>`;
        } else {
          return '<span type="button" class="tag tag-danger" data-action-type="Status">Inactive</span>';
        }
      },
    },
    {
      headerName: "Actions",
      width: 200,
      field: "actions",
      cellStyle: { "text-align": "center" },
      headerClass: "ag-center-header",
      suppressMenu: true,
      sortable: false,
      filter: false,
      cellRenderer: function (params) {
        return params.value;
      },
    },
  ],
  defaultColumDef: {
    editable: false,
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    minWidth: 100,
    searchhotel: "",
    searchhoteloption: "",
    hotelName: "",
  },
  searchhoteloption: { label: "All", value: "", name: "searchhotel" },
  editSpi: false,
  perPage: 25,
  columnreset: true,
  todoitemdata: {},
  listRedirect: false,
  dailyStatus: {
    isMandatory: false,
  },
  weeklyStatus: {
    isMandatory: false,
  },
  monthlyStatus: {
    isMandatory: false,
  },
  quarterlyStatus: {
    isMandatory: false,
  },
  halfyearlyStatus: {
    isMandatory: false,
  },
  yearlyStatus: {
    isMandatory: false,
  },
  AdhocStatus: {
    isMandatory: false,
  },
  activeStatus: {
    isMandatory: true,
  },
  description: "",
  priorityValue: { label: "Select...", value: "", name: "priority" },
  errors: {},
  storinginitialState: {}, // To store the initial state
  duration: null,
};
class NewListTodoList extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    const account = this.props.AccountState.account;
    const { params, path } = this.props.match;
    this.apiCall();
    this.setState({ hotelName: account.hotelName ? account.hotelName : "" });
    this.props.emptytodolist();
    this.props.listtodolistitem();
    this.setState({
      storinginitialState: {
        priorityValue: this.state.priorityValue,
        description: this.state.description,
        activeStatus: { ...this.state.activeStatus },
        todoitemdata: { ...this.state.todoitemdata },
      },
    });
  }
  apiCall = (query = {}) => {
    let formData = { parentslug: ["todo-duration"], ...query };
    this.props.AddTodoApis(formData);
  };

  onBtnExport = () => {
    this.gridApi.exportDataAsCsv();
  };

  exportToCSV = () => {
    const account = this.props.AccountState.account;
    this.gridApi.exportDataAsCsv({
      columnKeys: ImportedURL.SUPERADMIN.includes(account.role)
        ? this.state.hotelName && this.state.hotelName !== "All"
          ? ["categoryName", "title", "todo", "status"]
          : ["hotelName", "categoryName", "title", "todo", "status"]
        : ["categoryName", "title", "todo", "status"],

      fileName:
        "TodoItemList" +
        "_" +
        (this.state.hotelName && this.state.hotelName.replace(/ /g, "_")) +
        account.currentTime.replace(/ /g, "_") +
        ".csv",

      processCellCallback: function (params) {
        // Check if the column is 'status', and replace 1/0 with 'Active'/'In Active'
        if (params.column.colId === "status") {
          return params.value === 1
            ? "Active"
            : params.value === 0
            ? "In Active"
            : params.value;
        }
        // Return the value for other columns without modification
        return params.value;
      },
    });
  };

  exportPDF = () => {
    const languageData = this.props.AccountState.account.SelectedlanguageData;
    const account = this.props.AccountState.account;
    const api = this.gridApi;
    const filteredRows = api.getModel().rowsToDisplay;
    const exportData = filteredRows.map((rowNode) => rowNode.data);

    const selectedProperties =
      this.state.hotelName && this.state.hotelName !== "All"
        ? ["categoryName", "itemvalue", "title", "todo", "status"]
        : ["hotelName", "categoryName", "itemvalue", "title", "todo", "status"];

    const selected = exportData.map((item, i) => {
      const selectedData = {};
      selectedProperties.forEach((property) => {
        if (item.hasOwnProperty(property)) {
          if (property === "status") {
            // Handle the status field: 1 => 'Active', 0/others => 'InActive'
            selectedData[property] =
              item[property] === 1 ? "Active" : "InActive";
          } else {
            selectedData[property] = item[property] ? item[property] : "---";
          }
        }
      });
      return selectedData;
    });

    let data = {
      title: this.state.hotelName
        ? this.state.hotelName !== "All"
          ? this.state.hotelName + " - Todo Item List"
          : "Todo Item List"
        : "Todo Item List",
      head:
        this.state.hotelName && this.state.hotelName !== "All"
          ? ["#", "Category Name", "Title", "Todo Shortcode", "Status"]
          : [
              "#",
              "Hotel Name",
              "Category Name",
              "Title",
              "Todo Shortcode",
              "Status",
            ],
      body: selected,
      hotelNameExport:
        this.state.hotelName && this.state.hotelName !== "All"
          ? account.hotelNameExport
          : "",
      hoteladdressExport:
        this.state.hotelName && this.state.hotelName !== "All"
          ? account.hoteladdressExport
          : "",
      limit: this.state.perPage,
    };

    this.setState({ spinner: true });

    axios
      .post(ImportedURL.API.downloadPdf, data, { responseType: "blob" })
      .then((res) => {
        const blob = new Blob([res.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download =
          "TodoItemList" +
          "_" +
          (this.state.hotelName && this.state.hotelName !== "All"
            ? this.state.hotelName.replace(/ /g, "_")
            : "") +
          account.currentTime.replace(/ /g, "_") +
          ".pdf";
        link.click();
        link.remove();
        window.URL.revokeObjectURL(link.href);
        this.setState({ spinner: false });
      })
      .catch(({ err }) => {
        this.setState({ spinner: false });
      });
  };

  onRowClicked = (event) => {
    const rowValue = event.event.target;
    const value = rowValue.getAttribute("data-action-type");
    const languageData = this.props.AccountState.account.SelectedlanguageData;
    if (value === "View") {
      this.setState({ id: event.data._id, todoitemdata: event.data }, () => {});
    }
    if (value === "Edit") {
      // this.setState({ modalType: "Edit", id: event.data._id });
      // this.props.history.push('/edit-todo-listitem/' + event.data.category + '/' + event.data.hotel)
      // if (this.state.modalType === "Edit") return <Redirect to={'edit-to-do-list-item/' + this.state.id} />
      this.setState({ id: event.data._id, todoitemdata: event.data }, () => {});
    }
    // if (value === 'Delete') {
    //     showSwalDialog('Are you sure you want to delete?', '', 'Ok', 'Cancel', 'assets/images/delete.png').then((result) => {
    //         if (result.isConfirmed) {
    //             this.setState({ searchhoteloption: '', searchhotel: '' })
    //             axios.get(ImportedURL.API.softDeletetodolistitem + "/" + event.data._id)
    //                 .then((res) => {
    //                     Success((languageData && languageData.TODO_ITEM_LIST ? languageData.TODO_ITEM_LIST : "Todo Item List") + (languageData && languageData.DELETED_SUCCESSFULLY ? languageData.DELETED_SUCCESSFULLY : " Deleted successfully"));
    //                     this.props.listtodolistitem();
    //                 }).catch(({ response }) => {
    //                     ErrorResponse(response, languageData, "todo items")
    //                 });
    //         }
    //     })
    // }

    // if (value === 'Status') {
    //   axios.post(ImportedURL.API.todoitemstatusChange, { id: event.data._id, status: !event.data.status, model: 'newtodoitems' })
    //     .then((data) => {
    //       let response = data.data;
    //       let records = response.data;
    //       let relatedRecordsCount = records.length; // Number of related users
    //       let relatedIds = records.map(item => item._id); // Collect related record IDs
    // console.log("response",response);
    //       // Build the HTML table for Swal
    //       let tableHtml = `
    //         <table border="1" cellpadding="10" style="border-collapse: collapse; width: 100%;">
    //           <thead>
    //             <tr><th>Assigned User</th><th>Created By</th><th>Todo-Shortcode</th></tr>
    //           </thead>
    //           <tbody>
    //             ${records.map(item => `
    //               <tr>
    //                 <td>${item.usersNameCategory}</td>
    //                 <td>${item.createdbyName}</td>
    //                 <td>${item.newtodoitems[0].name}</td>
    //               </tr>`).join('')}
    //           </tbody>
    //         </table><p>Are you sure you want to change the Status?</p>`;

    //       // Show Swal Dialog with table
    //       Swal.fire({
    //         title: `There are ${relatedRecordsCount} related records.`,
    //         html: tableHtml,  // Pass the HTML string here
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonText: 'Ok',
    //         cancelButtonText: 'Cancel'
    //       }).then((result) => {
    //         if (result.isConfirmed) {
    //           let id = event.data._id;
    //           let status = !event.data.status;
    //           let model = 'newtodoitems';
    //           this.changeUserStatus(id, status, model, relatedIds);

    //           this.props.listtodolistitem();
    //            Success((languageData && languageData.STATUS ? languageData.STATUS + " " : "Status") +
    //                    (languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : " Updated Successfully"));
    //         } else {
    //           Error('Action cancelled.');
    //         }
    //       });
    //     }
    //   ).catch(({ response }) => {
    //       ErrorResponse(response, languageData);
    //     });
    // }

    //   if (value === 'Status') {
    //     axios.post(ImportedURL.API.todoitemstatusChange, { id: event.data._id, status: !event.data.status, model: 'newtodoitems' })
    //       .then((data) => {
    //         let response = data.data;
    //         let records = response.data;
    //         let relatedRecordsCount = records.length; // Number of related users
    //         let relatedIds = records.map(item => item._id); // Collect related record IDs

    //         console.log("response", response);

    //         // Check if there are related records to show
    //         if (relatedRecordsCount > 0) {
    //             let tableHtml = `
    //               <table border="1" cellpadding="10" style="border-collapse: collapse; width: 100%;">
    //                 <thead>
    //                   <tr><th>Assigned User</th><th>Created By</th><th>Todo-Shortcode</th></tr>
    //                 </thead>
    //                 <tbody>
    //                   ${records.map(item => `
    //                     <tr>
    //                       <td>${item.usersNameCategory}</td>
    //                       <td>${item.createdbyName}</td>
    //                       <td>${item.newtodoitems[0].name}</td>
    //                     </tr>`).join('')}
    //                 </tbody>
    //               </table><p>Are you sure you want to change the Status?</p>`;

    //             // Show Swal dialog with table
    //             Swal.fire({
    //               title: `There are ${relatedRecordsCount} related records.`,
    //               html: tableHtml,
    //               icon: 'warning',
    //               showCancelButton: true,
    //               confirmButtonText: 'Ok',
    //               cancelButtonText: 'Cancel'
    //             }).then((result) => {
    //               if (result.isConfirmed) {
    //                 let id = event.data._id;
    //                 let status = !event.data.status;
    //                 let model = 'newtodoitems';
    //                 this.changeUserStatus(id, status, model, relatedIds);

    //                 this.props.listtodolistitem();
    //                 Success(`${languageData.STATUS} ${languageData.UPDATED_SUCCESSFULLY}`);
    //               } else {
    //                 Error('Action cancelled.');
    //               }
    //             });
    //         } else {
    //             // If no related records, just confirm the status change
    //             Swal.fire({
    //               title: 'No related records found.',
    //               text: 'Are you sure you want to change the status?',
    //               icon: 'warning',
    //               showCancelButton: true,
    //               confirmButtonText: 'Ok',
    //               cancelButtonText: 'Cancel'
    //             }).then((result) => {
    //               if (result.isConfirmed) {
    //                 let id = event.data._id;

    //                 let status = !event.data.status;
    //                 let model = 'newtodoitems';
    //                 this.changeUserStatus(id, status, model);

    //                 this.props.listtodolistitem();
    //                 Success(`${languageData.STATUS} ${languageData.UPDATED_SUCCESSFULLY}`);
    //               } else {
    //                 Error('Action cancelled.');
    //               }
    //             });
    //         }
    //       })
    //       .catch(({ response }) => {
    //         ErrorResponse(response, languageData);
    //       });
    // }

   if (value === "Status") {
      axios
        .post(ImportedURL.API.todoitemstatusChange, {
          id: event.data._id,
          status: !event.data.status,
          model: "newtodoitems",
        })
        .then((data) => {
            if (data.data.obj) {           
            // Show the second Swal dialog
            Swal.fire({
              title: "No related records found.",
              html: "Are you sure you want to change the status?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Ok",
              cancelButtonText: "Cancel",
            }).then((result) => {
              if (result.isConfirmed) {
                let id = event.data._id;
                let status = !event.data.status;
                let model = "newtodoitems";

                // Change user status
                this.changeUserStatus(id, status, model);

                this.props.listtodolistitem();
                Success(
                  (languageData && languageData.STATUS
                    ? languageData.STATUS + " "
                    : "Status") +
                    (languageData && languageData.UPDATED_SUCCESSFULLY
                      ? languageData.UPDATED_SUCCESSFULLY
                      : " Updated Successfully")
                );
              } 
            });
          } else {
            let response = data.data;
            let records = response.data;
            let relatedRecordsCount = records.length; // Number of related users
            let relatedIds = records.map((item) => item._id); // Collect related record IDs

            // Check if there are related records to show
            if (relatedRecordsCount > 0) {
              let tableHtml = `
                <table border="1" cellpadding="10" style="border-collapse: collapse; width: 100%;">
                  <thead>
                    <tr><th>Assigned User</th><th>Created By</th><th>Todo-Shortcode</th></tr>
                  </thead>
                  <tbody>
                    ${records
                      .map(
                        (item) => `
                          <tr>
                            <td>${item.usersNameCategory}</td>
                            <td>${item.createdbyName}</td>
                            <td>${item.newtodoitems[0].name}</td>
                          </tr>`
                      )
                      .join("")}
                  </tbody>
                </table><p>Are you sure you want to change the Status?</p>`;

              // Show Swal dialog with table
              Swal.fire({
                title: `There are ${relatedRecordsCount} related records.`,
                html: tableHtml,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Ok",
                cancelButtonText: "Cancel",
              }).then((result) => {
                if (result.isConfirmed) {
                  let id = event.data._id;
                  let status = !event.data.status;
                  let model = "newtodoitems";
                  this.changeUserStatus(id, status, model, relatedIds);

                  Success(
                    `${languageData.STATUS} ${languageData.UPDATED_SUCCESSFULLY}`
                  );
                } else {
                  // User clicked Cancel, return without sending anything to backend
                  return; // Added return statement
                }
              });
            }
          }
        })
        .catch(({ response }) => {
          ErrorResponse(response, languageData);
        });
    }
  };
  changeUserStatus(
    id,
    status,
    model,
    relatedIds // Pass the related IDs array here
  ) {
    axios
      .post(ImportedURL.API.todoitemstatusChangeFurther, {
        id,
        status,
        model,
        relatedIds,
      })
      .then((data) => {
        this.props.listtodolistitem();
      })
      .catch(({ response }) => {
        ErrorResponse(response);
      });
  }

  searchUser = (e) => {
    const { name, value, label } = e;
    this.props.listtodolistitem({ hotel: value });
    this.setState({
      searchhoteloption: e,
      searchhotel: value,
      hotelName: label,
    });
  };
  searchDuration = (e) => {
    const { name, value, label } = e;
    this.props.listtodolistitem({ duration: value });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.setState({ gridApi: params.api });
  };
  componentDidUpdate(prevProps, prevState) {
    const role = this.props.AccountState.role;
    if (
      this.gridApi &&
      role &&
      ImportedURL.SUPERADMIN.includes(role) &&
      this.state.columnreset
    ) {
      let updatedColumnDefs = [...this.state.columnDefs];
      updatedColumnDefs = updatedColumnDefs.map((col) => {
        if (col.field === "hotelName" || col.field === "logo") {
          return { ...col, hide: false };
        }
        return col;
      });
      this.gridApi.setColumnDefs(updatedColumnDefs);
      this.setState({ columnreset: false });
    }
  }
  handleFilterChanged = () => {
    const api = this.gridApi;
    if (api && api.getDisplayedRowCount() === 0) {
      api.showNoRowsOverlay();
      this.setState({ hideExport: true });
    } else {
      api.hideOverlay();
      this.setState({ hideExport: false });
    }
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      todoitemdata: { ...this.state.todoitemdata, [name]: value },
    });
  };

  setPriority = (selectedOption) => {
    this.setState({
      priorityValue: selectedOption,
      todoitemdata: {
        ...this.state.todoitemdata,
        priority: selectedOption.value,
      },
    });
  };

  // handleDailyChange = (event) => {
  //     const { checked } = event.target;
  //     this.setState(
  //       (prevState) => ({
  //         dailyStatus: {
  //           ...prevState.dailyStatus,
  //           isMandatory: checked,
  //         },
  //       }),
  //       () => {
  //         this.clearFrequencyErrorIfAnySelected(); // Clear error if needed
  //       }
  //     );
  //   };
  handleDailyChange = (e) => {
    const isChecked = e.target.checked; // Get the checkbox status (checked/unchecked)
    this.setState(
      (prevState) => ({
        todoitemdata: {
          ...prevState.todoitemdata,
          frequencyStatuses: {
            ...prevState.todoitemdata.frequencyStatuses,
            daily: isChecked, // Update the daily frequency status
          },
        },
      }),
      () => {
        this.clearFrequencyErrorIfAnySelected(); // Clear error if any frequency is selected
      }
    );
  };

  handleWeeklyChange = (e) => {
    let isChecked = e.target.checked;
    this.setState(
      (prevState) => ({
        todoitemdata: {
          ...prevState.todoitemdata,
          frequencyStatuses: {
            ...prevState.todoitemdata.frequencyStatuses,
            weekly: isChecked,
          },
        },
      }),
      () => {
        this.clearFrequencyErrorIfAnySelected(); // Clear error if needed
      }
    );
  };
  handleMonthlyChange = (e) => {
    let isChecked = e.target.checked;
    this.setState(
      (prevState) => ({
        todoitemdata: {
          ...prevState.todoitemdata,
          frequencyStatuses: {
            ...prevState.todoitemdata.frequencyStatuses,
            monthly: isChecked,
          },
        },
      }),
      () => {
        this.clearFrequencyErrorIfAnySelected(); // Clear error if needed
      }
    );
  };
  handleQuarterlyChange = (e) => {
    let isChecked = e.target.checked;
    this.setState(
      (prevState) => ({
        todoitemdata: {
          ...prevState.todoitemdata,
          frequencyStatuses: {
            ...prevState.todoitemdata.frequencyStatuses,
            quarterly: isChecked,
          },
        },
      }),
      () => {
        this.clearFrequencyErrorIfAnySelected(); // Clear error if needed
      }
    );
  };
  handleHalfYearlyChange = (e) => {
    let isChecked = e.target.checked;
    this.setState(
      (prevState) => ({
        todoitemdata: {
          ...prevState.todoitemdata,
          frequencyStatuses: {
            ...prevState.todoitemdata.frequencyStatuses,
            halfyearly: isChecked,
          },
        },
      }),
      () => {
        this.clearFrequencyErrorIfAnySelected(); // Clear error if needed
      }
    );
  };
  handleYearlyChange = (e) => {
    let isChecked = e.target.checked;
    this.setState(
      (prevState) => ({
        todoitemdata: {
          ...prevState.todoitemdata,
          frequencyStatuses: {
            ...prevState.todoitemdata.frequencyStatuses,
            yearly: isChecked,
          },
        },
      }),
      () => {
        this.clearFrequencyErrorIfAnySelected(); // Clear error if needed
      }
    );
  };
  handleAdhocChange = (e) => {
    let isChecked = e.target.checked;
    this.setState(
      (prevState) => ({
        todoitemdata: {
          ...prevState.todoitemdata,
          frequencyStatuses: {
            ...prevState.todoitemdata.frequencyStatuses,
            Adhoc: isChecked,
          },
        },
      }),
      () => {
        this.clearFrequencyErrorIfAnySelected(); // Clear error if needed
      }
    );
  };
  handleActiveStatus = (e) => {
    let isChecked = e.target.checked;
    this.setState(
      (prevState) => ({
        todoitemdata: {
          ...prevState.todoitemdata,
          frequencyStatuses: {
            ...prevState.todoitemdata.frequencyStatuses,
            status: isChecked,
          },
        },
      }),
      () => {}
    );
  };

  clearFrequencyErrorIfAnySelected = () => {
    const {
      dailyStatus,
      weeklyStatus,
      monthlyStatus,
      quarterlyStatus,
      halfyearlyStatus,
      yearlyStatus,
      AdhocStatus,
    } = this.state;

    if (
      dailyStatus.isMandatory ||
      weeklyStatus.isMandatory ||
      monthlyStatus.isMandatory ||
      quarterlyStatus.isMandatory ||
      halfyearlyStatus.isMandatory ||
      yearlyStatus.isMandatory ||
      AdhocStatus.isMandatory
    ) {
      // Clear frequency error
      this.setState({
        errors: { ...this.state.errors, frequency: null },
      });
    }
  };
  validateForm = () => {
    const { priorityValue, todoitemdata, durationOption } = this.state;
    const { priority, duration } = todoitemdata || {};
    let errors = {};

    // Validate Priority
    if (!priority || priority.value === "") {
      errors.priority = "Priority is required";
    }

    if (!duration || duration.trim() === "") {
      errors.frequency = "Duration is required";
    }

    this.setState({ errors });

    // If there are no errors, return true
    return Object.keys(errors).length === 0;
  };
  ErrorResponse = (response) => {
    const languageData = this.props.AccountState.account.SelectedlanguageData;
    if (response) {
      if (response.status == 500) {
        Error(
          response.status +
            (languageData && languageData.INTERNAL_SERVER_ERROR
              ? languageData.INTERNAL_SERVER_ERROR
              : " Internal Server Error")
        );
      } else if (response.status == 502) {
        Error(
          response.status +
            (languageData && languageData.BAD_GATEWAY
              ? languageData.BAD_GATEWAY
              : " Bad Gateway")
        );
      } else if (response.status == 400) {
        Error(
          languageData && languageData.BAD_REQUEST
            ? languageData.BAD_REQUEST
            : "Bad request"
        );
      } else if (response.status == 406) {
        Error(
          languageData && languageData.TODO_LIST_CANNOT_EDIT
            ? languageData.TODO_LIST_CANNOT_EDIT
            : "You can't edit todos created by others"
        );
      } else {
        Error(response.statusMessage);
      }
    } else {
      Error("Bad request");
    }
  };
  SubmitForm = (e) => {
    const languageData = this.props.AccountState.account.SelectedlanguageData;
    e.preventDefault();

    if (this.validateForm()) {
      const formData = {
        priority: this.state.todoitemdata.priority
          ? this.state.todoitemdata.priority
          : "",
        description: this.state.todoitemdata.description || "",
        //  frequencyStatuses: {
        //       daily: this.state.todoitemdata?.frequencyStatuses?.daily || false,
        //       weekly: this.state.todoitemdata?.frequencyStatuses?.weekly || false,
        //       monthly: this.state.todoitemdata?.frequencyStatuses?.monthly || false,
        //       quarterly: this.state.todoitemdata?.frequencyStatuses?.quarterly || false,
        //       halfyearly: this.state.todoitemdata?.frequencyStatuses?.halfyearly || false,
        //       yearly: this.state.todoitemdata?.frequencyStatuses?.yearly || false,
        //       Adhoc: this.state.todoitemdata?.frequencyStatuses?.Adhoc || false
        //   }
        frequencyStatuses: this.state.todoitemdata.duration
          ? this.state.todoitemdata.duration
          : "",
      };
      this.setState({ saving: true });
      axios
        .post(
          ImportedURL.API.updateNewTodolistitem + "/" + this.state.id,
          formData
        )
        .then((res) => {
          this.props.listtodolistitem();
          Success(
            (languageData && languageData.TO_DO_ITEMS
              ? languageData.TO_DO_ITEMS
              : "Todo Items") +
              (languageData && languageData.UPDATED_SUCCESSFULLY
                ? languageData.UPDATED_SUCCESSFULLY
                : " Updated Successfully")
          );
          //   let btn = document.getElementById("closeModal");
          //   btn.click();
          const closeModalBtn = document.getElementById("closeModalButton");
          if (closeModalBtn) {
            closeModalBtn.click(); // This will close the modal
          }
        })
        .catch(({ response }) => {
          ErrorResponse(response, languageData);
        });
    } else {
      console.log("Form validation failed, fix the errors.");
    }
  };

  // handleDurationChange = (selectedOption) => {
  //   this.setState({
  //     todoitemdata: {
  //       ...this.state.todoitemdata,
  //       durationOption: selectedOption.value // or selectedOption._id depending on your structure
  //     } ,
  //     errors: { ...this.state.errors, frequency: null },
  //   });
  // };

  // handleDurationChange = (selectedOption) => {
  //   this.setState({ durationOption: selectedOption,
  //     errors: { ...this.state.errors, frequency: null },
  //    });
  // };

  // handleDurationChange = (selectedOption) => {
  //   this.setState({
  //     todoitemdata: {
  //       ...this.state.todoitemdata,
  //       durationOption: selectedOption // Ensure this is an object like { value, label }
  //     },
  //     errors: { ...this.state.errors, frequency: null }
  //   });
  // };

  handleDurationChange = (selectedOption) => {
    this.setState({
      todoitemdata: {
        ...this.state.todoitemdata,
        duration: selectedOption ? selectedOption.value : null,
      },
      errors: { ...this.state.errors, frequency: null },
    });
  };

  render() {
    const { AccountState, LandingState, ConfigurationState } = this.props;
    const rowData = this.props.TodolistitemState.listtodolistitem;
    const ViewData = this.props.TodolistitemState.viewtodolistitem;
    const account = this.props.AccountState.account;
    const languageData = this.props.AccountState.account.SelectedlanguageData;
    const listHotelData =
      LandingState.landingdata &&
      LandingState.landingdata.data &&
      LandingState.landingdata.data.length > 0
        ? LandingState.landingdata.data[0]
        : [];
    const spinner = this.props.TodolistitemState.spinner;
    var hotelName = ViewData.hotelName;
    var logo = ViewData.logo;
    var duration = ViewData.duration;
    var itemvalue = ViewData.itemvalue;
    var categoryName = ViewData.categoryName;
    var time =
      " at " +
      TimeFormat(ViewData.starttime) +
      " - " +
      TimeFormat(ViewData.endtime);
    const {
      dailyStatus,
      weeklyStatus,
      monthlyStatus,
      yearlyStatus,
      halfyearlyStatus,
      quarterlyStatus,
      AdhocStatus,
      activeStatus,
    } = this.state;
    if (this.state.listRedirect) {
      return <Redirect to="/todo-listitem" />; // Make sure the route is wrapped in quotes
    }

    const listDurationConfigOptions =
      ConfigurationState.listDurationConfigOptions;

    const priorityList = [
      { value: "", label: "Select..." },
      { value: "1", label: "High" },
      { value: "2", label: "Medium" },
      { value: "3", label: "Low" },
    ];

    const previleges = AccountState.previleges;
    const Previlege = previleges.find((obj) => {
      return obj.name == "Todo_List_Item";
    });
    //  const editOption = Previlege?.edit ? '<button type="button" class="btn btn-icon" title="Edit" data-action-type="Edit"><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit"></i></button>' : '';
    //const deleteOption = ImportedURL.SUPERADMIN.includes(AccountState.role) ? '<button type="button" class="btn btn-icon js-sweetalert" title="Delete" data-action-type="Delete"><i class="fa fa-trash-o text-danger" style="color: red !important" data-action-type="Delete"/></button>' : ''
    const template =
      '<div><button title="View" type="button" class="btn btn-icon" data-action-type="View"><i class="fa fa-eye" style="color: #1DC9B7 !important" data-action-type="View"  data-toggle="modal" data-target="#viewtodoitem"></i></button>' +
      '<button title="Edit" type="button" class="btn btn-icon" data-action-type="Edit"><i class="fa fa-edit" style="color: #1DC9B7 !important" data-action-type="Edit"  data-toggle="modal" data-target="#edittodoitem"></i></button>' +
      //+ editOption
      // + deleteOption
      "</div>";
    if (rowData) {
      rowData.forEach((object) => {
        object.actions = template;
      });
    }
    const limitOptions = [
      { value: "25", label: "25" },
      { value: "50", label: "50" },
      { value: "100", label: "100" },
    ];

    var hotelOptionSearch = [];
    if (listHotelData.length > 1) {
      hotelOptionSearch.push({ label: "All", value: "", name: "searchhotel" });
    }
    if (listHotelData.length > 0) {
      listHotelData
        .filter((filterItem) => filterItem.status === true)
        .map((item) => {
          hotelOptionSearch.push({
            label: `${item.name} ${item.city} ${item.state}`,
            value: item._id,
            logo: item.logo,
            name: "searchhotel",
          });
        });
    }

    var durationOptions = [];
    const durationlist = listDurationConfigOptions;
    if (Array.isArray(durationlist) && durationlist.length > 0) {
      durationlist.forEach((item) => {
        if (item && !item.isdeleted && item.status) {
          const typeLabel = item.name || "Unknown"; // Use the name of the category or 'Unknown' if not defined
          const valueId = item._id || ""; // Use the _id as the value
          durationOptions.push({
            label: typeLabel,
            value: valueId,
            name: "duration",
          });
        }
      });
    }

    // Check if the duration exists and then find its name from listDurationConfigOptions
    const matchedDuration = this.state.todoitemdata.duration
      ? listDurationConfigOptions.find(
          (option) => option._id === this.state.todoitemdata.duration
        )
      : null;

    const selectedDuration = matchedDuration
      ? { value: matchedDuration._id, label: matchedDuration.name }
      : null;

    return (
      <>
        <div>
          <div className="breadcrump">
            <p>
              {" "}
              <Link to="/">
                <h6>
                  {languageData && languageData.DASHBOARD
                    ? languageData.DASHBOARD
                    : "Dashboard"}
                </h6>
              </Link>{" "}
              <span>
                <i className="fa fa-angle-right" aria-hidden="true"></i>{" "}
              </span>{" "}
              <Link to="/to-do-list-item">
                <h6 className="highlights_breadcrump">
                  {" "}
                  {languageData && languageData.TODO_ITEM_LIST
                    ? languageData.TODO_ITEM_LIST
                    : "Todo Item List"}
                </h6>
              </Link>
            </p>
          </div>
          <div className="section-body pt-3">
            <div className="container-fluid">
              <div className="tab-content">
                <div
                  className="tab-pane fade show active"
                  id="Departments-list"
                  role="tabpanel"
                >
                  <div className="card">
                    <div className="card-header tit_res">
                      <div className="d-flex">
                        <div className="rounded_icon">
                          <i className="fa fa-calendar mr-2 "></i>
                        </div>
                        <div className="d-flex align-items-center">
                          <h3 className="card-title d-flex ">
                            {" "}
                            <span className="">
                              {languageData && languageData.TODO_ITEM_LIST
                                ? languageData.TODO_ITEM_LIST
                                : "Todo Item List"}
                            </span>{" "}
                          </h3>
                        </div>
                      </div>

                      <div className="card-options add_card_options">
                        <div className="d-flex justify-content-between align-items-center">
                          <ul className="nav nav-tabs page-header-tab"></ul>
                          <div className="header-action">
                            {Previlege?.add ? (
                              <Link to="/add-todo-listitem">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  id="Add"
                                >
                                  <i className="fe fe-plus mr-2" id="Add" />
                                  Add
                                </button>
                              </Link>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        {rowData &&
                        rowData.length > 0 &&
                        !this.state.hideExport ? (
                          <>
                            <a
                              className="btn btn-primary btn-sm nav-link dropdown-toggle"
                              data-toggle="dropdown"
                              role="button"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i
                                className="fa fa-download mr-2"
                                aria-hidden="true"
                              ></i>
                              Export
                            </a>
                            <div className="dropdown-menu">
                              <a
                                className="dropdown-item"
                                type="button"
                                onClick={this.exportToCSV}
                              >
                                <i className="dropdown-icon fa fa-file-excel-o"></i>{" "}
                                Excel
                              </a>
                              <a
                                className="dropdown-item"
                                type="button"
                                onClick={this.exportPDF}
                              >
                                <i className="dropdown-icon fa fa-file-pdf-o"></i>{" "}
                                PDF
                              </a>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="card-body ">
                      <div className="row">
                        <div className="col-lg-2 col-md-2 col-sm-2 col-xl-3">
                          <label className="form-label">
                            {languageData && languageData.LIMIT
                              ? languageData.LIMIT
                              : "Limit"}
                          </label>
                          <div className="form-group">
                            <Select
                              onChange={(e) =>
                                this.gridApi.paginationSetPageSize(
                                  Number(e.value)
                                )
                              }
                              options={limitOptions}
                              defaultValue={limitOptions[0]}
                              className="limit_size"
                            />
                          </div>
                        </div>
                        {account?.allhotels == undefined && (
                          <div className="col-lg-2 col-md-4 col-sm-6 col-xl-6">
                            <label className="form-label">
                              {languageData && languageData.SEARCH
                                ? languageData.SEARCH
                                : "Search"}{" "}
                              {languageData && languageData.HOTEL
                                ? languageData.HOTEL
                                : "Hotel"}
                            </label>
                            <div
                              className="form-group"
                              style={{ marginBottom: "10px" }}
                            >
                              <Select
                                value={this.state.searchhoteloption}
                                onChange={this.searchUser}
                                options={hotelOptionSearch}
                                formatOptionLabel={(e) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {e.label != "All" && (
                                      <img
                                        className="all_search_image_size contain_image"
                                        src={ImportedURL.FILEURL + e.logo}
                                        onError={onErrorImage}
                                      />
                                    )}
                                    <div
                                      style={
                                        e.label != "All"
                                          ? { paddingLeft: "10px" }
                                          : {}
                                      }
                                    >
                                      <span className="all_search_text_size">
                                        {e.label}
                                      </span>
                                    </div>
                                  </div>
                                )}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="table-responsive">
                        <div className="ag-theme-alpine">
                          <AgGridReact
                            rowHeight={82}
                            paginationPageSize={25}
                            pagination={true}
                            onRowClicked={this.onRowClicked}
                            domLayout={"autoHeight"}
                            defaultColDef={this.state.defaultColumDef}
                            rowData={rowData}
                            rowDragManaged={true}
                            animateRows={true}
                            columnDefs={this.state.columnDefs}
                            onGridReady={this.onGridReady}
                            overlayNoRowsTemplate={"No rows to display"}
                            onFilterChanged={this.handleFilterChanged}
                          ></AgGridReact>
                        </div>
                        {spinner || this.state.spinner ? (
                          <div className="common_loader_ag_grid">
                            <img
                              className="loader_img_style_common_ag_grid"
                              src="../../assets/images/load.png"
                            />
                            <Spinner
                              className="spinner_load_common_ag_grid"
                              animation="border"
                              variant="info"
                            ></Spinner>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="overlay"
              style={{ display: spinner ? "block" : "none" }}
            ></div>
          </div>
        </div>
        <div
          className="modal fade"
          id="viewtodoitem"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <div className="rounded_icon">
                  <i className="fa fa-calendar mr-2"></i>
                </div>
                <h5
                  className="modal-title"
                  id="exampleModalLabel"
                  style={{ marginTop: "5px" }}
                >
                  {languageData && languageData.VIEW
                    ? languageData.VIEW
                    : "View"}{" "}
                  {languageData && languageData.TODO_ITEM_LIST
                    ? languageData.TODO_ITEM_LIST
                    : "Todo Item List"}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span
                    aria-hidden="true"
                    style={{ fontSize: "23px" }}
                    onClick={this.closeModal}
                  >
                    <img src="../../assets/images/cancel.png" />
                  </span>
                </button>
              </div>
              <div className="modal-body ribbon px-0">
                <div className="ribbon">
                  <div className="ribbon-box orange " style={{ zIndex: "1" }}>
                    {this.state.todoitemdata.hotelName}
                  </div>
                </div>
                <div className="card">
                  <div className="card-body text-center ribbon">
                    {ImportedURL.SUPERADMIN.includes(account.role) ? (
                      <>
                        <img
                          className="rounded-circle img-thumbnail w100 h100"
                          style={{ objectFit: "contain" }}
                          src={
                            ImportedURL.FILEURL + this.state.todoitemdata.logo
                          }
                          alt="fake_url"
                          onError={onErrorImage}
                        />
                        {/* <h6 className="mt-3">{this.state.todoitemdata.hotelName}</h6> */}
                      </>
                    ) : (
                      ""
                    )}
                    <div
                      className="row text-left"
                      style={{
                        marginTop: ImportedURL.SUPERADMIN.includes(account.role)
                          ? "20px"
                          : "20px",
                      }}
                    >
                      <div className="User_part_right row">
                        <div className="col-6">
                          <label>User Category</label>
                          <p className="mb-4">
                            {this.state.todoitemdata.categoryName || "N/A"}
                          </p>
                        </div>

                        <div className="col-md-6 col-sm-6">
                          <label>Title </label>
                          <p className="mb-4">
                            {this.state.todoitemdata.title || "N/A"}
                          </p>
                        </div>

                        <div className="col-md-6 col-sm-6">
                          <label>Todo - Shortcode </label>
                          <p className="mb-4">
                            {this.state.todoitemdata.todo || "N/A"}
                          </p>
                        </div>

                        {this.state.todoitemdata.description &&
                          this.state.todoitemdata.description.trim() !== "" && (
                            <div className="col-md-6 col-sm-6">
                              <label> Description </label>
                              <p className="mb-4">
                                {this.state.todoitemdata.description}
                              </p>
                            </div>
                          )}

                        <div className="col-md-6 col-sm-6">
                          <label> Priority </label>
                          <p className="mb-4">
                            {this.state.todoitemdata.priority === "1"
                              ? " High"
                              : this.state.todoitemdata.priority === "2"
                              ? " Medium"
                              : this.state.todoitemdata.priority === "3"
                              ? " Low"
                              : "N/A"}
                          </p>
                        </div>

                        <div className="col-md-4 col-sm-6 ">
                          <label> Frequency</label>
                          <p className="mb-4">
                            {matchedDuration ? matchedDuration.name : ""}
                          </p>

                          {this.state.errors.frequency && (
                            <div style={{ color: "red", marginTop: "5px" }}>
                              {this.state.errors.frequency}
                            </div>
                          )}
                        </div>

                        {/* Mandatory Compliances */}
                        {this.state.todoitemdata.mandatorycompliances !==
                          undefined && (
                          <div className="col-md-6 col-sm-6">
                            <label className="mb-2 mt-1">
                              Mandatory Compliances:
                            </label>
                            <p>
                              {this.state.todoitemdata.mandatorycompliances ===
                              true ? (
                                <i className="fa fa-check-circle">
                                  {" "}
                                  <span
                                    style={{
                                      fontFamily: "Montserrat-Medium",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Mandatory
                                  </span>
                                </i>
                              ) : (
                                <i className="fa fa-times-circle">
                                  {" "}
                                  <span
                                    style={{
                                      fontFamily: "Montserrat-Medium",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Non Mandatory
                                  </span>
                                </i>
                              )}
                            </p>
                          </div>
                        )}

                        {this.state.todoitemdata.status !== undefined && (
                          <div className="col-md-6 col-sm-6">
                            <label className="mb-2 mt-1">Status:</label>
                            <p>
                              {this.state.todoitemdata.status === 1 ? (
                                <i className="fa fa-check-circle">
                                  {" "}
                                  <span
                                    style={{
                                      fontFamily: "Montserrat-Medium",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Active
                                  </span>
                                </i>
                              ) : (
                                <i className="fa fa-times-circle">
                                  {" "}
                                  <span
                                    style={{
                                      fontFamily: "Montserrat-Medium",
                                      fontSize: "15px",
                                    }}
                                  >
                                    In Active
                                  </span>
                                </i>
                              )}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="edittodoitem"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <div className="rounded_icon">
                  <i className="fa fa-calendar mr-2"></i>
                </div>
                <h5
                  className="modal-title"
                  id="exampleModalLabel"
                  style={{ marginTop: "5px" }}
                >
                  {languageData && languageData.EDIT
                    ? languageData.EDIT
                    : "Edit"}{" "}
                  {languageData && languageData.TODO_ITEM_LIST
                    ? languageData.TODO_ITEM_LIST
                    : "Todo Item List"}
                </h5>
                <button
                  type="button"
                  id="closeModalButton"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span
                    aria-hidden="true"
                    style={{ fontSize: "23px" }}
                    onClick={this.closeModal}
                  >
                    <img src="../../assets/images/cancel.png" />
                  </span>
                </button>
              </div>
              <div className="modal-body ribbon px-0">
                <div className="ribbon">
                  <div className="ribbon-box orange " style={{ zIndex: "1" }}>
                    {this.state.todoitemdata.hotelName}
                  </div>
                </div>

                <div className="card">
                  <div className="card-body text-center ribbon">
                    {ImportedURL.SUPERADMIN.includes(account.role) ? (
                      <>
                        <img
                          className="rounded-circle img-thumbnail w100 h100"
                          style={{ objectFit: "contain" }}
                          src={
                            ImportedURL.FILEURL + this.state.todoitemdata.logo
                          }
                          alt="fake_url"
                          onError={onErrorImage}
                        />
                        {/* <h6 className="mt-3">{this.state.todoitemdata.hotelName}</h6> */}
                      </>
                    ) : (
                      ""
                    )}
                    <div
                      className="row text-left"
                      style={{
                        marginTop: ImportedURL.SUPERADMIN.includes(account.role)
                          ? "20px"
                          : "20px",
                      }}
                    >
                      <div className="col-md-6 col-sm-6">
                        <label className="mb-2 mt-1">User Category </label>
                        {/* <span>{this.state.todoitemdata.categoryName || "N/A"}</span> */}
                        <input
                          type="text"
                          className="form-control w-100"
                          name="category"
                          value={this.state.todoitemdata.categoryName || "N/A"}
                          readOnly="true"
                        ></input>
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <label className="mb-2 mt-1">Title </label>
                        {/* <span>{this.state.todoitemdata.title || "N/A"}</span> */}
                        <input
                          type="text"
                          className="form-control w-100"
                          name="title"
                          value={this.state.todoitemdata.title || "N/A"}
                          readOnly="true"
                        ></input>
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <label className="mb-2 mt-1">Todo - Shortcode </label>
                        {/* <span>{this.state.todoitemdata.todo || "N/A"}</span>*/}
                        <input
                          type="text"
                          className="form-control w-100"
                          name="Shortcode"
                          value={this.state.todoitemdata.todo || "N/A"}
                          readOnly="true"
                        ></input>
                      </div>

                      <div className="col-md-6 col-sm-6 ">
                        <label className="form-label mb-2 mt-1">
                          Priority
                          <span className="ml-1" style={{ color: "red" }}>
                            *
                          </span>
                        </label>
                        <div
                          className="form-group"
                          style={{ marginBottom: "10px" }}
                        >
                          <Select
                            value={priorityList.find(
                              (option) =>
                                option.value ===
                                this.state.todoitemdata.priority
                            )} // Match with the current priority
                            onChange={this.setPriority}
                            options={priorityList}
                          />
                        </div>
                        {this.state.errors.priority && (
                          <div style={{ color: "red", marginTop: "5px" }}>
                            {this.state.errors.priority}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <div
                          className="form-group"
                          style={{ position: "relative" }}
                        >
                          <label className="form-label">Description</label>
                          <textarea
                            className="form-control w-100"
                            name="description"
                            rows="2"
                            cols="50"
                            value={this.state.todoitemdata.description || ""} // Bind the value to state
                            onChange={this.handleChange} // Handle changes
                            placeholder={"Description"}
                          />
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-6 ">
                        <label className="form-label">
                          {languageData && languageData.ASSIGN_Duration
                            ? languageData.ASSIGN_DURATION
                            : "Assign Frequency"}
                          <span className="ml-1" style={{ color: "red" }}>
                            *
                          </span>
                        </label>
                        <div
                          className="form-group"
                          style={{ marginBottom: "10px" }}
                        >
                          <Select
                            // value={durationOptions.find(option => option.value === this.state.todoitemdata.duration) || null}
                            value={selectedDuration}
                            onChange={this.handleDurationChange}
                            options={durationOptions} // Options are in { value, label } format
                          />
                        </div>
                        {this.state.errors.frequency && (
                          <div style={{ color: "red", marginTop: "5px" }}>
                            {this.state.errors.frequency}
                          </div>
                        )}
                      </div>

                      {this.state.todoitemdata.mandatorycompliances !==
                        undefined && (
                        <div className="col-md-6 col-sm-6">
                          <label className="mb-2 mt-1">
                            Mandatory Compliances:
                          </label>
                          <p>
                            {this.state.todoitemdata.mandatorycompliances ===
                            true ? (
                              <i className="fa fa-check-circle">
                                {" "}
                                <span
                                  style={{
                                    fontFamily: "Montserrat-Medium",
                                    fontSize: "15px",
                                  }}
                                >
                                  Mandatory
                                </span>
                              </i>
                            ) : (
                              <i className="fa fa-times-circle">
                                {" "}
                                <span
                                  style={{
                                    fontFamily: "Montserrat-Medium",
                                    fontSize: "15px",
                                  }}
                                >
                                  Non Mandatory
                                </span>
                              </i>
                            )}
                          </p>
                        </div>
                      )}

                      {this.state.todoitemdata.status !== undefined && (
                        <div className="col-md-6 col-sm-6">
                          <label className="mb-2 mt-1">Status:</label>
                          <p>
                            {this.state.todoitemdata.status === 1 ? (
                              <i className="fa fa-check-circle">
                                {" "}
                                <span
                                  style={{
                                    fontFamily: "Montserrat-Medium",
                                    fontSize: "15px",
                                  }}
                                >
                                  Active
                                </span>
                              </i>
                            ) : (
                              <i className="fa fa-times-circle">
                                {" "}
                                <span
                                  style={{
                                    fontFamily: "Montserrat-Medium",
                                    fontSize: "15px",
                                  }}
                                >
                                  In Active
                                </span>
                              </i>
                            )}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="text-right">
                      <button
                        type="button"
                        className="btn commor_save"
                        onClick={this.SubmitForm}
                        style={{ marginRight: "10px" }}
                      >
                        Update
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        style={{ marginRight: "10px" }}
                        data-dismiss="modal"
                      >
                        <i className="fa fa-times mr-2"></i>Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  TodolistitemState: state.todolistitem,
  AccountState: state.account,
  LandingState: state.landing,
  ConfigurationState: state.configuration,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      listtodolistitem: AC_LIST_TODO_LIST_ITEM,
      viewtodolistitem: AC_VIEW_TODO_LIST_ITEM,
      emptytodolist: AC_EMPTY_LIST_TODO_ITEM,
      AddTodoApis: AC_ADD_TODO_APIS,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(NewListTodoList);
