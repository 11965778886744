import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AC_VIEW_HOTEL } from '../../actions/hotelAction';
import { Row } from 'react-bootstrap';
import axios from 'axios';
import ImportedURL from '../../common/api';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert2';
import { AC_LIST_COUNTRY } from '../../actions/countryAction';
import { onErrorImage } from '../../common/validate';
import showSwalDialog from '../../utilities/Modal/swal';
import { Error, Success } from '../../common/swal';



const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};
const initialState = {
    hotelData: '',
    loader: false
}
class ViewHotel extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }
    async componentDidMount() {
        // Optional: If there is a need to call an action or fetch additional data,
        // such as fetching a list of countries, it could be uncommented.
        // this.props.ListCountry();

        // Retrieve the 'id' parameter from the route match props. This assumes the 
        // component is rendered as part of a route with a URL parameter 'id'.
        let id = this.props.match.params.id;

        // Fetch hotel data from the API using the id obtained from the URL.
        // 'await' is used to wait for the asynchronous axios request to complete.
        await axios.get(ImportedURL.API.viewHotelData + '/' + id)
            .then((res) => {
                // Update the component state with the data received from the API.
                // 'hotelData' is set with the response data to be used in rendering.
                this.setState({ hotelData: res.data });

                // Set 'loader' state to true to indicate that data fetching is complete.
                // This can be used to hide any loading indicators in the UI.
                this.setState({ loader: true });
            });

        // Scroll the window to the top of the page. This ensures that when the data
        // is loaded and displayed, the user view starts from the top of the page.
        // 'behavior: 'smooth'' provides a smooth scrolling effect.
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    copyUrl = (e) => {
        navigator.clipboard.writeText(e);
        Swal.fire({ title: 'Copied', showConfirmButton: false, timer: 500 })
    }

    fetchData = () => {
        const { AccountState } = this.props;
        const data = this.state.hotelData;
        const languageData = AccountState.account.SelectedlanguageData
        showSwalDialog('Are you sure you want to fetch data?', '', 'Ok', 'Cancel', 'assets/images/status.png').then((result) => {
            if (result.isConfirmed) {
                this.setState({ fetchingSpinner: true });
                const formData = {
                    _id: data._id ? data._id : '',
                    socialreviews: data.socialreviews ? data.socialreviews : [],
                }
                axios.post(ImportedURL.API.fetchELFData, formData)
                    .then((res) => {
                        this.setState({ fetchingSpinner: false });
                        Success((languageData && languageData.FETCH_DATA_IN_ELF_SIGHT ? languageData.FETCH_DATA_IN_ELF_SIGHT : "Fetched ELF data successfully"))
                    }).catch(({ response }) => {
                        this.setState({ fetchingSpinner: false });
                        Error(response && response.data && response.data.message ? response.data.message : "Bad request")
                    });
            }
        })

    }

    render() {
        const { AccountState, LandingState } = this.props;
        const phoneCode = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 5 ? LandingState.landingdata.data[1] : [];;
        const account = AccountState.account;
        const languageData = AccountState.account.SelectedlanguageData
        const previleges = AccountState.previleges;
        const hotelPrevilege = previleges.find(obj => { return obj.name == "Hotel" });

        var imagesData = this.state.hotelData.images;
        var elfsights = this.state.hotelData.elfsights;

        var testArray = [];
        if (imagesData && imagesData != '') {
            imagesData.map((data, i) => {
                testArray.push(<img key={i} src={ImportedURL.FILEURL + data} style={{ width: '60px', height: '60px', marginRight: '15px' }} onError={onErrorImage} />)
            })
        }
        var communicationemail = this.state.hotelData.communicationemail;
        if (communicationemail) {
            var communicationemailData = communicationemail.join(" , ")
        }
        var location = this.state.hotelData.location;
        var locationError = true;
        location?.map((item) => {
            if (item.lat == '') {
                locationError = false
            }
            if (item.lon == '') {
                locationError = false
            }
        })

        var elfsightsError = true;
        elfsights?.map((item) => {
            if (item.elfmedia == '') {
                elfsightsError = false
            }
            if (item.elfurl == '') {
                elfsightsError = false
            }
        })
        var hotelData = this.state.hotelData;
        let ccData = '';
        let ccownerData = '';
        let ccgmData = '';
        let ccpersonData = '';
        let cctwilloData = '';
        if (phoneCode) {
            phoneCode.map(item => {
                if (this.state.hotelData.cc) {
                    if (item.code === this.state.hotelData.cc) {
                        ccData = item.phonecode
                    }
                    if (item.phonecode === this.state.hotelData.cc) {
                        ccData = item.phonecode
                    }
                } else {
                    if (item.phonecode === account.phonecode) {
                        ccData = item.phonecode
                    }
                    if (item.code === account.phonecode) {
                        ccData = item.phonecode
                    }
                }
                if (this.state.hotelData.ccowner) {
                    if (item.phonecode === this.state.hotelData.ccowner) {
                        ccownerData = item.phonecode
                    }
                    if (item.code === this.state.hotelData.ccowner) {
                        ccownerData = item.phonecode
                    }
                } else {
                    if (item.phonecode === account.phonecode) {
                        ccownerData = item.phonecode
                    }
                    if (item.code === account.phonecode) {
                        ccownerData = item.phonecode
                    }
                }
                if (this.state.hotelData.ccgm) {
                    if (item.phonecode === this.state.hotelData.ccgm) {
                        ccgmData = item.phonecode
                    }
                    if (item.code === this.state.hotelData.ccgm) {
                        ccgmData = item.phonecode
                    }
                } else {
                    if (item.phonecode === account.phonecode) {
                        ccgmData = item.phonecode
                    }
                    if (item.code === account.phonecode) {
                        ccgmData = item.phonecode
                    }
                }
                if (this.state.hotelData.ccperson) {
                    if (item.phonecode === this.state.hotelData.ccperson) {
                        ccpersonData = item.phonecode
                    }
                    if (item.code === this.state.hotelData.ccperson) {
                        ccpersonData = item.phonecode
                    }
                } else {
                    if (item.phonecode === account.phonecode) {
                        ccpersonData = item.phonecode
                    }
                    if (item.code === account.phonecode) {
                        ccpersonData = item.phonecode
                    }
                }
                if (this.state.hotelData.cctwillo) {
                    if (item.phonecode === this.state.hotelData.cctwillo) {
                        cctwilloData = item.phonecode
                    }
                    if (item.code === this.state.hotelData.cctwillo) {
                        cctwilloData = item.phonecode
                    }
                } else {
                    if (item.phonecode === account.phonecode) {
                        cctwilloData = item.phonecode
                    }
                    if (item.code === account.phonecode) {
                        cctwilloData = item.phonecode
                    }
                }
            })
        }
        const showFetchData = ((this.state.hotelData.socialreviews && this.state.hotelData.socialreviews.length > 0 ? this.state.hotelData.socialreviews.some((e) => (e.socialmediaurl)) : false) && ImportedURL.SUPERADMIN.includes(AccountState.role));

        return (
            <div>
                {ImportedURL.SUPERADMIN.includes(account.role) && <div className="breadcrump">
                    <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/list-hotel"><h6> Hotels List</h6></Link> <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/view-hotel/:id"><h6 className="highlights_breadcrump">View Hotel</h6></Link></p>
                </div>}
                <div id="overlay" style={{ display: this.state.loader ? 'none' : 'block' }}></div>
                {this.state.loader ?
                    <div>
                        <div className="section-body Width_changes_In_Scroll_Table view_hotel_sec mt-3 ">
                            <div className="container-fluid">
                                <div className="row clearfix">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="card">
                                            <div className="card-body">
                                                {
                                                    ImportedURL.SUPERADMIN.includes(AccountState.role) &&
                                                    <div className="header-action" style={{ marginTop: '5px' }}>
                                                        <Link to='/list-hotel'><button type="button" className="btn btn-primary" id='Add'><i className="fa fa-arrow-left mr-2" id='Add' />Back</button></Link>
                                                    </div>
                                                }
                                                {(account?.allhotels && account.allhotels?.length > 0) && hotelPrevilege.edit &&
                                                    <div className="header-action" style={{ marginTop: '5px' }}>
                                                        <Link to={'/edit-hotel/' + account.hotel}><button type="button" className="btn btn-primary" id='Add'><i className="fa fa-arrow-right mr-2" id='Add' />Edit</button></Link>
                                                    </div>
                                                }
                                                <div className='card-title d-flex'>
                                                    <div className='rounded_icon'><i className="fa fa-building-o mr-2 "></i></div>
                                                    <h3 className="card-title " style={{ marginTop: '10px' }}>VIEW HOTEL</h3>
                                                </div>

                                                <div className="card c_grid c_yellow ">
                                                    <div className="card-body text-center view_hotel_card">
                                                        <Row>
                                                            <div className='col-xl-6 col-lg 6 col-md-12 col-sm-12 col-12'>
                                                                <div className='top_img_details'>
                                                                    <div className="img_size">
                                                                        <img src={ImportedURL.FILEURL + this.state.hotelData.logo} alt="fake_url" onError={onErrorImage} />
                                                                    </div>
                                                                    <div className='text-left'>
                                                                        <h6 className="mt-4 mb-1">{(this.state.hotelData.name !== undefined) || (this.state.hotelData.name != null) ? this.state.hotelData.name : ''}</h6>
                                                                        <p className='mb-1'>{this.state.hotelData.email !== undefined ? this.state.hotelData.email : ''}</p>
                                                                        <p className='mb-1'>{ccData !== undefined ? ccData : ''} &nbsp;{this.state.hotelData.phonenumber !== undefined ? this.state.hotelData.phonenumber : ''}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-xl-6 col-lg 6 col-md-12 col-sm-12 col-12'>
                                                                <div style={{ textAlign: '-webkit-center' }} className='hotel_details'>
                                                                    <h6 className='left_question'>Hotel Card</h6>
                                                                    <div style={{ width: '300px', height: '100px' }}>
                                                                        <img src={ImportedURL.FILEURL + this.state.hotelData.card} alt='not found' style={{ width: '100%', height: '100%', objectFit: 'contain' }} onError={onErrorImage} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Row>
                                                    </div>
                                                    <div className='hotel_contact mt-3'>
                                                        <div className='row'>
                                                            <div className='col-lg-6'>
                                                                <div className="Over_all_table_style card">
                                                                    <div className="card-header d-flex  justify-content-between">
                                                                        <h3 className="card-title">HOTEL DETAILS</h3>
                                                                        <span className="input-group-btn">
                                                                            {
                                                                                (showFetchData)
                                                                                    ?
                                                                                    <>
                                                                                        {
                                                                                            this.state.fetchingSpinner
                                                                                                ?
                                                                                                <button type="button" className="btn btn-icon btn-sm show-fetch" ><i className="fa fa-spinner fa-spin mr-2"></i>Fetching</button>
                                                                                                :
                                                                                                <button className="btn btn-icon btn-sm show-fetch" type="submit" onClick={this.fetchData} >
                                                                                                    <span className="fa fa-refresh mr-2"></span>Fetch Data
                                                                                                </button>
                                                                                        }
                                                                                    </>
                                                                                    : ""
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                    <table className="Over_all_table_style table-responsive">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td><h5 className="table_text_left">Group</h5></td>
                                                                                <td> <h5 className='table_colon'> :</h5></td>
                                                                                <td> <p className='table_text_right'>{this.state.hotelData.group ? this.state.hotelData.group.name : ''}</p></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><h5 className="table_text_left">Hotel ID</h5></td>
                                                                                <td> <h5 className='table_colon'> :</h5></td>
                                                                                <td> <p className='table_text_right'>{this.state.hotelData.hotelid !== undefined ? this.state.hotelData.hotelid : ''}</p></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><h5 className="table_text_left">Hotel Address</h5></td>
                                                                                <td> <h5 className='table_colon'> :</h5></td>
                                                                                <td> <p className='table_text_right'>{this.state.hotelData.address !== undefined ? this.state.hotelData.address : ''}</p></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><h5 className="table_text_left">City</h5></td>
                                                                                <td> <h5 className='table_colon'> :</h5></td>
                                                                                <td> <p className='table_text_right'>{this.state.hotelData.city !== undefined ? this.state.hotelData.city : ''}</p></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><h5 className="table_text_left">State</h5></td>
                                                                                <td> <h5 className='table_colon'> :</h5></td>
                                                                                <td> <p className='table_text_right'>{this.state.hotelData.state !== undefined ? this.state.hotelData.state : ''}</p></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><h5 className="table_text_left">Postcode</h5></td>
                                                                                <td> <h5 className='table_colon'> :</h5></td>
                                                                                <td> <p className='table_text_right'>{this.state.hotelData.postcode !== undefined ? this.state.hotelData.postcode : ''}</p></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><h5 className="table_text_left">Country</h5></td>
                                                                                <td> <h5 className='table_colon'> :</h5></td>
                                                                                <td> <p className='table_text_right'>{this.state.hotelData.country !== undefined ? this.state.hotelData.country : ''}</p></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><h5 className="table_text_left">Latitude</h5></td>
                                                                                <td> <h5 className='table_colon'> :</h5></td>
                                                                                <td> <p className='table_text_right'>{this.state.hotelData.latitude !== undefined ? this.state.hotelData.latitude : ''}</p></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><h5 className="table_text_left">Longitude</h5></td>
                                                                                <td> <h5 className='table_colon'> :</h5></td>
                                                                                <td> <p className='table_text_right'>{this.state.hotelData.longitude !== undefined ? this.state.hotelData.longitude : ''}</p></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><h5 className="table_text_left">Time Zone</h5></td>
                                                                                <td> <h5 className='table_colon'> :</h5></td>
                                                                                <td> <p className='table_text_right'>{(hotelData?.timezone !== undefined && hotelData?.timezone) ? hotelData?.timezone?.value : '---'}</p></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><h5 className="table_text_left">Time Zone UTC</h5></td>
                                                                                <td> <h5 className='table_colon'> :</h5></td>
                                                                                <td> <p className='table_text_right'>{(hotelData?.timezoneutc !== undefined && hotelData?.timezoneutc) ? hotelData?.timezoneutc : '---'}</p></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><h5 className="table_text_left">Radius</h5></td>
                                                                                <td> <h5 className='table_colon'> :</h5></td>
                                                                                <td> <p className='table_text_right'>{this.state.hotelData.radius !== "null" ? (this.state.hotelData.radius !== undefined ? this.state.hotelData.radius : '') : ''}</p></td>
                                                                            </tr>
                                                                            {this.state.hotelData && this.state.hotelData.wifipassword &&
                                                                                <tr>
                                                                                    <td><h5 className="table_text_left">Wifi Password</h5></td>
                                                                                    <td> <h5 className='table_colon'> :</h5></td>
                                                                                    <td> <p className='table_text_right'>{this.state.hotelData.wifipassword}</p></td>
                                                                                </tr>
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                {
                                                                    (this.state.hotelData.googlereviewurl || this.state.hotelData.tripsadvisorurl || this.state.hotelData.website || this.state.hotelData.bookingurl)
                                                                    &&
                                                                    <div className='hotel_online my-4'>
                                                                        <div className='row'>
                                                                            <div className='col-12'>
                                                                                <div className="Over_all_table_style_border card">
                                                                                    <div className="card-header">
                                                                                        <h3 className="card-title">HOTEL ONLINE REVIEWS URL</h3>
                                                                                    </div>
                                                                                    <table className="table card-table">
                                                                                        <tbody>
                                                                                            {
                                                                                                this.state.hotelData.googlereviewurl
                                                                                                &&
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <div className='media_images'>
                                                                                                            <img src='../../assets/images/google.webp' alt='' style={{ padding: "7px" }} />
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td> <h5 className='table_colon'> :</h5></td>
                                                                                                    <td> <p className='table_text_right'><a href={this.state.hotelData.googlereviewurl !== undefined ? this.state.hotelData.googlereviewurl : ''} target="_blank" className='review_url'>Google Review URL</a></p></td>
                                                                                                </tr>
                                                                                            }
                                                                                            {
                                                                                                this.state.hotelData.tripsadvisorurl
                                                                                                &&
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <div className='media_images'>
                                                                                                            <img src='../../assets/images/tripadviser.png' style={{ padding: "7px" }} alt='' />
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td> <h5 className='table_colon'> :</h5></td>
                                                                                                    <td> <p className='table_text_right'><a href={this.state.hotelData.tripsadvisorurl !== undefined ? this.state.hotelData.tripsadvisorurl : ''} className='review_url' target="_blank">Trips Advisor URL</a></p></td>
                                                                                                </tr>
                                                                                            }
                                                                                            {
                                                                                                this.state.hotelData.website
                                                                                                &&
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <div className='media_images'>
                                                                                                            <img src={ImportedURL.FILEURL + this.state.hotelData.logo} alt='' onError={onErrorImage} />
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td> <h5 className='table_colon'> :</h5></td>
                                                                                                    <td> <p className='table_text_right'> <a href={`//${this.state.hotelData.website !== undefined ? this.state.hotelData.website : ''}`} target="_blank" className='review_url'> Website URL</a></p></td>
                                                                                                </tr>
                                                                                            }
                                                                                            {
                                                                                                this.state.hotelData.bookingurl
                                                                                                &&
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <div className='media_images'>
                                                                                                            <img src={ImportedURL.FILEURL + this.state.hotelData.logo} alt='' onError={onErrorImage} />
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td> <h5 className='table_colon'> :</h5></td>
                                                                                                    <td> <p className='table_text_right'><a href={this.state.hotelData.bookingurl !== undefined ? this.state.hotelData.bookingurl : ''} target="_blank" className='review_url'>  Booking URL</a></p></td>
                                                                                                </tr>
                                                                                            }

                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }

                                                            </div>
                                                            <div className='col-lg-6'>
                                                                <div className="Over_all_table_style card">
                                                                    <div className="card-header">
                                                                        <h3 className="card-title">CONTACT DETAILS</h3>
                                                                    </div>
                                                                    <table className="Over_all_table_style table-responsive">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td><h5 className="table_text_left">Contact Person Name</h5></td>
                                                                                <td> <h5 className='table_colon'> :</h5></td>
                                                                                <td> <p className='table_text_right'>{(this.state.hotelData.contactperson !== undefined && this.state.hotelData.contactperson != '') ? this.state.hotelData.contactperson : '---'}</p></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><h5 className="table_text_left">Contact Phone #</h5></td>
                                                                                <td> <h5 className='table_colon'> :</h5></td>
                                                                                {
                                                                                    (this.state.hotelData.contactphone !== undefined && this.state.hotelData.contactphone != '')
                                                                                        ?
                                                                                        <td> <p className='table_text_right'>{this.state.hotelData.contactphone ? (ccpersonData != "null" ? (ccpersonData != undefined ? ccpersonData : '') : '') : ''} &nbsp;{(this.state.hotelData.contactphone !== undefined && this.state.hotelData.contactphone != '') ? this.state.hotelData.contactphone : ''}</p></td>
                                                                                        :
                                                                                        <td> <p className='table_text_right'>{'---'}</p></td>
                                                                                }
                                                                            </tr>
                                                                            <tr>
                                                                                <td><h5 className="table_text_left">Communication Email List</h5></td>
                                                                                <td> <h5 className='table_colon'> :</h5></td>
                                                                                <td> <p className='table_text_right'>{communicationemailData ? communicationemailData : '---'}</p></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><h5 className="table_text_left">Message Phone #</h5></td>
                                                                                <td> <h5 className='table_colon'> :</h5></td>
                                                                                <td> <p className='table_text_right'>{(this.state.hotelData.twillo !== undefined && this.state.hotelData.twillo) ? (((cctwilloData !== undefined && cctwilloData) ? cctwilloData : '') + ' ' + this.state.hotelData.twillo) : ''}</p></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>

                                                                {location && location.length > 0 ?
                                                                    <>
                                                                        <div className='col-xl-12 col-md-12 col-sm-12 col-12 mt-4 p-0'>
                                                                            <div className="card">
                                                                                <div className="card-header">
                                                                                    <h3 className="card-title">HOTEL Location</h3>
                                                                                </div>
                                                                                <div className="">
                                                                                    <div className="Over_all_table_style table-responsive">
                                                                                        <table className="table  mb-0">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th>#</th>
                                                                                                    <th>Latitude</th>
                                                                                                    <th>longitude</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {location && location.map((data, i) =>
                                                                                                (
                                                                                                    <tr key={i}>
                                                                                                        <td> <p className='latitude_location'> {i + 1}</p></td>
                                                                                                        <td> <p className='latitude_location'>{data.lat}</p></td>
                                                                                                        <td> <p className='latitude_location'>{data.lon}</p> </td>
                                                                                                    </tr>
                                                                                                )
                                                                                                )}
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </> : ''
                                                                }
                                                                {elfsights && elfsights.length > 0 ?
                                                                    <>
                                                                        <div className='col-xl-12 col-md-12 col-sm-12 col-12 mt-4 p-0'>
                                                                            <div className="card">
                                                                                <div className="card-header">
                                                                                    <h3 className="card-title">HOTEL  OTA 's Review</h3>
                                                                                </div>
                                                                                <div class="">
                                                                                    <div class="Over_all_table_style table-responsive">
                                                                                        <table class="table  mb-0">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th>#</th>
                                                                                                    <th> OTA 's name</th>
                                                                                                    <th> OTA 's Url</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {elfsights && elfsights.map((data, i) =>
                                                                                                (
                                                                                                    <tr key={i}>
                                                                                                        <td> <p className='latitude_location'> {i + 1}</p></td>
                                                                                                        <td> <p className='latitude_location'>{data.elfmedia && data.elfmedia.socialmedianame}</p></td>
                                                                                                        <td> <p className='latitude_location'>{data.elfurl ? data.elfurl : "---"}</p> </td>
                                                                                                    </tr>
                                                                                                )
                                                                                                )}
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    : ""
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {
                                                        (this.state.hotelData.facebook || this.state.hotelData.twitter || this.state.hotelData.instagram || this.state.hotelData.pinterest || this.state.hotelData.youtube || this.state.hotelData.linkedin || this.state.hotelData.tiktok)
                                                        &&
                                                        <div className='media_reviews'>
                                                            <div className='row'>
                                                                <div className='col-lg-12'>
                                                                    <div className="Over_all_table_style card">
                                                                        <div className="card-header">
                                                                            <h3 className="card-title"> SOCIAL MEDIA DETAILS</h3>
                                                                        </div>
                                                                        <table className="Over_all_table_style table-responsive">
                                                                            <tbody>

                                                                                <tr>
                                                                                    <ul className='media_images_link'>
                                                                                        {
                                                                                            (this.state.hotelData.facebook !== undefined && this.state.hotelData.facebook)
                                                                                            &&
                                                                                            <li>
                                                                                                <div className='media_images'>
                                                                                                    <a href={this.state.hotelData.facebook !== undefined ? this.state.hotelData.facebook : ''} target="_blank">
                                                                                                        <img src='../../assets/images/fb.svg' alt='' />
                                                                                                    </a>
                                                                                                </div>
                                                                                            </li>
                                                                                        }
                                                                                        {
                                                                                            (this.state.hotelData.twitter !== undefined && this.state.hotelData.twitter)
                                                                                            &&
                                                                                            <li>
                                                                                                <div className='media_images'>
                                                                                                    <a href={this.state.hotelData.twitter !== undefined ? this.state.hotelData.twitter : ''} target="_blank">
                                                                                                        <img src='../../assets/images/twitter.png' alt='' />
                                                                                                    </a>
                                                                                                </div>
                                                                                            </li>
                                                                                        }
                                                                                        {
                                                                                            (this.state.hotelData.instagram !== undefined && this.state.hotelData.instagram)
                                                                                            &&
                                                                                            <li>
                                                                                                <div className='media_images'>
                                                                                                    <a href={this.state.hotelData.instagram !== undefined ? this.state.hotelData.instagram : ''} target="_blank">
                                                                                                        <img src='../../assets/images/instagram.webp' style={{ padding: "10px" }} alt='' />
                                                                                                    </a>
                                                                                                </div>
                                                                                            </li>
                                                                                        }
                                                                                        {
                                                                                            (this.state.hotelData.pinterest !== undefined && this.state.hotelData.pinterest)
                                                                                            &&
                                                                                            <li>
                                                                                                <div className='media_images'>
                                                                                                    <a href={this.state.hotelData.pinterest !== undefined ? this.state.hotelData.pinterest : ''} target="_blank">
                                                                                                        <img src='../../assets/images/pinterest.png' style={{ padding: "5px" }} alt='' />
                                                                                                    </a>
                                                                                                </div>
                                                                                            </li>

                                                                                        }
                                                                                        {
                                                                                            (this.state.hotelData.youtube !== undefined && this.state.hotelData.youtube)
                                                                                            &&
                                                                                            <li>
                                                                                                <div className='media_images'>
                                                                                                    <a href={this.state.hotelData.youtube !== undefined ? this.state.hotelData.youtube : ''} target="_blank">
                                                                                                        <img src="../../assets/images/youtube.svg" alt='' />
                                                                                                    </a>
                                                                                                </div>
                                                                                            </li>
                                                                                        }
                                                                                        {
                                                                                            (this.state.hotelData.linkedin !== undefined && this.state.hotelData.linkedin)
                                                                                            &&
                                                                                            <li>
                                                                                                <div className='media_images'>
                                                                                                    <a href={this.state.hotelData.linkedin !== undefined ? this.state.hotelData.linkedin : ''} target="_blank">
                                                                                                        <img src='../../assets/images/linkedin.webp' style={{ padding: "7px" }} alt='' />
                                                                                                    </a>
                                                                                                </div>
                                                                                            </li>
                                                                                        }
                                                                                        {
                                                                                            (this.state.hotelData.tiktok !== undefined && this.state.hotelData.tiktok)
                                                                                            &&
                                                                                            <li>
                                                                                                <div className='media_images'>
                                                                                                    <a href={this.state.hotelData.tiktok !== undefined ? this.state.hotelData.tiktok : ''} target="_blank">
                                                                                                        <img src='../../assets/images/tiktok.svg' alt='' />
                                                                                                    </a>
                                                                                                </div>
                                                                                            </li>
                                                                                        }
                                                                                    </ul>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    (this.state.hotelData.elfsighttripsadvisorurl || this.state.hotelData.elfsightgoogleurl || this.state.hotelData.elfsighthotelsurl || this.state.hotelData.elfsightbookingurl || this.state.hotelData.elfsightyelpurl)
                                                                    &&
                                                                    <div className='col-lg-6'>
                                                                        {ImportedURL.SUPERADMIN.includes(account.role) ?
                                                                            <div className="Over_all_table_style card">
                                                                                <div className="card-header">
                                                                                    <h3 className="card-title">REVIEWS</h3>
                                                                                </div>
                                                                                <table className="Over_all_table_style table-responsive">
                                                                                    <tbody>
                                                                                        {
                                                                                            this.state.hotelData.elfsighttripsadvisorurl
                                                                                            &&
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <div className='media_images'>
                                                                                                        <img src='../../assets/images/tripadviser.png' style={{ padding: "7px" }} alt='' />
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td> <h5 className='table_colon'> :</h5></td>
                                                                                                <td> <p className='table_text_right'><a href='javascript:void(0)' onClick={(e) => this.copyUrl(this.state.hotelData.elfsighttripsadvisorurl)}> <i className="fa fa-clone mr-2" data-action-type="Copyemail"></i>Tripadvisor Review URL</a></p></td>
                                                                                            </tr>
                                                                                        }
                                                                                        {
                                                                                            this.state.hotelData.elfsightgoogleurl
                                                                                            &&
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <div className='media_images'>
                                                                                                        <img src='../../assets/images/fb.svg' alt='' />
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td> <h5 className='table_colon'> :</h5></td>
                                                                                                <td> <p className='table_text_right'><a href='javascript:void(0)' onClick={(e) => this.copyUrl(this.state.hotelData.elfsightfacebookurl)}> <i className="fa fa-clone mr-2" data-action-type="Copyemail"></i>Facebook Review URL</a></p></td>
                                                                                            </tr>
                                                                                        }

                                                                                        {
                                                                                            this.state.hotelData.elfsighthotelsurl
                                                                                            &&
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <div className='media_images'>
                                                                                                        <img src='../../assets/images/hotels.png' alt='' style={{ padding: "7px" }} />
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td> <h5 className='table_colon'> :</h5></td>
                                                                                                <td> <p className='table_text_right'><a href='javascript:void(0)' onClick={(e) => this.copyUrl(this.state.hotelData.elfsighthotelsurl)}> <i className="fa fa-clone mr-2" data-action-type="Copyemail"></i>Hotels.com URL</a></p></td>
                                                                                            </tr>

                                                                                        }
                                                                                        {
                                                                                            this.state.hotelData.elfsightbookingurl
                                                                                            &&
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <div className='media_images'>
                                                                                                        <img src='../../assets/images/booking.png' alt='' style={{ padding: "7px" }} />
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td> <h5 className='table_colon'> :</h5></td>
                                                                                                <td> <p className='table_text_right'><a href='javascript:void(0)' onClick={(e) => this.copyUrl(this.state.hotelData.elfsightbookingurl)}> <i className="fa fa-clone mr-2" data-action-type="Copyemail"></i>Booking.com Review URL</a></p></td>
                                                                                            </tr>
                                                                                        }
                                                                                        {
                                                                                            this.state.hotelData.elfsightyelpurl
                                                                                            &&
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <div className='media_images'>
                                                                                                        <img src='../../assets/images/yelp.png' alt='' />
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td> <h5 className='table_colon'> :</h5></td>
                                                                                                <td> <p className='table_text_right'><a href='javascript:void(0)' onClick={(e) => this.copyUrl(this.state.hotelData.elfsightyelpurl)}> <i className="fa fa-clone mr-2" data-action-type="Copyemail"></i>Yelp Review URL</a></p></td>
                                                                                            </tr>
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                            : []
                                                                        }
                                                                    </div>

                                                                }

                                                            </div>
                                                        </div>
                                                    }
                                                    {imagesData.length ?
                                                        <div className='col-xl-12 col-md-12 col-sm-12 col-12'>
                                                            <div className="card">
                                                                <div className="card-header">
                                                                    <h3 className="card-title">Hotel Images</h3>
                                                                </div>
                                                                <div className="card-body">
                                                                    <Carousel responsive={responsive}>
                                                                        {imagesData ? imagesData.map((data, i) => (
                                                                            <div className='hot_img_size' key={i} >
                                                                                <img src={ImportedURL.FILEURL + data} alt='Not found' onError={onErrorImage} />
                                                                            </div>
                                                                        )
                                                                        ) : <div>
                                                                            <p>No data</p>
                                                                        </div>}
                                                                    </Carousel>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : []}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div>
                        <div className='common_loader'>
                            <img className='loader_img_style_common' src='../../assets/images/load.png' alt='' />
                            <Spinner className='spinner_load_common' animation="border" variant="info" >
                            </Spinner>
                        </div>
                    </div>
                }
            </div>
        )
    }
}
const mapStateToProps = state => ({
    AccountState: state.account,
    LandingState: state.landing
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ ViewHotel: AC_VIEW_HOTEL, ListCountry: AC_LIST_COUNTRY }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewHotel);