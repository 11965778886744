import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import Select from 'react-select';
import Chart from "react-apexcharts";
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import ImportedURL from '../../common/api';
import { bindActionCreators } from 'redux';
import { Error } from '../../common/swal';
import { objectToQueryString, onErrorImage } from '../../common/validate';
import showSwalDialog from '../../utilities/Modal/swal';
class ListIncidentTicket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columnDefs: [
                { headerName: 'Hotel ', field: 'hotel', hide: true, width: 250, },
                { headerName: 'Incident Ticket #', field: 'incidentticketid', width: 220, cellStyle: { 'text-align': 'center' } },
                {
                    headerName: 'Room #', field: 'roomno', width: 150, cellStyle: { 'text-align': 'center' },
                    valueGetter: function (params) {
                        if (params.data) {
                            if (params.data.roomno) {
                                return params.data.roomno
                            } else if (params.data.placefor) {
                                return "Other place"
                            } else {
                                return "---";
                            }
                        }
                    }
                },
                {
                    headerName: 'Occurrence Date', field: 'date', width: 150, cellStyle: { 'text-align': 'center' },
                    valueGetter: function (params) {
                        if (params.data && params.data.date) {
                            return moment(params.data.date).format(params.data.dFormat) + " " + (params.data.time ? moment(params.data.time).format(params.data.tFormat) : '');
                        } else {
                            return '---'
                        }
                    }
                },
                {
                    headerName: 'Incident Description', field: 'description', width: 200,
                    valueGetter: function (params) {
                        if (params.data && params.data.description) {
                            return params.data.description
                        } else {
                            return "---"
                        }
                    }
                },
                { headerName: 'Reporter', field: 'reporter', width: 150 },
                {
                    headerName: 'Remark', field: 'reason', width: 150,
                    valueGetter: function (params) {
                        if (params.data && params.data.reason) {
                            return params.data.reason;
                        } else {
                            return '---'
                        }
                    }
                },
                {
                    headerName: 'Incident Status',
                    field: 'reasonstatus',
                    width: 150,
                    cellRenderer: function (params) {
                        if (params.data && params.data.reasonstatus === true) {
                            return '<span class="tag ticket_completed">Completed</span>';
                        } else {
                            return '<span class="tag ticket_inprogress">Inprogress</span>';
                        }
                    }
                },
                {
                    headerName: 'Completed Date', field: 'completedAt', width: 150, cellStyle: { 'text-align': 'center' },
                    valueGetter: function (params) {
                        if (params.data && params.data.completedAt) {
                            return moment(params.data.completedAt).format(params.data.dtFormat);
                        } else {
                            return '---'
                        }
                    }
                },
            ],
            defaultColumDef: {
                editable: false,
                sortable: false,
                resizable: true,
                filter: false,
                flex: 1,
                minWidth: 100,
            },
            tickets: [],
            originalData: [],
            Completeddata: [],
            createdData: [],
            userTickets: [],
            openCount: 0,
            assignedCount: 0,
            inprogressCount: 0,
            inreviewCount: 0,
            completedCount: 0,
            reopenedCount: 0,
            reassignedCount: 0,
            ticketid: '',
            hotel: '',
            category: '',
            username: '',
            fromdate: '',
            todate: '',
            month: '',
            status: '',
            reasonstatusfalse: 0,
            reasonstatustrue: 0,
            hotelName: '',
            showTicket: true,
            openStatus: false,
            assignedStatus: false,
            inprogressStatus: false,
            inreviewStatus: false,
            completedStatus: false,
            reopenedStatus: false,
            reassignedStatus: false,
            spinner: true,
            columnreset: true,
            ticketCount: '',
            hotelName: '',
            allOption: true,
            isFilterShow: false,
            fromdateValue: '',
            todateValue: '',
            selectedOption: [{ label: 'All', value: '', name: 'hotel' }],
            groupoption: { label: 'All', value: '', name: 'hotel' },
            perPage: 25,
            isChartView: false,
        }
    }
    componentDidUpdate(prevProps, prevState) {
        const { AccountState } = this.props;
        const role = AccountState.role;

        // Check if Super Admin and column reset is needed
        if (this.gridApi && role && ImportedURL.SUPERADMIN.includes(role) && this.state.columnreset) {
            let updatedColumnDefs = [...this.state.columnDefs];
            updatedColumnDefs = updatedColumnDefs.map((col) => {
                if (col.field === 'hotel' || col.field === 'logo') {
                    return { ...col, hide: false };
                }
                return col;
            });
            this.gridApi.setColumnDefs(updatedColumnDefs);
            this.setState({ columnreset: false });
        }

        // Update data source if previleges or gridApi changed
        if (this.gridApi && AccountState.previleges && prevState.gridApi !== this.state.gridApi) {
            const dataSource = this.getDataSource();
            this.gridApi.setDatasource(dataSource);
        }

        // Update data when date, hotel, or view changes
        if (
            prevState.fromdate !== this.state.fromdate ||
            prevState.todate !== this.state.todate ||
            prevState.hotel !== this.state.hotel ||
            prevState.isChartView !== this.state.isChartView
        ) {
            if (!this.state.isChartView && this.gridApi) {
                const dataSource = this.getDataSource({
                    ticketid: this.state.ticketid,
                    hotel: this.state.hotel,
                    group: this.state.group,
                    fromdate: this.state.fromdate ? `${this.state.fromdate}T00:00:00` : '',
                    todate: this.state.todate ? `${this.state.todate}T23:59:59` : ''
                });

                this.gridApi.setDatasource(dataSource);

                // Force a grid refresh after updating the data source
                this.gridApi.refreshCells({ force: true });
            }
        }
    }

    componentDidMount() {
        const account = this.props.AccountState.account;
        this.setState({ hotelName: account.hotelName ? account.hotelName : '' });
        this.setInitialDates();
    }
    setInitialDates = () => {
        const today = moment();
        const todate = today.format('YYYY-MM-DD');
        const fromdate = today.subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
        this.setState({
            fromdate,
            fromdateValue: fromdate,
            todate,
            todateValue: todate,
            month: ''
        }, () => {
            if (this.gridApi) {
                const dataSource = this.getDataSource({
                    hotel: this.state.hotel,
                    group: this.state.group,
                    status: this.state.status,
                    fromdate: fromdate ? fromdate + "T00:00:00" : '',
                    todate: todate ? todate + "T23:59:59" : ''
                });
                this.gridApi.setDatasource(dataSource);
            } else {
                console.error("gridApi is not initialized");
            }
            if (this.state.isChartView) {
                this.fetchDataForChart({
                    fromdate: fromdate ? fromdate + "T00:00:00" : '',
                    todate: todate ? todate + "T23:59:59" : ''
                });
            }
        });
    }
    getDataSource = (query = {}, statuscheck = false) => {
        return {
            getRows: async (params) => {
                // Grid params for filter and sort


                let formData = {
                    ticketid: this.state.ticketid,
                    hotel: this.state.hotel,
                    group: this.state.group,
                    fromdate: this.state.fromdate ? `${this.state.fromdate}T00:00:00` : '',
                    todate: this.state.todate ? `${this.state.todate}T23:59:59` : '',
                    ...query
                }
                const account = this.props.AccountState.account;
                const dtFormat = (account.dateformate != '' ? account.dateformate : 'MM/DD/YYYY') + ' ' + (account.timeformat != '' ? account.timeformat : "hh:mm A")
                const page = Math.floor(params.startRow / this.state.perPage);
                const filter = params.filterModel;
                const sortModel = params.sortModel;
                this.setState({ spinner: true });
                var tzone = 'America/chicago'
                if (account != undefined && account && account.tzone != undefined && account.tzone) {
                    tzone = account.tzone
                }
                axios.post(ImportedURL.API.createIncidentTicketReport, { perPage: this.state.perPage, page: page, filter: filter, sort: sortModel.length ? sortModel[0] : {}, ...formData })
                    .then((res) => {
                        if (res.data) {
                            this.setState({
                                tickets: res.data.data,
                                originalData: res.data.data,
                                Completeddata: res.data.Completeddata,
                                createdData: res.data.createdData,
                                reasonstatusfalse: res.data.false || 0,
                                reasonstatustrue: res.data.true || 0,


                                spinner: false
                            });
                            res.data.data.forEach(object => {
                                object.actions = `<div><button type="button" class="btn btn-icon" data-action-type="View" data-toggle="modal" data-target="#exampleModal"><i class="fa fa-eye" style='color: #1DC9B7 !important' data-action-type="View"></i></button></div>`;
                                object.tzone = tzone
                                object.dtFormat = dtFormat
                            });
                            const lbLastRowOnPageEl = document.querySelector(`[ref=\'lbLastRowOnPage\']`);
                            if (lbLastRowOnPageEl) {
                                lbLastRowOnPageEl.innerHTML = (res.data && res.data.data) ? res.data.data.length + params.startRow : 0;
                            }
                        }
                        params.successCallback(res.data.data, res.data.total);
                        this.UpdateColumnDefs();
                    }).catch(({ response }) => {
                        this.setState({ spinner: false });
                        Error("Couldn't fetch records");
                        params.successCallback([], 0);
                    })
            }
        }
    }


    // fetchDataForChart = (query = {}) => {
    //     const { account } = this.props.AccountState;
    //     const languageData = account.SelectedlanguageData;
    //     axios
    //       .post(ImportedURL.API.createIncidentTicketReport, query)
    //       .then((res) => {
    //         if (res.data) {
    //           // Update state with the fetched data
    //           this.setState(
    //             {
    //                 tickets: res.data.data, 
    //                 originalData: res.data.data, 
    //                 reasonstatusfalse: res.data.false || 0,
    //                 reasonstatustrue: res.data.true || 0,
    //             },
    //             () => {
    //               // Log the updated state to verify
    //             }
    //           );
    //         }
    //       })
    //       .catch(({ response }) => {
    //         if (response.status === 500) {
    //           Error(
    //             response.status +
    //             (languageData && languageData.INTERNAL_SERVER_ERROR
    //               ? languageData.INTERNAL_SERVER_ERROR
    //               : " Internal Server Error")
    //           );
    //         } else if (response.status === 502) {
    //           Error(
    //             response.status +
    //             (languageData && languageData.BAD_GATEWAY
    //               ? languageData.BAD_GATEWAY
    //               : " Bad Gateway")
    //           );
    //         } else {
    //           Error(response.statusMessage);
    //         }
    //       });
    //   };

    fetchDataForChart = (query = {}) => {
        const account = this.props.AccountState.account;
        const dtFormat = (account.dateformate != '' ? account.dateformate : 'MM/DD/YYYY') + ' ' + (account.timeformat != '' ? account.timeformat : "hh:mm A")
        this.setState({ spinner: true });
        var tzone = 'America/chicago'
        if (account != undefined && account && account.tzone != undefined && account.tzone) {
            tzone = account.tzone
        }
        axios.post(ImportedURL.API.createIncidentTicketReport, query)
            .then((res) => {
                if (res.data) {
                    this.setState({
                        tickets: res.data.data,
                        originalData: res.data.data,
                        Completeddata: res.data.Completeddata,
                        createdData: res.data.createdData,
                        reasonstatusfalse: res.data.false || 0,
                        reasonstatustrue: res.data.true || 0,
                        spinner: false
                    });
                }
            }).catch(({ error }) => {
                this.setState({ spinner: false });
                Error("Couldn't fetch records");

            })

    }
    handleChange = e => {
        var value = []
        let label = ''
        let dataStatus = true
        let dataArr = []
        if (e.length > 0) {
            e.map((item, i) => {
                if (e[e.length - 1].label == "All") {
                    value = []
                }
                if (item.label == "All") {
                    dataStatus = false
                    this.setState({ allOption: false })
                } else {
                    if (item.label != 'All') {
                        dataArr.push(item)
                        value.push(item.value)
                        this.setState({ allOption: true })
                        dataStatus = true
                    }
                    if (e.length == 1 && i == 0) {
                        label = item.label
                    }
                }
                if (value.length == 1) {
                    label = item.label
                } else {
                    label = ''
                }
                if (item.label == "All") {
                    if (this.state.fromdate || this.state.todate || this.state.group) {
                        this.setState({ isFilterShow: true })
                    } else {
                        this.setState({ isFilterShow: false })
                    }
                } else {
                    this.setState({ isFilterShow: true })
                }
            })
        }
        const dataSource = this.getDataSource(
            {
                ticketid: this.state.ticketid, hotel: value, group: this.state.group,
                fromdate: this.state.fromdate ? this.state.fromdate + "T00:00:00" : '',
                todate: this.state.todate ? this.state.todate + "T23:59:59" : ''
            })
        this.gridApi.setDatasource(dataSource);
        this.setState({
            hotel: value,
            hotelName: label,
            selectedOption: dataStatus ? dataArr : [{ label: 'All', value: '', name: 'hotel' }],
        });
        if (this.state.isChartView) {
            this.fetchDataForChart(
                {
                    ticketid: this.state.ticketid, hotel: value, group: this.state.group,
                    fromdate: this.state.fromdate ? this.state.fromdate + "T00:00:00" : '',
                    todate: this.state.todate ? this.state.todate + "T23:59:59" : ''
                });

        }
    }
    setGroup = e => {
        const { name, value, label } = e;
        this.setState({ isFilterShow: true, group: value, hotel: '', hotelName: '', selectedOption: label == "All" ? [{ label: 'All', value: '', name: 'hotel' }] : [] });
        const languageData = this.props.AccountState.account.SelectedlanguageData
        const dataSource = this.getDataSource(
            {
                hotel: this.state.hotel, group: value,
                fromdate: this.state.fromdate ? this.state.fromdate + "T00:00:00" : '',
                todate: this.state.todate ? this.state.todate + "T23:59:59" : ''
            })
        this.gridApi.setDatasource(dataSource);
        this.setState({ [name + 'option']: e })
        if (this.state.isChartView) {
            this.fetchDataForChart({
                hotel: this.state.hotel, group: value,
                fromdate: this.state.fromdate ? this.state.fromdate + "T00:00:00" : '',
                todate: this.state.todate ? this.state.todate + "T23:59:59" : ''
            });
        }

    }
    setDateReport = async(e) => {
        const { name, value } = e.target;
        this.setState({ isFilterShow: true });
        let hotelId = this.state.hotel;
        const languageData = this.props.AccountState.account.SelectedlanguageData
        let newValue = value;

  if (!newValue) {
    let confirmationResult;
    
    if (name === "fromdate") {
      confirmationResult = await showSwalDialog(
        'Do you want to load all the data?',
        '',
        'Ok',
        'Cancel',
        'assets/images/status.png'
      );
    } else {
      confirmationResult = await showSwalDialog(
        'Do you want to empty To Date?',
        '',
        'Ok',
        'Cancel',
        'assets/images/status.png'
      );
    }

    if (!confirmationResult.isConfirmed) {
      return; // User chose not to confirm, exit the function
    }
    
    // User confirmed, clear the value
    newValue = "";
  }
          
      
        if (name == 'fromdate') {
            this.setState({ fromdate: newValue, fromdateValue: newValue, todate: "", todateValue: "", month: '' })
            const dataSource = this.getDataSource({ hotel: this.state.hotel, group: this.state.group, fromdate: newValue ? newValue + "T00:00:00" : '', todate: "" })
            this.gridApi.setDatasource(dataSource);
            if (this.state.isChartView) {
                this.fetchDataForChart({ hotel: this.state.hotel, group: this.state.group, fromdate: newValue ? newValue + "T00:00:00" : '', todate: "" });
            }
        }
        if (name == 'todate') {
            this.setState({ todate: newValue, todateValue: newValue, month: '' })
            const dataSource = this.getDataSource({
                hotel: this.state.hotel, group: this.state.group,
                fromdate: this.state.fromdate ? this.state.fromdate + "T00:00:00" : '', todate: newValue ? newValue + "T23:59:59" : ''
            })
            this.gridApi.setDatasource(dataSource);
            if (this.state.isChartView) {
                this.fetchDataForChart({
                    hotel: this.state.hotel, group: this.state.group,
                    fromdate: this.state.fromdate ? this.state.fromdate + "T00:00:00" : '', todate: newValue ? newValue + "T23:59:59" : ''
                });

            }
        }
    }

      
    getMonthlyReport = e => {
        const account = this.props.AccountState.account;
        var dFormat = (account.dateformate != '' ? account.dateformate : 'MM/DD/YYYY');
        const languageData = this.props.AccountState.account.SelectedlanguageData
        const { value } = e.target;
        this.setState({ isFilterShow: true });
        const newval = value == 0 ? 0 : value == 1 ? 1 : 2;
        let date = new Date();
        let startDate = new Date(date.getFullYear(), date.getMonth() - newval, 1);
        let endDate = new Date(date.getFullYear(), date.getMonth() - newval + 1, 0);
        this.setState({ hotel: this.state.hotel, group: this.state.group, fromdate: moment(startDate).format("YYYY-MM-DD") + "T00:00:00", todate: moment(endDate).format("YYYY-MM-DD") + "T23:59:59" });
        const dataSource = this.getDataSource(
            {
                hotel: this.state.hotel, group: this.state.group,
                fromdate: this.state.fromdate ? this.state.fromdate + "T00:00:00" : '',
                todate: value ? value + "T23:59:59" : ''
            })
        this.gridApi.setDatasource(dataSource);
        if (this.state.isChartView) {
            this.fetchDataForChart(
                {
                    hotel: this.state.hotel, group: this.state.group,
                    fromdate: this.state.fromdate ? this.state.fromdate + "T00:00:00" : '',
                    todate: value ? value + "T23:59:59" : ''
                });

        }
    }
    getMonthlyReport = e => {
        const account = this.props.AccountState.account;
        const { value } = e.target;
        this.setState({ isFilterShow: true });
        const newval = value == 0 ? 0 : value == 1 ? 1 : 2;
        let date = new Date();
        let startDate = new Date(date.getFullYear(), date.getMonth() - newval, 1);
        let endDate = new Date(date.getFullYear(), date.getMonth() - newval + 1, 0);
        this.setState({ fromdate: moment(startDate).format("YYYY-MM-DD"), todate: moment(endDate).format("YYYY-MM-DD"), month: value, fromdateValue: moment(startDate).format("YYYY-MM-DD"), todateValue: moment(endDate).format("YYYY-MM-DD") });
        const dataSource = this.getDataSource(
            {
                hotel: this.state.hotel, group: this.state.group,
                fromdate: moment(startDate).format("YYYY-MM-DD") + "T00:00:00",
                todate: moment(endDate).format("YYYY-MM-DD") + "T23:59:59"
            })
        this.gridApi.setDatasource(dataSource);
        if (this.state.isChartView) {
            this.fetchDataForChart(
                {
                    hotel: this.state.hotel, group: this.state.group,
                    fromdate: moment(startDate).format("YYYY-MM-DD") + "T00:00:00",
                    todate: moment(endDate).format("YYYY-MM-DD") + "T23:59:59"
                });

        }
    }
    refreshGridDataSource = () => {
        this.gridApi.purgeInfiniteCache();
        const newDataSource = this.getDataSource();
        if (this.gridApi) {
            this.gridApi.setDatasource(newDataSource);
        }
        this.UpdateColumnDefs();
    };
    UpdateColumnDefs = () => {
        const role = this.props.AccountState.role;
        if (this.gridApi && role && ImportedURL.SUPERADMIN.includes(role)) {
            let updatedColumnDefs = [...this.state.columnDefs];
            updatedColumnDefs = updatedColumnDefs.map((col) => {
                if (col.field === 'hotel' || col.field === 'logo') {
                    return { ...col, hide: false };
                }
                return col;
            });
            this.gridApi.setColumnDefs(updatedColumnDefs);
        }
    }
    getMonths = (mon) => {
        let date = new Date();
        date.setDate(1);
        date.setMonth(date.getMonth() - mon);
        let monthName = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
        return `${monthName}`;
    }
    onRowClicked = event => {
        const rowValue = event.event.target;
        this.setState({ modalType: "View", id: event.data.incidentticketid });
    }
    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
        this.setState({ gridApi: params.api })
    };
    exportToCSV = () => {
        const account = this.props.AccountState.account;
        const dtFormat = (account.dateformate !== '' ? account.dateformate : 'MM/DD/YYYY') + ' ' + (account.timeformat !== '' ? account.timeformat : 'hh:mm A');
        var firstRow = this.gridApi.getFirstDisplayedRow();
        var lastRow = this.gridApi.getLastDisplayedRow();

        this.gridApi.exportDataAsCsv({
            columnKeys: ['hotel', 'incidentticketid', 'roomno', 'date', 'description', 'reporter', 'reason', 'reasonstatus', 'completedAt'],
            fileName: "incidentticketsReport" + "_" + (this.state.hotelName && (this.state.hotelName).replace(/ /g, '_')) + (account.currentTime).replace(/ /g, '_') + ".csv",
            shouldRowBeSkipped: (params) => {
                return params.node.rowIndex < firstRow || params.node.rowIndex > lastRow;
            },
            processCellCallback: function (params) {
                if (params && params.column && params.column.colId === 'time') {
                    return moment(params.value).format(dtFormat);
                }
                // Process reasonstatus field
                else if (params && params.column && params.column.colId === 'reasonstatus') {
                    return params.value ? 'Completed' : 'InProgress';
                }
                else {
                    return params.value;
                }
            },
            // Add header names for each column
            columnHeaders: ['Hotel', 'Incident Ticket ID', 'Room No', 'Occurrence Date', 'Incident Description', 'Reporter', 'Remark', 'Status', 'Completed At']
        });
    }
    onPerPageChange = e => {
        const { value } = e;
        this.setState({ perPage: value }, () => {
            if (this.gridApi) {
                this.gridApi.paginationSetPageSize(parseInt(value, 10));
                this.gridApi.purgeInfiniteCache();
            }
        });
        if (this.state.isChartView) {
            this.fetchDataForChart({
                hotel: this.state.hotel, group: this.state.group,
                fromdate: this.state.fromdate ? this.state.fromdate + "T00:00:00" : '',
                todate: this.state.todate ? this.state.todate + "T23:59:59" : ''
            });
        }
    };
    toggleView = () => {
        // if (!this.state.isChartView && this.gridApi) {
        // const dataSource = this.getDataSource({
        //     ticketid: this.state.ticketid,
        //     hotel: this.state.hotel,
        //     group: this.state.group,
        //     fromdate: this.state.fromdate ? `${this.state.fromdate}T00:00:00` : '',
        //     todate: this.state.todate ? `${this.state.todate}T23:59:59` : ''
        // });

        // this.gridApi.setDatasource(dataSource);

        // Force a grid refresh after updating the data source
        //this.gridApi.refreshCells({ force: true });
        // }

        this.setState((prevState) => ({
            isChartView: !prevState.isChartView,
        }));

    };
    exportPDF = () => {
        var firstRowIndex = this.gridApi.getFirstDisplayedRow();
        var lastRowIndex = this.gridApi.getLastDisplayedRow();
        var rowData = [];
        for (var i = firstRowIndex; i <= lastRowIndex; i++) {
            var rowNode = this.gridApi.getDisplayedRowAtIndex(i);
            if (rowNode) {
                rowData.push(rowNode.data);
            }
        }
    
        const account = this.props.AccountState.account;
        const dtFormat = (account.dateformate !== '' ? account.dateformate : 'MM/DD/YYYY') + ' ' + (account.timeformat !== '' ? account.timeformat : "hh:mm A");
        const dFormat = (account.dateformate !== '' ? account.dateformate : 'MM/DD/YYYY');
        const tFormat = (account.timeformat !== '' ? account.timeformat : 'hh:mm A');
    
        const selectedProperties = this.state.hotelName
            ? ['incidentticketid', 'roomno', 'date', 'description', 'reporter', 'reason', 'reasonstatus', 'completedAt']
            : ['hotel', 'incidentticketid', 'roomno', 'date', 'description', 'reporter', 'reason', 'reasonstatus', 'completedAt'];
    
        const selectedTickets = rowData.map(ticket => {
            const selectedTicket = {};
            selectedProperties.forEach(property => {
                if (ticket.hasOwnProperty(property)) {
                    if (property === 'date' || property === 'completedAt') {
                        selectedTicket[property] = ticket[property]
                            ? moment(ticket[property]).format(dFormat) + " " + (ticket.time ? moment(ticket.time).format(tFormat) : '')
                            : "---";
                    } else if (property === 'roomno') {
                        selectedTicket[property] = ticket[property] ? ticket[property] : (ticket.placefor ? "Other Place" : "---");
                    } else if (property === 'description') {
                        selectedTicket[property] = ticket[property] && ticket[property].trim() !== '' ? ticket[property] : "---";
                    } else if (property === 'reasonstatus') {
                        selectedTicket[property] = ticket[property] ? 'Completed' : 'InProgress';
                    } else {
                        selectedTicket[property] = ticket[property] ? ticket[property] : "---";
                    }
                } else {
                    selectedTicket[property] = "---"; // If the property doesn't exist, set to "---"
                }
            });
            return selectedTicket;
        });
    
        let data = {
            title: this.state.hotelName
                ? (this.state.hotelName !== "All" ? this.state.hotelName + " - Incident Tickets Report" : "Incident Tickets Report")
                : "Incident Tickets Report",
            head: this.state.hotelName
                ? ["#", 'Incident Ticket ID', 'Room No', 'Occurrence Date', 'Incident Description', 'Reporter', 'Remark', 'Status', 'Completed At']
                : ["#", 'Hotel', 'Incident Ticket ID', 'Room No', 'Occurrence Date', 'Incident Description', 'Reporter', 'Remark', 'Status', 'Completed At'],
            body: selectedTickets,
            hotelNameExport: this.state.hotelName ? account.hotelNameExport : '',
            hoteladdressExport: this.state.hotelName ? account.hoteladdressExport : '',
            limit: this.state.perPage,
            ticketmanagement: true,
            hotelWidth: true
        };
    
        this.setState({ spinner: true });
    
        axios.post(ImportedURL.API.downloadPdf, data, { responseType: 'blob' })
            .then((res) => {
                const blob = new Blob([res.data], { type: 'application/pdf' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "incidentticketsReport" + "_" + (this.state.hotelName && (this.state.hotelName).replace(/ /g, '_')) + (account.currentTime).replace(/ /g, '_') + ".pdf";
                link.click();
                link.remove();
                window.URL.revokeObjectURL(link.href);
                this.setState({ spinner: false });
            }).catch((err) => {
                console.error(err);
                this.setState({ spinner: false });
            });
    };
    
    
    filterReset = (e) => {
        const role = this.props.AccountState.role;
        this.setState({
            selectedOption: [{ label: 'All', value: '', name: 'hotel' }],
            searchhotel: '',
            fromdate: '',
            todate: '',
            month: '',
            day: '',
            hotel: '', status: '',
            groupoption: { label: 'All', value: '', name: 'hotel' },
            fromdateValue: this.state.fromdate,
            todateValue: this.state.todate,
            statusProgress: { label: "All", value: 0 },
            isFilterShow: false,
            group: '',
        });
        if (ImportedURL.SUPERADMIN.includes(role)) {
            this.setState({ hotelName: '' })
        }
        this.setInitialDates();
        const dataSource = this.getDataSource({})
        this.gridApi.setDatasource(dataSource);
        // if (this.state.isChartView) {
        //     this.fetchDataForChart({
        //        // fromdate: fromdate ? fromdate + "T00:00:00" : '',
        //        // todate: todate ? todate + "T23:59:59" : ''});
        // }
    }
    handleFilterChanged = () => {
        const api = this.gridApi;
        if (api && api.getDisplayedRowCount() === 0) {
            api.showNoRowsOverlay();
            this.setState({ hideExport: true })
        } else {
            api.hideOverlay();
            this.setState({ hideExport: false })
        }
    };
    render() {
        const { LandingState, AccountState } = this.props;
        const listHotels = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 1 ? LandingState.landingdata.data[0] : [];;
        const account = AccountState.account;
        const languageData = AccountState.account.SelectedlanguageData
        var dtFormat = (account.dateformate != '' ? account.dateformate : 'MM/DD/YYYY') + ' ' + (account.timeformat != '' ? account.timeformat : "hh:mm A")
        var dFormat = (account.dateformate != '' ? account.dateformate : 'MM/DD/YYYY')
        var tFormat = (account.timeformat != '' ? account.timeformat : 'hh:mm A')
        var tzone = 'America/chicago'
        if (account != undefined && account && account.tzone != undefined && account.tzone) {
            tzone = account.tzone
        }
        if (this.state.tickets) {
            this.state.tickets.forEach(object => {
                object.dtFormat = dtFormat;
                object.dFormat = dFormat;
                object.tFormat = tFormat;
                object.tzone = tzone;
            });
        }
        if (this.state.modalType === 'View') {
            const myObject = { ticketid: this.state.ticketid, hotel: this.state.hotel, group: this.state.group, fromdate: this.state.fromdate ? this.state.fromdate + "T00:00:00" : '', todate: this.state.todate ? this.state.todate + "T23:59:59" : '' };
            const objectString = objectToQueryString(myObject)
            return <Redirect to={'view-incident-ticket/' + this.state.id + objectString} />
        }
        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]
        const { reasonstatusfalse, reasonstatustrue, Completeddata, createdData } = this.state;
        const dates = Completeddata.map(item => item.completedAt ? new Date(item.completedAt).toISOString().split('T')[0] : "");
        const countOccurrences = dates.reduce((acc, date) => {
            acc[date] = (acc[date] || 0) + 1;
            return acc;
        }, {});

        const totalSumofcompleted = Object.values(countOccurrences).reduce((sum, count) => sum + count, 0);
        const createdAtdates = createdData.map(item => new Date(item.createdAt).toISOString().split('T')[0]);
        const countOccurrencescreatedAt = createdAtdates.reduce((acc, date) => {
            acc[date] = (acc[date] || 0) + 1;
            return acc;
        }, {});
        const totalSumofcreated = Object.values(countOccurrencescreatedAt).reduce((sum, count) => sum + count, 0);


        // Extract dates without time for completedAt
        const completedDates = Completeddata.map(item => item.completedAt ? new Date(item.completedAt).toISOString().split('T')[0] : "");
        const countOccurrencesCompleted = completedDates.reduce((acc, date) => {
            acc[date] = (acc[date] || 0) + 1;
            return acc;
        }, {});
        const xaxisCategoriesCompleted = Object.keys(countOccurrencesCompleted);
        const seriesDataCompleted = Object.values(countOccurrencesCompleted);

        // Extract dates without time for createdAt
        const createdAtDates = createdData.map(item => new Date(item.createdAt).toISOString().split('T')[0]);
        const countOccurrencesCreatedAt = createdAtDates.reduce((acc, date) => {
            acc[date] = (acc[date] || 0) + 1;
            return acc;
        }, {});
        const xaxisCategoriesCreatedAt = Object.keys(countOccurrencesCreatedAt);
        const seriesDataCreatedAt = Object.values(countOccurrencesCreatedAt);

        // Combine xaxis categories
        const allCategories = [...new Set([...xaxisCategoriesCompleted, ...xaxisCategoriesCreatedAt])].sort();

        const formattedDates = allCategories.map(date => {
            const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
            const formattedDate = new Date(date).toLocaleDateString('en-US', options);

            // Convert 'MM/DD/YYYY' to 'MM-DD-YYYY'
            return formattedDate.replace(/\//g, '-');
        });

        const seriesDataCompletedFinal = allCategories.map(date => countOccurrencesCompleted[date] || 0);
        const seriesDataCreatedAtFinal = allCategories.map(date => countOccurrencesCreatedAt[date] || 0);
        // Chart configuration
        const options = {
            chart: {
                type: "line",
                toolbar: {
                    show: true,
                    tools: {
                        download: true,
                    },
                },
                events: {
                    mouseLeave: function (event, chartContext, config) {
                        chartContext.resetSeries();
                    },
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    distributed: true,
                },
            },
            colors: ["#FF0000", "#0000FF"], // Colors for both series
            dataLabels: {
                enabled: true,
                formatter: function (val, opts) {
                    return opts.w.config.series[opts.seriesIndex].data[
                        opts.dataPointIndex
                    ];
                },
            },
            stroke: {
                curve: 'smooth'
            },
            title: {
                text: 'Incident Tickets Trends by Date',
                align: 'left'
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            xaxis: {
                categories: formattedDates,
            },
            yaxis: {
                labels: {
                    formatter: function (value) {
                        return Math.floor(value); // Format y-axis labels as integers
                    }
                }
            },
            labels: ["Completed", "Created"],
        };

        const series = [
            {
                name: "Completed Tickets",
                data: seriesDataCompletedFinal,
            },
            {
                name: "Created Tickets",
                data: seriesDataCreatedAtFinal,
            }
        ];

        // Render chart with `options` and `series`

        // chart data ends
        var hotelOptions = [];
        if (listHotels.length > 0) {
            listHotels.map(item => {
                if (this.state.groupoption && this.state.groupoption.value) {
                    if (this.state.groupoption.value == item.group._id) {
                        hotelOptions.push({ label: `${item.name} ${item.city} ${item.state}`, value: item._id, logo: item.logo, name: 'hotel' });
                    }
                } else {
                    hotelOptions.push({ label: `${item.name} ${item.city} ${item.state}`, value: item._id, logo: item.logo, name: 'hotel' });
                }
            })
        }
        if (hotelOptions.length != this.state.selectedOption.length) {
            hotelOptions.unshift({ label: 'All', value: '', name: 'hotel' })
        }
        if (hotelOptions.length == 1) {
            hotelOptions = []
        }
        const listGroup = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 1 ? LandingState.landingdata.data[3] : [];
        const groupOptions = []
        if (listGroup.length > 0) {
            groupOptions.push({ label: 'All', value: '', name: 'group' })
        }
        if (listGroup.length > 0) {
            listGroup && listGroup?.filter(filterItem => filterItem.status === true).map(item => {
                groupOptions.push({ label: item.name, value: item._id, name: 'group' });
            })
        }
        return (
            <>
                <div>
                    <div>
                        <div className="breadcrump">
                            <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span> <Link to="/create-incident-ticket-report"><h6 className="highlights_breadcrump">{languageData && languageData.CREATE_INCIDENT_TICKET_REPORTS ? languageData.CREATE_INCIDENT_TICKET_REPORTS : "Incident Tickets"}</h6></Link></p>
                        </div>
                        <div className="section-body pt-3">
                            <div className="container-fluid">
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                        <div className="card">
                                            <div className="card-header">
                                                <div className='rounded_icon'><i className="fa fa-ticket mr-2 "></i></div>
                                                <h3 className="card-title"> {languageData && languageData.CREATE_INCIDENT_TICKET_REPORTS ? languageData.CREATE_INCIDENT_TICKET_REPORTS : "Incident Tickets"}</h3>
                                                <div className="card-options">
                                                    {this.state.isFilterShow &&
                                                        <button className='btn topsocial_rest_btn btn-primarys ' type='button' onClick={this.filterReset} style={{ height: '45px !important', width: '115pxpx !important', marginRight: "10px" }}>
                                                            <i className="fa fa-refresh" aria-hidden="true" style={{ fontSize: '20px !importent', marginRight: '7px' }}></i> Reset</button>
                                                    }

                                                    {this.state.tickets.length > 0 ? (
                                                        <>
                                                            <button
                                                                className="btn btn-sm btn-default"
                                                                style={{ fontSize: "25px" }}
                                                                onClick={this.toggleView}
                                                            >
                                                                <span
                                                                    className={
                                                                        this.state.isChartView
                                                                            ? "fa fa-bar-chart"
                                                                            : "fe fe-grid"
                                                                    }
                                                                ></span>
                                                            </button>
                                                            {!this.state.isChartView && (
                                                                <>
                                                                    <a
                                                                        className="btn btn-primary btn-sm nav-link dropdown-toggle ticket_export"
                                                                        data-toggle="dropdown"
                                                                        role="button"
                                                                        aria-haspopup="true"
                                                                        aria-expanded="false"
                                                                    >
                                                                        <i
                                                                            className="fa fa-download mr-2"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                        Export
                                                                    </a>
                                                                    <div className="dropdown-menu">
                                                                        <a
                                                                            className="dropdown-item"
                                                                            type="button"
                                                                            onClick={this.exportToCSV}
                                                                        >
                                                                            <i className="dropdown-icon fa fa-file-excel-o"></i>{" "}
                                                                            Excel
                                                                        </a>
                                                                        <a
                                                                            className="dropdown-item"
                                                                            type="button"
                                                                            onClick={this.exportPDF}
                                                                        >
                                                                            <i className="dropdown-icon fa fa-file-pdf-o"></i>{" "}
                                                                            PDF
                                                                        </a>
                                                                    </div> </>)}
                                                        </>
                                                    ) : (
                                                        []
                                                    )}

                                                    {/* {this.state.tickets && this.state.tickets.length > 0 && !this.state.hideExport ?
                                                        <>
                                                            <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i className="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                            <div className="dropdown-menu">
                                                                <a className="dropdown-item" type='button' onClick={this.exportToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                                <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                            </div>
                                                        </>
                                                        : []} */}

                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    {!this.state.isChartView && (
                                                        <div className="col-lg-2 col-md-2 col-sm-2 col-xl-3">
                                                            <label className="form-label">{languageData && languageData.LIMIT ? languageData.LIMIT : "Limit"}</label>
                                                            <div className="form-group">
                                                                <Select
                                                                    onChange={this.onPerPageChange}
                                                                    options={limitOptions}
                                                                    defaultValue={limitOptions[0]}
                                                                    className='limit_size'
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                    {
                                                        (account.allhotels && account.allhotels?.length > 0) ?
                                                            ''
                                                            :
                                                            <>
                                                                <div className="col-lg-2 col-md-2 col-sm-6 col-xl-3">
                                                                    <label className="form-label">{languageData && languageData.GROUP ? languageData.GROUP : "Group"}</label>
                                                                    <div className="form-group">
                                                                        <Select
                                                                            value={this.state.groupoption ? { label: this.state.groupoption.label } : ''}
                                                                            onChange={this.setGroup}
                                                                            options={groupOptions}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-6 col-sm-6 col-xl-6">
                                                                    <label className="form-label">{languageData && languageData.SEARCH ? languageData.SEARCH : "Search"} {languageData && languageData.HOTEL ? languageData.HOTEL : "Hotel"}</label>
                                                                    <div className="form-group" style={{ marginBottom: '10px' }}>
                                                                        <Select
                                                                            isMulti={true}
                                                                            value={this.state.selectedOption}
                                                                            onChange={this.handleChange}
                                                                            options={hotelOptions}
                                                                            formatOptionLabel={(e) => (
                                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                    {e.label != 'All' && <img className='all_search_image_size contain_image' src={ImportedURL.FILEURL + e.logo} onError={onErrorImage} />}
                                                                                    <div style={e.label != 'All' ? { paddingLeft: '10px' } : {}}><span className='all_search_text_size' >{e.label}</span></div>
                                                                                </div>
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </div>

                                                            </>
                                                    }
                                                    <div className="col-lg-2 col-md-2 col-sm-6 col-xl-3">
                                                        <label className='form-label'>From Date</label>
                                                        <div className='site_pass_calander'>
                                                            <input type='date' className="form-control" name="fromdate" value={this.state.fromdateValue} onChange={this.setDateReport} ></input>
                                                            <i className="fa fa-calendar-check-o" style={{ cursor: 'pointer', fontSize: '20px', opacity: '0.7' }}></i>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-md-4 col-sm-6 col-xl-3">
                                                        <label className='form-label'>To Date</label>
                                                        <div className='site_pass_calander'>
                                                            <input type='date' className="form-control" name="todate" min={this.state.fromdateValue ? this.state.fromdateValue : ''} onChange={this.setDateReport} value={this.state.todateValue}></input>
                                                            <i className="fa fa-calendar-check-o" style={{ cursor: 'pointer', fontSize: '20px', opacity: '0.7' }}></i>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-2 col-md-4 col-sm-6 col-xl-3">
                                                        <div className="form-group">
                                                            <label className='form-label'>Archived Reports</label>
                                                            <div className="selectgroup w-100">
                                                                <label className="selectgroup-item" style={{ zIndex: 0 }}>
                                                                    <input type="radio" name="month" className="selectgroup-input" value={0} onChange={this.getMonthlyReport} checked={this.state.month === '0'} />
                                                                    <span className="selectgroup-button first_month">{this.getMonths(0)}</span>
                                                                </label>
                                                                <label className="selectgroup-item" style={{ zIndex: 0 }}>
                                                                    <input type="radio" name="month" className="selectgroup-input" value={1} onChange={this.getMonthlyReport} checked={this.state.month === '1'} />
                                                                    <span className="selectgroup-button">{this.getMonths(1)}</span>
                                                                </label>
                                                                <label className="selectgroup-item" style={{ zIndex: 0 }}>
                                                                    <input type="radio" name="month" className="selectgroup-input" value={2} onChange={this.getMonthlyReport} checked={this.state.month === '2'} />
                                                                    <span className="selectgroup-button last_month">{this.getMonths(2)}</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {!this.state.isChartView && (
                                                    <div className="table-responsive">
                                                        <div className="ag-theme-alpine ticket_aggridrow">
                                                            <AgGridReact
                                                                key={this.state.perPage}
                                                                rowHeight={82}
                                                                paginationPageSize={this.state.perPage}
                                                                cacheBlockSize={this.state.perPage}
                                                                pagination={true}
                                                                // onRowClicked={this.onRowClicked}
                                                                domLayout={"autoHeight"}
                                                                defaultColDef={this.state.defaultColumDef}
                                                                columnDefs={this.state.columnDefs}
                                                                rowDragManaged={true}
                                                                animateRows={true}
                                                                onGridReady={this.onGridReady}
                                                                overlayNoRowsTemplate={"No rows to display"}
                                                                rowModelType={'infinite'}
                                                            >
                                                            </AgGridReact>
                                                        </div>
                                                        {
                                                            (this.state.tickets && this.state.tickets.length == 0)
                                                            &&
                                                            <span className='ag_grid_no_record_show_span' >No rows to display</span>
                                                        }
                                                    </div>
                                                )}
                                                <div className='row'>
                                                    {!this.state.isChartView && (<><h2 className="card-title mt-20">Summary Report</h2></>)}
                                                    {!this.state.isChartView && (
                                                        <div className="col-xl-5 col-lg-5 col-md-5 mt-10">
                                                            <div className="card report_table_open_count">
                                                                <div className="row text-center">
                                                                    <div className="Over_all_table_style table-responsive">

                                                                        <h6 className="tx-medium text-left">
                                                                            Status(Created/Completed) Wise Ticket Count
                                                                            <span
                                                                                style={{ cursor: "pointer" }}
                                                                            ></span>
                                                                        </h6>

                                                                        <table className="table table-striped table-vcenter mb-0">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th className="tx-medium text-left">
                                                                                        Status
                                                                                    </th>
                                                                                    <th className="text-right">
                                                                                        Incident Ticket Count
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td className="tx-medium text-left">
                                                                                        Created
                                                                                    </td>
                                                                                    <td className="text-right">
                                                                                        {reasonstatusfalse}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className="tx-medium text-left">
                                                                                        Completed
                                                                                    </td>
                                                                                    <td className="text-right">
                                                                                        {reasonstatustrue}
                                                                                    </td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td className="tx-medium text-left">
                                                                                        Total
                                                                                    </td>
                                                                                    <td className="text-right">
                                                                                        {reasonstatusfalse + reasonstatustrue}
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>)}

                                                    {this.state.isChartView && (
                                                        <div className="col-xl-5 col-lg-5 col-md-5 mt-10">
                                                            <div className="card report_table_open_count">
                                                                <div className="row text-center">
                                                                    <div className="Over_all_table_style table-responsive">
                                                                        <h6 className="tx-medium text-left">
                                                                            Status(Created/Completed) Wise Ticket Count
                                                                            <span
                                                                                style={{ cursor: "pointer" }}
                                                                            ></span>
                                                                        </h6>

                                                                        <table className="table table-striped table-vcenter mb-0">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th className="tx-medium text-left">
                                                                                        Status
                                                                                    </th>
                                                                                    <th className="text-right">
                                                                                        Incident Ticket Count
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td className="tx-medium text-left">
                                                                                        Created
                                                                                    </td>
                                                                                    <td className="text-right">
                                                                                        {totalSumofcreated}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className="tx-medium text-left">
                                                                                        Completed
                                                                                    </td>
                                                                                    <td className="text-right">
                                                                                        {totalSumofcompleted}
                                                                                    </td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td className="tx-medium text-left">
                                                                                        Total
                                                                                    </td>
                                                                                    <td className="text-right">
                                                                                        {totalSumofcreated + totalSumofcompleted}
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {this.state.isChartView && (
                                                        <>

                                                            <div className="col-xl-6 col-lg-6 col-md-6 mt-10">
                                                                <div className="card">
                                                                    <div className="row">
                                                                        <div className="col-sm-12 pb-4 pt-1">
                                                                            <Chart
                                                                                options={options}
                                                                                series={series}
                                                                                type="line"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                    </div>
                </div>
                <div id="overlay" style={{ display: this.state.spinner ? 'block' : 'none' }}></div>
                {
                    this.state.spinner ?
                        <div className='common_loader'>
                            <img className='loader_img_style_common' src='../../assets/images/load.png' />
                            <Spinner className='spinner_load_common' animation="border" variant="info" >
                            </Spinner>
                        </div> : ""
                }
            </>
        )
    }
}
const mapStateToProps = state => ({
    AccountState: state.account,
    LandingState: state.landing,
})
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ListIncidentTicket);