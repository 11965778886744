import React, { Component } from 'react';
import AgGridComponent from '../../../utilities/AgGridReact/aggrid';
import moment from 'moment';

class TodoList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columnDefs: [
                { headerName: 'Title', field: 'title', width: 200, floatingFilter: true },
                {
                    headerName: 'Description', field: 'description', width: 200, floatingFilter: true,
                    valueGetter: function (params) {
                        if (params.data)
                            if (params.data.description) {
                                return params.data.description
                            } else {
                                return "---"
                            }
                    }
                },
                {
                    headerName: 'Start Date', field: 'start', width: 200, floatingFilter: true,
                    valueGetter: function (params) {
                        if (params.data)
                            if (params.data.start) {
                                return moment(params.data.start).format(params.data.dFormat)
                            } else {
                                return "---"
                            }
                    }
                },
                {
                    headerName: 'Assigned By', field: 'createdby', width: 200, floatingFilter: true,
                    valueGetter: function (params) {
                        if (params.data)
                            if (params.data.createdby) {
                                return params.data.createdby
                            } else {
                                return "---"
                            }
                    }
                },
                {
                    headerName: 'Assigned To', field: 'users', width: 200, floatingFilter: true,
                    valueGetter: function (params) {
                        if (params.data)
                            if (params.data.users) {
                                return params.data.users
                            } else {
                                return "---"
                            }
                    }
                },
                
                {
                    headerName: 'Status', width: 250, field: 'todoprogress', cellStyle: { 'text-align': 'center' }, suppressMenu: true,
                    cellRenderer: function (params) {
                        if (params.value === 'inprogress') {
                            return '<span class="tag ticket_inprogress">Inprogress</span>';
                        } else if (params.value === 'completed') {
                            return '<span class="tag ticket_completed">Completed</span>';
                        } else if (params.value == 'upcoming') {
                            return `<span class="tag ticket_open">Upcoming</span>`;
                        } else if (params.value == 'todo') {
                            return `<span class="tag ticket_open">Todo</span>`;
                        } else if (params.value == 'pending') {
                            return `<span class="tag ticket_inreview">Pending</span>`;
                        }
                    }
                },
                {
                    headerName: 'Actions', width: 150, field: 'actions', headerClass: 'ag-center-header', cellStyle: { 'text-align': 'center' }, suppressMenu: true, sortable: false, filter: false,
                    cellRenderer: function (params) {
                        if (params.value) {
                            return params.value;
                        } else {
                            return "---"
                        }
                    }
                },
            ],
            defaultColumDef: {
                editable: false,
                sortable: true,
                resizable: true,
                filter: true,
                flex: 1,
                minWidth: 100,
            },
            perPage: 25,
        }
    }
    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
        this.setState({ gridApi: params.api })
    };
    onRowClicked = event => {
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        if (value === 'View' || value === 'Edit') {
            this.props.onChangeAction(event.data.id, value, event.data.start);
        }
    }
    handleFilterChanged = () => {
        const api = this.gridApi;
        if (api && api.getDisplayedRowCount() === 0) {
            api.showNoRowsOverlay();
            this.setState({ hideExport: true })
        } else {
            api.hideOverlay();
            this.setState({ hideExport: false })
        }
    };
    childFunction = (hotelName) => {
        const account = this.props.AccountState.account;
        const dFormat = (account.dateformate != '' ? account.dateformate : 'MM/DD/YYYY')
        var firstRow = this.gridApi.getFirstDisplayedRow();
        var lastRow = this.gridApi.getLastDisplayedRow();
        this.gridApi.exportDataAsCsv({
            columnKeys: ['title', 'description','createdby','users', 'start'],
            fileName: "allmytask" + "_" + (hotelName && (hotelName).replace(/ /g, '_')) + (account.currentTime).replace(/ /g, '_') + ".csv",
            shouldRowBeSkipped: (params) => {
                return params.node.rowIndex < firstRow || params.node.rowIndex > lastRow;
            },
            processCellCallback: function (params) {
                if (params && params.column && params.column.colId === 'start') {
                    return moment(params.value).format(dFormat);
                } else {
                    return params.value;
                }
            }
        })
    }
    render() {
        const { rowData, spinner, AccountState } = this.props;
        const account = AccountState.account;
        const previleges = AccountState.previleges;
        const Previlege = previleges.find(obj => { return obj.name == "Todo_List" });
        const editOption = Previlege.edit ? '<button type="button" class="btn btn-icon" title="Edit" data-action-type="Edit"><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit"></i></button>' : '';
        const dFormat = (account.dateformate != '' ? account.dateformate : 'MM-DD-YYYY');
         if (rowData) {
            rowData.forEach(object => {
                const isCompletedOrPending = object.todoprogress === 'completed' || object.todoprogress === 'pending'; // Check if todoprogress is "completed" or "pending"
                
                object.actions = 
                    '<div>' +
                    '<button title="View" type="button" class="btn btn-icon" data-action-type="View" data-toggle="modal" data-target="#exampleTodoModal">' +
                    '<i class="fa fa-eye" style="color: #1DC9B7 !important" data-action-type="View"></i>' +
                    '</button>' +
                    // Show the edit button only if todoprogress is not "completed" or "pending"
                    (!isCompletedOrPending 
                        ? (object.iseditable ? editOption : '<button type="button" class="btn btn-icon"><i class="fa fa-edit" style="color: #2196F3 !important' + (object.iseditable ? '' : '; visibility: hidden;') + '" ></i></button>')
                        : ''
                    ) +
                    '</div>';
        
                object.dFormat = dFormat;
            });
        }
        
        
        return (
            <>
                <AgGridComponent 
                spinner={spinner} 
                rowData={rowData} 
                state={this.state} 
                onRowClicked={(e) => this.onRowClicked(e)} 
                onGridReady={(e) => this.onGridReady(e)} 
                handleFilterChanged={(e) => this.handleFilterChanged(e)} 
                overlay={true} />
            </>
        )
    }
}

export default TodoList;
