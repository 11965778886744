import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, Redirect } from "react-router-dom";
import ImportedURL from "../../../common/api";
import { HiOutlineChevronRight, HiOutlineChevronLeft } from "react-icons/hi";
import Select from "react-select";
import moment from "moment";
import {
  AC_EMPTY_TO_DO_LIST,
  AC_LIST_TO_DO_LIST_REPORT,
  AC_MODAL_SPINNER_TO_DO_LIST,
  AC_SPINNER_TO_DO_LIST,
  AC_VIEW_TO_DO_LIST,
} from "../../../actions/todolistAction";
import axios from "axios";
import AgGridComponent from "../../../utilities/AgGridReact/aggrid";
import Viewmodal from "../Allmytask/viewmodal";
import HotelSelectComponent from "../../../utilities/SelectOption/hotelselect";
import SelectComponent from "../../../utilities/SelectOption/select";
import { AC_LIST_USER } from "../../../actions/userAction";
import { AC_LIST_CONFIGOPTIONS_TODO_STATUS } from "../../../actions/configurationAction";
import { imageCellRendererList } from "../../../common/validate";
import Spinner from "../../../utilities/Spinner/spinner";
import Swal from "sweetalert2";

class TodoReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: [
        {
          headerName: "Logo",
          field: "logo",
          sortable: false,
          hide: true,
          resizable: false,
          width: 100,
          cellStyle: { "text-align": "center" },
          cellRenderer: imageCellRendererList,
        },
        { headerName: "Hotel", field: "hotel", hide: true, width: 200 },
        {
          headerName: "Title",
          field: "title",
          width: 200,
          floatingFilter: true,
        },
        {
          headerName: "Description",
          field: "description",
          width: 200,
          floatingFilter: true,
          valueGetter: function (params) {
            if (params.data)
              if (params.data.description) {
                return params.data.description;
              } else {
                return "---";
              }
          },
        },
        {
          headerName: "User Category",
          field: "todousercategory",
          width: 200,
          floatingFilter: true,
        },
        {
          headerName: "User Name",
          field: "todouser",
          width: 200,
          floatingFilter: true,
        },
        {
          headerName: "Start Date",
          field: "start",
          width: 200,
          floatingFilter: true,
          valueGetter: function (params) {
            if (params.data)
              if (params.data.start) {
                return moment(params.data.start).format(params.data.dFormat);
              } else {
                return "---";
              }
          },
        },
        {
          headerName: "Frequency",
          field: "duration",
          width: 200,
          floatingFilter: true,
        },
        {
          headerName: "Priority",
          field: "taskpriority",
          width: 200,
          floatingFilter: true,
          valueGetter: (params) => {
            // Define a mapping of priority values to their corresponding labels
            const priorityMapping = {
              1: "High",
              2: "Medium",
              3: "Low",
            };

            // Get the taskpriority from the params data
            const priorityValue = params.data.taskpriority;

            // Return the corresponding label based on the value
            return priorityMapping[priorityValue] || "Unknown"; // Default to 'Unknown' if value is not found
          },
        },

        {
          headerName: "Mandatory Compliances",
          field: "mandatorycompliances",
          width: 200,
          floatingFilter: true,
          valueGetter: (params) => {
            const compliance = params.data.mandatorycompliances;
            if (compliance === true) {
              return "Mandatory";
            } else if (compliance === false) {
              return "Non-Mandatory";
            } else {
              return "----"; // Handle blank or null/undefined
            }
          },
        },
        {
          headerName: "Status",
          field: "isdeleted",
          width: 200,
          floatingFilter: true,
          cellRenderer: (params) => {
            const del = params.data.isdeleted;
            if (del === true) {
              return '<span class="tag ticket_completed">Active</span>';
            } else if (del === false) {
              return '<span class="tag ticket_inreview">InActive</span>';
            } else {
              return "<span>----</span>"; // Handle blank or null/undefined
            }
          },
        },

        {
          headerName: "Task Status",
          width: 250,
          field: "todostatus",
          cellStyle: { "text-align": "center" },
          suppressMenu: true,
          cellRenderer: function (params) {
            if (params.value === "inprogress") {
              return '<span class="tag ticket_inprogress">Inprogress</span>';
            } else if (params.value === "completed") {
              return '<span class="tag ticket_completed">Completed</span>';
            } else if (params.value == "upcoming") {
              return `<span class="tag ticket_open">Upcoming</span>`;
            } else if (params.value == "todo") {
              return `<span class="tag ticket_open">Todo</span>`;
            } else if (params.value == "pending") {
              return `<span class="tag ticket_inreview">Pending</span>`;
            }
          },
        },
        {
          headerName: "Actions",
          width: 100,
          field: "actions",
          headerClass: "ag-center-header",
          cellStyle: { "text-align": "center" },
          suppressMenu: true,
          sortable: false,
          filter: false,
          cellRenderer: function (params) {
            if (params.value) {
              return params.value;
            } else {
              return "---";
            }
          },
        },
      ],
      defaultColumDef: {
        editable: false,
        sortable: true,
        resizable: true,
        filter: false,
        flex: 1,
        minWidth: 140,
      },
      perPage: 25,
      searchhoteloption: { label: "All", value: "", name: "searchhotel" },
      searchcategoryoption: { label: "All", value: "", name: "searchcategory" },
      searchuseroption: { label: "All", value: "", name: "searchuser" },
      searchdurationoption: { label: "All", value: "", name: "searchduration" },
      searchmandstatusoption: {
        label: "All",
        value: "",
        name: "searchmandstatus",
      },
      searchdeletedstatusoption: {
        label: "All",
        value: "",
        name: "searchdeletedstatus",
      },
      searchpriorityoption: { label: "All", value: "", name: "searchpriority" },
      editStatus: false,
      isFilterShow: false,
      id: "",
      calendarType: "months",
      startdate: "",
      enddate: "",
      pagecount: 0,
      date: "",
      columnreset: true,
      totalStatus: true,
      todoStatus: false,
      inprogressStatus: false,
      completedStatus: false,
      pendingStatus: false,
      durationState: [], // This should be populated from an API or other sources
      complianceStatus: this.props.complianceStatus || {
        isMandatory: false,
      },
    };
  }
  componentDidMount() {
    const account = this.props.AccountState.account;
    this.props.ListUser({ status: true });
    this.props.ListTodoStatus({ parentslug: "todo-status" });
    let date = moment(new Date()).format("MM-DD-YYYY hh:mm A");
    this.setState({
      hotelName: account.hotelName ? account.hotelName : "",
      hotel: account.hotel ? account.hotel : "",
      startdate: date,
    });
    this.getDates(0, this.state.calendarType);
    this.fetchDurationFromConfiguration();
  }
  componentDidUpdate(prevProps, prevState) {
    const { AccountState } = this.props;
    const role = AccountState.role;
    if (
      this.gridApi &&
      role &&
      ImportedURL.SUPERADMIN.includes(role) &&
      this.state.columnreset
    ) {
      let updatedColumnDefs = [...this.state.columnDefs];
      updatedColumnDefs = updatedColumnDefs.map((col) => {
        if (col.field === "hotel" || col.field === "logo") {
          return { ...col, hide: false };
        }
        return col;
      });
      this.gridApi.setColumnDefs(updatedColumnDefs);
      this.setState({ columnreset: false });
    }
  }
  apiCall = (query = {}) => {
    this.props.spinnerTodo();
    this.props.ListTodoReport({
      startdate: this.state.startdate,
      enddate: this.state.enddate,
      hotel: this.state.hotel,
      category: this.state.category,
      user: this.state.user,
      duration: this.state.searchdurationoption.value,
      todostatus: this.state.todostatus,
      ...query,
    });
  };
  viewTodo = (id, date = "") => {
    this.setState({ id: id, date: date });
    this.props.spinnerModalTodo();
    this.props.ViewTodo(id);
  };

  onChangePage = (type) => {
    let count = this.state.pagecount;
    if (type === "next") {
      count++;
    } else if (type === "previous") {
      count--;
    } else if (type === "today") {
      count = 0;
    }
    this.setState({ pagecount: count });
    this.getDates(count, this.state.calendarType);
  };

  onChangeCalendar = (type) => {
    this.setState({ calendarType: type, pagecount: 0 }, () => {
      this.getDates(0, type);
    });
  };

  getDates = (count, calendarType) => {
    const { tzone } = this.props.AccountState.account;
    let currentDate = moment().tz(tzone);
    let name = calendarType ? calendarType.slice(0, -1) : "";
    let startOfDate = currentDate
      .clone()
      .add(count, calendarType)
      .startOf(name);
    let endOfDate = currentDate.clone().add(count, calendarType).endOf(name);
    let start = moment(startOfDate).format("YYYY-MM-DD");
    let end = moment(endOfDate).format("YYYY-MM-DD");
    this.setState({ startdate: start, enddate: end });
    this.apiCall({ startdate: start, enddate: end });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.setState({ gridApi: params.api });
  };
  onRowClicked = (event) => {
    const rowValue = event.event.target;
    const value = rowValue.getAttribute("data-action-type");
    if (value === "View") {
      this.setState({ id: event.data.id, date: event.data.start });
      this.props.EmptyTodoList();
      this.props.spinnerModalTodo();
      this.props.ViewTodo(event.data.id);
    }
  };
  handleFilterChanged = () => {
    const api = this.gridApi;
    if (api && api.getDisplayedRowCount() === 0) {
      api.showNoRowsOverlay();
      this.setState({ hideExport: true });
    } else {
      api.hideOverlay();
      this.setState({ hideExport: false });
    }
  };
  getDasbBoardName = (name) => {
    const listTodoStatusArray = this.props.ConfigurationState.listTodoStatus;
    let find = listTodoStatusArray.find((e) => e.suboption == name);
    return find ? find.name : "";
  };
  searchSelect = (e) => {
    const account = this.props.AccountState.account;
    const { name, value, label } = e;
    let apiName = name ? name.replace(/search/g, "") : "";
    this.setState(
      {
        [name + "option"]: e,
        [name]: value,
        [apiName]: value,
      },
      () => {}
    );

    if (name == "searchhotel") {
      this.apiCall({
        [apiName]: value,
        category: "",
        category: this.state.searchcategoryoption.value,
        user: this.state.searchuseroption.value,
        duration: this.state.searchdurationoption.value,
        mandstatus: this.state.searchmandstatusoption.value,
        priority: this.state.searchpriorityoption.value,
        deletedstatus: this.searchdeletedstatusoption,
      });
      this.setState({
        hotelName: label,
        searchcategory: "",
        searchuser: "",
        category: "",
        user: "",
        searchcategoryoption: {
          label: "All",
          value: "",
          name: "searchcategory",
        },
      });
      if (label == "All") this.setState({ isFilterShow: false });
      else this.setState({ isFilterShow: true });
    } else if (name == "searchcategory") {
      this.apiCall({
        [apiName]: value,
        user: this.state.searchuseroption.value,
        duration: this.state.searchdurationoption.value,
        mandstatus: this.state.searchmandstatusoption.value,
        priority: this.state.searchpriorityoption.value,
        deletedstatus: this.searchdeletedstatusoption,
      });
      this.setState({
        searchuser: "",
        user: "",
        searchuseroption: { label: "All", value: "", name: "searchuser" },
      });
      if (label == "All" && !ImportedURL.SUPERADMIN.includes(account.role))
        this.setState({ isFilterShow: false });
      else this.setState({ isFilterShow: true });
    } else if (name == "searchduration") {
      this.apiCall({
        [apiName]: value,
        category: this.state.searchcategoryoption.value,
        user: this.state.searchuseroption.value,
        mandstatus: this.state.searchmandstatusoption.value,
        priority: this.state.searchpriorityoption.value,
        deletedstatus: this.searchdeletedstatusoption,
      });
      this.setState({ searchduration: "", duration: "" });
      if (label == "All" && !ImportedURL.SUPERADMIN.includes(account.role))
        this.setState({ isFilterShow: false });
      else this.setState({ isFilterShow: true });
    } else if (name == "searchmandstatus") {
      this.apiCall({
        [apiName]: value,
        user: this.state.searchuseroption.value,
        category: this.state.searchcategoryoption.value,
        duration: this.state.searchdurationoption.value,
        priority: this.state.searchpriorityoption.value,
        deletedstatus: this.searchdeletedstatusoption,
      });
      this.setState({ searchmandstatus: "", mandstatus: "" });
      if (label == "All" && !ImportedURL.SUPERADMIN.includes(account.role))
        this.setState({ isFilterShow: false });
      else this.setState({ isFilterShow: true });
    } else if (name == "searchdeletedstatus") {
      this.apiCall({
        [apiName]: value,
        user: this.state.searchuseroption.value,
        category: this.state.searchcategoryoption.value,
        duration: this.state.searchdurationoption.value,
        priority: this.state.searchpriorityoption.value,
      });
      this.setState({ searchdeletedstatus: "", deletedstatus: "" });
      if (label == "All" && !ImportedURL.SUPERADMIN.includes(account.role))
        this.setState({ isFilterShow: false });
      else this.setState({ isFilterShow: true });
    } else if (name == "searchpriority") {
      this.apiCall({
        [apiName]: value,
        user: this.state.searchuseroption.value,
        category: this.state.searchcategoryoption.value,
        mandstatus: this.state.searchmandstatusoption.value,
        duration: this.state.searchdurationoption.value,
        deletedstatus: this.searchdeletedstatusoption,
      });
      this.setState({ searchpriority: "", priority: "" });
      if (label == "All" && !ImportedURL.SUPERADMIN.includes(account.role))
        this.setState({ isFilterShow: false });
      else this.setState({ isFilterShow: true });
    } else {
      this.apiCall({
        [apiName]: value,
        duration: this.state.searchdurationoption.value,
        category: this.state.searchcategoryoption.value,
        mandstatus: this.state.searchmandstatusoption.value,
        priority: this.state.searchpriorityoption.value,
        deletedstatus: this.searchdeletedstatusoption,
      });
    }
  };

  // setDateReport = (event) => {
  //     const { name, value } = event.target;
  //     console.log(name,value)
  //     if (name ==='startdate' && value =='')
  //     {
  //         console.log("blank start value")
  //         this.setState({enddate:''})
  //     }
  //     if(name ==='enddate' && value =='')
  //     {
  //         console.log("blank endvalue value")
  //     }
  //     this.setState(
  //         { [name]: value }, // Dynamically update the state for the corresponding field name
  //         () => {
  //             const { startdate, enddate, selectedFilter } = this.state; // Get the updated start and end date from the state
  //             if (selectedFilter === "All") {
  //                 this.setState({ isFilterShow: false });
  //             } else {
  //                 this.setState({ isFilterShow: true });
  //             }
  //             this.apiCall({ startdate, enddate }); // Pass the updated dates to the API call
  //         }
  //     );
  // };

  setDateReport = (event) => {
    const { name, value } = event.target;

    // Check if both startdate and enddate are empty
    const { startdate, enddate } = this.state;
    if (name === "startdate" && value === "") {
      this.setState({ enddate: "" });
    }
    if (name === "enddate" && value === "") {
    }

    // Update state for the corresponding field name
    this.setState({ [name]: value }, () => {
      const { startdate, enddate, selectedFilter } = this.state; // Get the updated start and end date from the state

      // Show error if both dates are blank
      if (!startdate || !enddate) {
        Swal.fire({
          icon: "error",
          title: "Missing Dates",
          text: "Both start date and end date are required to fetch records.",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            // Set current date as both startdate and enddate
            this.getDates(0, this.state.calendarType);
          }
        });
        return; // Prevent further execution
      }

      // Proceed with filtering logic if dates are valid
      if (selectedFilter === "All") {
        this.setState({ isFilterShow: false });
      } else {
        this.setState({ isFilterShow: true });
      }
      this.apiCall({ startdate, enddate }); // Pass the updated dates to the API call
    });
  };

  exportToCSV = () => {
    const account = this.props.AccountState.account;
    const dFormat =
      account.dateformate != "" ? account.dateformate : "MM-DD-YYYY";
    var firstRow = this.gridApi.getFirstDisplayedRow();
    var lastRow = this.gridApi.getLastDisplayedRow();
    this.gridApi.exportDataAsCsv({
      columnKeys:
        this.state.hotelName && this.state.hotelName != "All"
          ? [
              "title",
              "description",
              "todousercategory",
              "todouser",
              "duration",
              "mandatorycompliances",
              "isdeleted",
              "todostatus",
              "taskpriority",
              "start",
            ]
          : [
              "hotel",
              "title",
              "description",
              "todousercategory",
              "todouser",
              "duration",
              "mandatorycompliances",
              "isdeleted",
              "todostatus",
              "taskpriority",
              "start",
            ],
      fileName:
        "todoreport" +
        "_" +
        (this.state.hotelName && this.state.hotelName != "All"
          ? this.state.hotelName.replace(/ /g, "_")
          : "") +
        account.currentTime.replace(/ /g, "_") +
        ".csv",
      shouldRowBeSkipped: (params) => {
        return (
          params.node.rowIndex < firstRow || params.node.rowIndex > lastRow
        );
      },
      processCellCallback: function (params) {
        if (params && params.column && params.column.colId === "start") {
          return moment(params.value).format(dFormat);
        }
        // Check if the column is 'isdeleted' and return 'Active' or 'Inactive'
        else if (
          params &&
          params.column &&
          params.column.colId === "isdeleted"
        ) {
          return params.value === true ? "Active" : "Inactive";
        } else if (
          params &&
          params.column &&
          params.column.colId === "todostatus"
        ) {
          return params.value
            ? params.value.charAt(0).toUpperCase() +
                params.value.slice(1).toLowerCase()
            : "";
        } else {
          return params.value;
        }
      },
    });
  };

  exportPDF = () => {
    const languageData = this.props.AccountState.account.SelectedlanguageData;
    const account = this.props.AccountState.account;
    const dFormat =
      account.dateformate != "" ? account.dateformate : "MM-DD-YYYY";
    const api = this.gridApi;
    const currentPage = api.paginationGetCurrentPage(); // Get the current page number
    const pageSize = api.paginationGetPageSize(); // Get the page size (number of rows per page)
    const startRow = currentPage * pageSize; // Calculate the starting row index
    const endRow = (currentPage + 1) * pageSize; // Calculate the ending row index
    const filteredRows = api.getModel().rowsToDisplay.slice(startRow, endRow); // Get the rows on the current page
    const exportData = filteredRows.map((rowNode) => rowNode.data);

    const selectedProperties =
      this.state.hotelName && this.state.hotelName != "All"
        ? [
            "title",
            "description",
            "todousercategory",
            "todouser",
            "duration",
            "mandatorycompliances",
            "isdeleted",
            "todostatus",
            "taskpriority",
            "start",
          ]
        : [
            "hotel",
            "title",
            "description",
            "todousercategory",
            "todouser",
            "duration",
            "mandatorycompliances",
            "isdeleted",
            "todostatus",
            "taskpriority",
            "start",
          ];
    const selected = exportData.map((item, i) => {
      const selectedData = {};
      selectedProperties.forEach((property) => {
        if (item.hasOwnProperty(property)) {
          if (property == "start") {
            selectedData[property] = item[property]
              ? moment(item[property]).format(dFormat)
              : "---";
          } else if (property === "mandatorycompliances") {
            // Handle true/false/null for mandatorycompliances
            selectedData[property] =
              item[property] === true
                ? "Mandatory"
                : item[property] === false
                ? "Non-Mandatory"
                : "---";
          } else if (property === "isdeleted") {
            // Handle true/false/null for mandatorycompliances
            selectedData[property] =
              item[property] === true
                ? "Active"
                : item[property] === false
                ? "InActive"
                : "---";
          } else if (property === "taskpriority") {
            // Convert priority to readable string or use "---"
            selectedData[property] =
              item[property] == "1"
                ? "High"
                : item[property] == "2"
                ? "Medium"
                : item[property] == "3"
                ? "Low"
                : "---";
          } else if (property === "todostatus") {
            // Convert the value of todostatus to uppercase
            selectedData[property] = item[property]
              ? item[property].charAt(0).toUpperCase() +
                item[property].slice(1).toLowerCase()
              : "---";
          } else {
            selectedData[property] = item[property] ? item[property] : "---";
          }
        }
      });
      return selectedData;
    });
    let data = {
      title:
        (this.state.hotelName && this.state.hotelName != "All"
          ? this.state.hotelName + " - "
          : "") +
        (languageData && languageData.TODO_REPORT
          ? languageData.TODO_REPORT
          : "To-Do Report"),
      head:
        this.state.hotelName && this.state.hotelName != "All"
          ? [
              "#",
              "Title",
              "Description",
              "User Category",
              "User Name",
              "Frequency",
              "Mandatory Compliances",
              "Status",
              "Task Status",
              "Priority",
              "Start Date",
            ]
          : [
              "#",
              "Hotel",
              "Title",
              "Description",
              "User Category",
              "User Name",
              "Frequency",
              "Mandatory Compliances",
              "Status",
              "Task Status",
              "Priority",
              "Start Date",
            ],
      body: selected,
      hotelNameExport:
        this.state.hotelName && this.state.hotelName != "All"
          ? account.hotelNameExport
          : "",
      hoteladdressExport:
        this.state.hotelName && this.state.hotelName != "All"
          ? account.hoteladdressExport
          : "",
      limit: this.state.perPage,
    };
    this.setState({ spinner: true });
    axios
      .post(ImportedURL.API.downloadPdf, data, { responseType: "blob" })
      .then((res) => {
        const blob = new Blob([res.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download =
          "todoreport" +
          "_" +
          (this.state.hotelName && this.state.hotelName != "All"
            ? this.state.hotelName.replace(/ /g, "_")
            : "") +
          account.currentTime.replace(/ /g, "_") +
          ".pdf";
        link.click();
        link.remove();
        window.URL.revokeObjectURL(link.href);
        this.setState({ spinner: false });
      })
      .catch(({ err }) => {
        this.setState({ spinner: false });
      });
  };
  total = (e) => {
    this.setState({
      todostatus: "",
      totalStatus: true,
      todoStatus: false,
      inprogressStatus: false,
      completedStatus: false,
      pendingStatus: false,
    });
    this.apiCall({ todostatus: "" });
  };
  todo = (e) => {
    this.setState({
      todostatus: "todo",
      totalStatus: false,
      todoStatus: true,
      inprogressStatus: false,
      completedStatus: false,
      pendingStatus: false,
    });
    this.apiCall({ todostatus: "todo" });
  };
  inprogress = (e) => {
    this.setState({
      todostatus: "inprogress",
      totalStatus: false,
      todoStatus: false,
      inprogressStatus: true,
      completedStatus: false,
      pendingStatus: false,
    });
    this.apiCall({ todostatus: "inprogress" });
  };
  completed = (e) => {
    this.setState({
      todostatus: "completed",
      totalStatus: false,
      todoStatus: false,
      inprogressStatus: false,
      completedStatus: true,
      pendingStatus: false,
    });
    this.apiCall({ todostatus: "completed" });
  };
  pending = (e) => {
    this.setState({
      todostatus: "pending",
      totalStatus: false,
      todoStatus: false,
      inprogressStatus: false,
      completedStatus: false,
      pendingStatus: true,
    });
    this.apiCall({ todostatus: "pending" });
  };
  filterReset = (e) => {
    const role = this.props.AccountState.role;
    if (role && ImportedURL.SUPERADMIN.includes(role)) {
      this.setState({
        searchhoteloption: { label: "All", value: "", name: "searchhotel" },
        searchhotel: "",
        hotel: "",
      });
    }
    this.getDates(0, this.state.calendarType);
    this.setState({
      isFilterShow: false,
      todostatus: "",
      totalStatus: true,
      todoStatus: false,
      inprogressStatus: false,
      completedStatus: false,
      pendingStatus: false,
      searchcategoryoption: { label: "All", value: "", name: "searchcategory" },
      searchuseroption: { label: "All", value: "", name: "searchuser" },
      searchcategory: "",
      searchuser: "",
      category: "",
      user: "",
      searchdurationoption: { label: "All", value: "", name: "searchduration" },
      searchmandstatusoption: {
        label: "All",
        value: "",
        name: "searchmandstatus",
      },
      searchpriorityoption: { label: "All", value: "", name: "searchpriority" },
      searchdeletedstatusoption: {
        label: "All",
        value: "",
        name: "searchdeletedstatus",
      },
      // startdate:this.state.start,
      // enddate:this.state.end
    });

    this.apiCall({
      hotel: "",
      category: "",
      user: "",
      todostatus: "",
      duration: "",
      mandstatus: "",
      startdate: this.state.start,
      enddate: this.state.end,
      deletedstatus: "",
    });
  };
  fetchDurationFromConfiguration = () => {
    const { account } = this.props.AccountState;
    const languageData = account.SelectedlanguageData;
    axios
      .get(ImportedURL.API.fetchDurationFromConfiguration)
      .then((res) => {
        if (res.data) {
          this.setState({ durationState: res.data });
        }
      })
      .catch((error) => {
        const { response } = error;
        const status = response?.status;
        const statusText = response?.statusText ?? "An error occurred";
        switch (status) {
          case 500:
            Error(
              `${status}: ${
                languageData?.INTERNAL_SERVER_ERROR ?? "Internal Server Error"
              }`
            );
            break;
          case 502:
            Error(`${status}: ${languageData?.BAD_GATEWAY ?? "Bad Gateway"}`);
            break;
          default:
            Error(`${status}: ${statusText}`);
            break;
        }
      });
  };
  //   handleChange = (e) => {
  // this.setState({ selectedDuration: e.target.value });
  //};
  render() {
    if (this.state.editStatus)
      return <Redirect to={"edit-todo-list/" + this.state.id} />;

    const {
      AccountState,
      TodoListState,
      LandingState,
      UserState,
      ConfigurationState,
    } = this.props;
    const account = AccountState.account;
    const languageData = AccountState.account.languageData;
    const listHotels =
      LandingState.landingdata &&
      LandingState.landingdata.data &&
      LandingState.landingdata.data.length > 0
        ? LandingState.landingdata.data[0]
        : [];
    const listCategory =
      LandingState.landingdata &&
      LandingState.landingdata.data &&
      LandingState.landingdata.data.length > 0
        ? LandingState.landingdata.data[7]
        : [];
    const listUser = UserState.listusers;
    const rowData = TodoListState.todolistreport;
    const count = TodoListState.count;
    const spinner = TodoListState.spinner;
    const modalspinner = TodoListState.modalspinner;
    const data = TodoListState.todo;

    const dFormat =
      account.dateformate != "" ? account.dateformate : "MM-DD-YYYY";
    const template =
      '<div><button title="View" type="button" class="btn btn-icon" data-action-type="View" data-toggle="modal" data-target="#exampleTodoModal" ><i class="fa fa-eye" style="color: #1DC9B7 !important" data-action-type="View"></i></button></div>';
    if (rowData) {
      rowData.forEach((object) => {
        object.actions = template;
        object.dFormat = dFormat;
      });
    }

    const priorityList = [
      { value: "1", label: "High", status: true },
      { value: "2", label: "Medium", status: true },
      { value: "3", label: "Low", status: true },
    ];

    // Initialize the priorityOptions array
    let priorityOptions = [];

    // Add 'All' option if there is more than one status:true item
    if (
      priorityList.filter((filterItem) => filterItem.status === true).length > 1
    ) {
      priorityOptions.push({ label: "All", value: "", name: "searchpriority" });
    }

    // Filter priorityList based on the `status` being true and map to priorityOptions
    priorityOptions = priorityOptions.concat(
      priorityList
        .filter((item) => item.status === true) // Ensure status is true
        .map((item) => ({
          label: item.label, // Use 'label' instead of 'name' as in your original array
          value: item.value,
          name: "searchpriority",
        }))
    );
    // var durationOptions = [];
    const durationList = this.state.durationState;
    var durationOptions = [];
    if (
      durationList.filter((filterItem) => filterItem.status === true).length > 1
    ) {
      durationOptions.push({ label: "All", value: "", name: "searchduration" });
    }
    if (durationList.length > 0) {
      durationList
        .filter((filterItem) => filterItem.status === true)
        .map((item) => {
          durationOptions.push({
            label: `${item.name}`,
            value: item._id,
            name: "searchduration",
          });
        });
    }

    let displayDate = moment(this.state.startdate).format("MMMM YYYY");
    if (this.state.calendarType == "weeks") {
      let sameMonth =
        moment(this.state.startdate).format("MMMM") ==
        moment(this.state.enddate).format("MMMM");
      displayDate = sameMonth
        ? moment(this.state.startdate).format("MMM DD") +
          " - " +
          moment(this.state.enddate).format("DD, YYYY")
        : moment(this.state.startdate).format("MMM DD") +
          " - " +
          moment(this.state.enddate).format("MMM DD, YYYY");
    }
    if (this.state.calendarType == "days") {
      displayDate = moment(this.state.startdate).format("MMM DD, YYYY");
    }

    var hotelOptionSearch = [];
    if (
      listHotels.filter((filterItem) => filterItem.status === true).length > 1
    ) {
      hotelOptionSearch.push({ label: "All", value: "", name: "searchhotel" });
    }
    if (listHotels.length > 0) {
      listHotels
        .filter((filterItem) => filterItem.status === true)
        .map((item) => {
          hotelOptionSearch.push({
            label: `${item.name} ${item.city} ${item.state}`,
            value: item._id,
            logo: item.logo,
            name: "searchhotel",
          });
        });
    }

    var categoryOptionSearch = [];
    if (listCategory.length > 0) {
      let list = listCategory.filter(
        (filterItem) => filterItem.status === true
      );
      list.map((item) => {
        let userIncludes = listUser.some(
          (e) => e.categoryid == item._id && this.state.hotel == e.hotelid
        );
        if (userIncludes)
          categoryOptionSearch.push({
            label: `${item.name}`,
            value: item._id,
            name: "searchcategory",
          });
      });
    }
    if (categoryOptionSearch.length > 1) {
      categoryOptionSearch.unshift({
        label: "All",
        value: "",
        name: "searchcategory",
      });
    }

    var userOptionSearch = [];
    if (listUser.length > 0) {
      let list = listUser.filter((filterItem) => filterItem.status === true);
      list.map((item) => {
        if (
          this.state.hotel == item.hotelid &&
          this.state.searchcategory == item.categoryid
        ) {
          userOptionSearch.push({
            label: `${item.name}`,
            value: item._id,
            name: "searchuser",
          });
        }
      });
    }
    if (userOptionSearch.length > 1) {
      userOptionSearch.unshift({ label: "All", value: "", name: "searchuser" });
    }

    const limitOptions = [
      { value: "25", label: "25" },
      { value: "50", label: "50" },
      { value: "100", label: "100" },
    ];

    const mandatorycomplainanceList = [
      { value: true, label: "Mandatory", status: true },
      { value: false, label: "Non-Mandatory", status: true },
    ];

    var mandatoryOptions = [];
    const filtereddList = mandatorycomplainanceList.filter(
      (filterItem) => filterItem.status === true
    );
    if (filtereddList.length > 0) {
      filtereddList.map((item) => {
        mandatoryOptions.unshift({
          label: item.label,
          value: item.value,
          name: "searchmandstatus",
        });
      });
    }

    // If the filtered list has more than one option, add an 'All' option
    if (filtereddList.length > 1) {
      mandatoryOptions.unshift({
        label: "All",
        value: "",
        name: "searchmandstatus",
      });
    }

    const deletedList = [
      { value: true, label: "Active", status: true },
      { value: false, label: "Inactive", status: true },
    ];

    var deletedOptions = [];
    const filteredddeletedList = deletedList.filter(
      (filterItem) => filterItem.status === true
    );
    if (filteredddeletedList.length > 0) {
      filteredddeletedList.map((item) => {
        deletedOptions.unshift({
          label: item.label,
          value: item.value,
          name: "searchdeletedstatus",
        });
      });
    }

    // If the filtered list has more than one option, add an 'All' option
    if (filteredddeletedList.length > 1) {
      deletedOptions.unshift({
        label: "All",
        value: "",
        name: "searchdeletedstatus",
      });
    }

    return (
      <div>
        <div>
          <div className="breadcrump">
            <p>
              {" "}
              <Link to="/">
                <h6>
                  {languageData && languageData.DASHBOARD
                    ? languageData.DASHBOARD
                    : "Dashboard"}
                </h6>
              </Link>{" "}
              <span>
                <i className="fa fa-angle-right" aria-hidden="true"></i>{" "}
              </span>{" "}
              <h6 className="highlights_breadcrump">
                {languageData && languageData.TODO_REPORT
                  ? languageData.TODO_REPORT
                  : "To-Do Report"}
              </h6>
            </p>
          </div>
          <div className="section-body pt-3 list_ticket">
            <div className="container-fluid">
              <div className="row clearfix">
                <div className="col">
                  <div className="card">
                    <div
                      className="card-body ribbon"
                      style={{
                        backgroundColor: "#e9c1e4",
                        borderRadius: this.state.totalStatus ? "10px" : "",
                        boxShadow: this.state.totalStatus
                          ? "0 0 5px #0a0a0a"
                          : "",
                      }}
                    >
                      <div className="ribbon-box orange">
                        {count && count.total ? count.total : 0}
                      </div>
                      <a className="my_sort_cut" href="#" onClick={this.total}>
                        <span className="dash_img">
                          <img src="../../assets/images/totaltickets.png" />
                        </span>
                        <span className="dash_text">Total Todo</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card">
                    <div
                      className="card-body ribbon "
                      style={{
                        backgroundColor: "#a8e1ff",
                        borderRadius: this.state.todoStatus ? "10px" : "",
                        boxShadow: this.state.todoStatus
                          ? "0 0 5px #0a0a0a"
                          : "",
                      }}
                    >
                      <div className="ribbon-box orange">
                        {count && count.todo ? count.todo : 0}
                      </div>
                      <a
                        className="my_sort_cut text-muted"
                        href="#"
                        onClick={this.todo}
                      >
                        <span className="dash_img">
                          <img src="../../assets/images/opentickets.png" />
                        </span>
                        <span
                          style={{ color: "#444444" }}
                          className="dash_text"
                        >
                          {this.getDasbBoardName("todo")
                            ? this.getDasbBoardName("todo")
                            : "Todo"}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card">
                    <div
                      className="card-body ribbon"
                      style={{
                        backgroundColor: "#eeeaaf",
                        borderRadius: this.state.inprogressStatus ? "10px" : "",
                        boxShadow: this.state.inprogressStatus
                          ? "0 0 5px #0a0a0a"
                          : "",
                      }}
                    >
                      <div className="ribbon-box orange">
                        {count && count.inprogress ? count.inprogress : 0}
                      </div>
                      <a
                        className="my_sort_cut text-muted"
                        href="#"
                        onClick={this.inprogress}
                      >
                        <span className="dash_img">
                          <img src="../../assets/images/inprogresstickets.png" />
                        </span>
                        <span
                          style={{ color: "#444444" }}
                          className="dash_text"
                        >
                          {this.getDasbBoardName("inprogress")
                            ? this.getDasbBoardName("inprogress")
                            : "Inprogress"}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card">
                    <div
                      className="card-body ribbon"
                      style={{
                        backgroundColor: "#c4e4a5",
                        borderRadius: this.state.completedStatus ? "10px" : "",
                        boxShadow: this.state.completedStatus
                          ? "0 0 5px #0a0a0a"
                          : "",
                      }}
                    >
                      <div className="ribbon-box orange">
                        {count && count.completed ? count.completed : 0}
                      </div>
                      <a
                        className="my_sort_cut text-muted"
                        href="#"
                        onClick={this.completed}
                      >
                        <span className="dash_img">
                          <img src="../../assets/images/completedtickets.png" />
                        </span>
                        <span
                          style={{ color: "#444444" }}
                          className="dash_text"
                        >
                          {this.getDasbBoardName("completed")
                            ? this.getDasbBoardName("completed")
                            : "Completed"}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card">
                    <div
                      className="card-body ribbon"
                      style={{
                        backgroundColor: "#f5d7bb",
                        borderRadius: this.state.pendingStatus ? "10px" : "",
                        boxShadow: this.state.pendingStatus
                          ? "0 0 5px #0a0a0a"
                          : "",
                      }}
                    >
                      <div className="ribbon-box orange">
                        {count && count.pending ? count.pending : 0}
                      </div>
                      <a
                        className="my_sort_cut text-muted"
                        href="#"
                        onClick={this.pending}
                      >
                        <span className="dash_img">
                          <img src="../../assets/images/inprogresstickets.png" />
                        </span>
                        <span
                          style={{ color: "#444444" }}
                          className="dash_text"
                        >
                          Pending
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-body pt-3 user_sec todo_all_task">
            <div className="container-fluid">
              <div className="tab-content">
                <div
                  className="tab-pane fade show active"
                  id="Departments-list"
                  role="tabpanel"
                >
                  <div className="card">
                    <div className="card-header">
                      <div className="rounded_icon">
                        <i class="icon-clock" aria-hidden="true"></i>
                      </div>
                      <h3 className="card-title">
                        {languageData && languageData.TODO_REPORT
                          ? languageData.TODO_REPORT
                          : "To-Do Report"}
                      </h3>
                      <div className="card-options">
                        <div className="d-flex justify-content-between align-items-center">
                          {this.state.isFilterShow && (
                            <button
                              className="btn topsocial_rest_btn btn-primarys "
                              type="button"
                              onClick={this.filterReset}
                              style={{
                                height: "45px !important",
                                width: "115pxpx !important",
                              }}
                            >
                              <i
                                className="fa fa-refresh"
                                aria-hidden="true"
                                style={{
                                  fontSize: "20px !importent",
                                  marginRight: "7px",
                                }}
                              ></i>{" "}
                              Reset
                            </button>
                          )}
                          {rowData && rowData.length > 0 ? (
                            <div className="card-options">
                              <a
                                className="btn btn-primary btn-sm nav-link dropdown-toggle ticket_export"
                                data-toggle="dropdown"
                                role="button"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i
                                  className="fa fa-download mr-2"
                                  aria-hidden="true"
                                ></i>
                                Export
                              </a>
                              <div className="dropdown-menu">
                                <a
                                  className="dropdown-item"
                                  type="button"
                                  onClick={this.exportToCSV}
                                >
                                  <i className="dropdown-icon fa fa-file-excel-o"></i>{" "}
                                  Excel
                                </a>
                                <a
                                  className="dropdown-item"
                                  type="button"
                                  onClick={this.exportPDF}
                                >
                                  <i className="dropdown-icon fa fa-file-pdf-o"></i>{" "}
                                  PDF
                                </a>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="calendar_filter">
                        <div className="selectgroup">
                          <label className="selectgroup-item">
                            {/* <span className="selectgroup-button first_month" onClick={() => this.onChangePage('previous')} ><HiOutlineChevronLeft /></span> */}
                            <span
                              className="selectgroup-button first_month"
                              onClick={() => this.onChangePage("previous")}
                            >
                              Previous
                            </span>
                          </label>
                          <label className="selectgroup-item">
                            <span
                              className="selectgroup-button"
                              onClick={() => this.onChangePage("today")}
                            >
                              {"Today"}
                            </span>
                          </label>
                          <label className="selectgroup-item">
                            {/* <span className="selectgroup-button last_month" onClick={() => this.onChangePage('next')}><HiOutlineChevronRight /></span> */}
                            <span
                              className="selectgroup-button last_month"
                              onClick={() => this.onChangePage("next")}
                            >
                              Next
                            </span>
                          </label>
                        </div>
                        <div>
                          <span className="todo_center_name">
                            {displayDate}
                          </span>
                        </div>
                        <div className="selectgroup">
                          <label className="selectgroup-item">
                            <span
                              className={`${
                                this.state.calendarType == "months"
                                  ? "active "
                                  : ""
                              } selectgroup-button first_month`}
                              onClick={() => this.onChangeCalendar("months")}
                            >
                              {"Month"}
                            </span>
                          </label>
                          <label className="selectgroup-item">
                            <span
                              className={`${
                                this.state.calendarType == "weeks"
                                  ? "active "
                                  : ""
                              } selectgroup-button`}
                              onClick={() => this.onChangeCalendar("weeks")}
                            >
                              {"Week"}
                            </span>
                          </label>
                          <label className="selectgroup-item">
                            <span
                              className={`${
                                this.state.calendarType == "days"
                                  ? "active "
                                  : ""
                              } selectgroup-button last_month`}
                              onClick={() => this.onChangeCalendar("days")}
                            >
                              {"Day"}
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-2 col-sm-6 col-xl-3 mt-n2">
                          <label className="form-label">
                            {languageData && languageData.LIMIT
                              ? languageData.LIMIT
                              : "Limit"}
                          </label>
                          <div className="form-group">
                            <Select
                              onChange={(e) => {
                                this.gridApi.paginationSetPageSize(
                                  Number(e.value)
                                );
                                this.setState({ perPage: Number(e.value) });
                              }}
                              options={limitOptions}
                              defaultValue={limitOptions[0]}
                              className="limit_size"
                            />
                          </div>
                        </div>

                        {ImportedURL.SUPERADMIN.includes(account.role) ? (
                          <>
                            <div className="col-lg-3 col-md-2 col-sm-6 col-xl-3 mt-n2">
                              <label className="form-label">
                                {languageData && languageData.SEARCH
                                  ? languageData.SEARCH
                                  : "Search"}{" "}
                                {languageData && languageData.HOTEL
                                  ? languageData.HOTEL
                                  : "Hotel"}
                              </label>
                              <div
                                className="form-group"
                                style={{ marginBottom: "10px" }}
                              >
                                <HotelSelectComponent
                                  options={hotelOptionSearch}
                                  onChange={this.searchSelect}
                                  value={this.state.searchhoteloption}
                                />
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        {/* {
                                                    (this.state.searchhotel || !ImportedURL.SUPERADMIN.includes(account.role)) */}
                        {/* ? */}
                        <div className="col-lg-3 col-md-2 col-sm-6 col-xl-3 mt-n2">
                          <label className="form-label">User Category</label>
                          <div
                            className="form-group"
                            style={{ marginBottom: "10px" }}
                          >
                            <SelectComponent
                              value={this.state.searchcategoryoption}
                              onChange={this.searchSelect}
                              options={categoryOptionSearch}
                            />
                          </div>
                        </div>
                        {/* : '' */}
                        {/* } */}
                        {/* {
                                                    this.state.searchcategory ? */}
                        <div className="col-lg-3 col-md-2 col-sm-6 col-xl-3 mt-n2">
                          <label className="form-label">User Name</label>
                          <div
                            className="form-group"
                            style={{ marginBottom: "10px" }}
                          >
                            <SelectComponent
                              value={this.state.searchuseroption}
                              onChange={this.searchSelect}
                              options={userOptionSearch}
                            />
                          </div>
                        </div>
                        {/* : '' */}
                        {/* } */}
                        {/* {
                                                    this.state.searchduration ? */}
                        <div className="col-lg-3 col-md-2 col-sm-6 col-xl-3 mt-n2 ">
                          <label className="form-label">Frequency</label>
                          <div
                            className="form-group"
                            style={{ marginBottom: "10px" }}
                          >
                            <SelectComponent
                              value={this.state.searchdurationoption}
                              onChange={this.searchSelect}
                              options={durationOptions}
                            />
                          </div>
                        </div>

                        <div className="col-md-3 col-sm-6 mt-n2">
                          <label className="form-label">Priority</label>
                          <div
                            className="form-group"
                            style={{ marginBottom: "10px" }}
                          >
                            <SelectComponent
                              value={this.state.searchpriorityoption}
                              onChange={this.searchSelect}
                              options={priorityOptions}
                            />
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-2 col-sm-6 col-xl-3 mt-n2">
                          <label className="form-label">
                            Mandatory Compliances
                          </label>
                          <div
                            className="form-group"
                            style={{ marginBottom: "10px" }}
                          >
                            <SelectComponent
                              value={this.state.searchmandstatusoption}
                              onChange={this.searchSelect}
                              options={mandatoryOptions}
                            />
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-2 col-sm-6 col-xl-3 mt-n2">
                          <label className="form-label">Status</label>
                          <div
                            className="form-group"
                            style={{ marginBottom: "10px" }}
                          >
                            <SelectComponent
                              value={this.state.searchdeletedstatusoption}
                              onChange={this.searchSelect}
                              options={deletedOptions}
                            />
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-2 col-sm-6 col-xl-3 mt-n2">
                          <label className="form-label">From Date</label>
                          <div className="site_pass_calander">
                            <input
                              type="date"
                              className="form-control"
                              name="startdate"
                              value={this.state.startdate}
                              onChange={this.setDateReport}
                            />
                            <i
                              className="fa fa-calendar-check-o"
                              style={{
                                cursor: "pointer",
                                fontSize: "20px",
                                opacity: "0.7",
                              }}
                            ></i>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-2 col-sm-6 col-xl-3 mb-3 mt-n2">
                          <label className="form-label">To Date</label>
                          <div className="site_pass_calander">
                            <input
                              type="date"
                              className="form-control"
                              name="enddate"
                              min={this.state.startdate || ""}
                              value={this.state.enddate}
                              onChange={this.setDateReport}
                            />
                            <i
                              className="fa fa-calendar-check-o"
                              style={{
                                cursor: "pointer",
                                fontSize: "20px",
                                opacity: "0.7",
                              }}
                            ></i>
                          </div>
                        </div>

                        {/* :''
                                               } */}
                      </div>
                      <div>
                        <AgGridComponent
                          rowData={rowData}
                          state={this.state}
                          onRowClicked={(e) => this.onRowClicked(e)}
                          onGridReady={(e) => this.onGridReady(e)}
                          handleFilterChanged={(e) =>
                            this.handleFilterChanged(e)
                          }
                          overlay={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Spinner spinner={spinner || this.state.spinner}></Spinner>
        </div>
        <Viewmodal
          {...this.props}
          data={data}
          spinner={modalspinner}
          startdate={
            this.state.date ? moment(this.state.date).format("MM-DD-YYYY") : ""
          }
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  AccountState: state.account,
  TodoListState: state.todolist,
  LandingState: state.landing,
  ConfigurationState: state.configuration,
  UserState: state.user,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ListTodoStatus: AC_LIST_CONFIGOPTIONS_TODO_STATUS,
      ListTodoReport: AC_LIST_TO_DO_LIST_REPORT,
      ViewTodo: AC_VIEW_TO_DO_LIST,
      spinnerTodo: AC_SPINNER_TO_DO_LIST,
      spinnerModalTodo: AC_MODAL_SPINNER_TO_DO_LIST,
      EmptyTodoList: AC_EMPTY_TO_DO_LIST,
      ListUser: AC_LIST_USER,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TodoReport);
