import React, { Component } from 'react';
import { connect } from 'react-redux';
import MetisMenu from 'react-metismenu';
import { Switch, Route, NavLink, Redirect } from 'react-router-dom';
import Header from '../Shared/Header';
import Footer from '../Shared/Footer';
import DefaultLink from './DefaultLink';

import Login from '../Authentication/login';
import Signup from '../Authentication/signup';
import ForgotPassword from '../Authentication/forgotpassword';
import NotFound from '../Authentication/404';
import Forbidden from '../Authentication/403';
import ListHistory from '../History/listhistory';
import InternalServer from '../Authentication/500';
import ListHotel from '../Hotel/listhotel';
import addhotel from '../Hotel/addhotel';
import ListCategory from '../Category/listcategory';
import AddCategory from '../Category/addcategory';
import DashBoardIndex from '../Dashboard/index'
import ListUser from '../User/listuser';
import ListCountry from '../Country/listcountry';
import ListCountryTrash from '../Trash/countrytrash';


import NewListTodoList from '../DefineTodoList/NewListTodoList';
import NewaddListtodo from  '../DefineTodoList/NewaddListtodo';

import UserEdit from '../User/adduser';
import SiteSetting from '../Settings/adminsettings';
import ChangePassword from '../Settings/changepassword';
import NewTicket from '../Ticket/timeSeriesChart';
import Ticket from '../Ticket/listticket';
import ViewTicket from '../Ticket/viewticket';
import ViewTicketApex from '../Ticket/viewticketsapex';
import Review from '../Reviews/review';
import ReviewOpt from '../Reviews/reviewoption';
import ReviewSuccess from '../Reviews/reviewprocess';
import ReviewFailure from '../Reviews/feedback';
import listfeedback from '../Reviews/listfeedback';
import viewfeedback from '../Reviews/viewfeedback';
import listnotification from '../Notifications/listnotification'
import reports from '../Reviews/reports';
import TicketReports from '../Ticket/reports';
import ReportCreateTicket from '../Ticket/reportCreateTicket';
import ReportCreateAndClosedTicket from '../Ticket/reportCreateAndClosedTicket';
import AgeingReport from '../Ticket/ageingReport';
import ReportCreateIncidentTicket from '../Ticket/reportCreateIncidentTicket';
import usertickets from '../Ticket/usertickets';
import socialreviews from '../Reviews/socialreviews';
import listgroup from '../Group/listgroup';
import sitesettings from '../Settings/sitesettings';
import ViewHotel from '../Hotel/viewhotel';
import TicketReport from '../Reports/Tickets/';
import HotelTicketReport from '../Reports/Hotels/';
import viewreview from '../Reviews/viewreview';
import viewnotification from '../Notifications/viewnotification';
import TodolistItem from '../Todolistitem/listtodolistitem';
import AddTodolistItem from '../Todolistitem/addtodolistitem';






import ListTimeZone from '../TimeZone/listtimezone';

import {
	darkModeAction, darkHeaderAction, fixNavbarAction,
	darkMinSidebarAction, darkSidebarAction, iconColorAction,
	gradientColorAction, rtlAction, fontAction,
	subMenuIconAction,
	menuIconAction,
	boxLayoutAction,
	statisticsAction, friendListAction,
	statisticsCloseAction, friendListCloseAction, toggleLeftMenuAction
} from '../../actions/settingsAction';
import CategoryTrash from '../Trash/categoryTrash';
import grouptrash from '../Trash/grouptrash';
import SocialMediaType from '../SocialMedia/socialmediatype';
import listcurrency from '../Currency/listcurrency';
import ImportedURL from '../../common/api';
import todolisttrash from '../Trash/todolisttrash';
import todoitemtrash from '../Trash/todoitemtrash';
import problemKeywordTrash from '../Trash/problemkeywordtrash';
import designationTrash from '../Trash/designationTrash';
import pushmessage from '../Settings/pushmessage';
import ProblemKeyWord from "../ProblemKeyWord/listproblemkeyword";
import AddProblemKeyWord from '../ProblemKeyWord/addproblemkeyword';
import designation from '../Designation/listdesignation';
import sentimentalviewdescription from '../Reports/Tickets/sentimentalviewdescription';
import sentimentalviewreview from '../Reviews/sentimentalview';


import listPhoneNumberblocklist from '../BlockList/listPhoneNumberblocklist';
import usereportview from '../Ticket/usereportview';
import listincidentticket from '../Ticket/IncidentTicket/listincidentticket';
import viewincidentticket from '../Ticket/IncidentTicket/viewincidentticket';

import roomareaamenity from '../RoomareaAmenities/listroomareaamenity';
import addroomareaamenity from '../RoomareaAmenities/addroomareaamenity';
import roomareaamenityTrash from '../Trash/roomareaamenityTrash';
import addsettingroomtype from '../Settings/addroomtype';
import viewsettingroomtype from '../SettingRoomtypeComponents/viewroomtypetab';

import listsettingroomtype from '../Settings/listroomtype';
import addsettingroom from '../SettingRoomtypeComponents/addroom';
import viewsettingroom from '../SettingRoomtypeComponents/viewroomtab';
import addsettingmultipleroom from '../SettingRoomtypeComponents/addmultipleroom';

import outofservice from '../SettingRoomtypeComponents/outofservicetab';
import settingroomtypetrash from '../Trash/settingroomtyptrash';

import listconfiguration from '../Configuration/listconfiguration';
import addconfiguration from '../Configuration/addconfiguration';
import configurationTrash from '../Trash/configurationTrash';
import listlanguage from '../Language/listlanguage';
import languageTrash from '../Trash/languageTrash';
import editlanguage from "../Language/editlanguage";
import TicketFlow from '../TicketWorkflow/dashboard';
import livekeysettings from '../Settings/livekeysettings';
import guestreview from '../Reviews/guesttouch/guestreview';
import guestreports from '../Reviews/guesttouch/guestreports';
import guestfeedback from '../Reviews/guesttouch/guestfeedback';
import Reviewflow from '../ReviewFlow/reviewflow';
import ReminderDashoard from '../Reminder/dashboard';

import overallreport from '../Reviews/overallreport';
import guesttouchoverallreport from '../Reviews/guesttouch/guesttouchoverallreport';
import ticketTrash from '../Trash/ticketTrash';
import viewroomtypeenquiry from '../SettingRoomtypeComponents/viewroomtypeenquiry';
import { GetManagementUserStatus, onErrorImage } from '../../common/validate';
import todotag from '../Todolist/Todotag/todotag';
import mytask from '../Todolist/Mytask/mytask';
import addtodolist from '../Todolist/Mytask/addtodolist';
import addnewtodolist from '../Todolist/Mytask/addnewtodolist';
import allmytask from '../Todolist/Allmytask/allmytask';
import mylist from '../Todolist/Mylist/mylist';
import edittodolistitem from '../Todolistitem/edittodolistitem';
import ViewMylist from '../Todolist/Mylist/viewmylist';
import timezoneTrash from '../Trash/timezoneTrash';
import listtodoreport from '../Todolist/TodoReport/listtodoreport';
import ageingtodoreport from '../Todolist/TodoReport/ageingtodoreport'
import createdvscompletedtodoreport from '../Todolist/TodoReport/createdvscompletedtodoreport'
import pmsprivileges from '../PMSPrevileges/pmsprivileges';
import listdeveshguest from '../DeveshGuest/listdeveshguest';
import sendinvite from '../DeveshGuest/sendinvite';
import listloginhistory from '../LoginHistory/listloginhistory';
import PhoneNumberblocklistTrash from '../Trash/PhoneNumberblocklistTrash';
import listemployee from '../Employee/listemployee';
import viewemployee from '../Employee/viewemployee';
import EditTask from '../Todolist/Mytask/edit-todotask-list';

const masterNone = {
	display: 'none',
};

const masterBlock = {
	display: 'block',
};

const MissingRoute = (props) => {
	const hotelupdate = localStorage.getItem('hotelaiuserchange');
	if (hotelupdate) {
		window.location.href = '/';
		localStorage.removeItem('hotelaiuserchange');
	}
	else
		return <NotFound />;
}

class Menu extends Component {
	constructor(props) {
		super(props);
		this.toggleLeftMenu = this.toggleLeftMenu.bind(this);
		this.toggleUserMenu = this.toggleUserMenu.bind(this);
		this.toggleRightSidebar = this.toggleRightSidebar.bind(this);
		this.toggleSubMenu = this.toggleSubMenu.bind(this);
		this.handleDarkMode = this.handleDarkMode.bind(this);
		this.handleFixNavbar = this.handleFixNavbar.bind(this);
		this.handleDarkHeader = this.handleDarkHeader.bind(this);
		this.handleMinSidebar = this.handleMinSidebar.bind(this);
		this.handleSidebar = this.handleSidebar.bind(this);
		this.handleIconColor = this.handleIconColor.bind(this);
		this.handleGradientColor = this.handleGradientColor.bind(this);
		this.handleRtl = this.handleRtl.bind(this);
		this.handleFont = this.handleFont.bind(this);
		this.handleStatistics = this.handleStatistics.bind(this);
		this.handleFriendList = this.handleFriendList.bind(this);
		this.closeFriendList = this.closeFriendList.bind(this);
		this.closeStatistics = this.closeStatistics.bind(this);
		this.handleBoxLayout = this.handleBoxLayout.bind(this);
		this.handler = this.handler.bind(this);
		this.state = {
			isToggleLeftMenu: false,
			isOpenUserMenu: false,
			isOpenRightSidebar: false,
			isBoxLayout: false,
			parentlink: null,
			childlink: null,
			isTopToggle: false
		};

		this.toggleVisible = this.toggleVisible.bind(this);

	}

	componentDidMount() {
		const { location } = this.props;
		const links = location.pathname.substring(1).split(/-(.+)/);
		const parentlink = links[0];
		const nochildlink = links[1];
		if (parentlink && nochildlink && nochildlink === 'dashboard') {
			this.handler(parentlink, `${parentlink}${nochildlink}`);
		} else if (parentlink && nochildlink && nochildlink !== 'dashboard') {
			this.handler(parentlink, nochildlink);
		} else if (parentlink) {
			this.handler(parentlink, '');
		} else {
			this.handler('hr', 'dashboard');
		}
		window.addEventListener('scroll', this.toggleVisible);
	}

	componentDidUpdate(prevprops, prevstate) {
		const { location } = this.props;
		const links = location.pathname.substring(1).split(/-(.+)/);
		const parentlink = links[0];
		const nochildlink = links[1];
		if (prevprops.location !== location) {
			if (parentlink && nochildlink && nochildlink === 'dashboard') {
				this.handler(parentlink, `${parentlink}${nochildlink}`);
			} else if (parentlink && nochildlink && nochildlink !== 'dashboard') {
				this.handler(parentlink, nochildlink);
			} else if (parentlink) {
				this.handler(parentlink, '');
			} else {
				this.handler('hr', 'dashboard');
			}
		}
	}

	handler(parentlink, nochildlink) {
		this.setState({
			parentlink: parentlink,
			childlink: nochildlink,
		});
	}

	handleDarkMode(e) {
		this.props.darkModeAction(e.target.checked)
	}
	handleFixNavbar(e) {
		this.props.fixNavbarAction(e.target.checked)
	}
	handleDarkHeader(e) {
		this.props.darkHeaderAction(e.target.checked)
	}
	handleMinSidebar(e) {
		this.props.darkMinSidebarAction(e.target.checked)
	}
	handleSidebar(e) {
		this.props.darkSidebarAction(e.target.checked)
	}
	handleIconColor(e) {
		this.props.iconColorAction(e.target.checked)
	}
	handleGradientColor(e) {
		this.props.gradientColorAction(e.target.checked)
	}
	handleRtl(e) {
		this.props.rtlAction(e.target.checked)
	}
	handleFont(e) {
		this.props.fontAction(e)
	}
	handleFriendList(e) {
		this.props.friendListAction(e)
	}
	handleStatistics(e) {
		this.props.statisticsAction(e)
	}
	closeFriendList(e) {
		this.props.friendListCloseAction(e)
	}
	closeStatistics(e) {
		this.props.statisticsCloseAction(e)
	}
	handleSubMenuIcon(e) {
		this.props.subMenuIconAction(e)
	}
	handleMenuIcon(e) {
		this.props.menuIconAction(e)
	}
	handleBoxLayout(e) {
		this.props.boxLayoutAction(e.target.checked)
	}
	toggleLeftMenu(e) {
		this.props.toggleLeftMenuAction(e)
	}
	toggleRightSidebar() {
		this.setState({ isOpenRightSidebar: !this.state.isOpenRightSidebar })
	}
	toggleUserMenu() {
		this.setState({ isOpenUserMenu: !this.state.isOpenUserMenu })
	}
	toggleSubMenu(e) {
		let menucClass = ''
		if (e.itemId) {
			const subClass = e.items.map((menuItem, i) => {
				if (menuItem.to === this.props.location.pathname) {
					menucClass = "in";
				} else {
					menucClass = "collapse";
				}
				return menucClass
			})
			return subClass
			// return "collapse";
		} else {
			return e.visible ? "collapse" : "metismenu";
		}
	}

	getPrevilege = (name) => {
		const { AccountState } = this.props;
		const previleges = AccountState.previleges;
		const Previlege = previleges ? previleges.find(obj => { return obj.name == name }) : {};
		return Previlege ? Previlege : {};
	}

	// Set dynamic routes according to previleges
	getRoutes = () => {
		const { account } = this.props.AccountState;
		const managementStatus = GetManagementUserStatus(account.defaultmanagementcategory, account.userId, account.categoryType, null);
		const Routes = [
			{
				path: "/login",
				name: 'login',
				exact: true,
				pageTitle: "Tables",
				component: Login
			},
			{
				path: "/signup",
				name: 'signup',
				exact: true,
				pageTitle: "Tables",
				component: Signup
			},
			{
				path: "/forgotpassword",
				name: 'forgotpassword',
				exact: true,
				pageTitle: "Tables",
				component: ForgotPassword
			},
			{
				path: "/notfound",
				name: 'notfound',
				exact: true,
				pageTitle: "Tables",
				component: NotFound
			},
			{
				path: "/access-denied",
				name: 'notfound',
				exact: true,
				pageTitle: "Tables",
				component: Forbidden
			},
			{
				path: "/internalserver",
				name: 'internalserver',
				exact: true,
				pageTitle: "Tables",
				component: InternalServer
			},
			{
				path: "/change-password",
				name: 'changepassword',
				exact: true,
				pageTitle: "Change Password",
				component: ChangePassword
			},
			{
				path: "/review",
				name: 'ReviewOpt',
				exact: true,
				pageTitle: "Tables",
				component: ReviewOpt
			},
			{
				path: "/review-success",
				name: 'ReviewOpt',
				exact: true,
				pageTitle: "Tables",
				component: ReviewSuccess
			},
			{
				path: "/review-failure",
				name: 'ReviewOpt',
				exact: true,
				pageTitle: "Tables",
				component: ReviewFailure
			},
			{
				path: "/",
				name: 'Dashboard',
				exact: true,
				pageTitle: "Dashboard",
				component: DashBoardIndex
			},
			{
				path: "/site-setting",
				name: 'sitesetting',
				exact: true,
				pageTitle: "Site Settings",
				component: SiteSetting
			},

			{
				path: "/notifications",
				name: 'notifications',
				exact: true,
				pageTitle: "Notification",
				component: listnotification
			},
			{
				path: "/view-notification/:id",
				name: 'viewnotifications',
				exact: true,
				pageTitle: "View Notification",
				component: viewnotification
			},
			{
				path: "/phone-number-block-list-trash",
				name: 'Block List',
				exact: true,
				pageTitle: "Block List",
				component: PhoneNumberblocklistTrash
			},
		];

		const { AccountState } = this.props;
		const role = AccountState.role;


		// History routes
		if (ImportedURL.SUPERADMIN.includes(role)) Routes.push(
			{
				path: "/booking-count",
				name: 'listhistory',
				exact: true,
				pageTitle: "Booking Count",
				component: ListHistory
			},
			{
				path: "/push-message",
				name: 'pushmessage',
				exact: true,
				pageTitle: "Push message",
				component: pushmessage
			},
			{
				path: "/livekey-settings",
				name: 'livekeysettings',
				exact: true,
				pageTitle: "Livekey Settings",
				component: livekeysettings
			},
		)

		if (!ImportedURL.SUPERADMIN.includes(role) || role != 'vendor') {
			if (managementStatus) {
				Routes.push(
					{
						path: "/ticket-flow",
						name: 'ticketflow',
						exact: true,
						pageTitle: "Ticket Workflow",
						component: TicketFlow
					},
				)
			}
		}
		if (!ImportedURL.SUPERADMIN.includes(role) || role != 'vendor') {
			if (managementStatus) {
				Routes.push(
					{
						path: "/review-flow",
						name: 'reviewflow',
						exact: true,
						pageTitle: "Review Workflow",
						component: Reviewflow
					},
				)
			}
		}
		if (!ImportedURL.SUPERADMIN.includes(role) || role != 'vendor') {
			if (managementStatus) {
				Routes.push(
					{
						path: "/reminder",
						name: 'reminder dashoard',
						exact: true,
						pageTitle: "Reminder Dashoard",
						component: ReminderDashoard
					},
				)
			}
		}

		let Room_Type_And_Rooms = this.getPrevilege('Room_Type_And_Rooms');
		if (Room_Type_And_Rooms.view) Routes.push(
			{
				path: "/list-setting-roomtype",
				name: 'list-roomtype"',
				exact: true,
				pageTitle: "ListRoomtype",
				component: listsettingroomtype
			},
			{
				path: "/view-setting-roomtype/:id",
				name: 'view-setting-roomtype',
				exact: true,
				pageTitle: "AddRoomarea Amenity",
				component: viewsettingroomtype
			},
			{
				path: "/view-setting-room/:id",
				name: 'view-setting-roomtype',
				exact: true,
				pageTitle: "AddRoomarea Amenity",
				component: viewsettingroom
			},
			{
				path: "/outofservice",
				name: 'add-setting-roomtype',
				exact: true,
				pageTitle: "AddRoomarea Amenity",
				component: outofservice
			},
			{
				path: "/view-roomtype-enquiry/:id",
				name: 'view-setting-roomtype',
				exact: true,
				pageTitle: "AddRoomarea Amenity",
				component: viewroomtypeenquiry
			},
		)
		if (Room_Type_And_Rooms.add) Routes.push(
			{
				path: "/add-setting-roomtype",
				name: 'add-setting-roomtype',
				exact: true,
				pageTitle: "AddRoomarea Amenity",
				component: addsettingroomtype
			},
			{
				path: "/add-setting-multipleroom",
				name: 'add-setting-roomtype',
				exact: true,
				pageTitle: "AddRoomarea Amenity",
				component: addsettingmultipleroom
			},
			{
				path: "/addsetting-room",
				name: 'add-setting-roomtype',
				exact: true,
				pageTitle: "AddRoomarea Amenity",
				component: addsettingroom
			},
		)
		if (Room_Type_And_Rooms.edit) Routes.push(
			{
				path: "/edit-setting-roomtype/:id",
				name: 'add-setting-roomtype',
				exact: true,
				pageTitle: "AddRoomarea Amenity",
				component: addsettingroomtype
			},
			{
				path: "/edit-setting-room/:id",
				name: 'edit-setting-roomtype',
				exact: true,
				pageTitle: "AddRoomarea Amenity",
				component: addsettingroom
			},
		)


		if (role == ImportedURL.HOTELOWNER || ImportedURL.SUPERADMIN.includes(role)) Routes.push(
			{
				path: "/list-loginhistory",
				name: 'listloginhistory',
				exact: true,
				pageTitle: "List Loginhistory",
				component: listloginhistory
			},
		)

		// if (ImportedURL.SUPERADMIN.includes(role)) Routes.push(
		// 	{
		// 		path: "/devesh-guest",
		// 		name: 'deveshguest',
		// 		exact: true,
		// 		pageTitle: "Devesh Guest",
		// 		component: listdeveshguest
		// 	},
		// 	{
		// 		path: "/send-invite",
		// 		name: 'send-invite',
		// 		exact: true,
		// 		pageTitle: "Send Invite",
		// 		component: sendinvite
		// 	},
		// )

		// Hotel routes
		let Hotel = this.getPrevilege('Hotel');
		if (Hotel.view) Routes.push(
			{
				path: "/list-hotel",
				name: 'listhotel',
				exact: true,
				pageTitle: "Hotel",
				component: ListHotel
			},
			{
				path: "/view-hotel/:id",
				name: 'addhotel',
				exact: true,
				pageTitle: "Hotel",
				component: ViewHotel
			},
		)
		if (Hotel.add) Routes.push(
			{
				path: "/add-hotel",
				name: 'addhotel',
				exact: true,
				pageTitle: "Hotel",
				component: addhotel
			},
		)
		if (Hotel.edit) Routes.push(
			{
				path: "/edit-hotel/:id",
				name: 'addhotel',
				exact: true,
				pageTitle: "Hotel",
				component: addhotel
			},
		)

		// Group routes
		if (this.getPrevilege('Group').view) Routes.push(
			{
				path: "/list-group",
				name: 'listgroup',
				exact: true,
				pageTitle: "Group",
				component: listgroup
			},
		)
		if (this.getPrevilege('Phone_Number_Block_List').view) Routes.push(
			{
				path: "/phone-number-block-list",
				name: 'listphonenumberblocklist',
				exact: true,
				pageTitle: "Block List",
				component: listPhoneNumberblocklist
			},
		)

		// Category routes
		let Category = this.getPrevilege('Category');

		if (Category.view) Routes.push(
			{
				path: "/list-category",
				name: 'listcategory',
				exact: true,
				pageTitle: "Category",
				component: ListCategory
			},
			{
				path: "/view-category/:id",
				name: 'addcategory',
				exact: true,
				pageTitle: "Category",
				component: AddCategory
			},
		)
		if (Category.add) Routes.push(
			{
				path: "/add-category",
				name: 'addcategory',
				exact: true,
				pageTitle: "Category",
				component: AddCategory
			},
		)
		if (Category.edit) Routes.push(
			{
				path: "/edit-category/:id",
				name: 'addcategory',
				exact: true,
				pageTitle: "Category",
				component: AddCategory
			},
		)

		// User routes
		let User = this.getPrevilege('User');

		if (User.view) Routes.push(
			{
				path: "/list-user",
				name: 'listuser',
				exact: true,
				pageTitle: "User",
				component: ListUser
			},
		)
		if (User.edit) Routes.push(
			{
				path: "/edit-user/:email",
				name: 'edituser',
				exact: true,
				pageTitle: "Edit User",
				component: UserEdit
			},
		)

		let Currency = this.getPrevilege('Currency');

		if (Currency.view) Routes.push(
			{
				path: "/list-currency",
				name: 'listcurrency',
				exact: true,
				pageTitle: "Currency",
				component: listcurrency
			},
		)
		let Country = this.getPrevilege('Country');

		if (Country.view) Routes.push(
			{
				path: "/list-country",
				name: 'listcountry',
				exact: true,
				pageTitle: "Country",
				component: ListCountry
			},
		)

	//	let NewListTodoList =this.getPrevilege('NewListTodoList');
		if (this.getPrevilege('NewListTodoList').view) Routes.push(
			{
				path: "/todo-listitem",
				name: 'todolistitem',
				exact: true,
				pageTitle: "NewListTodoList",
				component: NewListTodoList
			},
			{
				path: "/add-todo-listitem",
				name: 'addtodolistitem',
				exact: true,
				pageTitle: "NewaddListtodo",
				component: NewaddListtodo
			},
		)
		// Ticket routes
		// let TicketRoute = this.getPrevilege('Ticket');

		if (this.getPrevilege('Ticket').view) Routes.push(
			{
				path: "/tickets",
				name: 'tickets',
				exact: true,
				pageTitle: "Ticket",
				component: Ticket
			},
			{
				path: "/view-ticket/:ticketid",
				name: 'viewticket',
				exact: true,
				pageTitle: "View Ticket",
				component: ViewTicket
			},
			{
				path: "/view-ticket-analytics/:ticketid",
				name: 'viewticket',
				exact: true,
				pageTitle: "View Ticket",
				component: ViewTicketApex
			},
		)
		if (this.getPrevilege('Ticket_Analytics').view) Routes.push(
			{
				path: "/ticket-analytics",
				name: 'tickets',
				exact: true,
				pageTitle: "NewTicket",
				component: NewTicket
			}
		)
		if (this.getPrevilege('Incident_Ticket').view) Routes.push(
			{
				path: "/incident-ticket",
				name: 'tickets',
				exact: true,
				pageTitle: "Ticket",
				component: listincidentticket
			},
			{
				path: "/view-incident-ticket/:ticketid",
				name: 'viewticket',
				exact: true,
				pageTitle: "View Ticket",
				component: viewincidentticket
			},
		)

		if (this.getPrevilege('Ticket_Report').view) Routes.push(
			{
				path: "/ticket-report",
				name: 'ticketreport',
				exact: true,
				pageTitle: "Ticket Report",
				component: TicketReports
			},
			{
				path: "/user-ticket/:id/:type",
				name: 'userticket',
				exact: true,
				pageTitle: "User Tickets",
				component: usertickets
			},
			{
				path: "/view-user-ticket/:id/:type/:ticketid",
				name: 'viewticket',
				exact: true,
				pageTitle: "View Ticket",
				component: usereportview
			},
		)
		if (this.getPrevilege('Create_Ticket_Report').view) Routes.push(
			{
				path: "/create-ticket-report",
				name: 'createticketreport',
				exact: true,
				pageTitle: "Create Ticket Report",
				component: ReportCreateTicket
			},

		)
		if (this.getPrevilege('Create_Close_Ticket_Report').view) Routes.push(
			{
				path: "/create-close-ticket-report",
				name: 'createandcloseticketreport',
				exact: true,
				pageTitle: "Create And Close Ticket Report",
				component: ReportCreateAndClosedTicket
			},

		)
		if (this.getPrevilege('Ageing_Report').view) Routes.push(
			{
				path: "/ageing-report",
				name: 'ageingreport',
				exact: true,
				pageTitle: "Ageing Report",
				component: AgeingReport
			},

		)
		if (this.getPrevilege('Create_Incident_Ticket_Report').view) Routes.push(
			{
				path: "/create-incident-ticket-report",
				name: 'createincidentticketreport',
				exact: true,
				pageTitle: "Create Incident Ticket Report",
				component: ReportCreateIncidentTicket
			},

		)




		// Review routes
		if (ImportedURL.SUPERADMIN.includes(role)) Routes.push(
			{
				path: "/reviews",
				name: 'review',
				exact: true,
				pageTitle: "Review",
				component: Review
			},
			{
				path: "/guest-reviews",
				name: 'review',
				exact: true,
				pageTitle: "Review",
				component: guestreview
			},
		)
		if (this.getPrevilege('Feedback').view) Routes.push(
			{
				path: "/feedbacks",
				name: 'feedbacks',
				exact: true,
				pageTitle: "Feedback",
				component: listfeedback
			},
			{
				path: "/myhotel-feedbacks",
				name: 'feedbacks',
				exact: true,
				pageTitle: "Feedback",
				component: guestfeedback
			},
		)
		Routes.push(
			{
				path: "/view-feedbacks/:id",
				name: 'feedbacks',
				exact: true,
				pageTitle: "Feedback",
				component: viewfeedback
			}
		)
		Routes.push(
			{
				path: "/reports",
				name: 'reports',
				exact: true,
				pageTitle: "Reports",
				component: reports
			},
			{
				path: "/guest-reports",
				name: 'reports',
				exact: true,
				pageTitle: "Reports",
				component: guestreports
			},
		)
		if (ImportedURL.SUPERADMIN.includes(role)) {
			Routes.push(
				{
					path: "/overall-reports",
					name: 'reports',
					exact: true,
					pageTitle: "Reports",
					component: overallreport
				},
				{
					path: "/overall-guestreports",
					name: 'reports',
					exact: true,
					pageTitle: "Reports",
					component: guesttouchoverallreport
				}
			)
		}
		if (this.getPrevilege('Social_Review').view) Routes.push(
			{
				path: "/social-reviews",
				name: 'socialreviews',
				exact: true,
				pageTitle: "Social Reviews",
				component: socialreviews
			},
			{
				path: "/social-review/:id/:reviewType",
				name: 'socialreviews',
				exact: true,
				pageTitle: "Social Reviews",
				component: socialreviews
			},
			{
				path: "/social-review/:id",
				name: 'socialreviews',
				exact: true,
				pageTitle: "Social Reviews",
				component: socialreviews
			},
			{
				path: "/social-review-type/:reviewType",
				name: 'socialreviews',
				exact: true,
				pageTitle: "Social Reviews",
				component: socialreviews
			},
			{
				path: "/view-review/:id",
				name: 'viewreview',
				exact: true,
				pageTitle: "View Social Review",
				component: viewreview
			},
			{
				path: "/sentimental-viewreview/:id/:from/:to",
				name: 'sentimental view viewreview',
				exact: true,
				pageTitle: "Sentimental View Review",
				component: sentimentalviewreview
			},
			{
				path: "/sentimental-viewreview/:id",
				name: 'sentimental view viewreview',
				exact: true,
				pageTitle: "Sentimental View Review",
				component: sentimentalviewreview
			},
			{
				path: "/sentimental-viewreview/:id/:from",
				name: 'sentimental view viewreview',
				exact: true,
				pageTitle: "Sentimental View Review",
				component: sentimentalviewreview
			},
			{
				path: "/sentimental-viewreview/:id/:to",
				name: 'sentimental view viewreview',
				exact: true,
				pageTitle: "Sentimental View Review",
				component: sentimentalviewreview
			},
		)

		if (this.getPrevilege('All_Reports').view) Routes.push(
			{
				path: "/ticket-reports",
				name: 'ticket-reports',
				exact: true,
				pageTitle: "Ticket Reports",
				component: TicketReport
			},
			{
				path: "/hotel-ticket-reports",
				name: 'ticket-reports',
				exact: true,
				pageTitle: "Ticket Reports",
				component: HotelTicketReport
			},
			{
				path: "/sentimental-viewdescription/:id",
				name: 'sentimental view description',
				exact: true,
				pageTitle: "Sentimental View Description",
				component: sentimentalviewdescription
			},
		)

		if (AccountState.account.allhotels && AccountState.account.allhotels.length > 1) Routes.push(
			{
				path: "/site-settings",
				name: 'sitesettings',
				exact: true,
				pageTitle: "Site settings",
				component: sitesettings
			},
		)

		let TodoReport = this.getPrevilege("Todo_Report");
		if (TodoReport.view) Routes.push(
			{
				path: "/todo-report",
				name: 'todoReport',
				exact: true,
				pageTitle: "Todo Tag",
				component: listtodoreport
			},
			{
				path: "/ageing-todo-report",
				name: 'ageingtodoReport',
				exact: true,
				pageTitle: "Todo Tag",
				component: ageingtodoreport
			},
			{
				path: "/createdvscompleted-todo-report",
				name: 'createdvscompletedtodoreport',
				exact: true,
				pageTitle: "Todo Tag",
				component:createdvscompletedtodoreport
			},
		)
		// let TodoTagReport = this.getPrevilege("Todo_Tag");
		// if (TodoTagReport.view) Routes.push(
		// 	{
		// 		path: "/todo-tag",
		// 		name: 'todoReport',
		// 		exact: true,
		// 		pageTitle: "Todo Tag",
		// 		component: todotag
		// 	},
		// )
		// let TodoMyList = this.getPrevilege("Todo_My_List");
		// if (TodoMyList.view) Routes.push(
		// 	{
		// 		path: "/todo-my-list",
		// 		name: 'todoReport',
		// 		exact: true,
		// 		pageTitle: "Todo Tag",
		// 		component: mylist
		// 	},
		// )
		// if (TodoMyList.view) Routes.push(
		// 	{
		// 		path: "/view-my-list/:id",
		// 		name: 'ViewTodo',
		// 		exact: true,
		// 		pageTitle: "View Todo",
		// 		component: ViewMylist
		// 	},
		// )
		let Todo_List = this.getPrevilege("Todo_List");
		if (Todo_List.view) Routes.push(
			{
				path: "/to-do-allmytask",
				name: 'allmytask',
				exact: true,
				pageTitle: "All My Task",
				component: allmytask
			},
		)

		let TodoMyTask = this.getPrevilege("Todo_List");
		if (!ImportedURL.SUPERADMIN.includes(role)) {
			if (TodoMyTask.view) Routes.push(
				{
					path: "/todo-my-task",
					name: 'todomytask',
					exact: true,
					pageTitle: "Todo My Tag",
					component: mytask
				},
			)
			if (TodoMyTask.add) Routes.push(
				{
					path: "/add-todo-list",
					name: 'addtodolist',
					exact: true,
					pageTitle: "Add todo",
					component: addtodolist
				},
			)
			if (TodoMyTask.add) Routes.push(
				{
					path: "/add-newtodo-list",
					name: 'addnewtodolist',
					exact: true,
					pageTitle: "Add todo",
					component: addnewtodolist
				},
			)
			// if (TodoMyTask.edit) Routes.push(
			// 	{
			// 		path: "/edit-todo-list/:id",
			// 		name: 'addtodolist',
			// 		exact: true,
			// 		pageTitle: "Add todo",
			// 		component: addtodolist
			// 	},
			// )
			if (TodoMyTask.edit) Routes.push(
				{
					path: "/edit-todotask-list/:id",
					name: 'addtodolist',
					exact: true,
					pageTitle: "Edit todo",
					component: EditTask
				},
			)
		}

		let TodoListItem = this.getPrevilege("Todo_List_Item");
		// if (TodoListItem.view) Routes.push(
		// 	{
		// 		path: "/to-do-list-item",
		// 		name: 'todo list item',
		// 		exact: true,
		// 		pageTitle: "Todo List Items",
		// 		component: TodolistItem
		// 	},
		// 	{
		// 		path: "/add-to-do-list-item",
		// 		name: 'add todo list item',
		// 		exact: true,
		// 		pageTitle: "Add Todo List Item",
		// 		component: AddTodolistItem
		// 	},
		// )
		if (TodoListItem.edit) Routes.push(
			{
				path: "/edit-to-do-list-item/:id/:hotelid",
				name: 'edit todo list item',
				exact: true,
				pageTitle: "Edit Todo List Item",
				component: edittodolistitem
			}
		)

		let TimeZone = this.getPrevilege("Time_Zone");
		if (TimeZone.view) Routes.push(
			{
				path: "/list-time-zone",
				name: 'listtimezone',
				exact: true,
				pageTitle: "Time zone",
				component: ListTimeZone
			},
		)


		let ProblemKeyword = this.getPrevilege("Problem_Keyword");
		if (ProblemKeyword.view) Routes.push(
			{
				path: "/list-problem-keyword",
				name: 'listproblemkeyword"',
				exact: true,
				pageTitle: "Problem KeyWord",
				component: ProblemKeyWord
			},
		)
		if (ProblemKeyword.add) Routes.push(
			{
				path: "/add-problem-keyword",
				name: 'add-problem-keyword',
				exact: true,
				pageTitle: "Add Problem KeyWord",
				component: AddProblemKeyWord
			},
		)
		if (ProblemKeyword.edit) Routes.push(
			{
				path: "/edit-problem-keyword/:id",
				name: 'edit-problem-keyword',
				exact: true,
				pageTitle: "Edit Problem KeyWord",
				component: AddProblemKeyWord
			},
		)

		let RoomareaAmenity = this.getPrevilege("Room_Amenity");
		if (RoomareaAmenity.view) Routes.push(
			{
				path: "/list-roomarea-amenity",
				name: 'listroomareaamenity"',
				exact: true,
				pageTitle: "Roomarea Amenity",
				component: roomareaamenity
			},
		)
		if (RoomareaAmenity.add) Routes.push(
			{
				path: "/add-roomarea-amenity",
				name: 'add-roomarea-amenity',
				exact: true,
				pageTitle: "AddRoomarea Amenity",
				component: addroomareaamenity
			},
		)

		if (RoomareaAmenity.edit) Routes.push(
			{
				path: "/edit-roomarea-amenity/:id",
				name: 'edit-roomarea-amenity',
				exact: true,
				pageTitle: "AddRoomarea Amenity",
				component: addroomareaamenity
			}
		)

		let Configuration = this.getPrevilege("Configuration");
		if (Configuration.view) Routes.push(
			{
				path: "/list-configuration",
				name: 'listroomareaamenity"',
				exact: true,
				pageTitle: "Configuration",
				component: listconfiguration
			},
		)
		if (Configuration.add) Routes.push(
			{
				path: "/add-configuration",
				name: 'add-configuration',
				exact: true,
				pageTitle: "AddRoomarea Amenity",
				component: addconfiguration
			},
		)
		if (Configuration.edit) Routes.push(
			{
				path: "/edit-configuration/:id",
				name: 'edit-configuration',
				exact: true,
				pageTitle: "AddRoomarea Amenity",
				component: addconfiguration
			}
		)

		let PMSPrivileges = this.getPrevilege("PMS_Privileges");
		if (PMSPrivileges.view) Routes.push(
			{
				path: "/pms-privileges",
				name: 'listroomareaamenity"',
				exact: true,
				pageTitle: "Newsletter",
				component: pmsprivileges
			},
		)

		let Designation = this.getPrevilege("Designation");
		if (Designation.view) Routes.push(
			{
				path: "/list-designation",
				name: 'list designation',
				exact: true,
				pageTitle: "List Designation",
				component: designation
			},
		)

		let List_Employee = this.getPrevilege("List_Employee");
		if (List_Employee.view) Routes.push(
			{
				path: "/list-employee",
				name: 'listemployee',
				exact: true,
				pageTitle: "Employees List ",
				component: listemployee
			},
			{
				path: "/view-employee/:id",
				name: 'view employee',
				exact: true,
				pageTitle: "View Employee",
				component: viewemployee
			},
		)

		let Language = this.getPrevilege("Language");
		if (Language.view) Routes.push(
			{
				path: "/list-language",
				name: 'list language',
				exact: true,
				pageTitle: "List Language",
				component: listlanguage
			},
		)
		if (Language.edit) Routes.push(
			{
				path: "/edit-language/:id",
				name: 'list language',
				exact: true,
				pageTitle: "List Language",
				component: editlanguage
			},
		)

		if (ImportedURL.SUPERADMIN.includes(role)) Routes.push(
			{
				path: "/category-trash",
				name: 'category trash',
				exact: true,
				pageTitle: "Category Trash",
				component: CategoryTrash
			},
			{
				path: "/country-trash",
				name: 'country trash',
				exact: true,
				pageTitle: "country Trash",
				component: ListCountryTrash
			},
			// {
			// 	path: "/todolistitem-trash",
			// 	name: 'todolistitem trash',
			// 	exact: true,
			// 	pageTitle: "todolistitem Trash",
			// 	component: NewListtodoListItem
			// },

			{
				path: "/group-trash",
				name: 'group trash',
				exact: true,
				pageTitle: "Group Trash",
				component: grouptrash
			},
			{
				path: "/ticket-trash",
				name: 'ticket trash',
				exact: true,
				pageTitle: "Ticket Trash",
				component: ticketTrash
			},
			{
				path: "/social-media-type",
				name: 'social media type',
				exact: true,
				pageTitle: "Social Media Type",
				component: SocialMediaType
			},
			{
				path: "/to-do-list-trash",
				name: 'list todo list trash',
				exact: true,
				pageTitle: "List Todo List Trash",
				component: todolisttrash
			},
			{
				path: "/to-do-list-trash-item",
				name: 'smoke detector list trash',
				exact: true,
				pageTitle: "Smoke Detector List Trash",
				component: todoitemtrash
			},
			{
				path: "/list-problemkeyword-trash",
				name: 'list problemkeyword trash',
				exact: true,
				pageTitle: "Add Dynamic Category",
				component: problemKeywordTrash
			},
			{
				path: "/list-designation-trash",
				name: 'list designation-trash',
				exact: true,
				pageTitle: "Designation Trash",
				component: designationTrash
			},
			{
				path: "/list-roomarea-amenity-trash",
				name: 'list-roomarea-amenity-trash',
				exact: true,
				pageTitle: "Designation Trash",
				component: roomareaamenityTrash
			},
			{
				path: "/list-setting-roomtype-trash",
				name: '/list-setting-roomtype-trash',
				exact: true,
				pageTitle: "Roomtype Trash",
				component: settingroomtypetrash
			},

			{
				path: "/list-configuration-trash",
				name: '/list-configuration-trash',
				exact: true,
				pageTitle: "Roomtype Trash",
				component: configurationTrash
			},
			{
				path: "/list-language-trash",
				name: '/list-language-trash',
				exact: true,
				pageTitle: "Roomtype Trash",
				component: languageTrash
			},
			{
				path: "/list-time-zone-trash",
				name: '/list-time-zone-trash',
				exact: true,
				pageTitle: "Time Zone Trash",
				component: timezoneTrash
			},
		)

		return Routes;
	}

	// Set dynamic content for sidebar according to previleges
	getContent = () => {
		const { AccountState } = this.props;
		const account = AccountState?.account;
		const SelectedlanguageData = AccountState.account.SelectedlanguageData;
		const role = AccountState.role;
		const categoryType = AccountState.account.categoryType;
		let unique = 0;
		const managementStatus = GetManagementUserStatus(account.defaultmanagementcategory, account.userId, account.categoryType, null);
		let content = [
			{
				"id": 'SiteManagement',
				"label": SelectedlanguageData && SelectedlanguageData.SITE_MANAGEMENT ? SelectedlanguageData.SITE_MANAGEMENT : "Site Management",
			},
			{
				"id": unique++,
				"label": SelectedlanguageData && SelectedlanguageData.DASHBOARD ? SelectedlanguageData.DASHBOARD : "Dashboard",
				"icon": "fa fa-area-chart",
				"to": "/"
			},
		];
		if (ImportedURL.SUPERADMIN.includes(role)) {
			content.push({
				"id": unique++,
				"icon": "fa fa-building-o",
				"label": SelectedlanguageData && SelectedlanguageData.HOTELS ? SelectedlanguageData.HOTELS : "Hotels",
				"to": "/list-hotel"
			})
		} else if (account?.allhotels && account.allhotels?.length > 0 && this.getPrevilege('Hotel').view) {
			content.push({
				"id": unique++,
				"icon": "fa fa-building-o",
				"label": SelectedlanguageData && SelectedlanguageData.HOTEL ? SelectedlanguageData.HOTEL : "Hotel",
				"to": "/view-hotel/" + account.hotel
			})
		}

		if (this.getPrevilege('User').view) content.push(
			{
				"id": unique++,
				"icon": "icon-user",
				"label": SelectedlanguageData && SelectedlanguageData.USERS ? SelectedlanguageData.USERS : "Users",
				"to": "/list-user",
			}
		)

		const ticketInnerContent = [];
		let linkToTicket = '';
		let nameTicket = '';
		let iconTicket = '';
		if (this.getPrevilege('Ticket').view) {
			ticketInnerContent.push(
				{
					"id": unique++,
					"label": SelectedlanguageData && SelectedlanguageData.TICKETS_LIST ? SelectedlanguageData.TICKETS_LIST : "Tickets List",
					"to": "/tickets"
				},
			)
			linkToTicket = '/tickets'
			nameTicket = SelectedlanguageData && SelectedlanguageData.TICKETS_LIST ? SelectedlanguageData.TICKETS_LIST : "Tickets List"
			iconTicket = "fa fa-ticket"
		}

		if (this.getPrevilege('Ticket_Report').view) {
			ticketInnerContent.push(
				{
					"id": unique++,
					"label": SelectedlanguageData && SelectedlanguageData.USER_REPORTS ? SelectedlanguageData.USER_REPORTS : "Users Reports",
					"to": "/ticket-report"
				},
			)
			linkToTicket = '/ticket-report'
			nameTicket = SelectedlanguageData && SelectedlanguageData.USER_REPORTS ? SelectedlanguageData.USER_REPORTS : "Users Reports"
			iconTicket = "fa fa-list-alt"
		}
		if (this.getPrevilege('Create_Ticket_Report').view) {
			ticketInnerContent.push(
				{
					"id": unique++,
					"label": SelectedlanguageData && SelectedlanguageData.CREATE_TICKET_REPORTS ? SelectedlanguageData.CREATE_TICKET_REPORTS : "Create Tickets Reports",
					"to": "/create-ticket-report"
				}

			)
			linkToTicket = '/create-ticket-report'
			nameTicket = "Create Ticket Reports"
			iconTicket = "fa fa-list-alt"
		}

		if (this.getPrevilege('Create_Close_Ticket_Report').view) {
			ticketInnerContent.push(
				{
					"id": unique++,
					"label": SelectedlanguageData && SelectedlanguageData.CREATE_CLOSE_TICKET_REPORTS ? SelectedlanguageData.CREATE_CLOSE_TICKET_REPORTS : "Create And Close Tickets Reports",
					"to": "/create-close-ticket-report"
				}

			)
			linkToTicket = '/create-close-ticket-report'
			nameTicket = "Create And Close Ticket Reports"
			iconTicket = "fa fa-list-alt"
		}

		if (this.getPrevilege('Ageing_Report').view) {
			ticketInnerContent.push(
				{
					"id": unique++,
					"label": SelectedlanguageData && SelectedlanguageData.AGEING_REPORTS ? SelectedlanguageData.AGEING_REPORTS : "Ageing Reports",
					"to": "/ageing-report"
				}

			)
			linkToTicket = '/ageing-report'
			nameTicket = "Ageing Reports"
			iconTicket = "fa fa-list-alt"
		}

		if (this.getPrevilege('All_Reports').view) {
			ticketInnerContent.push(
				{
					"id": unique++,
					"label": SelectedlanguageData && SelectedlanguageData.TICKET_REPORTS ? SelectedlanguageData.TICKET_REPORTS : "Tickets Reports",
					"to": "/ticket-reports"
				},
				{
					"id": unique++,
					"label": SelectedlanguageData && SelectedlanguageData.HOTEL_REPORTS ? SelectedlanguageData.HOTEL_REPORTS : "Hotels Reports",
					"to": "/hotel-ticket-reports"
				},
			)
			linkToTicket = '/ticket-report'
			nameTicket = "Reports"
			iconTicket = "fa fa-list-alt"
		}
		if (this.getPrevilege('Incident_Ticket').view) {
			ticketInnerContent.push(
				{
					"id": unique++,
					"label": SelectedlanguageData && SelectedlanguageData.INCIDENT_TICKET ? SelectedlanguageData.INCIDENT_TICKET : "Incident Tickets",
					"to": "/incident-ticket"
				},
			)
			linkToTicket = '/incident-ticket'
			nameTicket = SelectedlanguageData && SelectedlanguageData.INCIDENT_TICKET ? SelectedlanguageData.INCIDENT_TICKET : "Incident Tickets"
			iconTicket = "fa fa-ticket"
		}
		if (this.getPrevilege('Create_Incident_Ticket_Report').view) {
			ticketInnerContent.push(
				{
					"id": unique++,
					"label": SelectedlanguageData && SelectedlanguageData.CREATE_INCIDENT_TICKET_REPORTS ? SelectedlanguageData.CREATE_INCIDENT_TICKET_REPORTS : "Create Incident Tickets Reports",
					"to": "/create-incident-ticket-report"
				}

			)
			linkToTicket = '/create-incident-ticket-report'
			nameTicket = "Create Incident Ticket Reports"
			iconTicket = "fa fa-list-alt"
		}
		if (this.getPrevilege('Ticket_Analytics').view) {
			ticketInnerContent.push(
				{
					"id": unique++,
					"label": SelectedlanguageData && SelectedlanguageData.TICKET_ANALYTICS ? SelectedlanguageData.TICKET_ANALYTICS : "Ticket Analytics",
					"to": "/ticket-analytics"
				},
			)
			linkToTicket = '/ticket-analytics'
			nameTicket = SelectedlanguageData && SelectedlanguageData.TICKET_ANALYTICS ? SelectedlanguageData.TICKET_ANALYTICS : "Ticket Analytics"
			iconTicket = "fa fa-ticket"
		}
		if (ticketInnerContent.length == 1) {
			content.push({
				"id": unique++,
				"icon": iconTicket,
				"label": nameTicket,
				"to": linkToTicket,
			})

		}
		else if (ticketInnerContent.length) {
			content.push({
				"id": unique++,
				"icon": "fa fa-ticket",
				"label": SelectedlanguageData && SelectedlanguageData.TICKETS ? SelectedlanguageData.TICKETS : "Tickets",
				content: ticketInnerContent
			})
		}

		const reviewInnerContent = [];
		let linkToReview = '';
		let nameReview = '';
		let iconReview = '';
		if (ImportedURL.SUPERADMIN.includes(role)) {
			reviewInnerContent.push(
				{
					"id": unique++,
					"label": SelectedlanguageData && SelectedlanguageData.SEND_REVIEW ? SelectedlanguageData.SEND_REVIEW : "Send Review",
					"to": "/reviews"
				},
				{
					"id": unique++,
					"label": SelectedlanguageData && SelectedlanguageData.REPORTS ? SelectedlanguageData.REPORTS : "Reports",
					"to": "/reports"
				},
			)
		}

		if (this.getPrevilege('Feedback').view) {
			reviewInnerContent.push(
				{
					"id": unique++,
					"label": SelectedlanguageData && SelectedlanguageData.CLIENT_FEEDBACKS ? SelectedlanguageData.CLIENT_FEEDBACKS : "Client Feedbacks",
					"to": "/feedbacks"
				},
			)
			linkToReview = "/feedbacks"
			nameReview = SelectedlanguageData && SelectedlanguageData.CLIENT_FEEDBACKS ? SelectedlanguageData.CLIENT_FEEDBACKS : "Client Feedbacks"
			iconReview = "fa fa-comments"
		}
		if (ImportedURL.SUPERADMIN.includes(role)) {
			reviewInnerContent.push(
				{
					"id": unique++,
					"label": SelectedlanguageData && SelectedlanguageData.GUEST_TOUCH ? SelectedlanguageData.GUEST_TOUCH : "Guest Touch",
					"to": "/guest-reviews"
				},
				{
					"id": unique++,
					"label": SelectedlanguageData && SelectedlanguageData.GUEST_TOUCH_REPORTS ? SelectedlanguageData.GUEST_TOUCH_REPORTS : "Guest Touch Reports",
					"to": "/guest-reports"
				},
			)
		}
		if (this.getPrevilege('Social_Review').view) {
			reviewInnerContent.push(
				{
					"id": unique++,
					"label": SelectedlanguageData && SelectedlanguageData.SOCIAL_REVIEWS ? SelectedlanguageData.SOCIAL_REVIEWS : "Social Reviews",
					"to": "/social-reviews"
				},
			)
			linkToReview = "/social-reviews"
			nameReview = SelectedlanguageData && SelectedlanguageData.SOCIAL_REVIEWS ? SelectedlanguageData.SOCIAL_REVIEWS : "Social Reviews"
			iconReview = "icon-star"
		}
		if (reviewInnerContent.length == 1) {
			content.push({
				"id": unique++,
				"icon": iconReview,
				"label": nameReview,
				"to": linkToReview,
			})

		} else if (reviewInnerContent.length) {
			content.push(
				{
					"id": unique++,
					"icon": "icon-star",
					"label": SelectedlanguageData && SelectedlanguageData.REVIEWS ? SelectedlanguageData.REVIEWS : "Reviews",
					content: reviewInnerContent
				},
			)
		}
		let todoInnerContent = []
		let linkToToDo = ""
		let nameToDo = ""
		let iconToDo = ""
		if (this.getPrevilege('Todo_List').view && !ImportedURL.SUPERADMIN.includes(role)) {
			todoInnerContent.push(
				{
					"id": unique++,
					"label": SelectedlanguageData && SelectedlanguageData.TODO_MY_TASK ? SelectedlanguageData.TODO_MY_TASK : "My Task",
					"to": "/todo-my-task"
				},
			)
			linkToToDo = "/todo-my-task"
			nameToDo = "To-Do My Task"
			iconToDo = "icon-clock"
		}
		if (!ImportedURL.SUPERADMIN.includes(role) && this.getPrevilege('Todo_List').view) {
			todoInnerContent.push(
				{
					"id": unique++,
					"label": SelectedlanguageData && SelectedlanguageData.ALL_MY_TASK ? SelectedlanguageData.ALL_MY_TASK : "All My Task",
					"to": "/to-do-allmytask"
				},
			)
			linkToToDo = "/to-do-allmytask"
			nameToDo = SelectedlanguageData && SelectedlanguageData.ALL_MY_TASK ? SelectedlanguageData.ALL_MY_TASK : "All My Task";
			iconToDo = "icon-clock"
		}
		if (this.getPrevilege('Todo_Report').view) {
			todoInnerContent.push(
				{
					"id": unique++,
					"label": SelectedlanguageData && SelectedlanguageData.TODO_REPORT ? SelectedlanguageData.TODO_REPORT : "To-Do Report",
					"to": "/todo-report"
				},
			)
			linkToToDo = "/todo-report"
			nameToDo = SelectedlanguageData && SelectedlanguageData.TODO_REPORT ? SelectedlanguageData.TODO_REPORT : "To-Do Report"
			iconToDo = "icon-clock"
		}
		if (this.getPrevilege('Ageing_Todo_Report').view) {
			todoInnerContent.push(
				{
					"id": unique++,
					"label": SelectedlanguageData && SelectedlanguageData.AGEING_TODO_REPORT ? SelectedlanguageData.AGEING_TODO_REPORT : "Ageing To-Do Report",
					"to": "/ageing-todo-report"
				},
			)
			linkToToDo = "/ageing-todo-report"
			nameToDo = SelectedlanguageData && SelectedlanguageData.AGEING_TODO_REPORT ? SelectedlanguageData.AGEING_TODO_REPORT : "Ageing To-Do Report"
			iconToDo = "icon-clock"
		}
		if (this.getPrevilege('CreatedvsCompleted_Todo_Report').view) {
			todoInnerContent.push(
				{
					"id": unique++,
					"label": SelectedlanguageData && SelectedlanguageData.CREATED_VS_COMPLETED_TODO_REPORT ? SelectedlanguageData.CREATED_VS_COMPLETED_TODO_REPORT : "Created Vs Completed To-Do Report",
					"to": "/createdvscompleted-todo-report"
				},
			)
			linkToToDo = "/createdvscompleted-todo-report"
			nameToDo = SelectedlanguageData && SelectedlanguageData.CREATED_VS_COMPLETED_TODO_REPORT ? SelectedlanguageData.CREATED_VS_COMPLETED_TODO_REPORT : "Created Vs Completed To-Do Report"
			iconToDo = "icon-clock"
		}
		// if (this.getPrevilege('Todo_Tag').view) {
		// 	todoInnerContent.push(
		// 		{
		// 			"id": unique++,
		// 			"label": SelectedlanguageData && SelectedlanguageData.TODO_TAGS ? SelectedlanguageData.TODO_TAGS : "Tags",
		// 			"to": "/todo-tag"
		// 		},
		// 	)
		// 	linkToToDo = "/todo-tag"
		// 	nameToDo = SelectedlanguageData && SelectedlanguageData.TODO_TAGS ? SelectedlanguageData.TODO_TAGS : "Tags"
		// 	iconToDo = "fa fa-tags"
		// }
		// if (this.getPrevilege('Todo_My_List').view) {
		// 	todoInnerContent.push(
		// 		{
		// 			"id": unique++,
		// 			"label": SelectedlanguageData && SelectedlanguageData.TODO_MY_LIST ? SelectedlanguageData.TODO_MY_LIST : "My List",
		// 			"to": "/todo-my-list"
		// 		},
		// 	)
		// 	linkToToDo = "/todo-my-list"
		// 	nameToDo = SelectedlanguageData && SelectedlanguageData.TODO_MY_LIST ? SelectedlanguageData.TODO_MY_LIST : "My List"
		// 	iconToDo = "fa fa-folder-o"
		// }
		// if (this.getPrevilege('Todo_List_Item').view) {
		// 	todoInnerContent.push(
		// 		{
		// 			"id": unique++,
		// 			"label": SelectedlanguageData && SelectedlanguageData.CHECK_LIST ? SelectedlanguageData.CHECK_LIST : "Check List",
		// 			"to": "/to-do-list-item"
		// 		}
		// 	)
		// 	linkToToDo = "/to-do-list-item"
		// 	nameToDo = SelectedlanguageData && SelectedlanguageData.CHECK_LIST ? SelectedlanguageData.CHECK_LIST : "Check List"
		// 	iconToDo = "fa fa-pagelines"
		// }
		if (todoInnerContent.length == 1) {
			content.push({
				"id": unique++,
				"icon": iconToDo,
				"label": nameToDo,
				"to": linkToToDo,
			})

		} else if (todoInnerContent.length) {
			content.push(
				{
					"id": unique++,
					"icon": "icon-clock",
					"label": SelectedlanguageData && SelectedlanguageData.TODO_LIST ? SelectedlanguageData.TODO_LIST : "To-Do List",
					content: todoInnerContent
				},
			)
		}


		if (this.getPrevilege('Notification').view) content.push(
			{
				"id": unique++,
				"icon": "icon-bell",
				"label": SelectedlanguageData && SelectedlanguageData.NOTIFICATIONS ? SelectedlanguageData.NOTIFICATIONS : "Notifications",
				"to": "/notifications",
			},
		)
		if (ImportedURL.SUPERADMIN.includes(role)) content.push(
			{
				"id": unique++,
				"icon": "icon-list",
				"label": SelectedlanguageData && SelectedlanguageData.BOOKING_COUNT ? SelectedlanguageData.BOOKING_COUNT : "Booking Count",
				"to": "/booking-count",
			},
		)
		const setupInnerContent = [];
		let linkToSetup = '';
		let nameSetup = '';
		let iconSetup = '';

		if (this.getPrevilege('Problem_Keyword').view) {
			setupInnerContent.push(
				{
					"id": unique++,
					"label": SelectedlanguageData && SelectedlanguageData.PROBLEM_KEYWORD ? SelectedlanguageData.PROBLEM_KEYWORD : "Problem Keyword",
					"to": "/list-problem-keyword"
				}
			)
			linkToSetup = "/list-problem-keyword"
			nameSetup = SelectedlanguageData && SelectedlanguageData.PROBLEM_KEYWORD ? SelectedlanguageData.PROBLEM_KEYWORD : "Problem Keyword"
			iconSetup = "fa fa-puzzle-piece"
		}

		if (this.getPrevilege('Designation').view) {
			setupInnerContent.push(
				{
					"id": unique++,
					"label": SelectedlanguageData && SelectedlanguageData.DESIGNATION ? SelectedlanguageData.DESIGNATION : "Designation",
					"to": "/list-designation"
				}
			)
			linkToSetup = "/list-designation"
			nameSetup = SelectedlanguageData && SelectedlanguageData.DESIGNATION ? SelectedlanguageData.DESIGNATION : "Designation"
			iconSetup = "fa fa-puzzle-piece"
		}

		if (this.getPrevilege('Room_Amenity').view) {
			setupInnerContent.push(
				{
					"id": unique++,
					"label": SelectedlanguageData && SelectedlanguageData.ROOM_AMENITY ? SelectedlanguageData.ROOM_AMENITY : "Room Amenity",
					"to": "/list-roomarea-amenity"
				}
			)
			linkToSetup = "/list-roomarea-amenity"
			nameSetup = SelectedlanguageData && SelectedlanguageData.ROOM_AMENITY ? SelectedlanguageData.ROOM_AMENITY : "Room Amenity"
			iconSetup = "icon-home"
		}



		if (setupInnerContent.length == 1) {
			content.push(
				{
					"id": unique++,
					"icon": iconSetup,
					"label": nameSetup,
					'to': linkToSetup,
				}
			)
		} else if (setupInnerContent.length) {
			content.push(
				{
					"id": unique++,
					"icon": "fa fa-diamond",
					"label": SelectedlanguageData && SelectedlanguageData.SETUP ? SelectedlanguageData.SETUP : "Setup",
					content: setupInnerContent,
				}
			)
		}




		// =============== Master 
		const masterInnerContent = [];
		let linkToMaster = '';
		let nameMaster = '';
		let iconMaster = '';

		if (this.getPrevilege('Category').view) {
			masterInnerContent.push(
				{
					"id": unique++,
					"label": SelectedlanguageData && SelectedlanguageData.CATEGORIES ? SelectedlanguageData.CATEGORIES : "Categories",
					"to": "/list-category"
				}
			)
			linkToMaster = "/list-category"
			nameMaster = SelectedlanguageData && SelectedlanguageData.CATEGORIES ? SelectedlanguageData.CATEGORIES : "Categories"
			iconMaster = "icon-bar-chart"
		}
		if (this.getPrevilege('Country').view) {
			masterInnerContent.push(
				{
					"id": unique++,
					"label": SelectedlanguageData && SelectedlanguageData.COUNTRIES ? SelectedlanguageData.COUNTRIES : "Countries",
					"to": "/list-country"
				}
			)
			linkToMaster = "/list-country"
			nameMaster = SelectedlanguageData && SelectedlanguageData.COUNTRIES ? SelectedlanguageData.COUNTRIES : "Countries"
			iconMaster = "fa fa-flag"
		}
		if (this.getPrevilege('NewListTodoList').view) {
			masterInnerContent.push(
				{
					"id": unique++,
					"label": SelectedlanguageData && SelectedlanguageData.ADDTODOLISTITEM ? SelectedlanguageData.ADDTODOLISTITEM : "TodoListItems",
					"to": "/todo-listitem"
				}
			)
			linkToMaster = "/todo-listitem"
			nameMaster = SelectedlanguageData && SelectedlanguageData.ADDTODOLISTITEM ? SelectedlanguageData.ADDTODOLISTITEM : "TodoListItems"
			iconMaster = "fa fa-flag"
		}
		if (this.getPrevilege('NewaddListtodo').view) {
			masterInnerContent.push(
				{
					"id": unique++,
					"label": SelectedlanguageData && SelectedlanguageData.ADDTODOLISTITEM ? SelectedlanguageData.ADDTODOLISTITEM : "AddTodoListItems",
					"to": "/add-todo-listitem"
				}
			)
			linkToMaster = "/NewaddListtodo"
			nameMaster = SelectedlanguageData && SelectedlanguageData.ADDTODOLISTITEM ? SelectedlanguageData.ADDTODOLISTITEM : "AddTodoListItems"
			iconMaster = "fa fa-flag"
		}
		if (this.getPrevilege('Group').view) {
			masterInnerContent.push(
				{
					"id": unique++,
					"label": SelectedlanguageData && SelectedlanguageData.GROUP ? SelectedlanguageData.GROUP : "Groups",
					"to": "/list-group"
				}
			)
			linkToMaster = "/list-group"
			nameMaster = SelectedlanguageData && SelectedlanguageData.GROUP ? SelectedlanguageData.GROUP : "Groups"
			iconMaster = "icon-globe-alt"
		}
		if (this.getPrevilege('Currency').view) {
			masterInnerContent.push(
				{
					"id": unique++,
					"label": SelectedlanguageData && SelectedlanguageData.CURRENCIES ? SelectedlanguageData.CURRENCIES : "Currencies",
					"to": "/list-currency"
				}
			)
			linkToMaster = "/list-currency"
			nameMaster = SelectedlanguageData && SelectedlanguageData.CURRENCIES ? SelectedlanguageData.CURRENCIES : "Currencies"
			iconMaster = "fa fa-dollar"
		}

		if (ImportedURL.SUPERADMIN.includes(role)) {
			masterInnerContent.push(
				{
					"id": unique++,
					"label": SelectedlanguageData && SelectedlanguageData.OTA_REVIEW ? SelectedlanguageData.OTA_REVIEW : "OTA'S Reviews",
					"to": "/social-media-type"
				},
			)
		}
		if (this.getPrevilege('Time_Zone').view) {
			if (ImportedURL.SUPERADMIN.includes(role)) {
				masterInnerContent.push(
					{
						"id": unique++,
						"label": SelectedlanguageData && SelectedlanguageData.TIME_ZONE ? SelectedlanguageData.TIME_ZONE : "Time Zone",
						"to": "/list-time-zone"
					},
				)
			}
		}
		if (this.getPrevilege('List_Employee').view) {
			masterInnerContent.push(
				{
					"id": unique++,
					"label": SelectedlanguageData && SelectedlanguageData.EMPLOYEE_LIST ? SelectedlanguageData.EMPLOYEE_LIST : "Employees List",
					"to": "/list-employee"
				}
			)
			linkToMaster = "/list-employee"
			nameMaster = SelectedlanguageData && SelectedlanguageData.EMPLOYEE_LIST ? SelectedlanguageData.EMPLOYEE_LIST : "Employees List"
			iconMaster = "fa fa-comments"
		}
		if (this.getPrevilege('Phone_Number_Block_List').view) {
			masterInnerContent.push(
				{
					"id": unique++,
					"label": SelectedlanguageData && SelectedlanguageData.BLOCK_LIST ? SelectedlanguageData.BLOCK_LIST : "Block List",
					"to": "/phone-number-block-list"
				}
			)
			linkToMaster = "/phone-number-block-list"
			nameMaster = SelectedlanguageData && SelectedlanguageData.BLOCK_LIST ? SelectedlanguageData.BLOCK_LIST : "Block List"
			iconMaster = "fa fa-ban"
		}
		if (this.getPrevilege('Configuration').view) {
			masterInnerContent.push(
				{
					"id": unique++,
					"label": SelectedlanguageData && SelectedlanguageData.CONFIGURATION ? SelectedlanguageData.CONFIGURATION : "Configuration",
					"to": "/list-configuration"
				}
			)
			linkToMaster = "/list-configuration"
			nameMaster = SelectedlanguageData && SelectedlanguageData.CONFIGURATION ? SelectedlanguageData.CONFIGURATION : "Configuration"
			iconMaster = "icon-home"
		}
		if (this.getPrevilege('Language').view) {
			masterInnerContent.push(
				{
					"id": unique++,
					"label": SelectedlanguageData && SelectedlanguageData.LANGUAGE ? SelectedlanguageData.LANGUAGE : "Language",
					"to": "/list-language"
				}
			)
			linkToMaster = "/list-language"
			nameMaster = SelectedlanguageData && SelectedlanguageData.LANGUAGE ? SelectedlanguageData.LANGUAGE : "Language"
			iconMaster = "icon-home"
		}

		if (ImportedURL.SUPERADMIN.includes(role) && this.getPrevilege('PMS_Privileges').view) {
			masterInnerContent.push(
				{
					"id": unique++,
					"label": SelectedlanguageData && SelectedlanguageData.PMS_PRIVILEGES_LABEL ? SelectedlanguageData.PMS_PRIVILEGES_LABEL : "PMS Privileges",
					"to": "/pms-privileges"
				}
			)
			linkToMaster = "/pms-privileges"
			nameMaster = SelectedlanguageData && SelectedlanguageData.PMS_PRIVILEGES_LABEL ? SelectedlanguageData.PMS_PRIVILEGES_LABEL : "PMS Privileges"
			iconMaster = "fa fa-universal-access"
		}
		if (masterInnerContent.length == 1) {
			content.push(
				{
					"id": unique++,
					"icon": iconMaster,
					"label": nameMaster,
					'to': linkToMaster,
				}
			)
		} else if (masterInnerContent.length) {
			content.push(
				{
					"id": unique++,
					"icon": "fa fa-table",
					"label": SelectedlanguageData && SelectedlanguageData.MASTER ? SelectedlanguageData.MASTER : "Master",
					content: masterInnerContent,
				}
			)
		}
		const setupInnerContentSettings = [];
		setupInnerContentSettings.push(
			{
				"id": unique++,
				"label": SelectedlanguageData && SelectedlanguageData.SITE_SETTINGS ? SelectedlanguageData.SITE_SETTINGS : "Site Settings",
				"to": "/site-setting"
			},
		)
		if (managementStatus) {
			setupInnerContentSettings.push(
				{
					"id": unique++,
					"label": SelectedlanguageData && SelectedlanguageData.TICKET_WORKFLOW ? SelectedlanguageData.TICKET_WORKFLOW : "Ticket Workflow",
					"to": "/ticket-flow"
				},
			)
		}
		if (managementStatus) {
			setupInnerContentSettings.push(
				{
					"id": unique++,
					"label": SelectedlanguageData && SelectedlanguageData.REVIEW_FLOW ? SelectedlanguageData.REVIEW_FLOW : "Review Flow",
					"to": "/review-flow"
				},
			)
		}
		if (managementStatus) {
			setupInnerContentSettings.push(
				{
					"id": unique++,
					"label": SelectedlanguageData && SelectedlanguageData.REMINDER ? SelectedlanguageData.REMINDER : "Reminder",
					"to": "/reminder"
				},
			)
		}
		setupInnerContentSettings.push(
			{
				"id": unique++,
				"label": SelectedlanguageData && SelectedlanguageData.CHANGE_PASSWORD ? SelectedlanguageData.CHANGE_PASSWORD : "Change Password",
				"to": "/change-password"
			},
		)
		if (ImportedURL.SUPERADMIN.includes(role)) setupInnerContentSettings.push(
			{
				"id": unique++,
				"label": SelectedlanguageData && SelectedlanguageData.LOGIN_HISTORY ? SelectedlanguageData.LOGIN_HISTORY : "Login History",
				"to": "/list-loginhistory"
			},
			// {
			// 	"id": unique++,
			// 	"label": SelectedlanguageData && SelectedlanguageData.DEVESH_GUEST ? SelectedlanguageData.DEVESH_GUEST : "Devesh Guest",
			// 	"to": "/devesh-guest"
			// },
			// {
			// 	"id": unique++,
			// 	"label": SelectedlanguageData && SelectedlanguageData.SEND_INVITE ? SelectedlanguageData.SEND_INVITE : "Send Invite",
			// 	"to": "/send-invite"
			// },
			{
				"id": unique++,
				"label": SelectedlanguageData && SelectedlanguageData.PUSH_MESSAGE ? SelectedlanguageData.PUSH_MESSAGE : "Push Message",
				"to": "/push-message"
			},
		)

		if (this.getPrevilege('Room_Type_And_Rooms').view) {
			if (role != "vendor") setupInnerContentSettings.push(
				{
					"id": unique++,
					"label": SelectedlanguageData && SelectedlanguageData.ROOMTYPES_ROOMS ? SelectedlanguageData.ROOMTYPES_ROOMS : "Room Type & Rooms",
					"to": "/list-setting-roomtype"
				}
			)
		}

		if (setupInnerContentSettings.length) content.push(
			{
				"id": unique++,
				"icon": "fa fa-gear",
				"label": SelectedlanguageData && SelectedlanguageData.SETTINGS ? SelectedlanguageData.SETTINGS : "Settings",
				content: setupInnerContentSettings,
			}
		)



		if (ImportedURL.SUPERADMIN.includes(role)) content.push(
			{
				"id": unique++,
				"icon": "fa fa-trash-o",
				"label": SelectedlanguageData && SelectedlanguageData.TRASH ? SelectedlanguageData.TRASH : "Trash",
				content: [
					{
						"id": unique++,
						"label": SelectedlanguageData && SelectedlanguageData.TICKETS ? SelectedlanguageData.TICKETS : "Tickets",
						"to": "/ticket-trash"
					},
					{
						"id": unique++,
						"label": SelectedlanguageData && SelectedlanguageData.GROUP ? SelectedlanguageData.GROUP : "Groups",
						"to": "/group-trash"
					},
					{
						"id": unique++,
						"label": SelectedlanguageData && SelectedlanguageData.CATEGORIES ? SelectedlanguageData.CATEGORIES : "Categories",
						"to": "/category-trash"
					},
					{
						"id": unique++,
						"label": SelectedlanguageData && SelectedlanguageData.COUNTRIES ? SelectedlanguageData.COUNTRIES : "Countries",
						"to": "/country-trash"
					},
					{
						"id": unique++,
						"label": SelectedlanguageData && SelectedlanguageData.DESIGNATION ? SelectedlanguageData.DESIGNATION : "Designation",
						"to": "/list-designation-trash"
					},
					{
						"id": unique++,
						"label": SelectedlanguageData && SelectedlanguageData.BLOCK_LIST ? SelectedlanguageData.BLOCK_LIST : "Block List",
						"to": "/phone-number-block-list-trash"
					},
					{
						"id": unique++,
						"label": SelectedlanguageData && SelectedlanguageData.ROOM_AMENITY ? SelectedlanguageData.ROOM_AMENITY : "Room Amenity",
						"to": "/list-roomarea-amenity-trash"
					},
					{
						"id": unique++,
						"label": SelectedlanguageData && SelectedlanguageData.ROOMTYPES_ROOMS ? SelectedlanguageData.ROOMTYPES_ROOMS : "Room Type & Rooms",
						"to": "/list-setting-roomtype-trash"
					},
					{
						"id": unique++,
						"label": SelectedlanguageData && SelectedlanguageData.TIME_ZONE ? SelectedlanguageData.TIME_ZONE : "Time Zone",
						"to": "/list-time-zone-trash"
					},
					{
						"id": unique++,
						"label": SelectedlanguageData && SelectedlanguageData.CONFIGURATION ? SelectedlanguageData.CONFIGURATION : "Configuration",
						"to": "/list-configuration-trash"
					},
					{
						"id": unique++,
						"label": SelectedlanguageData && SelectedlanguageData.LANGUAGE ? SelectedlanguageData.LANGUAGE : "Language",
						"to": "/list-language-trash"
					},
				]
			}
		)
		return content;
	}
	toggleVisible = () => {
		const scrolled = document.documentElement.scrollTop;
		if (scrolled > 300) {
			this.setState({ isTopToggle: true })
		}
		else if (scrolled <= 300) {
			this.setState({ isTopToggle: false })
		}
	};

	scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
			/* you can also use 'auto' behaviour
			   in place of 'smooth' */
		});
	};

	render() {
		const { AccountState } = this.props;
		const previleges = AccountState.previleges;
		let content = this.getContent();
		let Routes = this.getRoutes();
		const { isOpenRightSidebar } = this.state;
		const { darkMinSidebar, istoggleLeftMenu, } = this.props
		const pageHeading = Routes.filter((route) => route.path === this.props.location.pathname)
		const adminName = AccountState.account.name;

		return (
			<>
				<div className={`${istoggleLeftMenu ? "offcanvas-active" : ""}`}>
					<div style={this.state.parentlink === 'login' ? masterNone : masterBlock}>
						<div id="header_top" className={`header_top ${!darkMinSidebar && 'dark'}`}>
							{/* <div id="header_top" className="header_top dark"> */}
							<div className="container">
								<div className="hleft">
									<NavLink
										to="/"
										onClick={() => this.handler('hr', 'dashboard')}
										className="header-brand"
									>
										<img className="avatar custom-sidebar-logo" src={ImportedURL.LOCALIMAGEURL + "assets/images/reviewslogo.png"} data-toggle="tooltip" data-original-title="Avatar Name" alt="fake_url" style={{ background: "white" }} onError={onErrorImage} />
									</NavLink>
								</div>
								<div className="hright">
									<div className="dropdown">
										<span className="nav-link icon settingbar" onClick={this.toggleRightSidebar}>
											<i
												className="fa fa-gear fa-spin"
												data-toggle="tooltip"
												data-placement="right"
												title="Settings"
											/>
										</span>
										<p className="nav-link icon menu_toggle" onClick={() => this.toggleLeftMenu(!istoggleLeftMenu)}>
											<i className="fa  fa-align-left" />
										</p>
									</div>
								</div>
							</div>
						</div>
						<div id="rightsidebar" className={`right_sidebar ${isOpenRightSidebar && "open"}`}>
							<span className="p-3 settingbar float-right" onClick={this.toggleRightSidebar}>
								<i className="fa fa-close" />
							</span>
							<ul className="nav nav-tabs" role="tablist">
								<li className="nav-item">
									<a className="nav-link active" data-toggle="tab" href="#Settings" aria-expanded="true">
										Settings
									</a>
								</li>
							</ul>
							<div className="tab-content">
								<div
									role="tabpanel"
									className="tab-pane vivify fadeIn active"
									id="Settings"
									aria-expanded="true"
								>
									<div className="mb-4">
										<h6 className="font-14 font-weight-bold text-muted">Font Style</h6>
										<div className="custom-controls-stacked font_setting">
											<label className="custom-control custom-radio custom-control-inline">
												<input
													type="radio"
													className="custom-control-input"
													name="font"
													defaultChecked
													defaultValue="font-opensans"
													onChange={() => this.handleFont('font-opensans')}
												/>
												<span className="custom-control-label">Open Sans Font</span>
											</label>
											<label className="custom-control custom-radio custom-control-inline">
												<input
													type="radio"
													className="custom-control-input"
													name="font"
													defaultValue="font-montserrat"
													onChange={() => this.handleFont('font-montserrat')}
												/>
												<span className="custom-control-label">Montserrat Google Font</span>
											</label>
											<label className="custom-control custom-radio custom-control-inline">
												<input
													type="radio"
													className="custom-control-input"
													name="font"
													onChange={() => this.handleFont('font-roboto')}
												/>
												<span className="custom-control-label">Robot Google Font</span>
											</label>
										</div>
									</div>
									<div className="mb-4">
										<h6 className="font-14 font-weight-bold text-muted">Selected Menu Icon</h6>
										<div className="custom-controls-stacked arrow_option">
											<label className="custom-control custom-radio custom-control-inline">
												<input
													type="radio"
													className="custom-control-input"
													name="marrow"
													defaultValue="arrow-a"
													onChange={() => this.handleMenuIcon('list-a')}
												/>
												<span className="custom-control-label">A</span>
											</label>
											<label className="custom-control custom-radio custom-control-inline">
												<input
													type="radio"
													className="custom-control-input"
													name="marrow"
													defaultValue="arrow-b"
													onChange={() => this.handleMenuIcon('list-b')}
												/>
												<span className="custom-control-label">B</span>
											</label>
											<label className="custom-control custom-radio custom-control-inline">
												<input
													type="radio"
													className="custom-control-input"
													name="marrow"
													defaultValue="arrow-c"
													defaultChecked
													onChange={() => this.handleMenuIcon('list-c')}
												/>
												<span className="custom-control-label">C</span>
											</label>
										</div>

										<h6 className="font-14 font-weight-bold mt-4 text-muted">SubMenu List Icon</h6>
										<div className="custom-controls-stacked list_option">
											<label className="custom-control custom-radio custom-control-inline">
												<input
													type="radio"
													className="custom-control-input"
													name="listicon"
													defaultValue="list-a"
													defaultChecked
													onChange={() => this.handleSubMenuIcon('list-a')}
												/>
												<span className="custom-control-label">A</span>
											</label>
											<label className="custom-control custom-radio custom-control-inline">
												<input
													type="radio"
													className="custom-control-input"
													name="listicon"
													defaultValue="list-b"
													onChange={() => this.handleSubMenuIcon('list-b')}
												/>
												<span className="custom-control-label">B</span>
											</label>
											<label className="custom-control custom-radio custom-control-inline">
												<input
													type="radio"
													className="custom-control-input"
													name="listicon"
													defaultValue="list-c"
													onChange={() => this.handleSubMenuIcon('list-c')}
												/>
												<span className="custom-control-label">C</span>
											</label>
										</div>
									</div>
									<div>
										<h6 className="font-14 font-weight-bold mt-4 text-muted">General Settings</h6>
										<ul className="setting-list list-unstyled mt-1 setting_switch">
											<li>
												<label className="custom-switch">
													<span className="custom-switch-description">Night Mode</span>
													<input
														type="checkbox"
														name="custom-switch-checkbox"
														className="custom-switch-input btn-darkmode"
														onChange={(e) => this.handleDarkMode(e)}
													/>
													<span className="custom-switch-indicator" />
												</label>
											</li>
											<li>
												<label className="custom-switch">
													<span className="custom-switch-description">Fix Navbar top</span>
													<input
														type="checkbox"
														name="custom-switch-checkbox"
														className="custom-switch-input btn-fixnavbar"
														onChange={(e) => this.handleFixNavbar(e)}
													/>
													<span className="custom-switch-indicator" />
												</label>
											</li>
											<li>
												<label className="custom-switch">
													<span className="custom-switch-description">Header Dark</span>
													<input
														type="checkbox"
														name="custom-switch-checkbox"
														className="custom-switch-input btn-pageheader"
														onChange={(e) => this.handleDarkHeader(e)}
													/>
													<span className="custom-switch-indicator" />
												</label>
											</li>
											<li>
												<label className="custom-switch">
													<span className="custom-switch-description">Min Sidebar Light</span>
													<input
														type="checkbox"
														name="custom-switch-checkbox"
														className="custom-switch-input btn-min_sidebar"
														onChange={(e) => this.handleMinSidebar(e)}
													/>
													<span className="custom-switch-indicator" />
												</label>
											</li>
											<li>
												<label className="custom-switch">
													<span className="custom-switch-description">Sidebar Dark</span>
													<input
														type="checkbox"
														name="custom-switch-checkbox"
														className="custom-switch-input btn-sidebar"
														onChange={(e) => this.handleSidebar(e)}
													/>
													<span className="custom-switch-indicator" />
												</label>
											</li>
											<li>
												<label className="custom-switch">
													<span className="custom-switch-description">Icon Color</span>
													<input
														type="checkbox"
														name="custom-switch-checkbox"
														className="custom-switch-input btn-iconcolor"
														onChange={(e) => this.handleIconColor(e)}
													/>
													<span className="custom-switch-indicator" />
												</label>
											</li>
											<li>
												<label className="custom-switch">
													<span className="custom-switch-description">Gradient Color</span>
													<input
														type="checkbox"
														name="custom-switch-checkbox"
														className="custom-switch-input btn-gradient"
														onChange={(e) => this.handleGradientColor(e)}
													/>
													<span className="custom-switch-indicator" />
												</label>
											</li>

											<li>
												<label className="custom-switch">
													<span className="custom-switch-description">RTL Support</span>
													<input
														type="checkbox"
														name="custom-switch-checkbox"
														className="custom-switch-input btn-rtl"
														onChange={(e) => this.handleRtl(e)}
													/>
													<span className="custom-switch-indicator" />
												</label>
											</li>

										</ul>
									</div>
									<hr />
								</div>
							</div>
						</div>
						<div className="theme_div">
							<div className="card">
								<div className="card-body">
									<ul className="list-group list-unstyled">
										<li className="list-group-item mb-2">
											<p>Default Theme</p>
											<a href="../main/index.html">
												<img
													src="/assets/images/themes/default.png"
													className="img-fluid"
													alt="fake_url" onError={onErrorImage}
												/>
											</a>
										</li>
										<li className="list-group-item mb-2">
											<p>Night Mode Theme</p>
											<a href="../dark/index.html">
												<img
													src="/assets/images/themes/dark.png"
													className="img-fluid"
													alt="fake_url" onError={onErrorImage}
												/>
											</a>
										</li>
										<li className="list-group-item mb-2">
											<p>RTL Version</p>
											<a href="../rtl/index.html">
												<img
													src="/assets/images/themes/rtl.png"
													className="img-fluid"
													alt="fake_url" onError={onErrorImage}
												/>
											</a>
										</li>
										<li className="list-group-item mb-2">
											<p>Theme Version2</p>
											<a href="../theme2/index.html">
												<img
													src="/assets/images/themes/theme2.png"
													className="img-fluid"
													alt="fake_url" onError={onErrorImage}
												/>
											</a>
										</li>
										<li className="list-group-item mb-2">
											<p>Theme Version3</p>
											<a href="../theme3/index.html">
												<img
													src="/assets/images/themes/theme3.png"
													className="img-fluid"
													alt="fake_url" onError={onErrorImage}
												/>
											</a>
										</li>
										<li className="list-group-item mb-2">
											<p>Theme Version4</p>
											<a href="../theme4/index.html">
												<img
													src="/assets/images/themes/theme4.png"
													className="img-fluid"
													alt="fake_url" onError={onErrorImage}
												/>
											</a>
										</li>
										<li className="list-group-item mb-2">
											<p>Horizontal Version</p>
											<a href="../horizontal/index.html">
												<img
													src="/assets/images/themes/horizontal.png"
													className="img-fluid"
													alt="fake_url" onError={onErrorImage}
												/>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div id="left-sidebar" className="sidebar ">
							<h5 className="brand-name">MyHotel AI</h5>
							<h6 className="brand-name siteNameAdmin">{adminName}</h6>
							<nav id="left-sidebar-nav" className="sidebar-nav">
								<MetisMenu className=""
									content={content}
									noBuiltInClassNames={true}
									classNameContainer={(e) => this.toggleSubMenu(e)}
									classNameContainerVisible="in"
									classNameItemActive="active"
									classNameLinkActive="active"
									classNameItemHasVisibleChild="active"
									classNameLink="has-arrow arrow-c"
									iconNameStateHidden="fa fa-angle-down"
									iconNameStateVisible="fa fa-angle-up"
									classNameStateIcon='right-allign-menu'
									classNameItem="ul-class"
									iconNamePrefix=""
									LinkComponent={(e) => <DefaultLink itemProps={e} />}
								/>
							</nav>
						</div>
					</div>

					<div className="page">
						<Header {...this.props} dataFromParent={this.props.dataFromParent} dataFromSubParent={pageHeading[0] ? pageHeading[0].pageTitle : ''} />
						<div className='except_header'>
							<Switch>
								{Routes.map((layout, i) => {
									return <Route key={i} exact={layout.exact} path={layout.path} component={layout.component}></Route>
								})}
								{previleges && previleges.length ? <Route path="*" component={MissingRoute} /> : ''}
							</Switch>
						</div>
						<Footer />

					</div>

				</div>
				<>
					<div className='scrool_top_button' style={{ display: this.state.isTopToggle ? 'block' : 'none' }}>
						<button type='button' className='top_icon' onClick={this.scrollToTop}>
							<i class="fa fa-arrow-up" data-toggle="tooltip" title="fa fa-arrow-up"></i></button>
					</div>
				</>
			</>
		);
	}
}

const mapStateToProps = state => ({
	darkMinSidebar: state.settings.isMinSidebar,
	statisticsOpen: state.settings.isStatistics,
	friendListOpen: state.settings.isFriendList,
	statisticsClose: state.settings.isStatisticsClose,
	friendListClose: state.settings.isFriendListClose,
	istoggleLeftMenu: state.settings.isToggleLeftMenu,
	AccountState: state.account,
})

const mapDispatchToProps = dispatch => ({
	darkModeAction: (e) => dispatch(darkModeAction(e)),
	darkHeaderAction: (e) => dispatch(darkHeaderAction(e)),
	fixNavbarAction: (e) => dispatch(fixNavbarAction(e)),
	darkMinSidebarAction: (e) => dispatch(darkMinSidebarAction(e)),
	darkSidebarAction: (e) => dispatch(darkSidebarAction(e)),
	iconColorAction: (e) => dispatch(iconColorAction(e)),
	gradientColorAction: (e) => dispatch(gradientColorAction(e)),
	rtlAction: (e) => dispatch(rtlAction(e)),
	fontAction: (e) => dispatch(fontAction(e)),
	subMenuIconAction: (e) => dispatch(subMenuIconAction(e)),
	menuIconAction: (e) => dispatch(menuIconAction(e)),
	boxLayoutAction: (e) => dispatch(boxLayoutAction(e)),
	statisticsAction: (e) => dispatch(statisticsAction(e)),
	friendListAction: (e) => dispatch(friendListAction(e)),
	statisticsCloseAction: (e) => dispatch(statisticsCloseAction(e)),
	friendListCloseAction: (e) => dispatch(friendListCloseAction(e)),
	toggleLeftMenuAction: (e) => dispatch(toggleLeftMenuAction(e))
})
export default connect(mapStateToProps, mapDispatchToProps)(Menu);
