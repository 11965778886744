import React, { Component } from 'react';
import { MdDateRange } from "react-icons/md";
import DatePicker from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon";
import moment from 'moment';

class DateComponent extends Component {
    render() {
        const { minDate, value, onChange, childProps, editable, currentDate,readOnly } = this.props;

        return (
            <div className='site_pass_calander'>
                <DatePicker
                    editable={editable}
                    placeholder="MM-DD-YYYY"
                    icon={<MdDateRange />}
                    render={<InputIcon />}
                    inputclassName="form-control width-datepicker"
                    value={value}
                    onChange={onChange}
                    format="MM-DD-YYYY"
                    minDate={minDate}
                    readOnly={readOnly}  // Apply the readOnly prop here
                    selected={currentDate ? currentDate : moment().tz("America/chicago")}
                    {...childProps}
                />
            </div>
        );
    }
}

export default DateComponent;