import axios from 'axios';
import ImportedURL from '../common/api';

export function AC_HANDLE_TODO_LIST_ITEM_CHANGE(name, value, i) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_TODO_LIST_ITEM_CHANGE", name: name, value: value, i: i })
    };
}

export function AC_HANDLE_TODO_LIST_ITEM_UPDATE(name, value, id) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_TODO_LIST_ITEM_UPDATE", name: name, value: value, id: id })
    };
}

export function AC_HANDLE_TODO_LIST_CHANGE(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_TODO_LIST_CHANGE", name: name, value: value })
    };
}

export function AC_LIST_TODO_LIST_ITEM(params = {}) {
    return function (dispatch) {
        return axios.get(ImportedURL.API.listTodolistitem, { params: params })
            .then((res) => {
                dispatch({ type: "LIST_TODO_LIST_ITEM", payload: res.data, spinner: false })
            }).catch((err) => { console.log(err); });
    };
}

export function AC_VIEW_TODO_LIST_ITEM(id, hotel, spinnerValue) {
    return function (dispatch) {
        return axios.get(ImportedURL.API.viewTodolistitem + "/" + id, { params: { hotel } })
            .then((res) => {
                dispatch({ type: "VIEW_TODO_LIST_ITEM", payload: res.data, spinner: spinnerValue })
            }).catch((err) => { console.log(err); });
    };
}
export function AC_EMPTY_TODO_LIST_ITEM_INSPECTION() {
    return function (dispatch) {
        dispatch({ type: "EMPTY_TODO_LIST_ITEM_INSPECTION" })
    }
}

export function AC_EMPTY_LIST_TODO_ITEM() {
    return function (dispatch) {
        dispatch({ type: "EMPTY_LIST_TODO_ITEM" })
    };
}

// -------------------------- Todo List -------------------------

export function AC_LIST_TO_DO_LIST(params = {}) {
    return function (dispatch) {
        return axios.get(ImportedURL.API.allmytask, { params: params })
            .then((res) => {
                dispatch({ type: "LIST_TO_DO_LIST", payload: res.data.response, total: res.data.total, spinner: false })
            }).catch((err) => {
                dispatch({ type: "LIST_TO_DO_LIST", payload: [], total: 0, spinner: false })
            });
    }
}

export function AC_LIST_PENDING_TO_DO_LIST(params = {}) {
    return function (dispatch) {
        return axios.get(ImportedURL.API.pendingTodoList, { params: params })
            .then((res) => {
                dispatch({ type: "LIST_PENDING_TO_DO_LIST", payload: res.data, spinner: false })
            }).catch((err) => {
                dispatch({ type: "LIST_PENDING_TO_DO_LIST", payload: [], total: 0, spinner: false })
            });
    }
}

export function AC_VIEW_TO_DO_LIST(id) {
    return function (dispatch) {
        return axios.get(ImportedURL.API.viewTodo + "/" + id)
            .then((res) => {
                dispatch({ type: "VIEW_TO_DO_LIST", payload: res.data.data, spinner: false })
            }).catch((err) => {
                dispatch({ type: "VIEW_TO_DO_LIST", payload: {}, spinner: false })
            });
    };
}

export function AC_PASS_VIEW_TO_DO_LIST(data) {
    return function (dispatch) {
        dispatch({ type: "VIEW_TO_DO_LIST", payload: data, spinner: false })
    };
}

export function AC_HANDLE_TO_DO_LIST_CHANGE(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_TO_DO_LIST_CHANGE", name: name, value: value })
    };
}

export function AC_EMPTY_TO_DO_LIST() {
    return function (dispatch) {
        dispatch({ type: "EMPTY_TO_DO_LIST" })
    };
}

export function AC_SPINNER_TO_DO_LIST() {
    return function (dispatch) {
        dispatch({ type: "SPINNER_TO_DO_LIST" })
    };
}

export function AC_MODAL_SPINNER_TO_DO_LIST() {
    return function (dispatch) {
        dispatch({ type: "MODAL_SPINNER_TO_DO_LIST" })
    };
}

export function AC_LIST_TO_DO_LIST_ITEMS(params = {}) {
    return function (dispatch) {
        return axios.get(ImportedURL.API.getTodolistitembyHotel, { params: params })
            .then((res) => {
                dispatch({ type: "LIST_TO_DO_LIST_ITEMS", payload: res.data.data })
            }).catch((err) => { console.log(err); });
    };
}

export function AC_HANDLE_CHANGE_TODO_BACK_TAB(value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_CHANGE_TODO_BACK_TAB", value: value })
    };
}

export function AC_ADD_TODO_APIS(params = {}) {
    return function (dispatch) {
        return axios.post(ImportedURL.API.toodAddAPI, params)
            .then((res) => {
                if (res.data && res.data.configoptions) {
                    let configoptions = res.data.configoptions ? res.data.configoptions : {};
                    let todomylists = res.data.todomylists ? res.data.todomylists : [];
                    let todotags = res.data.todotags ? res.data.todotags : [];
                    let duration = (configoptions['todo-duration'] && configoptions['todo-duration'][0] && configoptions['todo-duration'][0]._id) ? configoptions['todo-duration'][0]._id : '';

                    dispatch({ type: "HANDLE_TO_DO_LIST_CHANGE", name: 'duration', value: duration })

                    dispatch({ type: "LIST_CONFIGOPTIONS_PRIORITY", payload: configoptions['todo-priority'], spinner: false })
                    dispatch({ type: "LIST_CONFIGOPTIONS_DURATION", payload: configoptions['todo-duration'], spinner: false })
                    dispatch({ type: "LIST_CONFIGOPTIONS_TODO_NUMBER_OF_INTERVALS", payload: configoptions['todo-number-of-interval'], spinner: false })
                    dispatch({ type: "LIST_CONFIGOPTIONS_TODO_OCCURENCE", payload: configoptions['todo-occurrence'], spinner: false })
                    dispatch({ type: "LIST_CONFIGOPTIONS_TODO_REMINDER", payload: configoptions['todo-reminder'], spinner: false })

                    dispatch({ type: "LIST_TODO_MY_LIST", payload: todomylists, total: 0, spinner: false })
                    dispatch({ type: "LIST_TODO_TAGS", payload: todotags, total: 0, spinner: false })
                }
            }).catch((err) => { console.log(err); });
    };
}

// -------------------------- Todo tags -------------------------


export function AC_LIST_TODO_TAGS(formData = {}) {
    return function (dispatch) {
        return axios.post(ImportedURL.API.listTodoTag, formData)
            .then((res) => {
                dispatch({ type: "LIST_TODO_TAGS", payload: res.data.data, total: res.data.total, spinner: false })
            })
    }
}

export function AC_LIST_TODO_TAGS_SEND(list, total) {
    return function (dispatch) {
        dispatch({ type: "LIST_TODO_TAGS", payload: list, total: total, spinner: false })
    }
}

export function AC_VIEW_TODO_TAGS(id) {
    return function (dispatch) {
        return axios.get(ImportedURL.API.viewTodoTag + "/" + id)
            .then((res) => {
                dispatch({ type: "VIEW_TODO_TAGS", payload: res.data })
            }).catch((err) => { console.log(err); });
    };
}

export function AC_HANDLE_TODO_TAGS_CHANGE(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_TODO_TAGS_CHANGE", name: name, value: value })
    };
}

export function AC_EMPTY_TODO_TAGS() {
    return function (dispatch) {
        dispatch({ type: "EMPTY_TODO_TAGS" })
    };
}

export function AC_SPINNER_TODO_TAGS() {
    return function (dispatch) {
        dispatch({ type: "SPINNER_TODO_TAGS" })
    };
}

export function AC_MODAL_SPINNER_TODO_TAGS() {
    return function (dispatch) {
        dispatch({ type: "MODAL_SPINNER_TODO_TAGS" })
    };
}

export function AC_ADD_UPDATE_TODO_TAGS(data, total) {
    return function (dispatch) {
        dispatch({ type: "ADD_UPDATE_TODO_TAGS", payload: data, total: total })
    };
}

// -------------------------- Todo My List -------------------------

export function AC_LIST_TODO_MY_LIST(formData = {}) {
    return function (dispatch) {
        return axios.post(ImportedURL.API.listTodoMyList, formData)
            .then((res) => {
                dispatch({ type: "LIST_TODO_MY_LIST", payload: res.data.data, total: res.data.total, spinner: false })
            })
    }
}

export function AC_LIST_TODO_MY_LIST_SEND(list, total) {
    return function (dispatch) {
        dispatch({ type: "LIST_TODO_MY_LIST", payload: list, total: total, spinner: false })
    }
}

export function AC_VIEW_TODO_MY_LIST(id) {
    return function (dispatch) {
        return axios.get(ImportedURL.API.viewTodoMyList + "/" + id)
            .then((res) => {
                dispatch({ type: "VIEW_TODO_MY_LIST", payload: res.data })
            }).catch((err) => { console.log(err); });
    };
}
export function AC_HANDLE_TODO_MY_LIST_CHANGE(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_TODO_MY_LIST_CHANGE", name: name, value: value })
    };
}

export function AC_EMPTY_TODO_MY_LIST() {
    return function (dispatch) {
        dispatch({ type: "EMPTY_TODO_MY_LIST" })
    };
}

export function AC_SPINNER_TODO_MY_LIST() {
    return function (dispatch) {
        dispatch({ type: "SPINNER_TODO_MY_LIST" })
    };
}

export function AC_MODAL_SPINNER_TODO_MY_LIST() {
    return function (dispatch) {
        dispatch({ type: "MODAL_SPINNER_TODO_MY_LIST" })
    };
}

export function AC_ADD_UPDATE_TODO_MY_LIST(data, total) {
    return function (dispatch) {
        dispatch({ type: "ADD_UPDATE_TODO_MY_LIST", payload: data, total: total })
    };
}

// -------------------------- Todo My Task -------------------------

export function AC_LIST_TODO_MY_TASK(formData = {}) {
    return function (dispatch) {
        return axios.get(ImportedURL.API.mytodos, formData)
            .then((res) => {
                dispatch({ type: "LIST_TODO_MY_TASK", payload: res.data, spinner: false })
            })
    }
}
export function AC_EMPTY_TODO_LISTITEMS() {
    return function (dispatch) {
        dispatch({ type: "EMPTY_TODO_LISTITEMS" })
    };
}

// -------------------------- Todo Report -------------------------

export function AC_LIST_TO_DO_LIST_REPORT(params = {}) {
    return function (dispatch) {
        return axios.post(ImportedURL.API.userstaskreports, params)
            .then((res) => {
                dispatch({ type: "LIST_TO_DO_LIST_REPORT", payload: res.data.response, count: res.data.count, spinner: false })
            }).catch((err) => {
                dispatch({ type: "LIST_TO_DO_LIST_REPORT", payload: [], count: {}, spinner: false })
            });
    }
}
export function AC_LIST_TO_DO_LIST_AGEING_REPORT(params = {}) {
    return function (dispatch) {
        return axios.post(ImportedURL.API.userstaskageingreports, params)
            .then((res) => {
                dispatch({ type: "LIST_TO_DO_LIST_AGEING_REPORT", payload: res.data.response, count: res.data.count, spinner: false })
            }).catch((err) => {
                dispatch({ type: "LIST_TO_DO_LIST_AGEING_REPORT", payload: [], count: {}, spinner: false })
            });
    }
}
export function AC_LIST_CLOSED_VS_COMPLETED_TO_DO_REPORT(params = {}) {
    return function (dispatch) {
        return axios.post(ImportedURL.API.closedvscompletedtodoreport, params)
            .then((res) => {
                dispatch({ type: "LIST_CLOSED_VS_COMPLETED_TO_DO_REPORT", payload: res.data.response, count: res.data.count, spinner: false })
            }).catch((err) => {
                dispatch({ type: "LIST_CLOSED_VS_COMPLETED_TO_DO_REPORT", payload: [], count: {}, spinner: false })
            });
    }
}