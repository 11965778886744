import axios from "axios";
import moment from "moment-timezone";
import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { bindActionCreators } from "redux";
import ImportedURL from "../../../common/api";
import "react-multi-date-picker/styles/backgrounds/bg-dark.css";
import { FileAndImagevalidation, onErrorImage } from "../../../common/validate";
import { AC_LIST_USER } from "../../../actions/userAction";
import {
  AC_ADD_TODO_APIS,
  AC_EMPTY_TODO_LISTITEMS,
  AC_EMPTY_TO_DO_LIST,
  AC_HANDLE_TO_DO_LIST_CHANGE,
  AC_LIST_TODO_MY_LIST,
  AC_LIST_TODO_TAGS,
  AC_LIST_TO_DO_LIST_ITEMS,
  AC_PASS_VIEW_TO_DO_LIST,
  AC_SPINNER_TO_DO_LIST,
  AC_VIEW_TO_DO_LIST,
} from "../../../actions/todolistAction";
import { GrAttachment } from "react-icons/gr";
import HotelSelectComponent from "../../../utilities/SelectOption/hotelselect";
import MultiSelectComponent from "../../../utilities/SelectOption/multiselect";
import DateComponent from "../../../utilities/Date/date";
import SelectComponent from "../../../utilities/SelectOption/select";
import { Error, Success } from "../../../common/swal";
import DateTimeComponent from "../../../utilities/Date/dateandtime";
import AddTagandMylistModal from "./addtagandmylistmodal";
import SpinnerComponent from "../../../utilities/Spinner/spinner";
import { AC_LIST_CONFIGOPTIONS_TODO_STATUS } from "../../../actions/configurationAction";
import Select from "../../../utilities/SelectOption/select";

const initialState = {
  modalType: "Add",
  id: "",
  initial: true,
  categorySelectAll: true,
  usersSelectAll: true,
  selectedStatus: null,
  daysArray: [
    { date: "", day: "Sun", id: "" },
    { date: "", day: "Mon", id: "" },
    { date: "", day: "Tue", id: "" },
    { date: "", day: "Wed", id: "" },
    { date: "", day: "Thu", id: "" },
    { date: "", day: "Fri", id: "" },
    { date: "", day: "Sat", id: "" },
  ],
  statusOptions: [],
  isButtonDisabled: true,
  reason: "",
  isCompletedDateDisable: true,
};

class EditTodotaskList extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.hotelInputRef = React.createRef();
    this.titleInputRef = React.createRef();
    this.resetRef = React.createRef();
    this.resetRefTag = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { ConfigurationState, TodoListState, AccountState } = this.props;
    const { listTodoStatus } = ConfigurationState;
    const { todo } = TodoListState;
    const { account } = AccountState;
    if (this.state.initial) {
      if (!ImportedURL.SUPERADMIN.includes(AccountState.role)) {
        this.props.HandleInputChange("hotel", account.hotel);
      }
      this.setState({ initial: false });
    }
  }

  componentDidMount() {
    const { tzone } = this.props.AccountState.account;
    this.props.ListTodoStatus({ parentslug: "todo-status" });
    this.props.EmptyTodoList();
    this.props.EmptyTodoListItems();
    this.apiCall();
    const { params, path } = this.props.match;
    this.props.ListUser({ status: true });

    const currentDate = moment(new Date()).tz(tzone);
    const completedDate = currentDate.format("MM-DD-YYYY hh:mm A");
    this.setState({
      completeddate: completedDate,
    });

    if (params.id) {
      this.props.SpinnerTodoList();
      this.setState({
        modalType: path === "/edit-todotask-list" ? "Add" : "Edit",
        id: params.id,
      });

      axios
        .get(ImportedURL.API.viewTodo + "/" + params.id)
        .then((res) => {
          const data = res.data.data ? res.data.data : {};
          const completeddate = data.completeddate
            ? moment(data.completeddate)
            : moment(completedDate);
          this.setState({ completeddate, reason: data.reason });
          this.arrageDays(data.completeddate);

          // Set the status options
          const { ConfigurationState } = this.props; // Ensure ConfigurationState is populated
          const statusOptions = ConfigurationState.listTodoStatus.map(
            (status) => ({
              label: status.name,
              value: status.suboption, // or status._id if you prefer
            })
          );

          // Set statusOptions in state
          this.setState({ statusOptions });

          // Find and set the selected status
          const selectedStatus = statusOptions.find(
            (option) => option.value === data.todostatus
          );
          this.setState({ selectedStatus });

          this.props.ListTodoListItems({
            hotel: data.hotel,
            category: data.category,
          });
          this.props.ViewTodoList(data);
        })
        .catch((err) => {
          this.props.ViewTodoList({});
        });
    }
  }

  apiCall = (query = {}) => {
    let formData = {
      parentslug: [
        "todo-priority",
        "todo-duration",
        "todo-number-of-interval",
        "todo-occurrence",
        "todo-reminder",
      ],
      ...query,
    };
    this.props.AddTodoApis(formData);
    this.props.HandleInputChange("endtype", "enddate");
    this.props.HandleInputChange("numberofoccurrence", 1);
    this.props.HandleInputChange("numberofinterval", 1);
    this.props.HandleInputChange("neverends", false);
  };
  arrageDays = (date) => {
    var daysArray = this.state.daysArray ? [...this.state.daysArray] : [];
    for (let i = 0; i < 7; i++) {
      let startDate = new Date(date);
      startDate.setDate(startDate.getDate() + i);
      let index = daysArray.findIndex(
        (e) => e.day == moment(startDate).format("ddd")
      );
      if (index !== -1) {
        daysArray = [
          ...daysArray.slice(0, index),
          {
            ...daysArray[index],
            date: moment(startDate).format("MM-DD-YYYY hh:mm A"),
            id: moment(startDate).format("dddd"),
          },
          ...daysArray.slice(index + 1),
        ];
      }
    }
    this.setState({ daysArray: daysArray });
  };

  handleChangeDate = (e, name) => {
    const value = moment(e?.toDate?.().toString()).format("MM-DD-YYYY hh:mm A");
    // this.props.HandleInputChange(name, value);
    this.setState({
      completeddate: value,
      isButtonDisabled: false,
    });
  };

  submit = (event) => {
    const { AccountState } = this.props;
    const { selectedStatus, completeddate, id, reason } = this.state;
    const languageData = AccountState.account.SelectedlanguageData;

    if (this.state.isButtonDisabled) {
      event.preventDefault(); // Prevent any action from happening
      return;
    }

    // Ensure selectedStatus and completeddate are valid
    if (!selectedStatus || !completeddate) {
      console.error("selectedStatus or completeddate is missing.");
      return;
    }

    let formattedCompletedDate = moment.isMoment(completeddate)
      ? completeddate.format("MM-DD-YYYY hh:mm A") // You can adjust this format as per your requirement
      : completeddate;

    const payload = {
      completeddate: formattedCompletedDate,
      selectedStatus: selectedStatus.value,
      reason,
    };

    // Make the API request
    axios
      .post(`${ImportedURL.API.editTodolist}/${id}`, payload)
      .then((res) => {
        this.setState({ saving: false, listRedirect: true });
        const successMessage = `${languageData?.TODO_MY_TASK || "My Task"} ${
          languageData?.UPDATED_SUCCESSFULLY || "Updated Successfully"
        }`;
        Success(successMessage);
      })
      .catch(({ response }) => {
        this.setState({ saving: false });
        this.ErrorResponse(response);
      });
  };

  ErrorResponse = (response) => {
    const languageData = this.props.AccountState.account.SelectedlanguageData;
    if (response) {
      if (response.status == 500) {
        Error(
          response.status +
            (languageData && languageData.INTERNAL_SERVER_ERROR
              ? languageData.INTERNAL_SERVER_ERROR
              : " Internal Server Error")
        );
      } else if (response.status == 502) {
        Error(
          response.status +
            (languageData && languageData.BAD_GATEWAY
              ? languageData.BAD_GATEWAY
              : " Bad Gateway")
        );
      } else if (response.status == 400) {
        Error(
          languageData && languageData.BAD_REQUEST
            ? languageData.BAD_REQUEST
            : "Bad request"
        );
      } else if (response.status == 406) {
        Error(
          languageData && languageData.TODO_LIST_CANNOT_EDIT
            ? languageData.TODO_LIST_CANNOT_EDIT
            : "You can't edit todos created by others"
        );
      } else {
        Error(response.statusMessage);
      }
    } else {
      Error("Bad request");
    }
  };

  handleStatusChange = (selectedOption) => {
    // Update the state
    if (selectedOption.value === "completed") {
      this.setState({ isCompletedDateDisable: false });
    } else {
      this.setState({ isCompletedDateDisable: true });
    }
    this.setState({ selectedStatus: selectedOption, isButtonDisabled: false });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ reason: value, isButtonDisabled: false });
  };

  render() {
    const {
      AccountState,
      LandingState,
      UserState,
      TodoListState,
      ConfigurationState,
    } = this.props;

    const path = TodoListState.changepath;
    if (this.state.listRedirect) {
      return <Redirect to={path} />;
    }
    const languageData = AccountState.account.SelectedlanguageData;

    // const listTodoStatusArray = ConfigurationState.listTodoStatus;
    const data = TodoListState.todo;
    const spinner = TodoListState.spinner;

    const days = data.startdate ? moment(data.startdate).format("dddd") : "";
    let dueDate = "";
    if (data.startdate) {
      const originalDateTime = moment(data.startdate, "MM-DD-YYYY hh:mm A");
      const dateTimePlusOneMinute = originalDateTime.clone().add(1, "minute");
      dueDate = dateTimePlusOneMinute.format("MM-DD-YYYY hh:mm A");
    }

    return (
      <>
        <div>
          <div className="breadcrump">
            <p>
              {" "}
              <Link to="/">
                <h6>
                  {languageData && languageData.DASHBOARD
                    ? languageData.DASHBOARD
                    : "Dashboard"}
                </h6>
              </Link>{" "}
              <span>
                <i class="fa fa-angle-right" aria-hidden="true"></i>{" "}
              </span>{" "}
              <Link to={path}>
                <h6>
                  {path == "/to-do-allmytask"
                    ? languageData && languageData.TODO_MY_TASK
                      ? languageData.TODO_MY_TASK
                      : "My Task"
                    : languageData && languageData.ALL_MY_TASK
                    ? languageData.ALL_MY_TASK
                    : "All My Task"}
                  {languageData && languageData.LIST
                    ? languageData.LIST
                    : " List"}
                </h6>
              </Link>{" "}
              <span>
                <i class="fa fa-angle-right" aria-hidden="true"></i>{" "}
              </span>{" "}
              <h6
                className="highlights_breadcrump"
                style={{ cursor: "pointer" }}
              >
                {this.state.modalType}{" "}
                {languageData && languageData.TO_DO
                  ? languageData.TO_DO
                  : "To-Do"}
              </h6>
            </p>
          </div>
          <div className="section-body pt-3">
            <div className="container-fluid add_to_do_list">
              <div className="tab-content">
                <div
                  className="tab-pane fade show active"
                  id="Departments-list"
                  role="tabpanel"
                >
                  <div className="card">
                    <div className="card-body border-0">
                      <div className="title_mt">
                        <div className="d-flex">
                          <div className="rounded_icon">
                            <i class="fa fa-calendar mr-2"></i>
                          </div>
                          <div>
                            <h3
                              className="card-title"
                              style={{ marginTop: "10px" }}
                            >
                              {this.state.modalType}{" "}
                              {languageData && languageData.TO_DO
                                ? languageData.TO_DO
                                : "To-Do"}
                            </h3>
                          </div>
                        </div>
                        <div
                          className="header-action"
                          style={{ marginTop: "5px" }}
                        >
                          <Link to={path}>
                            <button
                              type="button"
                              className="btn btn-primary"
                              id="Add"
                            >
                              <i className="fa fa-arrow-left mr-2"></i>Back
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="card-body  border-0">
                      <form id="form_reset">
                        <Row>
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <label className="form-label">
                                Assign Users Category :{" "}
                                {data.categoryName ? data.categoryName : ""}
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <label className="form-label">
                                Assign User Name :{" "}
                                {data.usersName ? data.usersName : ""}
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <label className="form-label">
                                Assign User Name :{" "}
                                {data.createdbyName ? data.createdbyName : ""}
                              </label>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6">
                            <div className="form-group">
                              <label className="form-label">
                                Title : {data.title ? data.title : ""}
                              </label>
                            </div>
                          </div>
                          {data.newtodoitems &&
                            data.newtodoitems &&
                            data.newtodoitems.length > 0 && (
                              <div className="col-sm-6 col-md-6">
                                <div className="form-group">
                                  <label className="form-label">
                                    Todo - Shortcode:
                                  </label>

                                  <ul>
                                    {data.newtodoitems.map((item) => (
                                      <li key={item.id}>{item.name}</li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            )}

                          <div className="col-sm-6 col-md-6">
                            <div
                              className="form-group"
                              style={{ position: "relative" }}
                            >
                              <label className="form-label">
                                Description :{" "}
                                {data.description ? data.description : ""}
                              </label>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6">
                            <div className="form-group">
                              <label className="form-label">
                                Start Date & Time : {data.startdate}
                              </label>
                              {/* <DateTimeComponent onChange={(e) => this.handleChangeDate(e, 'startdate')} value={data.startdate} minDate={moment(new Date()).tz(account.tzone).format("MM-DD-YYYY hh:mm A")} editable={false} /> */}
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6">
                            <div className="form-group">
                              <label className="form-label">
                                End Date & Time : {data.enddate}
                              </label>
                              {/* <DateTimeComponent onChange={(e) => this.handleChangeDate(e, 'startdate')} value={data.startdate} minDate={moment(new Date()).tz(account.tzone).format("MM-DD-YYYY hh:mm A")} editable={false} /> */}
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6">
                            <div className="form-group">
                              <label className="form-label">
                                Due Date & Time : {data.duedate}
                              </label>
                              {/* <DateTimeComponent onChange={(e) => this.handleChangeDate(e, 'duedate')} 
                                                            value={data.duedate} minDate={data.startdate ? dueDate : moment(new Date()).tz(account.tzone).format("MM-DD-YYYY hh:mm A")} 
                                                            editable={false} 
                                                            minTime={data.startdate ? moment(data.startdate).add(1, 'minute') : moment(new Date()).tz(account.tzone).format("MM-DD-YYYY hh:mm A")} /> */}
                            </div>
                          </div>
                          {data.taskpriority && (
                            <div className="col-lg-6 col-md-6 mb-3">
                              <div className="form-group">
                                <label className="form-label">
                                  Priority :
                                  {data.taskpriority === "1" && " High"}
                                  {data.taskpriority === "2" && " Medium"}
                                  {data.taskpriority === "3" && " Low"}
                                  {!["1", "2", "3"].includes(
                                    data.taskpriority
                                  ) && "N/A"}
                                </label>
                              </div>
                            </div>
                          )}
                          {data.durationName && (
                            <div className="col-lg-6 col-md-6">
                              <div className="form-group">
                                <label className="form-label">
                                  Duration :{" "}
                                  {data.durationName ? data.durationName : ""}
                                </label>
                              </div>
                            </div>
                          )}

                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <label className="form-label">
                                Mandatory Compliances :{" "}
                                {data.mandatorycompliances === true
                                  ? "Mandatory"
                                  : "Non Mandatory"}{" "}
                              </label>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <label className="form-label">Status</label>
                              <Select
                                value={this.state.selectedStatus}
                                onChange={this.handleStatusChange}
                                options={this.state.statusOptions}
                                placeholder="Select a status"
                              />
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <label className="form-label">Reason</label>
                              <textarea
                                className="form-control w-100"
                                name="reason"
                                rows="3"
                                cols="50"
                                onChange={this.handleChange}
                                value={
                                  this.state.reason ? this.state.reason : ""
                                }
                                placeholder={"Reason..."}
                              />
                            </div>
                          </div>

                          {!this.state.isCompletedDateDisable && (
                            <div className="col-sm-6 col-md-6">
                              <div className="form-group">
                                <label className="form-label">
                                  Completed Date & Time
                                </label>
                                <DateTimeComponent
                                  onChange={(e) =>
                                    this.handleChangeDate(e, "completeddate")
                                  }
                                  value={this.state.completeddate}
                                  // minDate={moment(new Date()).tz(account.tzone).format("MM-DD-YYYY hh:mm A")}
                                  editable={false}
                                />
                              </div>
                            </div>
                          )}
                        </Row>
                      </form>
                    </div>
                    <div className="card-footer text-right mandatory">
                      <label className="form-label text-left mandatory-label">
                        <span className="mr-1" style={{ color: "red" }}>
                          *
                        </span>
                        Mandatory Fields{" "}
                      </label>
                      <div className="text-right">
                        {this.state.saving ? (
                          <button type="button" className="btn commor_save">
                            <i className="fa fa-spinner fa-spin mr-2"></i>
                            {this.state.modalType == "Edit"
                              ? "Updating"
                              : "Saving"}
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn commor_save"
                            onClick={this.submit}
                            disabled={this.state.isButtonDisabled} // Disable the button based on state
                          >
                            <i className="fe fe-save mr-2"></i>
                            {this.state.modalType === "Edit"
                              ? "Update"
                              : "Save"}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AddTagandMylistModal
          {...this.props}
          title={"Tag"}
          showcanvas={this.state.showcanvasTag}
          ref={this.resetRefTag}
          onHidecanvas={() => this.setState({ showcanvasTag: false })}
          type="tag"
          data={data}
          submit={this.tagSubmit}
        />
        <AddTagandMylistModal
          {...this.props}
          title={"My List"}
          showcanvas={this.state.showcanvasMylist}
          ref={this.resetRef}
          onHidecanvas={() => this.setState({ showcanvasMylist: false })}
          type="mylist"
          data={data}
          submit={this.mylistSubmit}
        />
        <SpinnerComponent spinner={spinner} />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  AccountState: state.account,
  LandingState: state.landing,
  TodoListState: state.todolist,
  UserState: state.user,
  ConfigurationState: state.configuration,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ListUser: AC_LIST_USER,
      HandleInputChange: AC_HANDLE_TO_DO_LIST_CHANGE,
      ListTodoMyList: AC_LIST_TODO_MY_LIST,
      ListTodoTag: AC_LIST_TODO_TAGS,
      ListTodoListItems: AC_LIST_TO_DO_LIST_ITEMS,
      EmptyTodoList: AC_EMPTY_TO_DO_LIST,
      SpinnerTodoList: AC_SPINNER_TO_DO_LIST,
      ViewTodoList: AC_PASS_VIEW_TO_DO_LIST,
      EmptyTodoListItems: AC_EMPTY_TODO_LISTITEMS,
      AddTodoApis: AC_ADD_TODO_APIS,
      ListTodoStatus: AC_LIST_CONFIGOPTIONS_TODO_STATUS,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EditTodotaskList);
