import axios from "axios";
import moment, { duration } from "moment-timezone";
import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { bindActionCreators } from "redux";
import ImportedURL from "../../../common/api";
import "react-multi-date-picker/styles/backgrounds/bg-dark.css";
import { FileAndImagevalidation, onErrorImage } from "../../../common/validate";
import { AC_LIST_USER } from "../../../actions/userAction";
import {
  AC_ADD_TODO_APIS,
  AC_EMPTY_TODO_LISTITEMS,
  AC_EMPTY_TO_DO_LIST,
  AC_HANDLE_TO_DO_LIST_CHANGE,
  AC_LIST_TODO_MY_LIST,
  AC_LIST_TODO_TAGS,
  AC_LIST_TO_DO_LIST_ITEMS,
  AC_PASS_VIEW_TO_DO_LIST,
  AC_SPINNER_TO_DO_LIST,
  AC_VIEW_TO_DO_LIST,
} from "../../../actions/todolistAction";
import { AC_LIST_TODO_LIST_ITEM } from "../../../actions/todolistAction";
import { GrAttachment } from "react-icons/gr";
import HotelSelectComponent from "../../../utilities/SelectOption/hotelselect";
//import MultiSelectComponent from '../../../utilities/SelectOption/multiselect';
import Select from "../../../utilities/SelectOption/select";
import DateComponent from "../../../utilities/Date/date";
import SelectComponent from "../../../utilities/SelectOption/select";
import { Error, Success } from "../../../common/swal";
import DateTimeComponent from "../../../utilities/Date/dateandtime";
import AddTagandMylistModal from "./addtagandmylistmodal";
import SpinnerComponent from "../../../utilities/Spinner/spinner";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import {
  format,
  differenceInCalendarDays,
  addDays,
  getDay,
  eachDayOfInterval,
  startOfWeek,
  endOfWeek,
} from "date-fns";
const initialState = {
  modalType: "Add",
  id: "",
  initial: true,
  Mandatory_Compliance: "",
  usersSelectAll: true,
  daysArray: [
    { date: "", day: "Sun", id: "" },
    { date: "", day: "Mon", id: "" },
    { date: "", day: "Tue", id: "" },
    { date: "", day: "Wed", id: "" },
    { date: "", day: "Thu", id: "" },
    { date: "", day: "Fri", id: "" },
    { date: "", day: "Sat", id: "" },
  ],
  selectedDays: [],
  weeks: [],
  selectedDay: null,
  setSelectedDay: null,
  setWeeks: [],
  showInput: false,
  showneverendInput: false,
  datesArray: [],
  selectedTitle: "", // Title from selected Todo
  selectedDescription: "", // Description from selected Todo
  selectedMandatoryCompliances: "", // Mandatory compliances from selected Todo
  selectedpriority: "",
  selectedfrequency: [],

  errors: {
    categoryError: "",
    todoError: "",
    userError: "",
  },
  selectedCategory: null,
  selectedTodoShortcut: null,
  selectedUser: null,
  isButtonEnabled: false, // Button is disabled initially
  formattedDate: "",
};

class EditTaskReminder extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.hotelInputRef = React.createRef();
    this.titleInputRef = React.createRef();
    this.resetRef = React.createRef();
    this.resetRefTag = React.createRef();
  }

  componentWillUpdate(nextProps) {
    const newaccount = nextProps.AccountState.account;
    if (this.state.initial) {
      if (!ImportedURL.SUPERADMIN.includes(nextProps.AccountState.role)) {
        this.props.HandleInputChange("hotel", newaccount.hotel);
      }
      this.setState({ initial: false });
    }
  }
  componentDidMount() {
    const { tzone } = this.props.AccountState.account;
    this.props.EmptyTodoList();
    this.props.EmptyTodoListItems();
    this.apiCall();
    const { params, path } = this.props.match;
    this.props.ListUser({ status: true });
    this.props.listtodolistitem({ status: 1 });
    // Check if datesArray is empty and set the current date formatted with the account's timezone
    if (!this.state.datesArray || this.state.datesArray.length === 0) {
      const currentDateFormatted = moment()
        .tz(tzone)
        .format("dddd, MM-DD-YYYY hh:mm A");
      this.setState({ datesArray: [currentDateFormatted] });
    }

    const currentDate = moment(new Date()).tz(tzone);
    const startdate = currentDate.format("MM-DD-YYYY hh:mm A");

    const enddate = moment(startdate, "MM-DD-YYYY hh:mm A")
      .set({ hour: 23, minute: 59 })
      .format("MM-DD-YYYY hh:mm A");
    //let duedate = moment(startdate, "MM-DD-YYYY hh:mm A").set({ hour: 23, minute: 59 }).format("MM-DD-YYYY hh:mm A");
    this.props.HandleInputChange("startdate", startdate);
    this.props.HandleInputChange("enddate", enddate);
    const newDate = currentDate.clone().add(1, "hour");
    // const endate = newDate.format("MM-DD-YYYY hh:mm A");
    this.props.HandleInputChange("duedate", enddate);
    this.arrageDays(startdate);
    // }
  }
  apiCall = (query = {}) => {
    let formData = {
      parentslug: [
        "todo-priority",
        "todo-duration",
        "todo-number-of-interval",
        "todo-occurrence",
        "todo-reminder",
      ],
      ...query,
    };
    this.props.AddTodoApis(formData);
    this.props.HandleInputChange("endtype", "enddate");
    this.props.HandleInputChange("numberofoccurrence", 1);
    this.props.HandleInputChange("numberofinterval", 1);
    this.props.HandleInputChange("neverends", false);
  };
  arrageDays = (date) => {
    var daysArray = this.state.daysArray ? [...this.state.daysArray] : [];
    for (let i = 0; i < 7; i++) {
      let startDate = new Date(date);
      startDate.setDate(startDate.getDate() + i);
      let index = daysArray.findIndex(
        (e) => e.day == moment(startDate).format("ddd")
      );
      if (index !== -1) {
        daysArray = [
          ...daysArray.slice(0, index),
          {
            ...daysArray[index],
            date: moment(startDate).format("MM-DD-YYYY hh:mm A"),
            id: moment(startDate).format("dddd"),
          },
          ...daysArray.slice(index + 1),
        ];
      }
    }
    this.setState({ daysArray: daysArray });
  };
  handleComplianceChange = (event) => {
    const { checked } = event.target;
    this.setState(
      (prevState) => ({
        complianceStatus: {
          ...prevState.complianceStatus,
          isMandatory: checked, // Update compliance status based on checkbox state
        },
      }),
      () => {
        this.setState({
          errors: { ...this.state.errors, Mandatory_Compliance: null },
        });
      }
    );
  };
  handleChangeSelect = (e) => {
    const {
      TodoListState,
      UserState,
      ConfigurationState,
      AccountState,
      TodolistitemState,
    } = this.props;
    const listtodolistitem = TodolistitemState.listtodolistitem;
    const languageData = AccountState.account.SelectedlanguageData;
    const { tzone } = AccountState.account;
    const account1 = AccountState.account;
    const currentDateTime = moment().tz(tzone);
    const listDurationConfigOptions =
      ConfigurationState.listDurationConfigOptions;
    const listUser = UserState.listusers;
    const data = TodoListState.todo;
    const { name, value, selectAll } = e;
    const Error1 = name + "Error";
    const SelectAll = name + "SelectAll";
    this.setState({ [Error1]: false, [SelectAll]: selectAll });

    if (name == "reminderselect") {
      const startDate = moment(data.startdate);
      const reminderDate = startDate.subtract({ minutes: value });
      // this.props.HandleInputChange('reminderdate', moment(reminderDate).format('MM-DD-YYYY hh:mm A'));
      let calcuateMoment = moment(reminderDate);
      const calcuateMomentformatted = moment(
        calcuateMoment,
        "MM-DD-YYYY hh:mm A"
      ).format("MM-DD-YYYY hh:mm A");
      const currentDateformatted = moment(
        currentDateTime,
        "MM-DD-YYYY hh:mm A"
      ).format("MM-DD-YYYY hh:mm A");
      if (value) {
        if (value && value != "custom") {
          if (calcuateMomentformatted > currentDateformatted) {
            this.props.HandleInputChange(name, value);
            this.props.HandleInputChange(
              "reminderdate",
              moment(reminderDate).format("MM-DD-YYYY hh:mm A")
            );
          } else {
            Error(
              languageData && languageData.TODO_REMAINDER_ERROR_ALERT_SELECT
                ? languageData.TODO_REMAINDER_ERROR_ALERT_SELECT
                : "To set reminder select the start time few minutes ahead of current time"
            );
          }
        } else {
          this.props.HandleInputChange(name, value);
          this.props.HandleInputChange("reminderdate", moment(data.startdate));
        }
      } else {
        this.props.HandleInputChange("reminderdate", moment(data.startdate));
        this.props.HandleInputChange(name, "");
      }
      this.props.HandleInputChange("reminderminute", 0);
      this.props.HandleInputChange("reminderhour", 0);
      this.props.HandleInputChange("reminderday", 0);
    } else {
      this.props.HandleInputChange(name, value);
    }

    if (name === "category") {
      let userId = [];
      let userData = listUser.filter(
        (e) => data.users && data.users.includes(e._id)
      );

      // Clear userError immediately when a category is selected, before user data is fetched
      this.setState((prevState) => ({
        errors: { ...prevState.errors, userError: null }, // Clear userError here
      }));

      // Pass the user IDs to parent component or further logic
      this.props.HandleInputChange("users", userId);

      if (value && value.length > 0) {
        // Set the selected category and clear categoryError
        this.setState(
          (prevState) => ({
            selectedCategory: value,
            errors: { ...prevState.errors, categoryError: null }, // Clear categoryError
          }),
          () => {}
        );

        this.props.ListTodoListItems({ hotel: data.hotel, category: value });

        // Check if users are available for the selected category
        if (userData.length > 0) {
          userData.forEach((item) => {
            if (value.includes(item.categoryid)) userId.push(item._id);
          });
        } else {
        }
      } else {
        // If no category is selected, reset the todo list
        this.props.EmptyTodoListItems();
      }
    }

    if (name == "hotel") {
      this.props.HandleInputChange("category", []);
      this.props.HandleInputChange("users", []);
      this.apiCall({ hotel: value });
    }
    if (name == "numberofinterval") {
      if (value && Number(value) > 1) {
        const listDurationConfigOptions =
          ConfigurationState.listDurationConfigOptions;
        let duration = listDurationConfigOptions.find(
          (e) => e._id === data.duration
        );
        let added = Number(value) * (duration ? duration.suboption : 0);
        const currentDate = moment(data.startdate);
        const newDate = currentDate.clone().add(added, "day");
        const endate = newDate.format("MM-DD-YYYY hh:mm A");
        this.props.HandleInputChange("enddate", endate);
      } else {
        this.props.HandleInputChange("enddate", data.startdate);
      }
    }
    // if (name == 'numberofoccurrence') {
    //     if (value && Number(value) > 1) {
    //         const listDurationConfigOptions = ConfigurationState.listDurationConfigOptions;
    //         let duration = listDurationConfigOptions.find((e) => e._id === data.duration);

    //         if (duration) {
    //             let added = Number(value-1) * (duration ? duration.suboption : 0);
    //             const currentDate = moment(data.startdate);
    //             const newDate = currentDate.clone().add(added, 'day');
    //             const endate = newDate.format("MM-DD-YYYY hh:mm A");

    //             this.props.HandleInputChange('duedate', endate);

    //             let datesArray = [];
    //             for (let i = 0; i < Number(value); i++) {
    //                 const iterationDate = currentDate.clone().add(duration.suboption * i, 'day');
    //                 const formattedDate = iterationDate.format("dddd, MM-DD-YYYY hh:mm A");
    //                  datesArray.push(formattedDate);
    //             }
    //             // Store datesArray in state if needed
    //             this.setState({ datesArray:datesArray });
    //         }
    //     } else {
    //         this.props.HandleInputChange('duedate', data.startdate);
    //     }
    // }
    if (name == "numberofoccurrence") {
      if (value && Number(value) >= 1) {
        const listDurationConfigOptions =
          ConfigurationState.listDurationConfigOptions;
        let duration = listDurationConfigOptions.find(
          (e) => e._id === data.duration
        );

        if (duration) {
          let added = Number(value - 1) * (duration ? duration.suboption : 0);

          // Adjusting the currentDate to add 1 minute to startdate or using the current date and time in the specified timezone
          const currentDate = data.startdate
            ? moment(data.startdate, "MM-DD-YYYY hh:mm A").add(1, "minute")
            : moment(new Date())
                .tz(account1.tzone)
                .format("MM-DD-YYYY hh:mm A");

          const newDate = moment(currentDate, "MM-DD-YYYY hh:mm A").add(
            added,
            "day"
          ); // Convert to moment object if necessary
          const endDate = newDate.format("MM-DD-YYYY hh:mm A");

          this.props.HandleInputChange("duedate", endDate);
          this.props.HandleInputChange("enddate", endDate);

          let datesArray = [];
          for (let i = 0; i < Number(value); i++) {
            const iterationDate = moment(currentDate, "MM-DD-YYYY hh:mm A")
              .clone()
              .add(duration.suboption * i, "day");
            const formattedDate = iterationDate.format(
              "dddd, MM-DD-YYYY hh:mm A"
            );
            datesArray.push(formattedDate);
          }

          // Store datesArray in state if needed
          this.setState({ datesArray: datesArray });
        }
      } else {
        const adjustedStartDate = data.startdate
          ? moment(data.startdate, "MM-DD-YYYY hh:mm A").format(
              "MM-DD-YYYY hh:mm A"
            )
          : null;

        this.props.HandleInputChange(
          "duedate",
          adjustedStartDate ||
            moment(new Date()).tz(account1.tzone).format("MM-DD-YYYY hh:mm A")
        );
      }
    }

    if (name == "todo") {
      const selectedTodoItem = listtodolistitem.find(
        (item) => item._id === e.value
      );

      if (selectedTodoItem) {
        this.setState({
          selectedTodo: e,
          selectedTitle: selectedTodoItem.title || "---", // Default to '---' if title is missing
          selectedDescription: selectedTodoItem.description || "---",
          selectedMandatoryCompliances: selectedTodoItem.mandatorycompliances,
          selectedpriority: selectedTodoItem.priority || "---",
          selectedfrequency: selectedTodoItem.duration,
        });
        this.setState(
          (prevState) => ({
            selectedTodoShortcut: value,
            errors: { ...prevState.errors, todoError: null },
          }),
          () => {
            this.handleChangeDuration("duration", selectedTodoItem.duration);
          }
        );

        // Optionally trigger any other actions
        this.props.HandleInputChange("todo", e.value);
      }
    }
    if (name == "users") {
      this.setState(
        (prevState) => ({
          selectedUser: value,
          errors: { ...prevState.errors, userError: null },
        }),
        () => {
          console.log("Updated state:", this.state);
        }
      );
    }
  };

  calcuateReminder = (name, number) => {
    const data = this.props.TodoListState.todo;
    const reminderDays =
      name == "reminderday"
        ? Number(number)
        : data.reminderday
        ? Number(data.reminderday)
        : 0;
    const reminderHours =
      name == "reminderhour"
        ? Number(number)
        : data.reminderhour
        ? Number(data.reminderhour)
        : 0;
    const reminderMinutes =
      name == "reminderminute"
        ? Number(number)
        : data.reminderminute
        ? Number(data.reminderminute)
        : 0;
    const startDate = moment(data.startdate);
    const reminderDate = startDate.subtract({
      days: reminderDays,
      hours: reminderHours,
      minutes: reminderMinutes,
    });
    return moment(reminderDate).format();
  };
  handleChange = (e) => {
    const {
      TodoListState,
      UserState,
      ConfigurationState,
      AccountState,
      TodolistitemState,
    } = this.props;
    const listtodolistitem = TodolistitemState.listtodolistitem;
    const listDurationConfigOptions =
      ConfigurationState.listDurationConfigOptions;
    const listUser = UserState.listusers;
    const data = TodoListState.todo;
    const { tzone } = this.props.AccountState.account;
    const languageData = this.props.AccountState.account.SelectedlanguageData;
    const currentDateTime = moment().tz(tzone);
    const { name, value, checked } = e.target;
    const Error1 = name + "Error";
    this.setState({ [Error1]: false });
    if (name == "neverends") {
      this.props.HandleInputChange(name, checked);
      if (checked === true) {
        this.setState({ showneverendInput: true });
        const listDurationConfigOptions =
          ConfigurationState.listDurationConfigOptions;
        let duration = listDurationConfigOptions.find(
          (e) => e._id === data.duration
        );

        if (duration) {
          // Adjusting the currentDate to add 1 minute to startdate or using the current date and time in the specified timezone
          const currentDate = data.startdate
            ? moment(data.startdate, "MM-DD-YYYY hh:mm A").add(1, "minute")
            : moment(new Date()).tz(tzone).format("MM-DD-YYYY hh:mm A");

          // Calculate end date as three months from the current date
          const endDate = moment(currentDate, "MM-DD-YYYY hh:mm A").add(
            3,
            "months"
          );

          let datesArray = [];
          let iterationDate = moment(currentDate, "MM-DD-YYYY hh:mm A").clone();

          while (iterationDate.isBefore(endDate)) {
            const formattedDate = iterationDate.format(
              "dddd, MM-DD-YYYY hh:mm A"
            );
            datesArray.push(formattedDate);

            // Add days depending on the duration (1 for daily, 7 for weekly, 30 for monthly)
            iterationDate.add(duration.suboption, "day");
          }

          // Store datesArray in state if needed
          this.setState({ datesArray: datesArray }, () => {
            // Optionally, you can set the last date in datesArray as the duedate
            const finalEndDate =
              datesArray.length > 0
                ? datesArray[datesArray.length - 1]
                : endDate.format("MM-DD-YYYY hh:mm A");
            this.props.HandleInputChange("duedate", finalEndDate);
          });
        }
      } else {
        this.setState({ showneverendInput: false });
        this.props.HandleInputChange("numberofoccurrence", 1);
        //  const currentDate1 = data.startdate
        // ? moment(data.startdate, "MM-DD-YYYY hh:mm A").add(1, 'minute')
        // : moment(new Date()).tz(tzone).format("MM-DD-YYYY hh:mm A");
        const currentDate1 = this.state.formattedDate;

        // Calculate end date as three months from the current date
        const endDate1 = moment(currentDate1, "MM-DD-YYYY hh:mm A")
          .set({ hour: 23, minute: 59 })
          .format("MM-DD-YYYY hh:mm A");
        this.props.HandleInputChange("enddate", endDate1);
        // This else should execute when checked is false
        this.setState({ datesArray: [] }, () => {
          // Set the current date formatted with the account's timezone
          const currentDateFormatted = moment(currentDate1)
            .tz(tzone)
            .format("dddd, MM-DD-YYYY hh:mm A");
          // Update the state with the new date
          this.setState({ datesArray: [currentDateFormatted] }, () => {
            console.log("Updated datesArray:", this.state.datesArray);
          });
        });
      }
    } else if (name == "endtype") {
      if (value == "enddate") {
        this.props.HandleInputChange("enddate", data.startdate);
        this.props.HandleInputChange("numberofinterval", 1);
      }
      if (value == "occurrence") {
        this.props.HandleInputChange("numberofoccurrence", 1);
      }
      this.props.HandleInputChange(name, value);
    } else if (
      name == "reminderminute" ||
      name == "reminderhour" ||
      name == "reminderday"
    ) {
      var number = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
      let calcuate = this.calcuateReminder(name, number);
      let calcuateMoment = moment(calcuate);
      const calcuateMomentformatted = moment(
        calcuateMoment,
        "MM-DD-YYYY hh:mm A"
      ).format("MM-DD-YYYY hh:mm A");
      const currentDateformatted = moment(
        currentDateTime,
        "MM-DD-YYYY hh:mm A"
      ).format("MM-DD-YYYY hh:mm A");
      if (number) {
        if (calcuateMomentformatted > currentDateformatted) {
          this.props.HandleInputChange(name, number);
          this.props.HandleInputChange(
            "reminderdate",
            moment(calcuate).format("MM-DD-YYYY hh:mm A")
          );
        } else {
          Error(
            languageData && languageData.TODO_REMAINDER_ERROR_ALERT_SELECT
              ? languageData.TODO_REMAINDER_ERROR_ALERT_SELECT
              : "To set reminder select the start time few minutes ahead of current time"
          );
        }
      } else {
        this.props.HandleInputChange("reminderdate", moment(data.startdate));
        this.props.HandleInputChange(name, number);
      }
    } else if (name == "title") {
      if (value.length <= 50) {
        this.props.HandleInputChange(name, value);
      } else {
        Error(
          languageData && languageData.MAXIMUM_CHAR_LIMIT_REACHED
            ? languageData.MAXIMUM_CHAR_LIMIT_REACHED
            : "Maximum character limit reached"
        );
      }
    } else if (name == "description") {
      if (value.length <= 250) {
        this.props.HandleInputChange(name, value);
      } else {
        Error(
          languageData && languageData.MAXIMUM_CHAR_LIMIT_REACHED
            ? languageData.MAXIMUM_CHAR_LIMIT_REACHED
            : "Maximum character limit reached"
        );
      }
    } else {
      this.props.HandleInputChange(name, value);
    }
  };
  handleChangeDate = (e, name) => {
    const data = this.props.TodoListState.todo;
    //const value = moment(e?.toDate?.().toString()).format("MM-DD-YYYY hh:mm A")
    const value = moment(e?.toDate?.() || e).format("MM-DD-YYYY hh:mm A");
    // Set the formatted value in the state
    this.setState({ formattedDate: value });
    this.props.HandleInputChange(name, value);
    const { tzone } = this.props.AccountState.account;
    const currentDateTime = moment().tz(tzone);
    const languageData = this.props.AccountState.account.SelectedlanguageData;
    if (name == "startdate") {
      if (data.numberofoccurrence && Number(data.numberofoccurrence) > 1) {
        const listDurationConfigOptions =
          this.props.ConfigurationState.listDurationConfigOptions;
        let duration = listDurationConfigOptions.find(
          (e) => e._id === data.duration
        );
        let added =
          Number(data.numberofoccurrence) * (duration ? duration.suboption : 0);
        const currentDate = moment(value);
        // const newDate = currentDate.clone().add(added, 'day');
        // const endate = newDate.format("MM-DD-YYYY hh:mm A");
        const endate = moment(currentDate, "MM-DD-YYYY hh:mm A")
          .set({ hour: 23, minute: 59 })
          .format("MM-DD-YYYY hh:mm A");
        this.props.HandleInputChange("enddate", endate);
        this.props.HandleInputChange("numberofoccurrence", 1);
        this.setState({ datesArray: [] }, () => {
          const currentDateFormatted = moment(currentDate)
            .tz(tzone)
            .format("dddd, MM-DD-YYYY hh:mm A");
          // Update the state with the new date
          this.setState({ datesArray: [currentDateFormatted] }, () => {});
        });
      } else {
        this.props.HandleInputChange("numberofoccurrence", 1);
        this.props.HandleInputChange("enddate", value);
        this.setState({ datesArray: [] }, () => {
          const currentDateFormatted = moment(value)
            .tz(tzone)
            .format("dddd, MM-DD-YYYY hh:mm A");
          // Update the state with the new date
          this.setState({ datesArray: [currentDateFormatted] }, () => {});
        });
      }
      const currentDate = moment(value);
      const newDate = currentDate.clone().add(1, "hour");
      const endate = newDate.format("MM-DD-YYYY hh:mm A");
      this.props.HandleInputChange("duedate", endate);
      this.arrageDays(value);
      if (data.reminderselect !== undefined) {
        const currentDate = moment(value);
        let reminder =
          data.reminderselect && data.reminderselect != "custom"
            ? Number(data.reminderselect)
            : this.convertToMinutes(
                data.reminderday,
                data.reminderhour,
                data.reminderminute
              );
        const reminderDate = currentDate.subtract({ minutes: reminder });
        let calcuateMoment = moment(reminderDate);
        const calcuateMomentformatted = moment(
          calcuateMoment,
          "MM-DD-YYYY hh:mm A"
        ).format("MM-DD-YYYY hh:mm A");
        const currentDateformatted = moment(
          currentDateTime,
          "MM-DD-YYYY hh:mm A"
        ).format("MM-DD-YYYY hh:mm A");
        if (calcuateMomentformatted > currentDateformatted) {
          this.props.HandleInputChange(
            "reminderdate",
            moment(reminderDate).format("MM-DD-YYYY hh:mm A")
          );
        } else {
          this.props.HandleInputChange("reminderselect", "");
          this.props.HandleInputChange("reminderdate", "");
          Error(
            languageData && languageData.TODO_REMAINDER_ERROR_ALERT_SELECT
              ? languageData.TODO_REMAINDER_ERROR_ALERT_SELECT
              : "To set reminder select the start time few minutes ahead of current time"
          );
        }
      }
    }
  };
  convertToMinutes = (reminderday, reminderhour, reminderminute) => {
    let days = reminderday ? Number(reminderday) : 0;
    let hours = reminderhour ? Number(reminderhour) : 0;
    let minutes = reminderminute ? Number(reminderminute) : 0;
    const totalMinutes = days * 24 * 60 + hours * 60 + minutes;
    return totalMinutes;
  };
  handleChangeTime = (e, name) => {
    this.props.HandleInputChange(name, e);
  };
  handleChangeCustomizeSelect = (name, value) => {
    const emptyList = name + "List";
    this.setState({ [emptyList]: [] });
    this.props.HandleInputChange(name, value);
  };
  handleChangeFile = (e) => {
    const { name, value, files } = e.target;
    const data = this.props.TodoListState.todo;
    const oldFiles = data[name] ? [...data[name]] : [];
    const filesList = Array.from(files);
    const newFiles = [];
    for (var i = 0; i < filesList.length; i++) {
      const imgvalidate = FileAndImagevalidation(filesList[i]);
      if (imgvalidate) {
        newFiles.push(filesList[i]);
      } else {
        Error("Invalid file is discarded");
      }
    }
    let concat = oldFiles.concat(newFiles);
    this.fileInputLengthChange(concat, name);
    this.props.HandleInputChange(name, concat);
  };
  fileInputLengthChange = (Array, name) => {
    if (Array.length > 0) {
      let content = Array.length + (Array.length > 1 ? " files" : " file");
      const tempFiles = new File(["Dummy File Content"], content, {
        type: "text/plain",
      });
      const fileList = new DataTransfer();
      fileList.items.add(tempFiles);
      const fileInput = document.getElementById(name + "Id");
      fileInput.files = fileList.files;
    }
  };
  findImageSrc = (image) => {
    let src = "";
    if (typeof image == "string") {
      const fileType = image.toLowerCase();
      const isPDF = fileType.endsWith(".pdf");
      if (isPDF) {
        src = "../../assets/images/pdf.png";
      } else {
        src = ImportedURL.FILEURL + image;
      }
    } else {
      const fileType = image.type.toLowerCase();
      const isPDF = fileType.startsWith("application/");
      if (isPDF) {
        src = "../../assets/images/pdf.png";
      } else {
        src = window.URL.createObjectURL(image);
      }
    }
    return src;
  };
  handleChangeRemoveImg = (index, name) => {
    const data = this.props.TodoListState.todo;
    const fileList = data[name] ? [...data[name]] : [];
    const file = fileList.filter((item, i) => index !== i);
    this.fileInputLengthChange(file, name);
    this.props.HandleInputChange(name, file);
  };
  handleChangeDuration = (name, value) => {
    const { ConfigurationState } = this.props;
    const data = this.props.TodoListState.todo;
    const { tzone } = this.props.AccountState.account;
    this.setState({
      isButtonEnabled: true,
    });
    this.props.HandleInputChange(name, value);
    this.props.HandleInputChange("numberofoccurrence", 1);
    this.props.HandleInputChange("enddate", data.startdate);
    let durationname = this.findDurationName(value);
    if (durationname === "Adhoc") {
      this.setState({ showInput: true }, () => {
        // This callback runs after `showInput` state is updated
        this.handleNeverEndInput();
      }); // Show input field
    } else {
      this.setState({ showInput: false }, () => {
        // This callback runs after `showInput` state is updated
        this.handleNeverEndInput();
      }); // Hide input field
    }
  };

  // Separate method to handle the logic for `showneverendInput`
  handleNeverEndInput = () => {
    const { ConfigurationState } = this.props;
    const data = this.props.TodoListState.todo;
    const { tzone } = this.props.AccountState.account;
    const listDurationConfigOptions =
      ConfigurationState.listDurationConfigOptions;
    let duration = listDurationConfigOptions.find(
      (e) => e._id === data.duration
    );

    if (this.state.showneverendInput === true) {
      // Adjusting the currentDate to add 1 minute to startdate or using the current date and time in the specified timezone
      const currentDate = data.startdate
        ? moment(data.startdate, "MM-DD-YYYY hh:mm A").add(1, "minute")
        : moment(new Date()).tz(tzone).format("MM-DD-YYYY hh:mm A");

      // Calculate end date as three months from the current date
      const endDate = moment(currentDate, "MM-DD-YYYY hh:mm A").add(
        3,
        "months"
      );

      let datesArray = [];
      let iterationDate = moment(currentDate, "MM-DD-YYYY hh:mm A").clone();

      while (iterationDate.isBefore(endDate)) {
        const formattedDate = iterationDate.format("dddd, MM-DD-YYYY hh:mm A");
        datesArray.push(formattedDate);

        // Add days depending on the duration (1 for daily, 7 for weekly, 30 for monthly)
        iterationDate.add(duration.suboption, "day");
      }

      // Store datesArray in state if needed
      this.setState({ datesArray: datesArray }, () => {
        // Optionally, you can set the last date in datesArray as the duedate
        const finalEndDate =
          datesArray.length > 0
            ? datesArray[datesArray.length - 1]
            : endDate.format("MM-DD-YYYY hh:mm A");
        this.props.HandleInputChange("duedate", finalEndDate);
      });
    }
  };

  findDurationName = (id) => {
    const { ConfigurationState } = this.props;
    const { tzone } = this.props.AccountState.account;
    const listDurationConfigOptions =
      ConfigurationState.listDurationConfigOptions;
    let data = listDurationConfigOptions.find((e) => e._id == id);
    this.setState({ datesArray: [] }, () => {
      // Set the current date formatted with the account's timezone
      const currentDateFormatted = moment()
        .tz(tzone)
        .format("dddd, MM-DD-YYYY hh:mm A");
      // Update the state with the new date
      this.setState({ datesArray: [currentDateFormatted] }, () => {
        console.log("Updated datesArray:", this.state.datesArray);
      });
    });
    return data ? data.name : "";
  };
  handleChangeDays = (e) => {
    const { date } = e;
    this.props.HandleInputChange("startdate", date);
    this.props.HandleInputChange("enddate", date);
    this.props.HandleInputChange("duedate", date);
  };
  validateForm = () => {
    const { selectedCategory, selectedTodoShortcut, selectedUser } = this.state;
    let errors = {};
    if (!selectedCategory) {
      errors.categoryError = "Category is required";
    }
    if (!selectedTodoShortcut) {
      errors.todoError = "Todo is required";
    }
    if (!selectedCategory) {
      errors.userError = "Select users  for this category";
    }

    if (!selectedUser) {
      errors.userError = "No users selected for this category";
    }

    this.setState({ errors });
    return (
      Object.keys(errors).filter((key) => errors[key] !== null).length === 0
    );
  };

  submit = (e) => {
    const { AccountState, TodoListState, ConfigurationState } = this.props;
    const listDurationConfigOptions =
      ConfigurationState.listDurationConfigOptions;
    const {
      selectedTitle,
      selectedTodo,
      selectedDescription,
      selectedMandatoryCompliances,
      selectedpriority,
    } = this.state;
    const account = AccountState.account;
    const { tzone } = this.props.AccountState.account;
    const newData = TodoListState.todo;
    const languageData = AccountState.account.SelectedlanguageData;
    let duration = listDurationConfigOptions.find(
      (e) => e._id === newData.duration
    );
    let existingAttachments = [];
    let currentAttachments = [];

    if (newData.attachments && newData.attachments.length > 0) {
      newData.attachments.forEach((item) => {
        if (typeof item === "string") {
          existingAttachments.push(item);
        } else {
          currentAttachments.push(item);
        }
      });
    }

    let editedby = newData.editedby || [];
    if (account.userId && this.state.modalType === "Edit") {
      if (!editedby.includes(account.userId)) {
        editedby.push(account.userId);
      }
    }
    if (this.validateForm()) {
      if (newData.reminderselect !== undefined) {
        if (!["custom", "Custom"].includes(newData.reminderselect)) {
          newData["reminder"] = Number(newData.reminderselect);
        } else {
          newData["reminder"] = this.convertToMinutes(
            newData.reminderday,
            newData.reminderhour,
            newData.reminderminute
          );
        }
      } else {
        newData["reminder"] = 0;
      }
      let recordsArray = [];
      // if(newData.neverends == false)
      // {
      recordsArray = this.state.datesArray.map((dateString, index) => {
        let dataClone = { ...newData };

        const startdate = moment(dateString, "dddd, MM-DD-YYYY hh:mm A").format(
          "MM-DD-YYYY hh:mm A"
        );
        let enddate, duedate;

        if (this.state.datesArray.length > 1) {
          // When there are multiple items, set enddate and duedate to 11:59 PM of the startdate
          enddate = moment(startdate, "MM-DD-YYYY hh:mm A")
            .set({ hour: 23, minute: 59 })
            .format("MM-DD-YYYY hh:mm A");
          duedate = moment(startdate, "MM-DD-YYYY hh:mm A")
            .set({ hour: 23, minute: 59 })
            .format("MM-DD-YYYY hh:mm A");
        } else {
          // When there's a single item, use enddate and duedate from TodoListState.todo
          enddate = moment(startdate, "MM-DD-YYYY hh:mm A")
            .set({ hour: 23, minute: 59 })
            .format("MM-DD-YYYY hh:mm A");
          duedate = moment(startdate, "MM-DD-YYYY hh:mm A")
            .set({ hour: 23, minute: 59 })
            .format("MM-DD-YYYY hh:mm A");
        }

        dataClone.startdate = startdate;

        if (duration.slug === "adhoc") {
          dataClone.duedate = newData.enddate;
          dataClone.enddate = newData.enddate;
        } else {
          dataClone.duedate = duedate;
          dataClone.enddate = enddate;
        }

        dataClone.editedby = editedby;
        dataClone.todo = selectedTodo.value;
        dataClone.description = selectedDescription;
        dataClone.title = selectedTitle;
        dataClone.mandatorycompliances = selectedMandatoryCompliances;
        dataClone.priority = selectedpriority;

        if (newData.reminder !== undefined) {
          dataClone.reminder = newData.reminder;
        }
        if (existingAttachments.length > 0) {
          dataClone.existingAttachments = existingAttachments;
        }
        if (currentAttachments.length > 0) {
          dataClone.currentAttachments = currentAttachments;
        }

        return dataClone;
      });

      const formData = new FormData();
      formData.append("records", JSON.stringify(recordsArray));
      currentAttachments.forEach((file, idx) => {
        formData.append("attachments", file); // Append the file object
      });

      this.setState({ saving: true });
      if (this.state.modalType == "Add") {
        axios
          .post(ImportedURL.API.addTodolist, formData)
          .then((res) => {
            this.setState({ saving: false, listRedirect: true });
            Success(
              (languageData && languageData.TODO_MY_TASK
                ? languageData.TODO_MY_TASK
                : "My Task") +
                (languageData && languageData.CREATED_SUCCESSFULLY
                  ? languageData.CREATED_SUCCESSFULLY
                  : " created successfully")
            );
            this.setState({
              isButtonEnabled: false, // Disable button after submission
            });
          })
          .catch(({ response }) => {
            this.setState({ saving: false });
            this.ErrorResponse(response);
          });
      } else {
        axios
          .post(ImportedURL.API.editTodolist + "/" + this.state.id, formData)
          .then((res) => {
            this.setState({ saving: false, listRedirect: true });
            Success(
              (languageData && languageData.TODO_MY_TASK
                ? languageData.TODO_MY_TASK
                : "My Task") +
                (languageData && languageData.UPDATED_SUCCESSFULLY
                  ? languageData.UPDATED_SUCCESSFULLY
                  : " Updated Successfully")
            );
          })
          .catch(({ response }) => {
            this.setState({ saving: false });
            this.ErrorResponse(response);
          });
      }
    } else {
      console.log("Form validation failed.");
    }
  };

  ErrorResponse = (response) => {
    const languageData = this.props.AccountState.account.SelectedlanguageData;

    if (response) {
      const statusCode = response.status;
      const statusMessage = response.statusText || "Unknown Error";

      switch (statusCode) {
        case 500:
          Error(
            `${statusCode} ${
              languageData?.INTERNAL_SERVER_ERROR || "Internal Server Error"
            }`
          );
          break;
        case 502:
          Error(`${statusCode} ${languageData?.BAD_GATEWAY || "Bad Gateway"}`);
          break;
        case 400:
          Error(languageData?.BAD_REQUEST || "Bad Request");
          break;
        case 406:
          Error(
            languageData?.TODO_LIST_CANNOT_EDIT ||
              "You can't edit todos created by others"
          );
          break;
        default:
          Error(statusMessage || "An unexpected error occurred");
          break;
      }
    } else {
      Error("Bad request");
    }
  };

  tagSubmit = () => {
    const data = this.props.TodoListState.todo;
    this.setState({ showcanvasTag: false });
    this.props.ListTodoTag({ hotel: data.hotel });
  };
  mylistSubmit = () => {
    const data = this.props.TodoListState.todo;
    this.setState({ showcanvasMylist: false });
    this.props.ListTodoMyList({ hotel: data.hotel });
  };

  render() {
    const {
      AccountState,
      LandingState,
      UserState,
      TodoListState,
      TodolistitemState,
      ConfigurationState,
    } = this.props;
    const { selectedDays, weeks, setSelectedDay, selectedDay } = this.state;
    const path = TodoListState.changepath;

    if (this.state.listRedirect) {
      return <Redirect to="/todo-my-task" />; // Make sure the route is wrapped in quotes
    }
    const languageData = AccountState.account.SelectedlanguageData;
    const listHotels =
      LandingState.landingdata &&
      LandingState.landingdata.data &&
      LandingState.landingdata.data.length > 0
        ? LandingState.landingdata.data[0]
        : [];
    const listCategory =
      LandingState.landingdata &&
      LandingState.landingdata.data &&
      LandingState.landingdata.data.length > 0
        ? LandingState.landingdata.data[7]
        : [];
    const listUser = UserState.listusers;
    const listMyList = TodoListState.listtodomylist;
    const data = TodoListState.todo;
    const listPriorityConfigOptions =
      ConfigurationState.listPriorityConfigOptions;
    const listDurationConfigOptions =
      ConfigurationState.listDurationConfigOptions;
    const listNumberOfIntervalConfigOptions =
      ConfigurationState.listNumberOfIntervalConfigOptions;
    const listtodooccurrenceConfigOptions =
      ConfigurationState.listtodooccurrenceConfigOptions;
    const listTodoReminderConfigOptions =
      ConfigurationState.listTodoReminderConfigOptions;
    const todolistitems = TodoListState.todolistitems;
    const account = AccountState.account;
    const listTag = TodoListState.listtodotags;
    const filteredWeeks = weeks.map((week) =>
      week.filter((day) => getDay(day) === selectedDay)
    );
    const spinner = TodoListState.spinner;
    const listtodolistitem = TodolistitemState.listtodolistitem;
    var selectedHotel = "";
    const hotelOptions = [];
    if (listHotels && listHotels.length > 0) {
      listHotels.map((item) => {
        if (data.hotel == item._id) {
          selectedHotel = {
            label: `${item.name} ${item.city}  ${item.state}`,
            value: item._id,
            logo: item.logo,
            name: "hotel",
          };
        }
        hotelOptions.push({
          label: `${item.name} ${item.city}  ${item.state}`,
          value: item._id,
          logo: item.logo,
          name: "hotel",
        });
      });
    }

    const matchedDuration = this.state.selectedfrequency
      ? listDurationConfigOptions.find(
          (option) => option._id === this.state.selectedfrequency
        )
      : null;

    const categoryOption = [];
    const selectedCategory = [];
    listCategory.map((item) => {
      if (
        data.category &&
        data.category.length > 0 &&
        data.category.includes(item._id)
      ) {
        selectedCategory.push({
          label: item.name,
          value: item._id,
          name: "category",
        });
      }
      let userIncludes = listUser.some(
        (e) => e.categoryid == item._id && data.hotel == e.hotelid
      );
      if (data.hotel && userIncludes) {
        categoryOption.push({
          label: item.name,
          value: item._id,
          name: "category",
        });
      }
    });

    var selectedTodoShortcut = "";
    const hotelTdodShortcut = [];
    if (listtodolistitem && listtodolistitem.length > 0) {
      listtodolistitem
        .filter((item) => item.status === 1) // Filter only items where status == 1
        .map((item) => {
          if (data.todo == item._id) {
            selectedTodoShortcut = {
              label: item.todo,
              value: item._id,
              name: "todo",
            };
          }
          if (data.hotel == item.hotel && data.category == item.category) {
            hotelTdodShortcut.push({
              label: item.todo,
              value: item._id,
              name: "todo",
            });
          }
        });
    }

    const userOption = [];
    const selectedUser = [];
    listUser.map((item) => {
      if (
        data.users &&
        data.users.length > 0 &&
        data.users.includes(item._id)
      ) {
        selectedUser.push({ label: item.name, value: item._id, name: "users" });
      }
      if (
        data.category &&
        data.category.length > 0 &&
        data.category.includes(item.categoryid) &&
        data.hotel == item.hotelid
      ) {
        userOption.push({ label: item.name, value: item._id, name: "users" });
      }
    });
    let showUserOption = userOption.filter(
      (e) => !(data.users && data.users.includes(e.value))
    );
    if (
      showUserOption.length > 1 &&
      userOption.length != selectedUser.length &&
      this.state.usersSelectAll
    ) {
      userOption.unshift({ label: "All", value: "", name: "users" });
    }

    let occurrenceOption = [];
    let occurrenceSelect = "";
    if (
      listtodooccurrenceConfigOptions &&
      listtodooccurrenceConfigOptions.length > 0
    ) {
      listtodooccurrenceConfigOptions.map((item) => {
        if (data.numberofoccurrence == item.name) {
          occurrenceSelect = {
            label: `${item.name}`,
            value: Number(item.name),
            name: "numberofoccurrence",
          };
        }
        occurrenceOption.push({
          label: `${item.name}`,
          value: Number(item.name),
          name: "numberofoccurrence",
        });
      });
    }

    let reminderOption = [];
    let reminderSelect = "";
    if (
      listTodoReminderConfigOptions &&
      listTodoReminderConfigOptions.length > 0
    ) {
      listTodoReminderConfigOptions.map((item) => {
        if (data.reminderselect == item.suboption) {
          reminderSelect = {
            label: `${item.name}`,
            value: item.suboption,
            name: "reminderselect",
          };
        } else if (data.reminderselect == "custom") {
          reminderSelect = {
            label: `Custom`,
            value: "custom",
            name: "reminderselect",
          };
        }
        reminderOption.push({
          label: `${item.name}`,
          value: item.suboption,
          name: "reminderselect",
        });
      });
      reminderOption.push({
        label: `Custom`,
        value: "custom",
        name: "reminderselect",
      });
    }

    const days = data.startdate ? moment(data.startdate).format("dddd") : "";
    let dueDate = "";
    if (data.startdate) {
      const originalDateTime = moment(data.startdate, "MM-DD-YYYY hh:mm A");
      const dateTimePlusOneMinute = originalDateTime.clone().add(1, "minute");
      dueDate = dateTimePlusOneMinute.format("MM-DD-YYYY hh:mm A");
    }

    return (
      <>
        <div>
          <div className="breadcrump">
            <p>
              {" "}
              <Link to="/">
                <h6>
                  {languageData && languageData.DASHBOARD
                    ? languageData.DASHBOARD
                    : "Dashboard"}
                </h6>
              </Link>{" "}
              <span>
                <i class="fa fa-angle-right" aria-hidden="true"></i>{" "}
              </span>{" "}
              <Link to={path}>
                <h6>
                  {path == "/todo-my-task"
                    ? languageData && languageData.TODO_MY_TASK
                      ? languageData.TODO_MY_TASK
                      : "My Task"
                    : languageData && languageData.ALL_MY_TASK
                    ? languageData.ALL_MY_TASK
                    : "All My Task"}
                  {languageData && languageData.LIST
                    ? languageData.LIST
                    : " List"}
                </h6>
              </Link>{" "}
              <span>
                <i class="fa fa-angle-right" aria-hidden="true"></i>{" "}
              </span>{" "}
              <h6
                className="highlights_breadcrump"
                style={{ cursor: "pointer" }}
              >
                {this.state.modalType}{" "}
                {languageData && languageData.TO_DO
                  ? languageData.TO_DO
                  : "To-Do"}
              </h6>
            </p>
          </div>
          <div className="section-body pt-3">
            <div className="container-fluid add_to_do_list">
              <div className="tab-content">
                <div
                  className="tab-pane fade show active"
                  id="Departments-list"
                  role="tabpanel"
                >
                  <div className="card">
                    <div className="card-body border-0">
                      <div className="title_mt">
                        <div className="d-flex">
                          <div className="rounded_icon">
                            <i class="fa fa-calendar mr-2"></i>
                          </div>
                          <div>
                            <h3
                              className="card-title"
                              style={{ marginTop: "10px" }}
                            >
                              {this.state.modalType}{" "}
                              {languageData && languageData.TO_DO
                                ? languageData.TO_DO
                                : "To-Do"}
                            </h3>
                          </div>
                        </div>
                        <div
                          className="header-action"
                          style={{ marginTop: "5px" }}
                        >
                          <Link to={path}>
                            <button
                              type="button"
                              className="btn btn-primary"
                              id="Add"
                            >
                              <i className="fa fa-arrow-left mr-2"></i>Back
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="card-body  border-0">
                      <form id="form_reset">
                        <Row>
                          <div className="col-sm-3 col-md-3">
                            <div className="form-group">
                              <label className="form-label">
                                Assign Users Category{" "}
                                <span className="ml-1" style={{ color: "red" }}>
                                  *
                                </span>
                              </label>
                              <Select
                                value={selectedCategory}
                                onChange={this.handleChangeSelect}
                                options={categoryOption}
                                name="category"
                              />
                            </div>
                            {this.state.errors.categoryError && (
                              <span style={{ color: "red", marginTop: "5px" }}>
                                {this.state.errors.categoryError}
                              </span>
                            )}
                          </div>

                          <div className="col-sm-3 col-md-3">
                            <div className="form-group">
                              <label className="form-label">
                                Todo - shortcode{" "}
                                <span className="ml-1" style={{ color: "red" }}>
                                  *
                                </span>
                              </label>
                              <Select
                                value={selectedTodoShortcut} // Preselected value
                                options={hotelTdodShortcut} // Options for the dropdown
                                onChange={this.handleChangeSelect} // Handle change when a new option is selected
                              />
                            </div>
                            {this.state.errors.todoError && (
                              <span style={{ color: "red", marginTop: "5px" }}>
                                {this.state.errors.todoError}
                              </span>
                            )}
                          </div>

                          <div className="col-sm-3 col-md-3">
                            <div className="form-group">
                              <label className="form-label">
                                Assign User Name{" "}
                                <span className="ml-1" style={{ color: "red" }}>
                                  *
                                </span>
                              </label>
                              <Select
                                value={selectedUser}
                                onChange={this.handleChangeSelect}
                                options={userOption}
                                name="users"
                              />
                            </div>
                            {this.state.errors.userError && (
                              <span style={{ color: "red", marginTop: "5px" }}>
                                {this.state.errors.userError}
                              </span>
                            )}
                          </div>

                          <div className="col-sm-3 col-md-3">
                            <div className="form-group">
                              <label className="form-label">Title </label>{" "}
                              {/* Dynamically updated title */}
                              <input
                                type="text"
                                className="form-control w-100"
                                name="title"
                                value={this.state.selectedTitle || ""}
                                readOnly="true"
                                disabled
                              ></input>
                            </div>
                          </div>

                          <div className="col-sm-4 col-md-3">
                            <div className="form-group">
                              <label className="form-label">Description </label>{" "}
                              {/* Dynamically updated description */}
                            </div>
                            <input
                              type="text"
                              className="form-control w-100"
                              name="Description"
                              value={this.state.selectedDescription}
                              readOnly="true"
                              disabled

                            ></input>
                          </div>

                          <div className="col-sm-3 col-md-3">
                            <div className="form-group">
                              <label className="form-label">Frequency</label>{" "}
                              {/* Dynamically updated description */}
                            </div>
                            <input
                              type="text"
                              className="form-control w-100"
                              name="Frequency"
                              value={
                                matchedDuration ? matchedDuration.name : ""
                              }
                              readOnly="true"
                              disabled

                            ></input>
                          </div>

                          <div className="col-sm-3 col-md-3">
                            <div className="form-group">
                              <label className="form-label">
                                {" "}
                                Mandatory compliance{" "}
                              </label>
                            </div>
                            <input
                              type="text"
                              className="form-control w-100"
                              name="Mandatory"
                              value={
                                this.state.selectedMandatoryCompliances === true
                                  ? " Yes"
                                  : " No"
                              }
                              readOnly="true"
                              disabled

                            ></input>
                          </div>
                          <div className="col-sm-3 col-md-3">
                            <div
                              className="form-group"
                              style={{ position: "relative" }}
                            >
                              <label className="form-label"> Priority</label>
                            </div>
                            <input
                              type="text"
                              className="form-control w-100"
                              name="priority"
                              value={
                                this.state.selectedpriority === "1"
                                  ? "High"
                                  : this.state.selectedpriority === "2"
                                  ? "Medium"
                                  : this.state.selectedpriority === "3"
                                  ? "Low"
                                  : ""
                              }
                              readOnly="true"
                              disabled

                            ></input>
                          </div>

                          <div className="add_todo_list_tabs">
                            <ul
                              class="nav nav-pills mb-3"
                              id="pills-tab"
                              role="tablist"
                            >
                              <li class="nav-item" role="presentation">
                                <button
                                  class="nav-link active"
                                  id="recurring-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#recurring"
                                  type="button"
                                  role="tab"
                                  aria-controls="recurring"
                                  aria-selected="true"
                                >
                                  <i
                                    class="fa fa-refresh mr-2"
                                    aria-hidden="true"
                                  ></i>
                                  Frequency
                                </button>
                              </li>
                              <li class="nav-item" role="presentation">
                                <button
                                  class="nav-link "
                                  id="attachment-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#attachments"
                                  type="button"
                                  role="tab"
                                  aria-controls="attachments"
                                  aria-selected="false"
                                >
                                  <GrAttachment
                                    style={{ marginRight: "5px" }}
                                  />
                                  Attachment
                                </button>
                              </li>

                              <li class="nav-item" role="presentation">
                                <button
                                  class="nav-link"
                                  id="reminder-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#reminder"
                                  type="button"
                                  role="tab"
                                  aria-controls="reminder"
                                  aria-selected="false"
                                >
                                  <i
                                    class="fa fa-calendar mr-2"
                                    aria-hidden="true"
                                  ></i>
                                  Reminder
                                </button>
                              </li>
                            </ul>
                            <div class="tab-content" id="pills-tabContent">
                              <div
                                class="tab-pane fade "
                                id="attachments"
                                role="tabpanel"
                                aria-labelledby="attachment-tab"
                              >
                                <Row>
                                  <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Attachment
                                      </label>
                                      <input
                                        type="file"
                                        className="form-control"
                                        name="attachments"
                                        accept="application/pdf,image/png,image/jpg,image/jpeg"
                                        id="attachmentsId"
                                        multiple
                                        onChange={this.handleChangeFile}
                                      />
                                    </div>
                                    <div className="photos_wid">
                                      {data.attachments &&
                                      data.attachments.length > 0 ? (
                                        <>
                                          {data.attachments.map((img, i) => {
                                            return (
                                              <div
                                                style={{
                                                  position: "relative",
                                                  marginRight: "10px",
                                                  marginBottom: "15px",
                                                }}
                                                key={i}
                                              >
                                                <div
                                                  style={{
                                                    position: "relative",
                                                  }}
                                                >
                                                  <img
                                                    src={this.findImageSrc(img)}
                                                    width="100px"
                                                    alt=""
                                                    style={{
                                                      marginRight: "10px",
                                                      height: "100px",
                                                      position: "relative",
                                                      objectFit: "contain",
                                                    }}
                                                    onError={onErrorImage}
                                                  />
                                                  <i
                                                    className="fa fa-times img_remove"
                                                    onClick={() =>
                                                      this.handleChangeRemoveImg(
                                                        i,
                                                        "attachments"
                                                      )
                                                    }
                                                  ></i>
                                                </div>
                                              </div>
                                            );
                                          })}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </Row>
                              </div>
                              <div
                                class="tab-pane fade show active"
                                id="recurring"
                                role="tabpanel"
                                aria-labelledby="recurring-tab"
                              >
                                <Row>
                                  {/* {
                                                                        listDurationConfigOptions && listDurationConfigOptions.length > 0
                                                                            ?
                                                                            <>
                                                                                <div className="col-lg-12 col-md-12">
                                                                                    <div className="form-group">
                                                                                       
                                                                                        <div className='todo_duration'>
                                                                                            {
                                                                                                listDurationConfigOptions
                                                                                                .filter(item => !(this.state.showneverendInput && item.name === 'Adhoc'))
                                                                                                .map((item, i) => {
                                                                                                    return (
                                                                                                        <div className='todo_duration_box' key={i}>
                                                                                                            <button type='button' className={`btn btn-primery ${data.duration == item._id ? ' todo_duration_button' : ' todo_duration_button'}`} 
                                                                                                            onClick={() => this.handleChangeDuration('duration', item._id)} >
                                                                                                                <i class="fa fa-calendar-o mr-2" aria-hidden="true"></i>{item.name}
                                                                                                            </button>
                                                                                                        </div>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </> : ''
                                                                    } */}

                                  {listDurationConfigOptions &&
                                  listDurationConfigOptions.length > 0 ? (
                                    <>
                                      <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                          <div className="todo_duration">
                                            {listDurationConfigOptions
                                              .filter(
                                                (item) =>
                                                  !(
                                                    this.state
                                                      .showneverendInput &&
                                                    item.name === "Adhoc"
                                                  )
                                              )
                                              .map((item, i) => {
                                                const isSelected =
                                                  this.state
                                                    .selectedfrequency ===
                                                  item._id; // Define isSelected inside map function

                                                return (
                                                  <div
                                                    className="todo_duration_box"
                                                    key={i}
                                                  >
                                                    {/* <button
                                            type='button'
                                            className={`btn btn-primary ${this.state.selectedfrequency === item._id ? 'todo_duration_button' : ''}`}
                                            onClick={() => this.handleChangeDuration('duration', item._id)}
                                            disabled={this.state.selectedfrequency !== item._id} // Disable unless selectedfrequency matches
                                        
                                       >
                                            <i className="fa fa-calendar-o mr-2" aria-hidden="true"></i>{item.name}
                                        </button> */}
                                                    <button
                                                      type="button"
                                                      className={`btn btn-primery ${
                                                        isSelected
                                                          ? "todo_duration_button_enabled"
                                                          : "todo_duration_button_disabled"
                                                      }`}
                                                      style={{
                                                        background: isSelected
                                                          ? "#1c3078"
                                                          : "", // Change background color when enabled
                                                        background: "#08396e", // Change background color when enabled
                                                        color: isSelected
                                                          ? "#ffffff"
                                                          : "",
                                                        color: "#ffffff",
                                                        cursor: isSelected
                                                          ? "pointer"
                                                          : "not-allowed", // Make button look clickable only when enabled
                                                      }}
                                                      disabled={!isSelected} // Disable button if not selected
                                                      onClick={() =>
                                                        this.handleChangeDuration(
                                                          "duration",
                                                          item._id
                                                        )
                                                      }
                                                    >
                                                      <i
                                                        className="fa fa-calendar-o mr-2"
                                                        aria-hidden="true"
                                                      ></i>
                                                      {item.name}
                                                    </button>
                                                  </div>
                                                );
                                              })}
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  {!this.state.showneverendInput && (
                                    <div className="col-sm-3 col-md-3">
                                      <div className="form-group">
                                        <label className="form-label">
                                          Start Date & Time
                                        </label>
                                        <DateTimeComponent
                                          onChange={(e) =>
                                            this.handleChangeDate(
                                              e,
                                              "startdate"
                                            )
                                          }
                                          value={data.startdate}
                                          minDate={moment(new Date())
                                            .tz(account.tzone)
                                            .format("MM-DD-YYYY hh:mm A")}
                                          editable={true}
                                        />
                                      </div>
                                    </div>
                                  )}

                                  {this.state.showInput &&
                                    !this.state.showneverendInput && (
                                      <div className="col-sm-3 col-md-3">
                                        <div className="form-group">
                                          <label className="form-label">
                                            Due Date & Time
                                          </label>
                                          <DateTimeComponent
                                            onChange={(e) =>
                                              this.handleChangeDate(
                                                e,
                                                "enddate"
                                              )
                                            }
                                            value={
                                              data.enddate
                                                ? moment(data.enddate).set({
                                                    hour: 23,
                                                    minute: 59,
                                                  })
                                                : moment(new Date()).set({
                                                    hour: 23,
                                                    minute: 59,
                                                  })
                                            }
                                            minDate={
                                              data.startdate
                                                ? dueDate
                                                : moment(new Date())
                                                    .tz(account.tzone)
                                                    .format(
                                                      "MM-DD-YYYY hh:mm A"
                                                    )
                                            }
                                            editable={true}
                                            minTime={
                                              data.startdate
                                                ? moment(data.startdate).add(
                                                    1,
                                                    "minute"
                                                  )
                                                : moment(new Date())
                                                    .tz(account.tzone)
                                                    .format(
                                                      "MM-DD-YYYY hh:mm A"
                                                    )
                                            }
                                          />
                                        </div>
                                      </div>
                                    )}

                                  {!this.state.showInput &&
                                    !this.state.showneverendInput && (
                                      <div className="col-lg-3 col-md-3">
                                        <div className="form-group">
                                          <label className="form-label">
                                            Number Of Occurrence
                                          </label>
                                          <SelectComponent
                                            value={occurrenceSelect}
                                            options={occurrenceOption}
                                            onChange={this.handleChangeSelect}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  {!this.state.showInput &&
                                    !this.state.showneverendInput && (
                                      <div className="col-sm-3 col-md-3">
                                        <div className="form-group">
                                          <label className="form-label">
                                            End Date & Time
                                          </label>
                                          <DateTimeComponent
                                            onChange={(e) =>
                                              this.handleChangeDate(
                                                e,
                                                "enddate"
                                              )
                                            }
                                            value={
                                              data.enddate
                                                ? moment(data.enddate).set({
                                                    hour: 23,
                                                    minute: 59,
                                                  })
                                                : moment(new Date()).set({
                                                    hour: 23,
                                                    minute: 59,
                                                  })
                                            }
                                            editable={false} //This will disable the date picker, making it readonly.
                                          />
                                        </div>
                                      </div>
                                    )}
                                  {!this.state.showInput && (
                                    <div className="col-sm-3 col-md-3">
                                      <div className="mb-4 todo-list-end-task-taggle">
                                        <label className="form-label">
                                          Task Never Ends
                                        </label>
                                        <label class="toggle mt-2">
                                          <input
                                            type="checkbox"
                                            name="neverends"
                                            checked={data.neverends}
                                            onClick={this.handleChange}
                                          />
                                          <span class="slider"></span>
                                          <span
                                            class="labels"
                                            data-on="ON"
                                            data-off="OFF"
                                          ></span>
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                </Row>
                              </div>

                              <div>
                                <b>Upcoming Task Dates</b>
                                {this.state.datesArray &&
                                this.state.datesArray.length > 0 ? (
                                  <ul>
                                    {this.state.datesArray.map(
                                      (date, index) => (
                                        <li key={index}>{date}</li>
                                      )
                                    )}
                                  </ul>
                                ) : (
                                  <p>No upcoming tasks.</p>
                                )}
                              </div>

                              <div
                                class="tab-pane fade"
                                id="reminder"
                                role="tabpanel"
                                aria-labelledby="reminder-tab"
                              >
                                <Row>
                                  <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Reminder
                                      </label>
                                      <SelectComponent
                                        value={reminderSelect}
                                        options={reminderOption}
                                        onChange={this.handleChangeSelect}
                                      />
                                    </div>
                                  </div>
                                  {data.reminderselect == "custom" ? (
                                    <>
                                      <div className="col-lg-4 col-md-4">
                                        <div className="form-group">
                                          <label className="form-label">
                                            Number Of Day
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Number Of Day"
                                            value={
                                              data.reminderday
                                                ? data.reminderday
                                                : ""
                                            }
                                            name="reminderday"
                                            onChange={this.handleChange}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-4">
                                        <div className="form-group">
                                          <label className="form-label">
                                            Number Of Hour
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Number Of Hour"
                                            value={
                                              data.reminderhour
                                                ? data.reminderhour
                                                : ""
                                            }
                                            name="reminderhour"
                                            onChange={this.handleChange}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-4">
                                        <div className="form-group">
                                          <label className="form-label">
                                            Number Of Minute
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Number Of Minute"
                                            value={
                                              data.reminderminute
                                                ? data.reminderminute
                                                : ""
                                            }
                                            name="reminderminute"
                                            onChange={this.handleChange}
                                          />
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {data.reminderdate ? (
                                    <div className="col-lg-12 col-md-12">
                                      <div className="form-group">
                                        <label className="form-label">
                                          {"Reminder at " +
                                            moment(data.reminderdate).format(
                                              "ddd DD MMM, YYYY hh:mm A"
                                            )}
                                        </label>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </Row>
                              </div>
                              <div
                                class="tab-pane fade"
                                id="task-list"
                                role="tabpanel"
                                aria-labelledby="task-list-tab"
                              ></div>
                            </div>
                          </div>
                        </Row>
                      </form>
                    </div>
                    <div className="card-footer text-right mandatory">
                      <label className="form-label text-left mandatory-label">
                        <span className="mr-1" style={{ color: "red" }}>
                          *
                        </span>
                        Mandatory Fields{" "}
                      </label>
                      <div className="text-right">
                        {this.state.saving ? (
                          <button type="button" className="btn commor_save">
                            <i className="fa fa-spinner fa-spin mr-2"></i>
                            {this.state.modalType == "Edit"
                              ? "Updating"
                              : "Saving"}
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn commor_save"
                            onClick={this.submit}
                            disabled={!this.state.isButtonEnabled} // Disable button based on isButtonEnabled state
                          >
                            <i className="fe fe-save mr-2"></i>
                            {this.state.modalType === "Edit"
                              ? "Update"
                              : "Save"}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <AddTagandMylistModal {...this.props} title={'Tag'} showcanvas={this.state.showcanvasTag} ref={this.resetRefTag} onHidecanvas={() => this.setState({ showcanvasTag: false })} type='tag' data={data} submit={this.tagSubmit} />
                <AddTagandMylistModal {...this.props} title={'My List'} showcanvas={this.state.showcanvasMylist} ref={this.resetRef} onHidecanvas={() => this.setState({ showcanvasMylist: false })} type='mylist' data={data} submit={this.mylistSubmit} /> */}
        <SpinnerComponent spinner={spinner} />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  AccountState: state.account,
  LandingState: state.landing,
  TodoListState: state.todolist,
  UserState: state.user,
  ConfigurationState: state.configuration,
  TodolistitemState: state.todolistitem,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ListUser: AC_LIST_USER,
      HandleInputChange: AC_HANDLE_TO_DO_LIST_CHANGE,
      ListTodoMyList: AC_LIST_TODO_MY_LIST,
      ListTodoTag: AC_LIST_TODO_TAGS,
      ListTodoListItems: AC_LIST_TO_DO_LIST_ITEMS,
      EmptyTodoList: AC_EMPTY_TO_DO_LIST,
      SpinnerTodoList: AC_SPINNER_TO_DO_LIST,
      ViewTodoList: AC_PASS_VIEW_TO_DO_LIST,
      EmptyTodoListItems: AC_EMPTY_TODO_LISTITEMS,
      AddTodoApis: AC_ADD_TODO_APIS,
      listtodolistitem: AC_LIST_TODO_LIST_ITEM,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EditTaskReminder);
