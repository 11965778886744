import React, { Component } from 'react';
import { MdDateRange } from "react-icons/md";
import DatePicker from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon";
import TimePicker from "react-multi-date-picker/plugins/time_picker";

class DateTimeComponent extends Component {
    render() {
        const { minDate, value, onChange, childProps, editable } = this.props;
        return (
            <div className='site_pass_calander'>
                <DatePicker
                    disabled={!editable}
                    placeholder="MM-DD-YYYY"
                    icon={<MdDateRange />}
                    render={<InputIcon />}
                    inputclassName="form-control width-datepicker"
                    value={new Date(value)}
                    // onChange={onChange}
                    onChange={editable ? onChange : null}  // Only allow onChange when editable is true
                    format="MM-DD-YYYY hh:mm A"
                    plugins={[
                        <TimePicker position="bottom" />
                    ]}
                    minDate={new Date(minDate)}
                    {...childProps}
                />
            </div>
        );
    }
}

export default DateTimeComponent;