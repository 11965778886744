import React, { Component } from "react";
import ErrorResponseComponent from "../../../utilities/ErrorStatus/errorresponse";
import ImportedURL from "../../../common/api";
import axios from "axios";
import { Success } from "../../../common/swal";
import moment from "moment";

class QuickAddTodoList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalType: "Add",
      title: "",
      description: "",
    };
    this.titleInputRef = React.createRef();
    this.resetTaskFun = this.resetTaskFun.bind(this);
  }
  resetTaskFun() {
    this.setState({ title: "", description: "", titleError: false });
  }
  handleChange = (e) => {
    const { name, value } = e.target;
    const Error = name + "Error";
    this.setState({ [name]: value, [Error]: false });
  };
  submit = (e) => {
    const { AccountState, startdate } = this.props;
    const data = this.state;
    const languageData = AccountState.account.SelectedlanguageData;
    for (let key in data) {
      if (data.hasOwnProperty(key) && typeof data[key] === "string") {
        data[key] = data[key].trim();
      }
    }

    let valid = 1;
    if (!data.title) {
      valid = 0;
      this.setState({ titleError: true });
      this.titleInputRef.current.focus();
    }
    if (valid) {
      var formData = {
        title: data.title ? data.title : "",
        description: data.description ? data.description : "",
      };
      if (startdate) {
        formData["startdate"] = startdate;
        // const coventDate = moment(startdate, 'MM-DD-YYYY hh:mm A');
        // const newDate = coventDate.clone().add(1, 'hour');
        //const enddate = newDate.format('MM-DD-YYYY hh:mm A');
        const enddate = moment(startdate, "MM-DD-YYYY hh:mm A")
          .set({ hour: 23, minute: 59 })
          .format("MM-DD-YYYY hh:mm A");

        formData["enddate"] = enddate;
        formData["duedate"] = enddate;
      }
      this.setState({ saving: true });
      axios
        .post(ImportedURL.API.addTodolist, formData)
        .then((res) => {
          this.setState({ description: "", title: "" });
          this.props.submit();
          this.setState({ saving: false });
          Success(
            (languageData && languageData.TODO_MY_TASK
              ? languageData.TODO_MY_TASK
              : "My Task") +
              (languageData && languageData.CREATED_SUCCESSFULLY
                ? languageData.CREATED_SUCCESSFULLY
                : " created successfully")
          );
        })
        .catch(({ response }) => {
          this.setState({ saving: false });
          ErrorResponseComponent(languageData, response);
        });
    }
  };

  render() {
    const { AccountState } = this.props;
    const languageData = AccountState.account.SelectedlanguageData;
    return (
      <>
        <div
          className="modal fade country_model"
          id="exampleModal1"
          autoComplete="off"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <div className="rounded_icon">
                  <i className="icon-clock mr-2 "></i>
                </div>
                <h5
                  className="modal-title"
                  id="exampleModalLabel"
                  style={{ marginTop: "5px" }}
                >
                  {this.state.modalType}{" "}
                  {languageData && languageData.VIEW_TODO
                    ? languageData.VIEW_TODO
                    : "Task"}
                </h5>
                <button
                  type="button"
                  id="closeModal"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true" style={{ fontSize: "23px" }}>
                    <img src="../../assets/images/cancel.png" />
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-sm-12 col-md-12">
                    <div className="form-group">
                      <label className="form-label">
                        Title
                        <span className="ml-1" style={{ color: "red" }}>
                          *
                        </span>
                      </label>
                      <input
                        type="text"
                        ref={this.titleInputRef}
                        className="form-control"
                        placeholder="Title"
                        value={this.state.title ? this.state.title : ""}
                        name="title"
                        onChange={this.handleChange}
                      />
                      <div
                        className="invalid-feedback"
                        style={{
                          display: this.state.titleError ? "block" : "none",
                        }}
                      >
                        Title is required
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12">
                    <div
                      className="form-group"
                      style={{ position: "relative" }}
                    >
                      <label className="form-label">Description</label>
                      <textarea
                        className="form-control w-100"
                        name="description"
                        rows="4"
                        cols="50"
                        onChange={this.handleChange}
                        value={
                          this.state.description ? this.state.description : ""
                        }
                        placeholder={"Description"}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer text-right mandatory">
                <label className="form-label text-left mandatory-label">
                  <span className="mr-1" style={{ color: "red" }}>
                    *
                  </span>
                  Mandatory Fields{" "}
                </label>
                <div className="">
                  {this.state.saving ? (
                    <button type="button" className="btn commor_save">
                      <i className="fa fa-spinner fa-spin mr-2"></i>
                      {this.state.modalType == "Edit" ? "Updating" : "Saving"}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn commor_save"
                      onClick={this.submit}
                    >
                      <i className="fe fe-save mr-2"></i>
                      {this.state.modalType == "Edit" ? "Update" : "Save"}
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn btn-secondary"
                    style={{ marginRight: "10px" }}
                    data-dismiss="modal"
                  >
                    <i className="fa fa-times mr-2"></i>Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default QuickAddTodoList;
