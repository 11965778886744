import React, { Component } from "react";
import AgGridComponent from "../../../utilities/AgGridReact/aggrid";
import moment from "moment";
import Select from "react-select";
import Swal from "sweetalert2";
import showSwalDialog from "../../../utilities/Modal/swal";
class PendingTodo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: [
        {
          headerName: "Title",
          field: "title",
          width: 200,
          floatingFilter: true,
        },
        {
          headerName: "Description",
          field: "description",
          width: 200,
          floatingFilter: true,
          valueGetter: function (params) {
            if (params.data)
              if (params.data.description) {
                return params.data.description;
              } else {
                return "---";
              }
          },
        },
        {
          headerName: "Work Date",
          field: "start",
          width: 200,
          floatingFilter: true,
          valueGetter: function (params) {
            if (params.data)
              if (params.data.start) {
                return moment(params.data.start).format(params.data.dFormat);
              } else {
                return "---";
              }
          },
        },
        {
          headerName: "Status",
          width: 250,
          field: "todoprogress",
          cellStyle: { "text-align": "center" },
          suppressMenu: true,
          cellRenderer: function (params) {
            if (params.value === "inprogress") {
              return '<span class="tag ticket_inprogress">Inprogress</span>';
            } else if (params.value === "completed") {
              return '<span class="tag ticket_completed">Completed</span>';
            } else if (params.value == "upcoming") {
              return `<span class="tag ticket_open">Upcoming</span>`;
            } else if (params.value == "todo") {
              return `<span class="tag ticket_open">Todo</span>`;
            } else if (params.value == "pending") {
              return `<span class="tag ticket_inreview" data-action-type="Status">Pending</span>`;
            }
          },
        },
        {
          headerName: "Actions",
          width: 150,
          field: "actions",
          headerClass: "ag-center-header",
          cellStyle: { "text-align": "center" },
          suppressMenu: true,
          sortable: false,
          filter: false,
          cellRenderer: function (params) {
            if (params.value) {
              return params.value;
            } else {
              return "---";
            }
          },
        },
      ],
      defaultColumDef: {
        editable: false,
        sortable: true,
        resizable: true,
        filter: true,
        flex: 1,
        minWidth: 100,
      },
      perPage: 25,
    };
  }
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.setState({ gridApi: params.api });
  };
  onRowClicked = (event) => {
    const languageData = this.props.AccountState.account.languageData;
    const { tzone } = this.props.AccountState.account;
    const { account } = this.props.AccountState;
    const rowValue = event.event.target;
    const value = rowValue.getAttribute("data-action-type");
    if (value === "View" || value === "Edit") {
      this.props.onChangeAction(event.data.id, value);
    }
    // if (value === 'Status') {
    //     let sentance = languageData && languageData.TODO_LIST_COMPLETE_TASK_CONTENT ? languageData.TODO_LIST_COMPLETE_TASK_CONTENT : "Are you sure you want to complete your task?"
    //     showSwalDialog(sentance, '', 'Yes', 'No', 'assets/images/status.png').then((result) => {
    //         if (result.isConfirmed) {
    //             this.props.handleSubmitPending('completed', event.data.todoprogressid)
    //         }
    //     })
    // }

    // if (value === 'Status') {
    //     let sentance = languageData && languageData.TODO_LIST_COMPLETE_TASK_CONTENT
    //         ? languageData.TODO_LIST_COMPLETE_TASK_CONTENT
    //         : "Are you sure you want to complete your task?";

    //     Swal.fire({
    //         title: sentance,
    //         input: 'text', // This adds a text box for the user to enter a reason
    //         inputPlaceholder: 'Enter reason for completing the task',
    //         showCancelButton: true,
    //         confirmButtonText: 'Yes',
    //         cancelButtonText: 'No',
    //         imageUrl: 'assets/images/status.png',
    //         preConfirm: (reason) => {
    //             console.log("Entered reason:", reason); // Debug log for the reason entered
    //             if (!reason || reason.trim() === '') {
    //                 Swal.showValidationMessage('You need to enter a reason');
    //                 return false;
    //             }
    //             return reason.trim();  // Return the trimmed reason
    //         }
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             console.log("Confirmed result value:", result.value); // Debug log for result.value
    //             // Pass the reason along with the status
    //             this.props.handleSubmitPending('completed', event.data.todoprogressid, result.value);
    //         }
    //     });
    // }

    if (value === "Status") {
      let sentance =
        languageData && languageData.TODO_LIST_COMPLETE_TASK_CONTENT
          ? languageData.TODO_LIST_COMPLETE_TASK_CONTENT
          : "Are you sure you want to complete your task?";

      Swal.fire({
        title: sentance,
        input: "text", // For reason input
        inputPlaceholder: "Enter reason for completing the task",
        html: `
                    <label class="form-label">Completed Date & Time</label>
                    <input type="datetime-local" id="completeddate" class="swal2-input" />
                    <style>
            .swal2-input.swal2-inputerror {
              box-shadow: none !important; /* Remove red box shadow */
              border-color: #ddd !important; /* Neutral border color */
            }
          </style>
                `,
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        imageUrl: "assets/images/status.png",
        preConfirm: () => {
          const reason = Swal.getInput().value; // Get reason entered
          const completedDateInput =
            document.getElementById("completeddate").value; // Get the completed date

          if (!reason || reason.trim() === "") {
            document.getElementById("completeddate").focus();
            Swal.showValidationMessage("You need to enter a reason");
            return false;
          }
          if (!completedDateInput) {
            document.getElementById("completeddate").focus();
            Swal.showValidationMessage(
              "You need to select a completed date and time"
            );
            return false;
          }

          const completedDate = moment(completedDateInput)
            .tz(account.tzone)
            .format("MM-DD-YYYY hh:mm A");

          return { reason: reason.trim(), completedDate }; // Return both reason and completed date
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.props.handleSubmitPending(
            "completed",
            event.data.todoprogressid,
            result.value.reason,
            result.value.completedDate
          );
        }
      });
    }
  };
  handleFilterChanged = () => {
    const api = this.gridApi;
    if (api && api.getDisplayedRowCount() === 0) {
      api.showNoRowsOverlay();
      this.setState({ hideExport: true });
    } else {
      api.hideOverlay();
      this.setState({ hideExport: false });
    }
  };
  childRefPending = (hotelName) => {
    const account = this.props.AccountState.account;
    const dFormat =
      account.dateformate != "" ? account.dateformate : "MM/DD/YYYY";
    var firstRow = this.gridApi.getFirstDisplayedRow();
    var lastRow = this.gridApi.getLastDisplayedRow();
    this.gridApi.exportDataAsCsv({
      columnKeys: ["title", "description", "start"],
      fileName:
        "overdue" +
        "_" +
        (hotelName && hotelName.replace(/ /g, "_")) +
        account.currentTime.replace(/ /g, "_") +
        ".csv",
      shouldRowBeSkipped: (params) => {
        return (
          params.node.rowIndex < firstRow || params.node.rowIndex > lastRow
        );
      },
      processCellCallback: function (params) {
        if (params && params.column && params.column.colId === "start") {
          return moment(params.value).format(dFormat);
        } else {
          return params.value;
        }
      },
    });
  };
  render() {
    const {
      rowData,
      spinner,
      AccountState,
      ConfigurationState,
      handleSubmitPending,
    } = this.props;
    const account = AccountState.account;
    const languageData = account.SelectedlanguageData;
    const previleges = AccountState.previleges;
    const Previlege = previleges.find((obj) => {
      return obj.name == "Todo_List";
    });
    const editOption = Previlege.edit
      ? '<button type="button" class="btn btn-icon" title="Edit" data-action-type="Edit"><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit"></i></button>'
      : "";
    const dFormat =
      account.dateformate != "" ? account.dateformate : "MM-DD-YYYY";
    const template =
      '<div><button title="View" type="button" class="btn btn-icon" data-action-type="View" data-toggle="modal" data-target="#exampleTodoModal" ><i class="fa fa-eye" style="color: #1DC9B7 !important" data-action-type="View"></i></button>' +
      //  + editOption
      "</div>";
    if (rowData && rowData.length > 0) {
      rowData.forEach((object) => {
        object.actions = template;
        object.dFormat = dFormat;
      });
    }
    const limitOptions = [
      { value: "25", label: "25" },
      { value: "50", label: "50" },
      { value: "100", label: "100" },
    ];
    const listTodoStatusArray = ConfigurationState.listTodoStatus;
    var statusArray = [];
    var selectedStatus = "";
    listTodoStatusArray &&
      listTodoStatusArray
        .filter((item) => item.suboption != "todo")
        .map((temp) => {
          if (temp.status == true) {
            if (this.state.todostatus == temp.suboption) {
              selectedStatus = {
                label: `${temp.name}`,
                value: temp.suboption,
                name: "status",
              };
            }
            statusArray.push({
              label: `${temp.name}`,
              value: temp.suboption,
              name: "status",
            });
          }
        });
    return (
      <>
        <div className="row">
          {/* <div className="col-lg-2 col-md-2 col-sm-2 col-xl-3">
                        <label className="form-label">{languageData && languageData.LIMIT ? languageData.LIMIT : "Limit"}</label>
                        <div className="form-group">
                            <Select
                                onChange={(e) => this.gridApi.paginationSetPageSize(Number(e.value))}
                                options={limitOptions}
                                defaultValue={limitOptions[0]}
                                className='limit_size'
                            />
                        </div>
                    </div> */}
        </div>
        <AgGridComponent
          spinner={spinner}
          rowData={rowData}
          state={this.state}
          onRowClicked={(e) => this.onRowClicked(e)}
          onGridReady={(e) => this.onGridReady(e)}
          handleFilterChanged={(e) => this.handleFilterChanged(e)}
          overlay={true}
        />
      </>
    );
  }
}
export default PendingTodo;
