import axios from "axios";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import Select from "react-select";
import moment from "moment";
import Chart from "react-apexcharts";
import Spinner from "react-bootstrap/Spinner";
import ImportedURL from "../../common/api";
import { bindActionCreators } from "redux";
import { Error, Success } from "../../common/swal";
import { AC_LIST_GROUP } from "../../actions/groupAction";
import { AC_LIST_HOTEL } from "../../actions/hotelAction";
import { AC_ADD_TODO_APIS } from "../../actions/todolistAction";
//import { frequencyStatuses } from "../../../schemas/newtodolistitem";

class NewaddListtodo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hotel: "",
      group: "",
      category: "",
      frequency: "",
      title: "",
      Mandatory_Compliance: "",
      Active: "",
      todo: "",
      description: "",
      username: "",
      hotelName: "",
      spinner: "",
      categoryName: "",
      allOption: true,
      allcategoryOption: true,
      isFilterShow: false,
      // hotelOptions :null,
      groupoption: { label: "Select...", value: "", name: "group" },
      statusoption: { label: "Select...", value: "", name: "status" },
      priorityValue: { label: "Select...", value: "", name: "priority" },
      selectedOption: [{ label: "Select...", value: "", name: "hotel" }],
      categoryOption: [{ label: "Select...", value: "", name: "category" }],
      categoryState: [],
      listRedirect: false,
      complianceStatus: this.props.complianceStatus || {
        isMandatory: false,
      },
      dailyStatus: this.props.dailyStatus || {
        isMandatory: false,
      },
      weeklyStatus: this.props.weeklyStatus || {
        isMandatory: false,
      },
      monthlyStatus: this.props.monthlyStatus || {
        isMandatory: false,
      },
      quarterlyStatus: this.props.quarterlyStatus || {
        isMandatory: false,
      },
      halfyearlyStatus: this.props.halfyearlyStatus || {
        isMandatory: false,
      },
      yearlyStatus: this.props.yearlyStatus || {
        isMandatory: false,
      },
      AdhocStatus: this.props.AdhocStatus || {
        isMandatory: false,
      },
      activeStatus: this.props.activeStatus || {
        isMandatory: true,
      },
      errors: {},
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { AccountState } = this.props;
    const role = AccountState.role;
  }

  componentDidMount() {
    const { AccountState } = this.props;
    const account = AccountState.account;
    this.apiCall();
    this.setState({ hotelName: account.hotelName ? account.hotelName : "" });
    this.fetchCategory();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  apiCall = (query = {}) => {
    let formData = { parentslug: ["todo-duration"], ...query };
    this.props.AddTodoApis(formData);
    // this.props.HandleInputChange('endtype', 'enddate');
    // this.props.HandleInputChange('numberofoccurrence', 1);
    // this.props.HandleInputChange('numberofinterval', 1);
    // this.props.HandleInputChange('neverends', false);
  };

  handleHotelChange = (selectedOption) => {
    // Update the selected hotel option
    this.setState({
      selectedOption,
      errors: { ...this.state.errors, hotel: null }, // Clear the error for hotel
    });
  };

  setGroup = (selectedOption) => {
    this.setState({
      groupoption: selectedOption,
      errors: { ...this.state.errors, group: null }, // Clear group-related error
    });
  };

  handleCategoryChange = (selectedOption) => {
    this.setState({
      categoryOption: selectedOption,
      errors: { ...this.state.errors, category: null },
    });
  };
  handleDurationChange = (selectedOption) => {
    this.setState({
      durationOption: selectedOption,
      errors: { ...this.state.errors, frequency: null },
    });
  };

  setPriority = (e) => {
    const { name, value, label } = e; // Destructure the value and label from the selected option
    this.setState({
      priorityValue: e,
      errors: { ...this.state.errors, priority: null },
    });

    this.setState({
      totalStatus: true,
    });
  };

  fetchCategory = () => {
    const { account } = this.props.AccountState;
    const languageData = account.SelectedlanguageData;

    // Make the API request with GET method
    axios
      .get(ImportedURL.API.listCategory)
      .then((res) => {
        if (res.data) {
          this.setState({ categoryState: res.data });
        }
      })
      .catch((error) => {
        const { response } = error;
        const status = response?.status;
        const statusText = response?.statusText ?? "An error occurred";

        switch (status) {
          case 500:
            Error(
              `${status}: ${
                languageData?.INTERNAL_SERVER_ERROR ?? "Internal Server Error"
              }`
            );
            break;
          case 502:
            Error(`${status}: ${languageData?.BAD_GATEWAY ?? "Bad Gateway"}`);
            break;
          default:
            Error(`${status}: ${statusText}`);
            break;
        }
      });
  };

  setStatus = (e) => {
    const { name, value, label } = e; // Destructure the value and label from the selected option
    this.setState({ statusoption: e });

    this.setState({
      totalStatus: true,
    });

    this.setState({ [name + "option"]: e });
  };

  // Method to handle checkbox change
  handleComplianceChange = (event) => {
    const { checked } = event.target;
    this.setState(
      (prevState) => ({
        complianceStatus: {
          ...prevState.complianceStatus,
          isMandatory: checked, // Update compliance status based on checkbox state
        },
      }),
      () => {
        this.setState({
          errors: { ...this.state.errors, Mandatory_Compliance: null },
        });
      }
    );
  };
  clearFrequencyErrorIfAnySelected = () => {
    const {
      dailyStatus,
      weeklyStatus,
      monthlyStatus,
      quarterlyStatus,
      halfyearlyStatus,
      yearlyStatus,
      AdhocStatus,
    } = this.state;

    if (
      dailyStatus.isMandatory ||
      weeklyStatus.isMandatory ||
      monthlyStatus.isMandatory ||
      quarterlyStatus.isMandatory ||
      halfyearlyStatus.isMandatory ||
      yearlyStatus.isMandatory ||
      AdhocStatus.isMandatory
    ) {
      // Clear frequency error
      this.setState({
        errors: { ...this.state.errors, frequency: null },
      });
    }
  };

  handleDailyChange = (event) => {
    const { checked } = event.target;
    this.setState(
      (prevState) => ({
        dailyStatus: {
          ...prevState.dailyStatus,
          isMandatory: checked,
        },
      }),
      () => {
        this.clearFrequencyErrorIfAnySelected(); // Clear error if needed
      }
    );
  };
  handleWeeklyChange = (event) => {
    const { checked } = event.target;
    this.setState(
      (prevState) => ({
        weeklyStatus: {
          ...prevState.weeklyStatus,
          isMandatory: checked, // Update compliance status based on checkbox state
        },
      }),
      () => {
        this.clearFrequencyErrorIfAnySelected(); // Clear error if needed
      }
    );
  };
  handleMonthlyChange = (event) => {
    const { checked } = event.target;
    this.setState(
      (prevState) => ({
        monthlyStatus: {
          ...prevState.monthlyStatus,
          isMandatory: checked, // Update compliance status based on checkbox state
        },
      }),
      () => {
        this.clearFrequencyErrorIfAnySelected(); // Clear error if needed
      }
    );
  };
  handleQuarterlyChange = (event) => {
    const { checked } = event.target;
    this.setState(
      (prevState) => ({
        quarterlyStatus: {
          ...prevState.quarterlyStatus,
          isMandatory: checked, // Update compliance status based on checkbox state
        },
      }),
      () => {
        this.clearFrequencyErrorIfAnySelected(); // Clear error if needed
      }
    );
  };
  handleHalfYearlyChange = (event) => {
    const { checked } = event.target;
    this.setState(
      (prevState) => ({
        halfyearlyStatus: {
          ...prevState.halfyearlyStatus,
          isMandatory: checked, // Update compliance status based on checkbox state
        },
      }),
      () => {
        this.clearFrequencyErrorIfAnySelected(); // Clear error if needed
      }
    );
  };
  handleYearlyChange = (event) => {
    const { checked } = event.target;
    this.setState(
      (prevState) => ({
        yearlyStatus: {
          ...prevState.yearlyStatus,
          isMandatory: checked, // Update compliance status based on checkbox state
        },
      }),
      () => {
        this.clearFrequencyErrorIfAnySelected(); // Clear error if needed
      }
    );
  };
  handleAdhocChange = (event) => {
    const { checked } = event.target;
    this.setState(
      (prevState) => ({
        AdhocStatus: {
          ...prevState.AdhocStatus,
          isMandatory: checked, // Update compliance status based on checkbox state
        },
      }),
      () => {
        this.clearFrequencyErrorIfAnySelected(); // Clear error if needed
      }
    );
  };
  handleActiveStatus = (event) => {
    const { checked } = event.target;
    this.setState(
      (prevState) => ({
        activeStatus: {
          ...prevState.activeStatus,
          isMandatory: checked, // Update compliance status based on checkbox state
        },
      }),
      () => {}
    );
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      errors: { ...this.state.errors, [name]: null }, // Clear error for that field when user starts typing
    });
  };

  validateForm = () => {
    // const { selectedOption, categoryOption,groupoption, priorityValue, title, todo,complianceStatus, dailyStatus, weeklyStatus, monthlyStatus, quarterlyStatus, halfyearlyStatus, yearlyStatus } = this.state;
    const {
      selectedOption,
      categoryOption,
      groupoption,
      priorityValue,
      title,
      todo,
      durationOption,
    } = this.state;

    let errors = {};
    let formIsValid = true;
    // Validate required fields
    if (
      !groupoption ||
      typeof groupoption.value === "undefined" ||
      groupoption.value.trim() === ""
    ) {
      errors.group = "Group is required";
    }
    if (
      !selectedOption ||
      typeof selectedOption.value === "undefined" ||
      selectedOption.value.trim() === ""
    ) {
      errors.hotel = "Hotel is required";
    }
    if (
      !categoryOption ||
      typeof categoryOption.value === "undefined" ||
      categoryOption.value.trim() === ""
    ) {
      errors.category = "Category is required";
    }

    if (!priorityValue || priorityValue.value === "") {
      errors.priority = "Priority is required";
    }

    if (!title || title.trim() === "") {
      errors.title = "Title is required";
    }
    if (title.length > 50) {
      errors.title = "Title cannot exceed 50 characters.";
    }

    if (!todo || todo.trim() === "") {
      errors.todo = "Todo is required";
    }
    if (todo.length > 10) {
      errors.todo = "Todo cannot exceed 10 characters.";
    }
    // Validate at least one frequency is selected
    // const isFrequencySelected = dailyStatus.isMandatory || weeklyStatus.isMandatory || monthlyStatus.isMandatory || quarterlyStatus.isMandatory || halfyearlyStatus.isMandatory || yearlyStatus.isMandatory;
    //   if (!isFrequencySelected) {
    //   errors.frequency = "At least one frequency must be selected";
    // }
    if (
      !durationOption ||
      typeof durationOption.value === "undefined" ||
      durationOption.value.trim() === ""
    ) {
      errors.frequency = "Frequency is required";
    }
    //const isMandatoryCompliances = complianceStatus.isMandatory;
    // if(!isMandatoryCompliances)
    // {
    //   errors.Mandatory_Compliance = "Mandatory Compliance must be selected";
    // }

    this.setState({ errors });

    // If there are no errors, return true
    return Object.keys(errors).length === 0;
  };

  SubmitForm = (e) => {
    const languageData = this.props.AccountState.account.SelectedlanguageData;
    e.preventDefault();
    if (this.validateForm()) {
      // Proceed with form submission logic here

      let formData = {
        group: this.state.groupoption ? this.state.groupoption.value : "",
        hotel: this.state.selectedOption ? this.state.selectedOption.value : "",
        category: this.state.categoryOption
          ? this.state.categoryOption.value
          : "",
        priority: this.state.priorityValue
          ? this.state.priorityValue.value
          : "",
        title: this.state.title,
        todo: this.state.todo,
        description: this.state.description,
        mandatorycompliances: this.state.complianceStatus.isMandatory,
        activeStatus: this.state.activeStatus.isMandatory,
        //   frequencyStatuses: {
        //     daily: this.state.dailyStatus.isMandatory,
        //     weekly: this.state.weeklyStatus.isMandatory,
        //     monthly: this.state.monthlyStatus.isMandatory,
        //     quarterly: this.state.quarterlyStatus.isMandatory,
        //     halfyearly: this.state.halfyearlyStatus.isMandatory,
        //     yearly: this.state.yearlyStatus.isMandatory,
        //     Adhoc:this.state.AdhocStatus.isMandatory
        // }
        frequencyStatuses: this.state.durationOption.value
          ? this.state.durationOption.value
          : "",
      };

      this.setState({ saving: true });
      axios
        .post(ImportedURL.API.addNewTodolistitem, formData)
        .then((res) => {
          this.setState({ saving: false, listRedirect: true });
          Success(
            (languageData && languageData.TO_DO_ITEMS
              ? languageData.TO_DO_ITEMS
              : " Todo Items") +
              (languageData && languageData.CREATED_SUCCESSFULLY
                ? languageData.CREATED_SUCCESSFULLY
                : " Created successfully")
          );
        })
        .catch(({ response }) => {
          this.setState({ saving: false });
          this.ErrorResponse(response);
        });
    } else {
      console.log("Form validation failed, fix the errors.");
    }
  };
  ErrorResponse = (response) => {
    const languageData = this.props.AccountState.account.SelectedlanguageData;
    if (response) {
      if (response.status == 500) {
        Error(
          response.status +
            (languageData && languageData.INTERNAL_SERVER_ERROR
              ? languageData.INTERNAL_SERVER_ERROR
              : " Internal Server Error")
        );
      } else if (response.status == 502) {
        Error(
          response.status +
            (languageData && languageData.BAD_GATEWAY
              ? languageData.BAD_GATEWAY
              : " Bad Gateway")
        );
      } else if (response.status == 400) {
        Error(
          languageData && languageData.BAD_REQUEST
            ? languageData.BAD_REQUEST
            : "Bad request"
        );
      } else if (response.status == 406) {
        Error(
          languageData && languageData.TODO_LIST_CANNOT_EDIT
            ? languageData.TODO_LIST_CANNOT_EDIT
            : "You can't edit todos created by others"
        );
      } else {
        Error(response.data.message);
      }
    } else {
      Error("Bad request");
    }
  };
  handleReset = () => {
    // Reset input fields
    this.setState({
      title: "",
      todo: "",
      selectedOption: null, // Assuming you are using null for the initial value
      groupoption: null, // Similarly reset for group option
      activeStatus: {
        isMandatory: true,
      },

      description: "",

      priorityValue: null,
      complianceStatus: {
        isMandatory: false,
      },
      categoryOption: null,
      durationOption: null,
      dailyStatus: {
        isMandatory: false,
      },
      weeklyStatus: {
        isMandatory: false,
      },
      monthlyStatus: {
        isMandatory: false,
      },
      quarterlyStatus: {
        isMandatory: false,
      },
      halfyearlyStatus: {
        isMandatory: false,
      },
      yearlyStatus: {
        isMandatory: false,
      },
      AdhocStatus: {
        isMandatory: false,
      },
      errors: {}, // Reset errors
    });
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  render() {
    const { AccountState, LandingState, ConfigurationState } = this.props;
    //const { complianceStatus,dailyStatus,weeklyStatus,monthlyStatus,yearlyStatus,halfyearlyStatus,quarterlyStatus,AdhocStatus,activeStatus } = this.state;
    const { complianceStatus, activeStatus } = this.state;
    const listDurationConfigOptions =
      ConfigurationState.listDurationConfigOptions;
    //console.log("listDurationConfigOptions",listDurationConfigOptions);

    const listHotels =
      LandingState.landingdata &&
      LandingState.landingdata.data &&
      LandingState.landingdata.data.length > 1
        ? LandingState.landingdata.data[0]
        : [];

    if (this.state.listRedirect) {
      return <Redirect to="/todo-listitem" />; // Make sure the route is wrapped in quotes
    }

    const account = AccountState.account;
    var dtFormat =
      (account.dateformate != "" ? account.dateformate : "MM/DD/YYYY") +
      " " +
      (account.timeformat != "" ? account.timeformat : "hh:mm A");

    const priorityList = [
      { value: "", label: "Select..." },
      { value: "1", label: "High" },
      { value: "2", label: "Medium" },
      { value: "3", label: "Low" },
    ];

    // -----------------------------------------------------------------------------------------------

    let hotelOptions = [];

    if (listHotels.length > 0) {
      listHotels.forEach((item) => {
        if (this.state.groupoption && this.state.groupoption.value) {
          if (this.state.groupoption.value === item.group._id) {
            hotelOptions.push({
              label: `${item.name} ${item.city} ${item.state}`,
              value: item._id,
              logo: item.logo,
              name: "hotel",
            });
          }
        } else {
          hotelOptions.push({
            label: `${item.name} ${item.city} ${item.state}`,
            value: item._id,
            logo: item.logo,
            name: "hotel",
          });
        }
      });
    }

    var categoryOptions = [];
    const categoryList = this.state.categoryState;
    if (Array.isArray(categoryList) && categoryList.length > 0) {
      categoryList.forEach((item) => {
        if (item && !item.isdeleted && item.status) {
          const typeLabel = item.name || "Unknown"; // Use the name of the category or 'Unknown' if not defined
          const valueId = item._id || ""; // Use the _id as the value
          categoryOptions.push({
            label: typeLabel,
            value: valueId,
            name: "category",
          });
        }
      });
    }
    var durationOptions = [];
    const durationlist = listDurationConfigOptions;
    if (Array.isArray(durationlist) && durationlist.length > 0) {
      durationlist.forEach((item) => {
        if (item && !item.isdeleted && item.status) {
          const typeLabel = item.name || "Unknown"; // Use the name of the category or 'Unknown' if not defined
          const valueId = item._id || ""; // Use the _id as the value
          durationOptions.push({
            label: typeLabel,
            value: valueId,
            name: "duration",
          });
        }
      });
    }

    // Add the "All" option if needed
    // if (hotelOptions.length > 1) {
    //   hotelOptions.unshift({ label: "All", value: "", name: "hotel" });
    // }

    const listGroup =
      LandingState.landingdata &&
      LandingState.landingdata.data &&
      LandingState.landingdata.data.length > 1
        ? LandingState.landingdata.data[3]
        : [];
    const groupOptions = [];
    if (listGroup.length > 0) {
      listGroup &&
        listGroup
          ?.filter((filterItem) => filterItem.status === true)
          .map((item) => {
            groupOptions.push({
              label: item.name,
              value: item._id,
              name: "group",
            });
          });
    }
    // const { percentage } = this.props; // Assuming percentage is passed as a prop

    const languageData = AccountState.account.SelectedlanguageData;
    const title = this.state.hotelName
      ? this.state.hotelName != "All"
        ? this.state.hotelName + " - Overall Maintenance Reports"
        : "Overall Maintenance Reports"
      : "Overall Maintenance Reports";
    return (
      <>
        <div>
          <div className="breadcrump">
            <p>
              {" "}
              <Link to="/">
                <h6>
                  {languageData && languageData.DASHBOARD
                    ? languageData.DASHBOARD
                    : "Dashboard"}
                </h6>
              </Link>{" "}
              <span>
                <i className="fa fa-angle-right" aria-hidden="true"></i>{" "}
              </span>{" "}
              <Link to="/add-todo-listitem">
                <h6 className="highlights_breadcrump">
                  {" "}
                  {languageData && languageData.ADD_TO_DO_ITEMS
                    ? languageData.ADD_TO_DO_ITEMS
                    : "Add to do Items"}
                </h6>
              </Link>
            </p>
            {/* <Link to={'/todo-listitem'}><button type="button" className="btn btn-primary" id='Add'><i className="fa fa-arrow-left mr-2"></i>Back</button></Link> */}
            {/* <div className="header-action" style={{ marginTop: '5px' }}>
        <Link to={'/todo-listitem'}><button type="button" className="btn btn-primary" id='Add'><i className="fa fa-arrow-left mr-2"></i>Back</button></Link>
    </div> */}
          </div>
          {/* {this.state.showTicket ? */}
          <div className="section-body">
            <div className="container-fluid">
              <div className="tab-content">
                <div
                  className="tab-pane fade show active"
                  id="Departments-list"
                  role="tabpanel"
                >
                  <div className="card">
                    <div
                      className="card-header res_tit_tick"
                      style={{ height: "65px" }}
                    >
                      <div className="d-flex">
                        <div className="rounded_icon">
                          <i className="fa fa-ticket mr-2 "></i>
                        </div>
                        <div className="d-flex align-items-center">
                          <h3 className="card-title">
                            {" "}
                            {languageData && languageData.ADD_TO_DO_ITEMS
                              ? languageData.ADD_TO_DO_ITEMS
                              : "Add to do Items"}
                          </h3>
                        </div>
                      </div>
                      <div className="card-options">
                        <Link to={"/todo-listitem"}>
                          <button
                            type="button"
                            className="btn btn-primary"
                            id="Add"
                          >
                            <i className="fa fa-arrow-left mr-2"></i>Back
                          </button>
                        </Link>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        {account.allhotels && account.allhotels?.length > 0 ? (
                          ""
                        ) : (
                          <>
                            <div className="col-md-3 col-sm-6">
                              <label className="form-label">
                                {languageData && languageData.GROUP
                                  ? languageData.GROUP
                                  : "Group"}
                              </label>
                              <div className="form-group">
                                <Select
                                  value={
                                    this.state.groupoption
                                      ? { label: this.state.groupoption.label }
                                      : ""
                                  }
                                  onChange={this.setGroup}
                                  options={groupOptions}
                                />
                              </div>
                              {this.state.errors.group && (
                                <div style={{ color: "red", marginTop: "5px" }}>
                                  {this.state.errors.group}
                                </div>
                              )}
                            </div>
                            <div className="col-md-3 col-sm-6  ">
                              <label className="form-label">
                                {languageData && languageData.HOTEL
                                  ? languageData.HOTEL
                                  : "Hotel"}
                                <span className="ml-1" style={{ color: "red" }}>
                                  *
                                </span>
                              </label>
                              <div
                                className="form-group"
                                style={{ marginBottom: "10px" }}
                              >
                                <Select
                                  value={this.state.selectedOption}
                                  onChange={this.handleHotelChange}
                                  options={hotelOptions}
                                />
                                {this.state.errors.hotel && (
                                  <div
                                    style={{ color: "red", marginTop: "5px" }}
                                  >
                                    {this.state.errors.hotel}
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                        <div className="col-md-3 col-sm-6 ">
                          <label className="form-label">
                            {languageData && languageData.ASSIGN_CATEGORY
                              ? languageData.ASSIGN_CATEGORY
                              : "Assign Users Category"}
                            <span className="ml-1" style={{ color: "red" }}>
                              *
                            </span>
                          </label>
                          <div
                            className="form-group"
                            style={{ marginBottom: "10px" }}
                          >
                            <Select
                              // isMulti={true}
                              value={this.state.categoryOption}
                              onChange={this.handleCategoryChange}
                              options={categoryOptions}
                            />
                          </div>
                          {this.state.errors.category && (
                            <div style={{ color: "red", marginTop: "5px" }}>
                              {this.state.errors.category}
                            </div>
                          )}
                        </div>

                        <div className="col-md-3 col-sm-6">
                          <label className="form-label">
                            Todo - Shortcode
                            <span className="ml-1" style={{ color: "red" }}>
                              *
                            </span>
                          </label>
                          <div className="form-group">
                            <input
                              type="text"
                              ref={this.todoInputRef}
                              className="form-control"
                              value={this.state.todo}
                              placeholder="Todo"
                              name="todo"
                              onChange={this.handleChange}
                            />
                          </div>
                          {this.state.errors.todo && (
                            <div style={{ color: "red", marginTop: "5px" }}>
                              {this.state.errors.todo}
                            </div>
                          )}
                        </div>
                        <div className="col-md-3 col-sm-6">
                          <label className="form-label">
                            Title
                            <span className="ml-1" style={{ color: "red" }}>
                              *
                            </span>
                          </label>
                          <div className="form-group">
                            <input
                              type="text"
                              ref={this.titleInputRef}
                              className="form-control"
                              value={this.state.title}
                              placeholder="Title"
                              name="title"
                              onChange={this.handleChange}
                            />
                          </div>
                          {this.state.errors.title && (
                            <div style={{ color: "red", marginTop: "5px" }}>
                              {this.state.errors.title}
                            </div>
                          )}
                        </div>

                        <div className="col-md-3 col-sm-6">
                          <div
                            className="form-group"
                            style={{ position: "relative" }}
                          >
                            <label className="form-label">Description</label>
                            <textarea
                              className="form-control w-100"
                              name="description"
                              rows="2"
                              cols="50"
                              value={this.state.description} // Bind the value to state
                              onChange={this.handleChange} // Handle changes
                              placeholder={"Description"}
                            />
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-6 ">
                          <label className="form-label">
                            {languageData && languageData.Priority
                              ? languageData.Priority
                              : "Priority"}
                            <span className="ml-1" style={{ color: "red" }}>
                              *
                            </span>
                          </label>
                          <div
                            className="form-group"
                            style={{ marginBottom: "10px" }}
                          >
                            <Select
                              value={this.state.priorityValue}
                              onChange={this.setPriority}
                              options={priorityList}
                            />
                          </div>
                          {this.state.errors.priority && (
                            <div style={{ color: "red", marginTop: "5px" }}>
                              {this.state.errors.priority}
                            </div>
                          )}
                        </div>
                        <div className="col-md-3 col-sm-6 ">
                          <label className="form-label">
                            {languageData && languageData.ASSIGN_Duration
                              ? languageData.ASSIGN_DURATION
                              : "Assign Frequency"}
                            <span className="ml-1" style={{ color: "red" }}>
                              *
                            </span>
                          </label>
                          <div
                            className="form-group"
                            style={{ marginBottom: "10px" }}
                          >
                            <Select
                              // isMulti={true}
                              value={this.state.durationOption}
                              onChange={this.handleDurationChange}
                              options={durationOptions}
                            />
                          </div>
                          {this.state.errors.frequency && (
                            <div style={{ color: "red", marginTop: "5px" }}>
                              {this.state.errors.frequency}
                            </div>
                          )}
                        </div>
                        <div className="col-md-4 col-sm-6">
                          <div
                            className="form-group"
                            style={{ marginBottom: "10px" }}
                          >
                            <label
                              className="custom-switch"
                              data-toggle="modal"
                              data-target="#exampleModal"
                            >
                              <input
                                type="checkbox"
                                checked={complianceStatus.isMandatory}
                                name="custom-switch-checkbox"
                                className="custom-switch-input"
                                onChange={this.handleComplianceChange}
                                style={{ cursor: "pointer" }}
                              />
                              <span className="custom-switch-indicator"></span>
                              <span
                                className="custom-switch-description"
                                style={{ cursor: "pointer" }}
                              >
                                {complianceStatus.isMandatory
                                  ? "Mandatory"
                                  : "Non Mandatory"}
                              </span>
                            </label>
                          </div>
                          {this.state.errors.Mandatory_Compliance && (
                            <div style={{ color: "red", marginTop: "5px" }}>
                              {this.state.errors.Mandatory_Compliance}
                            </div>
                          )}
                        </div>
                        {/* <label className="form-label">
                        {languageData && languageData.Status ? languageData.Frequency : "Frequency"}
                        <span className="ml-1" style={{ color: 'red' }}>*</span></label>
<div className="accordion" id="accordionExample">
   <div className="accordion-item">
      <h2 className="accordion-header" id="headingOne">
        
        
      </h2>
      <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
         <div className="accordion-body">
            <div className="row">
               <label className="form-label">
               </label>
               <div className="col-md-12 col-sm-12">
                  <div className="row">
                     <div className="col-md-3 col-sm-3">
                        <label className="form-label">
                        {languageData && languageData.Status ? languageData.Daily : "Daily"}
                        </label>
                        <div className="form-group" style={{ marginBottom: "10px" }}>
                        <label className="custom-switch" data-toggle="modal" data-target="#exampleModal">
                        <input
                        type="checkbox"
                        checked={dailyStatus.isMandatory}
                        name="custom-switch-checkbox"
                        className="custom-switch-input"
                        onChange={this.handleDailyChange}
                        style={{ cursor: 'pointer' }}
                        />
                        <span className="custom-switch-indicator"></span>
                        <span className="custom-switch-description" style={{ cursor: 'pointer' }}>
                        {dailyStatus.isMandatory ? "Enable" : "Disable"}
                        </span>
                        </label>
                     </div>
                  </div>
                  <div className="col-md-3 col-sm-3">
                     <label className="form-label">
                     {languageData && languageData.Status ? languageData.Weekly : "Weekly"}
                     </label>
                     <div className="form-group" style={{ marginBottom: "10px" }}>
                     <label className="custom-switch" data-toggle="modal" data-target="#exampleModal">
                     <input
                     type="checkbox"
                     checked={weeklyStatus.isMandatory}
                     name="custom-switch-checkbox"
                     className="custom-switch-input"
                     onChange={this.handleWeeklyChange}
                     style={{ cursor: 'pointer' }}
                     />
                     <span className="custom-switch-indicator"></span>
                     <span className="custom-switch-description" style={{ cursor: 'pointer' }}>
                     {weeklyStatus.isMandatory ? "Enable" : "Disable"}
                     </span>
                     </label>
                  </div>
               </div>
               <div className="col-md-3 col-sm-3">
                  <label className="form-label">
                  {languageData && languageData.Status ? languageData.Monthly : "Monthly"}
                  </label>
                  <div className="form-group" style={{ marginBottom: "10px" }}>
                  <label className="custom-switch" data-toggle="modal" data-target="#exampleModal">
                  <input
                  type="checkbox"
                  checked={monthlyStatus.isMandatory}
                  name="custom-switch-checkbox"
                  className="custom-switch-input"
                  onChange={this.handleMonthlyChange}
                  style={{ cursor: 'pointer' }}
                  />
                  <span className="custom-switch-indicator"></span>
                  <span className="custom-switch-description" style={{ cursor: 'pointer' }}>
                  {monthlyStatus.isMandatory ? "Enable" : "Disable"}
                  </span>
                  </label>
               </div>
            </div>
            <div className="col-md-3 col-sm-3">
               <label className="form-label">
               {languageData && languageData.Status ? languageData.Quarterly : "Quarterly"}
               </label>
               <div className="form-group" style={{ marginBottom: "10px" }}>
               <label className="custom-switch" data-toggle="modal" data-target="#exampleModal">
               <input
               type="checkbox"
               checked={quarterlyStatus.isMandatory}
               name="custom-switch-checkbox"
               className="custom-switch-input"
               onChange={this.handleQuarterlyChange}
               style={{ cursor: 'pointer' }}
               />
               <span className="custom-switch-indicator"></span>
               <span className="custom-switch-description" style={{ cursor: 'pointer' }}>
               {quarterlyStatus.isMandatory ? "Enable" : "Disable"}
               </span>
               </label>
            </div>
         </div>
         <div className="col-md-3 col-sm-3">
            <label className="form-label">
            {languageData && languageData.Status ? languageData.Half : "Half-yearly"}
            </label>
            <div className="form-group" style={{ marginBottom: "10px" }}>
            <label className="custom-switch" data-toggle="modal" data-target="#exampleModal">
            <input
            type="checkbox"
            checked={halfyearlyStatus.isMandatory}
            name="custom-switch-checkbox"
            className="custom-switch-input"
            onChange={this.handleHalfYearlyChange}
            style={{ cursor: 'pointer' }}
            />
            <span className="custom-switch-indicator"></span>
            <span className="custom-switch-description" style={{ cursor: 'pointer' }}>
            {halfyearlyStatus.isMandatory ? "Enable" : "Disable"}
            </span>
            </label>
         </div>
      </div>
      <div className="col-md-3 col-sm-3">
         <label className="form-label">
         {languageData && languageData.Status ? languageData.Yearly : "Yearly"}
         </label>
         <div className="form-group" style={{ marginBottom: "10px" }}>
         <label className="custom-switch" data-toggle="modal" data-target="#exampleModal">
         <input
         type="checkbox"
         checked={yearlyStatus.isMandatory}
         name="custom-switch-checkbox"
         className="custom-switch-input"
         onChange={this.handleYearlyChange}
         style={{ cursor: 'pointer' }}
         />
         <span className="custom-switch-indicator"></span>
         <span className="custom-switch-description" style={{ cursor: 'pointer' }}>
         {yearlyStatus.isMandatory ? "Enable" : "Disable"}
         </span>
         </label>
      </div>
   </div>
   <div className="col-md-3 col-sm-3">
      <label className="form-label">
      {languageData && languageData.Status ? languageData.Adhoc : "Adhoc"}
      </label>
      <div className="form-group" style={{ marginBottom: "10px" }}>
      <label className="custom-switch" data-toggle="modal" data-target="#exampleModal">
      <input
      type="checkbox"
      checked={AdhocStatus.isMandatory}
      name="custom-switch-checkbox"
      className="custom-switch-input"
      onChange={this.handleAdhocChange}
      style={{ cursor: 'pointer' }}
      />
      <span className="custom-switch-indicator"></span>
      <span className="custom-switch-description" style={{ cursor: 'pointer' }}>
      {AdhocStatus.isMandatory ? "Enable" : "Disable"}
      </span>
      </label>
   </div>
</div>
</div>
{this.state.errors.frequency && (
<div style={{ color: "red", marginTop: "5px" }}>{this.state.errors.frequency}</div>
)}
</div>
</div>
</div>
</div>
</div>
</div> */}
                        <div className="col-md-4 col-sm-6">
                          <label className="form-label">
                            {/* {languageData && languageData.Status ? languageData.Active : "Active"} */}
                          </label>
                          <div
                            className="form-group"
                            style={{ marginBottom: "10px" }}
                          >
                            <label
                              className="custom-switch"
                              data-toggle="modal"
                              data-target="#exampleModal"
                            >
                              <input
                                type="checkbox"
                                checked={activeStatus.isMandatory}
                                name="custom-switch-checkbox"
                                className="custom-switch-input"
                                onChange={this.handleActiveStatus}
                                style={{ cursor: "pointer" }}
                              />
                              <span className="custom-switch-indicator"></span>
                              <span
                                className="custom-switch-description"
                                style={{ cursor: "pointer" }}
                              >
                                {activeStatus.isMandatory
                                  ? "Active"
                                  : "InActive"}
                              </span>
                            </label>
                          </div>
                          {this.state.errors.Active && (
                            <div style={{ color: "red", marginTop: "5px" }}>
                              {this.state.errors.Active}
                            </div>
                          )}
                        </div>
                        <div></div>
                      </div>
                    </div>
                    <div className="card-footer text-right mandatory">
                      <label className="form-label text-left mandatory-label">
                        <span className="mr-1" style={{ color: "red" }}>
                          *
                        </span>
                        Mandatory Fields{" "}
                      </label>
                      <div className="text-right">
                        <button
                          type="button"
                          className="btn commor_save"
                          onClick={this.handleReset}
                          style={{ marginRight: "10px" }}
                        >
                          Reset
                        </button>
                        <button
                          type="button"
                          className="btn commor_save"
                          onClick={this.SubmitForm}
                          style={{ marginRight: "10px" }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="overlay"
                style={{
                  display: this.state.spinner ? "block" : "none",
                }}
              ></div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  AccountState: state.account,
  LandingState: state.landing,
  ConfigurationState: state.configuration,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ListGroup: AC_LIST_GROUP,
      ListHotel: AC_LIST_HOTEL,
      AddTodoApis: AC_ADD_TODO_APIS,
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(NewaddListtodo);
