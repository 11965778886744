const CONSTANTS = {};
let number = 1;
CONSTANTS.previleges = [
  { name: "Hotel", view: false, number: number++, sidebarname: "Hotels" },
  {
    name: "User",
    view: false,
    add: false,
    edit: false,
    invite: false,
    number: number++,
    sidebarname: "Users",
  },

  {
    name: "Ticket",
    view: false,
    number: number++,
    sidebarname: "Tickets List",
    mobile: true,
  },
  {
    name: "Ticket_Report",
    view: false,
    number: number++,
    sidebarname: "User Reports",
  },
  {
    name: "Create_Ticket_Report",
    view: false,
    number: number++,
    sidebarname: "Create Ticket Reports",
  },
  {
    name: "Create_Close_Ticket_Report",
    view: false,
    number: number++,
    sidebarname: "Create And Closed Ticket Reports",
  },
  {
    name: "Ageing_Report",
    view: false,
    number: number++,
    sidebarname: "Ageing Reports",
  },

  {
    name: "All_Reports",
    view: false,
    number: number++,
    sidebarname: "Ticket Reports, Hotel Reports",
  },
  {
    name: "Incident_Ticket",
    view: false,
    number: number++,
    sidebarname: "Incident Tickets",
    mobile: true,
  },
  {
    name: "Create_Incident_Ticket_Report",
    view: false,
    number: number++,
    sidebarname: "Create Incident Ticket Reports",
  },
  {
    name: "Ticket_Analytics",
    view: false,
    number: number++,
    sidebarname: "Ticket Analytics",
  },
  {
    name: "Ticket_Report_Mobile",
    view: false,
    number: number++,
    sidebarname: "Ticket Report",
    mobile: true,
  },

  {
    name: "Feedback",
    view: false,
    number: number++,
    sidebarname: "Client Feedbacks",
    mobile: true,
  },
  {
    name: "Social_Review",
    view: false,
    number: number++,
    sidebarname: "Social Reviews",
    mobile: true,
  },
  {
    name: "Weekly_Report",
    view: false,
    number: number++,
    sidebarname: "Weekly Report",
    mobile: true,
  },

  {
    name: "Todo_List",
    view: false,
    add: false,
    edit: false,
    number: number++,
    sidebarname: "To-Do List",
    mobile: true,
  },
  {
    name: "Todo_Report",
    view: false,
    number: number++,
    sidebarname: "To-Do Report",
  },
  {
    name: "Ageing_Todo_Report",
    view: false,
    number: number++,
    sidebarname: "Ageing To-Do Report",
  },
  {
    name: "CreatedvsCompleted_Todo_Report",
    view: false,
    number: number++,
    sidebarname: "Created Vs Completed To-Do Report",
  },
  // { "name": "Todo_Tag", "view": false, "add": false, "edit": false, "number": number++, "sidebarname": "Tags" },
  // { "name": "Todo_My_List", "view": false, "add": false, "edit": false, "number": number++, "sidebarname": "My List" },
  //{ "name": "Todo_List_Item", "view": false, "add": false, "edit": false, "number": number++, "sidebarname": "Check List" },

  {
    name: "Notification",
    view: false,
    number: number++,
    sidebarname: "Notification",
    mobile: true,
  },

  {
    name: "Message_Template",
    view: false,
    add: false,
    edit: false,
    number: number++,
    sidebarname: "Message Template",
  },
  {
    name: "Problem_Keyword",
    view: false,
    add: false,
    edit: false,
    number: number++,
    sidebarname: "Problem Keyword",
  },
  {
    name: "Designation",
    view: false,
    add: false,
    edit: false,
    number: number++,
    sidebarname: "Designation",
  },
  {
    name: "Room_Amenity",
    view: false,
    add: false,
    edit: false,
    number: number++,
    sidebarname: "Room Amenity",
  },

  {
    name: "Category",
    view: false,
    add: false,
    edit: false,
    number: number++,
    sidebarname: "Categories",
  },
  {
    name: "Country",
    view: false,
    add: false,
    edit: false,
    number: number++,
    sidebarname: "Countries",
  },
  {
    name: "NewListTodoList",
    view: false,
    add: false,
    edit: false,
    number: number++,
    sidebarname: "Todo List Item",
  },
  //   {
  //     name: "NewaddListtodo",
  //     view: false,
  //     add: false,
  //     edit: false,
  //     number: number++,
  //     sidebarname: "Add Todo List Item",
  //   },
  {
    name: "Group",
    view: false,
    add: false,
    edit: false,
    number: number++,
    sidebarname: "Groups",
  },
  {
    name: "Currency",
    view: false,
    add: false,
    edit: false,
    number: number++,
    sidebarname: "Currencies",
  },
  {
    name: "Time_Zone",
    view: false,
    add: false,
    edit: false,
    number: number++,
    sidebarname: "Time Zone",
  },
  {
    name: "Booking_Status",
    view: false,
    add: false,
    edit: false,
    number: number++,
    sidebarname: "Booking Status",
  },
  {
    name: "List_Employee",
    view: false,
    add: false,
    edit: false,
    number: number++,
    sidebarname: "Employee List",
  },
  {
    name: "Phone_Number_Block_List",
    view: false,
    add: false,
    edit: false,
    number: number++,
    sidebarname: "Block List",
  },
  {
    name: "Configuration",
    view: false,
    add: false,
    edit: false,
    number: number++,
    sidebarname: "Configuration",
  },
  {
    name: "Language",
    view: false,
    add: false,
    edit: false,
    number: number++,
    sidebarname: "Language",
  },
  {
    name: "PMS_Privileges",
    view: false,
    add: false,
    edit: false,
    number: number++,
    sidebarname: "PMS Privileges",
  },

  {
    name: "Room_Type_And_Rooms",
    view: false,
    add: false,
    edit: false,
    number: number++,
    sidebarname: "Room Type And Rooms",
  },
];

CONSTANTS.allprevileges = [
  {
    name: "Hotel",
    view: true,
    add: true,
    edit: true,
    number: number++,
    sidebarname: "Hotels",
  },
  {
    name: "User",
    view: true,
    add: true,
    edit: true,
    invite: true,
    number: number++,
    sidebarname: "Users",
  },

  {
    name: "Ticket",
    view: true,
    number: number++,
    sidebarname: "Tickets List",
    mobile: true,
  },
  {
    name: "Ticket_Report",
    view: true,
    number: number++,
    sidebarname: "User Reports",
  },
  {
    name: "Create_Ticket_Report",
    view: true,
    number: number++,
    sidebarname: "Create Ticket Reports",
  },
  {
    name: "Create_Close_Ticket_Report",
    view: true,
    number: number++,
    sidebarname: "Create And Closed Ticket Reports",
  },
  {
    name: "Ageing_Report",
    view: true,
    number: number++,
    sidebarname: "Ageing Reports",
  },

  {
    name: "All_Reports",
    view: true,
    number: number++,
    sidebarname: "Ticket Reports, Hotel Reports",
  },
  {
    name: "Incident_Ticket",
    view: true,
    number: number++,
    sidebarname: "Incident Tickets",
  },
  {
    name: "Create_Incident_Ticket_Report",
    view: true,
    number: number++,
    sidebarname: "Create Incident Ticket Reports",
  },
  {
    name: "Ticket_Analytics",
    view: true,
    number: number++,
    sidebarname: "Ticket Analytics",
  },
  {
    name: "Ticket_Report_Mobile",
    view: true,
    number: number++,
    sidebarname: "Ticket Report",
    mobile: true,
  },

  {
    name: "Feedback",
    view: true,
    number: number++,
    sidebarname: "Client Feedbacks",
  },
  {
    name: "Social_Review",
    view: true,
    number: number++,
    sidebarname: "Social Reviews",
    mobile: true,
  },
  {
    name: "Weekly_Report",
    view: true,
    number: number++,
    sidebarname: "Weekly Report",
    mobile: true,
  },

  {
    name: "Todo_List",
    view: true,
    add: true,
    edit: true,
    number: number++,
    sidebarname: "To-Do List",
    mobile: true,
  },
  {
    name: "Todo_Report",
    view: true,
    number: number++,
    sidebarname: "To-Do Report",
  },
  {
    name: "Ageing_Todo_Report",
    view: true,
    number: number++,
    sidebarname: "Ageing To-Do Report",
  },
  {
    name: "CreatedvsCompleted_Todo_Report",
    view: true,
    number: number++,
    sidebarname: "Created Vs Completed To-Do Report",
  },
  //{ "name": "Todo_Tag", "view": true, "add": true, "edit": true, "number": number++, "sidebarname": "Tag" },
  //{ "name": "Todo_My_List", "view": true, "add": true, "edit": true, "number": number++, "sidebarname": "My List" },
  // { "name": "Todo_List_Item", "view": true, "add": true, "edit": true, "number": number++, "sidebarname": "Check List" },

  {
    name: "Notification",
    view: true,
    number: number++,
    sidebarname: "Notifications",
    mobile: true,
  },

  {
    name: "Message_Template",
    view: true,
    add: true,
    edit: true,
    number: number++,
    sidebarname: "Message Template",
  },
  {
    name: "Problem_Keyword",
    view: true,
    add: true,
    edit: true,
    number: number++,
    sidebarname: "Problem Keyword",
  },
  {
    name: "Designation",
    view: true,
    add: true,
    edit: true,
    number: number++,
    sidebarname: "Designation",
  },
  {
    name: "Room_Amenity",
    view: true,
    add: true,
    edit: true,
    number: number++,
    sidebarname: "Room Amenity",
  },

  {
    name: "Category",
    view: true,
    add: true,
    edit: true,
    number: number++,
    sidebarname: "Categories",
  },
  {
    name: "Country",
    view: true,
    add: true,
    edit: true,
    number: number++,
    sidebarname: "Countries",
  },
  {
    name: "NewListTodoList",
    view: true,
    add: true,
    edit: true,
    number: number++,
    sidebarname: "Todo List Item",
  },
  // { "name": "NewaddListtodo", "view": true, "add": true, "edit": true, "number": number++, "sidebarname": "Add Todo List Item" },
  {
    name: "Group",
    view: true,
    add: true,
    edit: true,
    number: number++,
    sidebarname: "Groups",
  },
  {
    name: "Currency",
    view: true,
    add: true,
    edit: true,
    number: number++,
    sidebarname: "Currencies",
  },
  {
    name: "Time_Zone",
    view: true,
    add: true,
    edit: true,
    number: number++,
    sidebarname: "Time Zone",
  },
  {
    name: "Vendor",
    view: true,
    add: true,
    edit: true,
    number: number++,
    sidebarname: "Vendor",
  },
  {
    name: "Booking_Status",
    view: true,
    add: true,
    edit: true,
    number: number++,
    sidebarname: "Booking Status",
  },
  {
    name: "List_Employee",
    view: true,
    add: true,
    edit: true,
    number: number++,
    sidebarname: "Employee List",
  },
  {
    name: "Phone_Number_Block_List",
    view: true,
    add: true,
    edit: true,
    number: number++,
    sidebarname: "Block List",
  },
  {
    name: "Configuration",
    view: true,
    add: true,
    edit: true,
    number: number++,
    sidebarname: "Configuration",
  },
  {
    name: "Language",
    view: true,
    add: true,
    edit: true,
    number: number++,
    sidebarname: "Language",
  },
  {
    name: "PMS_Privileges",
    view: true,
    add: true,
    edit: true,
    number: number++,
    sidebarname: "PMS Privileges",
  },

  {
    name: "Room_Type_And_Rooms",
    view: true,
    add: true,
    edit: true,
    number: number++,
    sidebarname: "Room Type And Rooms",
  },
];

// use this for setting previleges
CONSTANTS.livepurpose = [
  { name: "Hotel", view: true, add: true, edit: true, sidebarname: "Hotels" },
  {
    name: "User",
    view: true,
    add: true,
    edit: true,
    invite: true,
    sidebarname: "Users",
  },

  { name: "Ticket", view: true, sidebarname: "Tickets List", mobile: true },
  { name: "Ticket_Report", view: true, sidebarname: "User Reports" },
  {
    name: "Create_Ticket_Report",
    view: true,
    sidebarname: "Create Ticket Reports",
  },
  {
    name: "Create_Close_Ticket_Report",
    view: true,
    sidebarname: "Create And Closed Ticket Reports",
  },
  { name: "Ageing_Report", view: true, sidebarname: "Ageing Reports" },

  {
    name: "All_Reports",
    view: true,
    sidebarname: "Ticket Reports, Hotel Reports",
  },
  { name: "Incident_Ticket", view: true, sidebarname: "Incident Tickets" },
  {
    name: "Create_Incident_Ticket_Report",
    view: true,
    sidebarname: "Create Incident Ticket Reports",
  },
  { name: "Ticket_Analytics", view: true, sidebarname: "Ticket Analytics" },
  {
    name: "Ticket_Report_Mobile",
    view: true,
    sidebarname: "Ticket Report",
    mobile: true,
  },

  { name: "Feedback", view: true, sidebarname: "Client Feedbacks" },
  {
    name: "Social_Review",
    view: true,
    sidebarname: "Social Reviews",
    mobile: true,
  },
  {
    name: "Weekly_Report",
    view: true,
    sidebarname: "Weekly Report",
    mobile: true,
  },

  {
    name: "Todo_List",
    view: true,
    add: true,
    edit: true,
    sidebarname: "To-Do List",
    mobile: true,
  },
  { name: "Todo_Report", view: true, sidebarname: "To-Do Report" },
  {
    name: "Ageing_Todo_Report",
    view: true,
    sidebarname: "Ageing To-Do Report",
  },
  {
    name: "CreatedvsCompleted_Todo_Report",
    view: true,
    sidebarname: "Created Vs Completed_Todo_Report",
  },
  //{ "name": "Todo_Tag", "view": true, "add": true, "edit": true, "sidebarname": "Tag" },
  // { "name": "Todo_My_List", "view": true, "add": true, "edit": true, "sidebarname": "My List" },
  // { "name": "Todo_List_Item", "view": true, "add": true, "edit": true, "sidebarname": "Check List" },

  {
    name: "Notification",
    view: true,
    sidebarname: "Notifications",
    mobile: true,
  },

  {
    name: "Problem_Keyword",
    view: true,
    add: true,
    edit: true,
    sidebarname: "Problem Keyword",
  },
  {
    name: "Designation",
    view: true,
    add: true,
    edit: true,
    sidebarname: "Designation",
  },
  {
    name: "Room_Amenity",
    view: true,
    add: true,
    edit: true,
    sidebarname: "Room Amenity",
  },

  {
    name: "Category",
    view: true,
    add: true,
    edit: true,
    sidebarname: "Categories",
  },
  {
    name: "Country",
    view: true,
    add: true,
    edit: true,
    sidebarname: "Countries",
  },
  {
    name: "NewListTodoList",
    view: true,
    add: true,
    edit: true,
    sidebarname: "Todo List Item",
  },
  //   {
  //     name: "NewaddListtodo",
  //     view: true,
  //     add: true,
  //     edit: true,
  //     sidebarname: "Add Todo List Item",
  //   },
  { name: "Group", view: true, add: true, edit: true, sidebarname: "Groups" },
  {
    name: "Currency",
    view: true,
    add: true,
    edit: true,
    sidebarname: "Currencies",
  },
  {
    name: "Time_Zone",
    view: true,
    add: true,
    edit: true,
    sidebarname: "Time Zone",
  },
  { name: "Vendor", view: true, add: true, edit: true, sidebarname: "Vendor" },
  {
    name: "Booking_Status",
    view: true,
    add: true,
    edit: true,
    sidebarname: "Booking Status",
  },
  {
    name: "List_Employee",
    view: true,
    add: true,
    edit: true,
    sidebarname: "Employee List",
  },
  {
    name: "Phone_Number_Block_List",
    view: true,
    add: true,
    edit: true,
    sidebarname: "Block List",
  },
  {
    name: "Configuration",
    view: true,
    add: true,
    edit: true,
    sidebarname: "Configuration",
  },
  {
    name: "Language",
    view: true,
    add: true,
    edit: true,
    sidebarname: "Language",
  },
  {
    name: "PMS_Privileges",
    view: true,
    add: true,
    edit: true,
    sidebarname: "PMS Privileges",
  },

  {
    name: "Room_Type_And_Rooms",
    view: true,
    add: true,
    edit: true,
    sidebarname: "Room Type And Rooms",
  },
];

export default CONSTANTS;
